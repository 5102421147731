export default function convertToIndex(str) {
  const invalidChars = ["\\", "/", "*", "?", '"', "<", ">", "|", " ", ","];
  let result = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (invalidChars.includes(char)) {
      result += "-";
    } else {
      result += char.toLowerCase();
    }
  }

  return result;
}