import ptBRIntl from "antd/lib/locale/pt_BR";
import {ProCard, ProTable} from "@ant-design/pro-components";
import {Button, ConfigProvider, Input, Tooltip} from "antd";
import React, {useState} from "react";

export const MyReports = ({data,columns,setIsModalVisible}) => {

    const [search,setSearch]= useState("");

    const items = search.length > 0 ? data.filter(({name}) => name.startsWith(search)) : data;

    const handleAddClick = () => {
        setIsModalVisible(true);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    return (
        <ProCard>
            <ConfigProvider locale={ptBRIntl}>
                <div style={{marginBottom: 16}}>
                    <Input.Search
                        placeholder="Buscar por nome"
                        value={search}
                        onChange={handleSearchChange}
                        allowClear
                        style={{width: "100%"}}
                    />
                </div>
                <ProTable
                    search={false}
                    rowKey="_id"
                    type="table"
                    columnsState={{
                        persistenceKey: "clm-pro-table-search-results-my-reports",
                        persistenceType: "sessionStorage",
                    }}
                    columns={columns}
                    dataSource={items}
                    dateFormatter={"number"}
                    scroll={{x: true}}
                    style={{padding: 0, height: "100%", width: "100%"}}
                    toolbar={{
                        actions: [
                            <Button type="primary"
                                    onClick={handleAddClick}>
                                Adicionar
                            </Button>
                        ],
                    }}
                />
            </ConfigProvider>
        </ProCard>)
}
