import React, { useState } from 'react';
import { Modal, Button, Form, Input, Radio, message } from 'antd';
import {reportAPI} from "../../../services/actions/reports-v2";
import {connect} from "react-redux";

const NewReport = ({reportUser,isModalVisible, setIsModalVisible,getReports}) => {
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields(); // Limpa os campos ao fechar o modal
    };

    const handleOk = () => {
        form
            .validateFields()
            .then(async values => {
                try {
                    const temp = {};
                    if(values.access === "private"){
                        temp.collection_id = reportUser.personal_collection_id;
                    }else{
                        temp.collection_id = null;
                    }
                    const data = {
                        ...values,
                        ...temp
                    }

                    const response = await reportAPI.createReport(data);
                    message.success('Relatório criado com sucesso!');
                }catch (error) {
                    message.error(error.message);
                }finally {
                    setIsModalVisible(false);
                    form.resetFields();
                    getReports();
                }
            })
            .catch(info => {
                console.log('Validação falhou:', info);
            });
    };

    return (
        <>
            <Modal
                title="Criar Relatório"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Criar"
                cancelText="Cancelar"
                width={700}
                height={400}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        {...tailFormItemLayout}
                        label="Nome do Relatório:"
                        name="name"
                        rules={[{ required: true, message: 'Por favor, insira o nome do relatório!' }]}
                    >
                        <Input placeholder="Nome do relatório" />
                    </Form.Item>

                    <Form.Item
                        {...tailFormItemLayout}
                        label="Descrição:"
                        name="description"
                        rules={[{ required: true, message: 'Por favor, insira a descrição!' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Descrição do relatório" />
                    </Form.Item>

                    <Form.Item
                        {...tailFormItemLayout}
                        label="Tipo de Acesso:"
                        name="access"
                        rules={[{ required: true, message: 'Por favor, selecione o tipo de acesso!' }]}
                    >
                        <Radio.Group>
                            <Radio value="private">Privado</Radio>
                            <Radio value="shared">Compartilhado</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};


const mapStateToProps = ({ session,report }) => ({
    user: session.user,
    reportUser: report.user,
});

export default connect(mapStateToProps)(NewReport);
