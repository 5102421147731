import {actions_types} from "../../constants/actions";
import {triggers_type} from "../../constants/triggers";

export const getNameFromAction = (action) => {
    return action.name &&  action.name.length > 0  ? action.name : action.defaultName
}

export const getNameFromActionByType = (type) => {
    const action = actions_types.find(item => item.type === type);
    if (action) {
        return action.defaultName;
    }else{
        return type;
    }
}

export const getNameFromTriggerByType = (type) => {
    const trigger = triggers_type.find(item => item.type === type);
    if (trigger) {
        return trigger.name;
    }else{
        return type;
    }
}

export const getNameToSnippets = (step, action = null, js = false) => {
    if(step === -1){
        return "trigger";
    }
    if(!action.name || action.name === action.defaultName){
        if(js){
            return `steps['${step}']`
        }
        return `steps.${step}`
    }else{
        return action.name;
    }
}
