import getUrlClient from "services/getUrlClient";
import axios from 'axios';

const apiClient = axios.create({
  baseURL:`${getUrlClient()}/reports/metabase`,
});


export  const reportAPI = {
  getUserData : async () => apiClient.get("user/current"),

  getMyReports: async () => apiClient.get('/dashboard', { params: {f : "mine"} }),

  getSharedReports: async () => apiClient.get('/dashboard', { params: {f : "all"} }),

  getReportById: async (id) => apiClient.get(`/dashboard/${id}`),

  createReport: async (data) => apiClient.post('/dashboard', data),

  getEmbedReport: async (id) => axios.get(`${getUrlClient()}/reports/forms/${id}/embed/`),

  getEmbedDefaultReport: async () => axios.get(`${getUrlClient()}/reports/forms/default/embed/`),
};


