export function getFieldType(type) {
    if (type) {
        switch (type) {
            case "Text":
                return "Texto";
            case "Numeric":
                return "Númerico";
            case "Date":
                return "Data";
            case "Time":
                return "Tempo";
            case "uniqueSelection":
                return "Seleção única";
            case "multipleSelection":
                return "Múltipla seleção";
            case "refUniqueSelection":
                return "Seleção única referenciada";
            case "refMultipleSelection":
                return "Múltipla seleção referenciada";
            case "refLookup":
                return "Consulta";
            case "formula":
                return "Fórmula";
            default:
                return "Padrão";
        }
    }
}


