import getFieldValue from "./getFieldValue";

export default function buildTableData(data) {
  const results = [];

  data.forEach((doc) => {
    const resultObj = {}; // Objeto que armazena as chaves e valores

    for (const key in doc) {
      resultObj[key] = getFieldValue(doc[key]);
    }

    results.push(resultObj);
  });
  return results;
}
