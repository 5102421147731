import {Button, Col, Drawer, Input, Row, Select, Space, Typography} from "antd";
import React, {useState} from "react";
import {ProCard} from "@ant-design/pro-components";
import {CopyOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import IconTriggerModal from "../../../Snippets/IconTriggerModal";
import uuid from "react-uuid";

export const QueryBuilderDrawer = ({
                                       fields,
                                       handleChangeFilter,
                                       value = [],
                                       open,
                                       onClose,
                                       onFinish,
                                       fieldName,
                                       fieldLabel
                                   }) => {
    const {Option} = Select;

    const conditionOptions = [
        {label: 'Igual a', value: 'equals', needValue: true},
        {label: 'Diferente de', value: 'not_equals', needValue: true},
        {label: 'Maior ou igual a', value: 'more_than_or_equal_to', needValue: true},
        {label: 'Menor ou igual a', value: 'less_than_or_equal_to', needValue: true},
        {label: 'Está vazio', value: 'is_empty', needValue: false},
        {label: 'Não está vazio', value: 'is_not_empty', needValue: false},
        // {label: 'Está em', value: 'is_in', needValue: true},
    ];


    const closeDrawer = (e) => {
        onClose();
    }

    const onOkDrawer = () => {
        onFinish(value);
        onClose();
    }

    const duplicateQuery = (index) => {
        const newQueries = [...value.filters];
        newQueries.splice(index, 0, {...value.filters[index]});
        handleChangeFilter(
            {
                ...value,
                filters: newQueries
            }
        )
    };

    const renderQuery = () => {
        if (value && value.filters && value.filters.length > 0) {
            return value.filters.map((query, index) => {
                const notNeedValue = !!conditionOptions.find((item) => query.condition && item.value === query.condition && !item.needValue);
                return (
                    <Row gutter={16} key={uuid()} style={{marginBottom: 16}}>
                        <Col span={5}>
                            <Select
                                placeholder="Campo"
                                options={fields}
                                value={query.field}
                                onChange={(value, option) => updateQuery(index, 'field', option)}
                                style={{marginRight: 8, width: "100%"}}
                            />
                        </Col>
                        <Col span={5}>
                            <Select
                                placeholder="Condição"
                                value={query.condition}
                                onChange={value => updateQuery(index, 'condition', value)}
                                style={{marginRight: 8, width: "100%"}}
                            >
                                {conditionOptions.map(opt => (
                                    <Option key={opt.value} value={opt.value}>{opt.label}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <Select
                                disabled={notNeedValue}
                                placeholder="Tipo do Valor"
                                value={(notNeedValue) ? null : query.valueType}
                                onChange={value => updateQuery(index, 'valueType', value)}
                                style={{marginRight: 8, width: "100%"}}
                            >
                                <Option value={'value'}>Valor</Option>
                                <Option value={'binding'}>Anotação</Option>
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Input
                                disabled={!(query && query.valueType) || notNeedValue}
                                placeholder="Valor"
                                defaultValue={(notNeedValue) ? null : query.value}
                                onBlur={e => updateQuery(index, 'value', e.target.value)}
                                style={{marginRight: 8}}
                                addonAfter={(query.valueType && query.valueType === 'binding') && !notNeedValue ?
                                    <IconTriggerModal fieldName={`${fieldName}.filters.${index}.value`}
                                                      fieldLabel={fieldLabel}/> : null}
                            />
                        </Col>
                        <Col span={2}>
                            <Button icon={<CopyOutlined/>} type={'dashed'} onClick={() => duplicateQuery(index)}
                                    style={{width: '100%'}}>
                            </Button>
                        </Col>
                        <Col span={2}>
                            <Button icon={<MinusCircleOutlined/>} danger onClick={() => removeQuery(index)}
                                    style={{width: '100%'}}>
                            </Button>
                        </Col>
                    </Row>);
            });
        } else return null

    }

    const addQueryToQuerys = () => {
        handleChangeFilter(
            {
                ...value,
                filters:[
                    ...(value.filters || []),
                    {field: '', condition: '', value: ''}
                ],
            }
        )
    }

    const removeQuery = (index) => {
        if (value) {
            handleChangeFilter(
                {
                    ...value,
                    filters: value.filters.filter((_, i) => i !== index)
                }
            )
        }
    }

    const updateQuery = (index, key, updateValue) => {
        const updatedQueries = [...value.filters];
        updatedQueries[index][key] = updateValue;
        if(key ==='condition'){
            const condition = conditionOptions.find((item) => item.value === updateValue);
            if(condition && !condition.needValue){
                updatedQueries[index]['valueType'] = null;
                updatedQueries[index]['value'] = null;
            }

        }
        handleChangeFilter(
            {
                ...value,
                filters: updatedQueries
            }
        )
    }

    const onChangeBehavior = (valueChange) => {
        if (value) {
            handleChangeFilter(
                {
                    ...value,
                    behavior: valueChange,
                }
            )
        }
    }

    const onChangeWhenFilterIsEmpty = (valueChange) => {
        if (value) {
            handleChangeFilter(
                {
                    ...value,
                    whenFilterIsEmpty: valueChange,
                }
            )
        }
    }

    return (<Drawer
        size={"large"}
        placement={"bottom"}
        destroyOnClose
        title={"Configurações de Filtros"}
        onClose={closeDrawer}
        visible={open}
        bodyStyle={{
            paddingBottom: 80,
        }}
        extra={
            <Space>
                <Button onClick={onClose}>Cancelar</Button>
                <Button type="primary" onClick={onOkDrawer}>
                    Salvar
                </Button>
            </Space>
        }
    >
        <ProCard split={'vertical'}
                 bordered
                 style={{height: "100%", padding: "10px"}}
        >
            {
                value && value.filters && value.filters.length > 0 ? (
                    <div>
                        <Row gutter={16} style={{marginBottom: 16}}>
                            <Col span={12}>
                                <label>Comportamento</label>
                                <Select value={value && value.behavior ? value.behavior : null} style={{width: '100%'}} onChange={onChangeBehavior}>
                                    <Option value="matchAny">Corresponde a qualquer filtro</Option>
                                    <Option value="matchAll">Corresponder a todos os filtros</Option>
                                </Select>
                            </Col>
                            <Col span={12}>
                                <label>Quando o retorno do filtro estiver vazio</label>
                                <Select  value={value && value.whenFilterIsEmpty ? value.whenFilterIsEmpty : null} style={{width: '100%'}} onChange={onChangeWhenFilterIsEmpty}>
                                    <Option value="returnAll">Retorna todas as linhas do formulário</Option>
                                    <Option value="returnNone">Não retornar nenhuma linha do formulário</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Typography.Title level={3}>Filtros</Typography.Title>
                        {renderQuery()}
                    </div>
                ) : <p>Adicione sua primeira expressão de filtragem.</p>
            }
            <Button icon={<PlusCircleOutlined/>} onClick={addQueryToQuerys}>Adicionar filtro</Button>
        </ProCard>
    </Drawer>)
}
