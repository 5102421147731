import {openOrCloseModalSnippets} from "../../../../../../ducks/automation";
import {connect} from "react-redux";
import CodeMirror, {basicSetup} from "@uiw/react-codemirror";
import {materialLight} from "@uiw/codemirror-theme-material";
import {javascript} from "@codemirror/lang-javascript";
import {autocompletion} from "@codemirror/autocomplete";
import React, {useRef, useState} from "react";
import {ProCard} from "@ant-design/pro-components";
import {Collapse, Divider, Input, List, Tooltip} from "antd";
import {InfoCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {helpersDoc} from "../../Snippets/helpers";
import {getNameFromAction, getNameToSnippets} from "./functions";
import {BsFillPlayCircleFill} from "react-icons/bs";
import SnippetSections from "../../Snippets/SnippetSection";

const {Panel} = Collapse;

const CodeEditor = ({ onChange,value,onBlur}) =>  {

    const handleEditorChange = (content) => {
        if (onChange) {
            onChange(content);
        }
    };

    const [code, setCode] = useState(value);

    const editorRef = useRef();

    const insertSnippets = (snippet, action = null, step, isLoop = false) => {
        if (editorRef.current) {
            let snippetFinal = getNameToSnippets(step, action,true)
            const editor = editorRef.current.view;
            const insertPos = editor.state.selection.main.head;
            let functionNameWithParentheses = `${snippetFinal}.${snippet}`;
            if(isLoop){
                functionNameWithParentheses = snippet
            }

            const transaction = editor.state.update({
                changes: {from: insertPos, insert: functionNameWithParentheses},
            });
            editor.dispatch(transaction);

            let cursorPos = insertPos + functionNameWithParentheses.length;
            editor.dispatch({
                selection: {anchor: cursorPos, head: cursorPos},
            });
            editor.focus();
        }
    }

    return (
        <ProCard split={'vertical'}
                 bordered
                 style={{height: "100%"}}
        >
            <ProCard colSpan={12} title={"Edite o código:"}>
                <CodeMirror
                    style={{flexGrow: 1}}
                    height="500px"
                    width="100%"
                    ref={editorRef}
                    theme={materialLight}
                    value={code}
                    extensions={[
                        javascript(),
                        basicSetup(),
                        autocompletion(),
                    ]}
                    onChange={(value) => {
                        setCode(value);
                        handleEditorChange(value)

                    }}
                />
            </ProCard>
            <ProCard colSpan={12} title={"Anotações"} style={{height: "500px", width:"100%",overflowY: 'auto'}}>
              <SnippetSections  insertSnippets={insertSnippets} hideHelpers />
            </ProCard>

        </ProCard>
    )
}
const mapStateToProps = ({automation}) => ({
    actions: automation.actions,
    actionStep: automation.actionStep,
    trigger: automation.trigger,
    snippets: automation.snippets
});

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenOrCloseModalSnippets: ({action, fieldName, fieldLabel}) => dispatch(openOrCloseModalSnippets({
            action,
            fieldName,
            fieldLabel,
        })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeEditor)
