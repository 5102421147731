import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Layout,
  PageHeader,
  Row,
  Col,
} from "antd";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import BinByForm from "components/Forms/Records/Bin/Table";

function BinOfForm({ user }) {
  const { name } = useParams();
  return (
    <Layout>
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 53px 53px", height: "235vh" }}>
            <PageHeader
              style={{ margin: "16px 0px 5px", paddingLeft: 0 }}
              title="Registros arquivados"
              onBack={() => window.history.back()}
            />
            <Row>
              <Col span={24}>
                {user && (
                  <BinByForm
                    name={name}
                    useMatchAll={true}
                    useBin={true}
                    user={user}
                  />
                )}
              </Col>
            </Row>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps)(BinOfForm);
