import React from "react";
import {List, Tag, Typography} from "antd";
import _ from "lodash";

function filterTypeOfField(type) {
    switch (type) {
        case "Numeric":
            return "Numérico";
        case "Text":
            return "Texto";
        case "uniqueSelection":
            return "Seleção única";
        case "multipleSelection":
            return "Múltipla seleção";
        case "refMultipleSelection":
            return "Múltipla seleção referenciada";
        case "refUniqueSelection":
            return "Seleçāo única referenciada";
        case "refLookup":
            return "Consulta";
        case "formula":
            return "Fórmula";
        default:
            return "Padrão";
    }
}

export const ItemActivityForm = ({id, form, action, entity, payload}) => {
    let content = [];
    switch (`${entity}_${action}`) {
        case 'form_created':
        case 'form_updated':
            if (payload.hasOwnProperty('needConfirmation')) {
                content.push(<Typography.Text code>Confirmaçāo antes de
                    enviar: <strong>{payload.needConfirmation ? "SIM" : "NÃO"}</strong>
                </Typography.Text>)
            }
            if (payload.hasOwnProperty('categories')) {
                payload.categories.forEach(category => {
                    content.push(<Typography.Text
                        code> {category.action === 'push' ? "Adicionado à" : "Removido de"} categoria <strong>{category.categoryName}</strong></Typography.Text>)
                })
            }
            break;
        case 'form_deleted':
            content.push(<Typography.Text code> O formulário <strong>{form}</strong> foi removido</Typography.Text>)
            break
        case 'form_sorted':
            content.push(<Typography.Text code> O formulário <strong>{form}</strong> teve seus campos ordenados</Typography.Text>)
            payload.forEach(field => {
                content.push(<Typography.Text>O campo <strong>{field.field}</strong> do tipo <strong>{filterTypeOfField(field.fieldtype)}</strong> em <strong>{field.index+1}º</strong> posição</Typography.Text>)
            })
            break
        case 'field_created':
        case 'field_updated':
            content.push(<Typography.Text code>Nome do Campo: <strong>{payload.field}</strong></Typography.Text>)
            content.push(<Typography.Text code>Tipo de campo: <strong>{filterTypeOfField(payload.fieldtype)}</strong></Typography.Text>)
            if(payload.fieldtype === "formula"){
                content.push(<Typography.Text code>Fórmula: <strong>{payload.formula}</strong></Typography.Text>)
            }
            content.push(<Typography.Text code>Dica: <strong>{ payload.dica && !_.isEmpty(payload.dica) ? payload.dica: "N/A"}</strong></Typography.Text>)
            content.push(<Typography.Text code>Posição do Campo: <strong>{payload.index}</strong></Typography.Text>)
            content.push(<Typography.Text
                code>Etiqueta: <strong>{!!payload.marker ? "SIM" : "NÃO"}</strong></Typography.Text>)
            content.push(<Typography.Text
                code>Preenchimento: <strong>{!!payload.autoFill ? "SIM" : "NÃO"}</strong></Typography.Text>)
            content.push(<Typography.Text code>Campo
                obrigatório: <strong>{!!payload.requiredField ? "SIM" : "NÃO"}</strong></Typography.Text>)
            break
        case 'field_archived':
        case 'field_deleted':
            content.push(<Typography.Text code> O Campo <strong> {payload.field}</strong> do
                tipo <strong>{filterTypeOfField(payload.fieldtype)}</strong> foi {action === 'archived' ? 'arquivado' : 'deletado'}
            </Typography.Text>)
            break;
        case 'category_created':
        case 'category_updated':
            content.push(<Typography.Text code>Nome: <strong>{payload.categoryName}</strong></Typography.Text>)
            content.push(
                <Typography.Paragraph>
                    Cor:
                    <Tag color={payload.categoryColor.hex}>
                        {payload.categoryColor.hex.toUpperCase()}
                    </Tag>
                </Typography.Paragraph>
            )
            break;
        case 'category_deleted':
            content.push(<Typography.Text code> A categoria <strong> {payload.categoryName}</strong>  foi <strong>removido</strong>
            </Typography.Text>)
            break;
        default:
            break
    }
    return (
        <List key={id}>
            {content.map(item => <List.Item>{item}</List.Item>)}
        </List>
    )
}
