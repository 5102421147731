import {
    BellOutlined,
    BugOutlined,
    FieldTimeOutlined,
    MailOutlined,
    SearchOutlined,
    ShareAltOutlined
} from "@ant-design/icons";
import {BsFillGearFill, BsJournalBookmarkFill, BsJournalPlus, BsJournalX} from "react-icons/bs";
import React from "react";
import {InputNumber} from "antd";
import RichTextEditor from "../Actions/utils/RichTextEditor";
import IconTriggerModal from "../Snippets/IconTriggerModal";
import {SelectForm} from "../Actions/utils/SelectForm";
import QueryBuilder from "../Actions/utils/QueryBuilder/index";
import CodeEditor from "../Actions/utils/CodeEditor";
import {getPropsDynamic} from "./triggers";
import {RowAction} from "../Actions/utils/RowAction";
import {SelectorUser} from "../Actions/utils/SelectUser";
import {LoopActionInput} from "../Actions/utils/LoopActionInput";
import {SelectAutomation} from "../Actions/utils/SelectAutomation";

const iconStyle = {fontSize: '25px'};

export const actions_types = [
    {
        defaultName: "Enviar E-mail",
        type: "send_mail",
        icon: <MailOutlined style={iconStyle}/>,
        description: "Envie um e-mail para um ou mais destinatários especificados.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
                "dataIndex": "sendTo",
                "title": "Enviar Para",
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        "placeholder": "exemplo@dominio.com",
                        "addonAfter": <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>
                    }
                },
            },
            {
                "dataIndex": "subject",
                "title": "Assunto do E-mail",
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        "placeholder": "Assunto",
                        "addonAfter": <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>
                    }
                },
            },
            {
                "dataIndex": "htmlContents",
                "title": "Conteúdo HTML",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<RichTextEditor fieldName={dataIndex} fieldLabel={title}/>)
                },
            },

        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Criar Registro",
        type: "create_row",
        icon: <BsJournalPlus style={iconStyle}/>,
        description: "Crie um novo registro em um formulário.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
                "dataIndex": "fields",
                "title": "Campos",
                renderFormItem: (dom) => {
                    const {dataIndex, title} = dom;
                    return <RowAction type={'create'} fieldName={dataIndex} fieldLabel={title} />;
                },
                formItemProps: {
                    label: false,
                },
            },
        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Atualizar Registro",
        type: "update_row",
        icon: <BsJournalBookmarkFill style={iconStyle}/>,
        description: "Atualize um registro existente em um formulário.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
                "dataIndex": "fields",
                "title": "Campos",
                renderFormItem: (dom) => {
                    const {dataIndex, title} = dom;
                    return <RowAction type={'update'} fieldName={dataIndex} fieldLabel={title} />;
                },
                formItemProps: {
                    label: false,
                },
            },
        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Deletar Registro",
        type: "delete_row",
        icon: <BsJournalX style={iconStyle}/>,
        description: "Exclua um registro em um formulário.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
                "dataIndex": "formName",
                "title": "Formulário",
                renderFormItem: () => <SelectForm/>,
            },

            {
                "dataIndex": "executeRelatedTriggersOnThisAction",
                "title": "Acionar gatilhos atrelados a esse formulário quando essa ação for executada",
                "valueType": 'switch',
                "fieldProps": {
                    checkedChildren:"Sim",
                    unCheckedChildren:"Não",
                    defaultChecked:true
                },

            },
            {
                "dataIndex": "recordId",
                "title": "ID do Registro",
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        placeholder: "",
                        addonAfter: <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>
                    }
                }

            },
        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Condição",
        type: "condition",
        icon: <ShareAltOutlined style={iconStyle}/>,
        description: "Adicione uma condição para executar ações baseadas em critérios.",
        propsInput: [
            {
                "dataIndex": "referenceValue",
                "title": "Valor de referência",
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        placeholder: "",
                        addonAfter: <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>
                    }
                }
            },
            {
                "dataIndex": "condition",
                "title": "Condição",
                "valueType": 'select',
                valueEnum: {
                    "equals": "Igual",
                    "not_equals": "Não é igual",
                    "greater_than": "Maior que",
                    "less_than": 'Menor que',
                }
            },
            {
                "dataIndex": "comparisonValue",
                "title": "Valor de comparação",
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        placeholder: "",
                        addonAfter: <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>
                    }
                }
            }
        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Executar script js",
        type: "script",
        icon: <BugOutlined style={iconStyle}/>,
        description: "Execute um script JavaScript personalizado.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
            "dataIndex": "script",
            "title": "Código do Script",
            renderFormItem: (props) => {
                const {title, dataIndex} = props
                return (<CodeEditor fieldName={dataIndex} fieldLabel={title}/>)
            },
        }],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Espera",
        type: "delay",
        icon: <FieldTimeOutlined style={iconStyle}/>,
        description: "Adicione um atraso antes de executar a próxima ação.",
        propsInput: [
            {
                returnType: "numeric",
                dataIndex: "delay",
                title: "Atraso em milissegundos",
                valueType: "digit",
                renderFormItem: (dom) => {
                    const {dataIndex, title} = dom;
                    return (
                        <InputNumber
                            style={{width: '100%'}}
                            min={0}
                            max={60000}
                            placeholder=""
                            formatter={null}
                            keyboard={true}
                            addonAfter={<IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>}
                        />
                    );
                },
            },
        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Consulta",
        type: "query",
        icon: <SearchOutlined style={iconStyle}/>,
        description: "Faça uma consulta ao banco de dados.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
                dataIndex: "query",
                title: "Filtros",
                formItemProps: {
                    label: false,
                },
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return <QueryBuilder fieldName={dataIndex} fieldLabel={title}/>;
                },
            },

        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Iniciar uma automação",
        type: "start_automation",
        icon: <BsFillGearFill style={iconStyle}/>,
        description: "Inicie uma automação específica.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
                "dataIndex": "automation",
                "title": "Automação",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<SelectAutomation fieldName={dataIndex} fieldLabel={title}/>)
                },
            },
            {
                "dataIndex": "payload",
                "title": "Dados iniciais do Gatilho",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<CodeEditor fieldName={dataIndex} fieldLabel={title}/>)
                },
            },
            ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    },
    {
        defaultName: "Enviar uma Notificação",
        type: "send_notificaiton",
        icon: <BellOutlined  style={iconStyle}/>,
        description: "Envia uma notificação para web para um usuario.",
        propsInput: [
            {

                "dataIndex": "loopData",
                "title": "Configuração de Repetição",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<LoopActionInput fieldName={dataIndex} fieldLabel={title}/>)
                },
                formItemProps: {
                    label: false,
                },
            },
            {
                "dataIndex": "title",
                "title": "Titulo",
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        placeholder: "",
                        addonAfter: <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>,
                    }
                },
                "formItemProps":{
                    rules: [
                        {
                            required: true,
                        }
                    ]
                }
            },
            {
                "dataIndex": "type",
                "title": "Tipo de Notificação",
                "valueType": 'select',
                "valueEnum": {
                    "success": "Sucesso",
                    "error": "Erro",
                    "info": "Informativo",
                    "warning": 'Aviso',
                },
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        allowClear:false,
                        placeholder: "",
                        suffixIcon: <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>
                    }
                },
                "formItemProps":{
                    rules: [
                        {
                            required: true,
                        }
                    ]
                }
            },
            {
                "dataIndex": "content",
                "title": "Conteúdo",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<RichTextEditor fieldName={dataIndex} fieldLabel={title}/>)
                },
                "formItemProps":{
                    rules: [
                        {
                            required: true,
                        }
                    ]
                }
            },
            {
                "dataIndex": "action",
                "title": "Url de Ação",
                "fieldProps": (form, config) => {
                    const {dataIndex, title} = config;
                    return {
                        placeholder: "",
                        addonAfter: <IconTriggerModal fieldName={dataIndex} fieldLabel={title}/>
                    }
                },
                formItemProps:{
                    "help": <p>Insira a URL completa para a ação desejada. URLs que começam com <b>'http://'</b> ou <b>'https://'</b> serão tratadas como links externos e redirecionarão para o site especificado.<br/>
                        URLs que não incluem o protocolo (por exemplo,  <b>'/formularios/todos'</b>) serão interpretadas como rotas internas, acessando páginas de rota relativa dentro da aplicação.</p>
                }
            },

            {
                "dataIndex": "recipient",
                "title": "Enviar Para",
                renderFormItem: (props) => {
                    const {title, dataIndex} = props
                    return (<SelectorUser fieldName={dataIndex} fieldLabel={title}/>)
                },
                "formItemProps":{
                    rules: [
                        {
                            required: true,
                        }
                    ]
                }
            }


        ],
        propsOutput: [
            {
                "dataIndex": "success",
                "title": "Sucesso",
            }
        ]
    }
];

export const actionAcceptLoop = [
    "send_mail",
    "create_row",
    "update_row",
    "delete_row",
    "script",
    "query",
    "send_notificaiton"
]

export const getPropsFromAction = async (action) => {
    const inLoop =  actionAcceptLoop.includes(action.type) &&
        action.config &&
        action.config.loopData &&
        action.config.loopData.active;

    let defaultProps = [
        {
            field: "success",
            type: "Boleano",
            fieldtype: "Boleano",
        }
    ];

    if(inLoop){
        defaultProps = [
            ...defaultProps,
            {
                field: "loop.item",
                type: "Objeto",
                fieldtype: "Objeto",
                propFromLoop: true
            },
            {
                field: "loop.index",
                type: "Texto",
                fieldtype: "Texto",
                propFromLoop: true
            },
            {
                field: "loop.isLast",
                type: "Boleano",
                fieldtype: "Boleano",
                propFromLoop: true
            }
        ]
    }

    if (action) {
        switch (action.type) {
            case "create_row":
            case "update_row":
            case "delete_row":
                const propsRow = [
                    {
                        field: "id",
                        type: "Object",
                        fieldtype: "objetc",
                    }
                ]
                let dynamicallyPropsRow = [];
                if (action.config && action.config.formName && action.config.formName.id) {
                    dynamicallyPropsRow = await getPropsDynamic(action.config.formName.id)
                }
                return [
                    ...defaultProps,
                    ...propsRow,
                    ...dynamicallyPropsRow
                ];
            case "script":
                const propsScript = [
                    {
                        field: "value",
                        type: "Retorno do processamento do script",
                        fieldtype: "Retorno do processamento do script",
                    }
                ]
                return [
                    ...defaultProps,
                    ...propsScript
                ]
            case "send_mail":
                const propsEmail = [
                    {
                        field: "response",
                        type: "Objeto",
                        fieldtype: "Objeto",
                    }
                ]
                return [
                    ...defaultProps,
                    ...propsEmail,
                ];
            case "condition":
                const propsCondition = [
                    {
                        field: "condition",
                        type: "Boleano",
                        fieldtype: "Boleano",
                    }
                ]
                return [
                    ...defaultProps,
                    ...propsCondition,
                ];
            case "delay":
                const propsDelay = [
                    {
                        field: "delay",
                        type: "Numérico",
                        fieldtype: "Numérico",
                    }
                ]
                return [
                    ...defaultProps,
                    ...propsDelay,
                ];
            case "query":
                const propsQuery = [
                    {
                        field: "rows",
                        type: "Objeto",
                        fieldtype: "Objeto",
                    }
                ]
                return [
                    ...defaultProps,
                    ...propsQuery,
                ];
            case "start_automation":
            default:
                return defaultProps
        }
    }
    return defaultProps
}
