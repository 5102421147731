import {connect, useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Row, Col, Button } from "antd";

/**
 * Actions
 */
import { logoutUser } from "auth/actions/userActions";

/**
 * Components
 */
import { StyledContainer } from "components/Styles";
import {
  CompanyLogoContainer,
  CompanyLogoImage,
  AvatarHolder,
  AvatarHolderTitle,
  AvatarHolderSubTitle,
  Workspaces,
} from "./styles";
import Logo from "assets/images/logo.png";

const Welcome = ({ logoutUser, user }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <StyledContainer
      style={{
        flexDirection: "column",
      }}
    >
      <CompanyLogoContainer>
        <CompanyLogoImage image={Logo} />
      </CompanyLogoContainer>
      <Row
        style={{
          marginTop: 30,
        }}
      >
        <Col>
          <Card bordered={false}>
            <div>
              <AvatarHolder>
                {user && user.firstName && user.lastName && (
                  <AvatarHolderTitle>
                    Bem-vindo, {user.firstName.toLowerCase()}{" "}
                    {user.lastName.toLowerCase()}!
                  </AvatarHolderTitle>
                )}
                <AvatarHolderSubTitle>{user.email}</AvatarHolderSubTitle>
              </AvatarHolder>
              <Workspaces>
                <Row gutter={36}>
                  <Col lg={24}>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        history.push("/dashboard/resumo");
                      }}
                    >
                      Acessar painel
                    </Button>
                  </Col>
                  <Col
                    lg={24}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Button
                      danger
                      type="link"
                      block
                      onClick={() => logoutUser(history,dispatch)}
                    >
                      Encerrar sessão
                    </Button>
                  </Col>
                </Row>
              </Workspaces>
            </div>
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  );
};

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps, { logoutUser })(Welcome);
