import React, { useEffect, useState } from 'react';
import { Input, Select, Modal, Button } from 'antd';
import IconTriggerModal from "../../Snippets/IconTriggerModal";
import {getUsers} from "../../../../../../auth/actions/userActions";

export const SelectorUser = ({value, onChange, fieldName, fieldLabel }) => {
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState(value || '');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    const handleSelectChange = (selectedValue) => {
        const newTags =  selectedValue
        setSelectedTags(newTags);
        const newValue = newTags.join(',');
        setInputValue(newValue);
        onChange(newValue);
    };

    const showModal = () => {
        if ( inputValue && inputValue.split(',').every(val => options.includes(val))) {
            setSelectedTags(inputValue.split(','));
        } else {
            setSelectedTags([]);
        }
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        const fetchData =  async () =>{
            const data = await requestUsers(null,true);
            setOptions(data);
        }
        fetchData()
    }, []);

    const requestUsers = async (value) => {
        let data;
        if (value) {
            data = await getUsers(value, true);
        } else {
            data = await getUsers(null, true);
        }
        data = data.message.map((item) => ({
            label: item.firstName,
            value: item._id,
        }));
        return data;
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Input
                    style={{ width: "calc(100% - 140px)" }}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Digite ou selecione opções"
                />
                <Button onClick={showModal} style={{ margin: "0 5px" }}>
                    Selecionar opções
                </Button>
                <IconTriggerModal fieldLabel={fieldLabel} fieldName={fieldName} withButton />
            </div>
            <Modal
                title={'Selecionar usuários'}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={null}
                    mode={"multiple"}
                    style={{ width: "100%" }}
                    value={selectedTags}
                    onChange={handleSelectChange}
                    placeholder="Selecionar usuários"
                    options={options}

                >
                </Select>
            </Modal>
        </div>
    );
};
