import * as React from "react";
const SvgQuickStart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="m20 9.1-4.7 8.5c-.1.2-.3.5-.3.6-.2.3-.5.9-1.3.7-.1 0-.2-.1-.4-.2l-.1-.1c-.1-.1-.4-.3-.7-.5l-1.5-1c-.1-.1-.2-.1-.3-.2-.1.4-.4.6-.5.7-1.4 1.4-1.7 1.7-2.3 1.5-.4-.1-.7-.4-.8-.9 0-.2-.3-1.4-.5-2.4-.1-.7 0-1.2.4-1.6 0 0 .1 0 .1-.1h-.2c-.3-.1-.6-.2-.9-.4L4.8 13c-.8-.6-.8-1.2-.8-1.5s.3-.9 1.2-1.1l13.2-3.3c.3-.1.6-.1.9-.1.4.1.7.4.9.7.1.3.2.8-.2 1.4zM7.7 15.8s.4 2.2.5 2.5c.1 0 1.4-1.3 1.4-1.3.4-.4.3-.5-.2-.9h-.1l-.3-.3-.6-.5c-.1-.1-.2-.1-.2-.1-.5-.2-.6.1-.5.6zM19 8h-.4L5.5 11.3c-.5.1-.6.5-.2.8l1.4.9c.2.1.3.2.6.2.4.1.9.1 1.2-.1l8.1-3.7-6.7 4.5c-.4.3-.5.6-.2.9l.3.2.1.1c.5.3 1.2.8 1.7 1.1l1.5 1c.5.3.8.6.8.6s.2-.4.5-.9l4.7-8.5c0-.1 0-.3-.3-.4z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#12b398",
      }}
      transform="translate(0 -.951)"
    />
    <path
      d="M12 25C5.4 25 0 19.6 0 13S5.4 1 12 1s12 5.4 12 12-5.4 12-12 12zm0-1c6.1 0 11-4.9 11-11S18.1 2 12 2 1 6.9 1 13s4.9 11 11 11z"
      style={{
        fill: "#12b398",
      }}
      transform="translate(0 -.951)"
    />
  </svg>
);
export default SvgQuickStart;
