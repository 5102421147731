import React, { useEffect, useState } from 'react';
import { Input, Select, Modal, Button } from 'antd';
import IconTriggerModal from "../../Snippets/IconTriggerModal";

export const SelectorInput = ({ options = [], mode = "uniqueSelection", value, onChange, fieldName, fieldLabel }) => {


    const [inputValue, setInputValue] = useState(value || '');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    const handleSelectChange = (selectedValue) => {
        const newTags = mode === "multipleSelection" ? selectedValue : [selectedValue];
        setSelectedTags(newTags);
        const newValue = newTags.join(',');
        setInputValue(newValue);
        onChange(newValue);
    };

    const showModal = () => {
        const optionsValues = options ? options.map(item => item.value) : [];
        if (optionsValues.includes(inputValue) || (mode === "multipleSelection" && (inputValue && inputValue.split(',').every(val => optionsValues.includes(val))))) {
            setSelectedTags(inputValue.split(','));
        } else {
            setSelectedTags([]);
        }
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Input
                    style={{ width: "calc(100% - 140px)" }}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Digite ou selecione opções"
                />
                <Button onClick={showModal} style={{ margin: "0 5px" }}>
                    Selecionar opções
                </Button>
                <IconTriggerModal fieldLabel={fieldLabel} fieldName={fieldName} withButton />
            </div>
            <Modal
                title={'Selecionar opções'}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <Select
                    mode={mode === "multipleSelection" ? "multiple" : undefined}
                    style={{ width: "100%" }}
                    value={selectedTags}
                    onChange={handleSelectChange}
                    placeholder="Selecionar opções"
                    options={options}
                >
                </Select>
            </Modal>
        </div>
    );
};
