import "./index.css";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {ProCard} from '@ant-design/pro-components';
import {
    Button,
    Modal,
    Timeline,
    Typography,
    Tooltip,
    Form,
    Input,
    Tabs,
    Select
} from 'antd';
import {DeleteOutlined, SaveOutlined, ExclamationCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {BsFillPlayCircleFill} from "react-icons/bs";
import React, {useState} from 'react';
import ModalAddAction from "./ModalAddAction";
import Trigger from "./Trigger";
import ActionRender from "./Actions";
import {EditTitleAction} from "./EditTitleAction";
import {connect} from "react-redux";
import {
    changeActionCurrentStep, deleteAutomation,
    removeActionFromAutomation,
    saveAutomationActionsAndTrigger,
    updateActionFromAutomation,
    updateBasicDataAutomation,
    updateNameActionFromAutomation
} from "../../../../ducks/automation";
import Snippets from "./Snippets";
import {getNameFromAction} from "./Actions/utils/functions";
import uuid from "react-uuid";
import {AutomationsLog} from "./Log";

const {confirm} = Modal;


const FlowBuilder = ({
                         automation,
                         onSaveAutomationActionsAndTrigger,
                         onChangeActionCurrentStep,
                         onRemoveActionFromAutomation,
                         onUpdateNameActionFromAutomation,
                         onUpdateBasicDataAutomation,
                         onUpdateActionFromAutomation,
                         tab,
                     }) => {

    const dispatch = useDispatch();
    const history = useHistory();


    const {actions, actionStep, loading} = automation;
    const [isOpenModalAddAction, setIsOpenModalAddAction] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const [formAction] = Form.useForm();
    const showModal = () => {
        setIsOpenModalAddAction(true);
    };

    const hideModal = () => {
        setIsOpenModalAddAction(false);
    };

    const removeActions = () => {
        confirm({
            title: "Deseja remover essa ação?",
            icon: <ExclamationCircleOutlined/>,
            okText: "Ok",
            cancelText: "Cancelar",
            content: "Toda configuração dessa ação será perdida!",
            onOk: () => onRemoveActionFromAutomation(actionStep)
        })
    }

    const handleRenderStep = () => {
        if (actionStep === -1) {
            return <Trigger/>
        } else {
            const action = actions[actionStep];
            return <ActionRender action={action} formAction={formAction}
                                 saveDataFromActionAfterChangeStep={saveDataFromActionAfterChangeStep}/>
        }
    }

    const getTitleStep = () => {
        if (actionStep === -1) {
            return "Editar Gatilho"
        } else {
            return getNameFromAction(actions[actionStep]);
        }
    }

    const getSubtitleStep = () => {
        if (actionStep === -1) {
            return "Defina as condições para que a automação seja iniciada!"
        } else {
            return actions[actionStep].description;
        }
    }

    const getExtraStep = () => {
        if (actionStep === -1) {
            return null
        } else {
            return <Button icon={<DeleteOutlined/>} onClick={removeActions}></Button>
        }
    }

    const timelineAddAction = (
        <Timeline.Item
            dot={
                <Tooltip placement="top" title={"Adicionar nova ação aqui!"}>
                    <PlusCircleOutlined/>
                </Tooltip>
            }
            style={{cursor: "pointer"}}
            onClick={showModal}
            key={uuid()}
        >
            &nbsp;
        </Timeline.Item>
    );

    const renderStepTimeline = () => {
        if (actions.length === 0) {
            return [
                // timelineAddAction
            ]
        }

        return actions.map((action, index) => {
            const color = (index === actionStep) ? "#12b398" : null;
            const className = (index === actionStep) ? "custom-timeline" : null
            const items = [];
            items.push(
                <Timeline.Item
                    dot={action.icon}
                    key={uuid()}
                    onClick={() => handlerChangeActionCurrentStep(index)}
                    style={{cursor: "pointer"}}
                    color={color}
                    className={className}
                >
                    <Typography.Text style={{color: color}}>
                        {
                            getNameFromAction(action)
                        }
                    </Typography.Text>
                </Timeline.Item>
            )
            if (index === actionStep) {
                items.push(timelineAddAction)
            }
            return items;

        })

    }

    const {automation: automationData} = automation

    const initialValues = automationData ? {
        name: automationData.name,
        description: automationData.description,
        active: automationData.active,
    } : {};

    const saveActions = () => {
        confirm({
            title: "Deseja salvar dados dessa automação?",
            icon: <SaveOutlined/>,
            content: "Toda configuração atual será salva!",
            okText: "Ok",
            cancelText: "Cancelar",
            onOk: () => {
                onSaveAutomationActionsAndTrigger()
            }
        })
    }

    const removeAutomation = () => {
        confirm({
            title: "Deseja excluir essa automação?",
            icon: <DeleteOutlined color={"red"}/>,
            content: "Toda os dados do gatilho,das ações e dos logs serão excluidos!",
            okText: "Ok",
            okType:"danger",
            cancelText: "Cancelar",
            onOk: () => {
                dispatch(deleteAutomation(automation.automation.id, history));
            }
        })
    }

    const renderBtnSaveActions = () => {
        return (
            <React.Fragment>
                <Button icon={<DeleteOutlined/>} onClick={removeAutomation} type={"danger"}>Excluir Automação</Button>
                &nbsp;
                &nbsp;
                <Button icon={<SaveOutlined/>} onClick={saveActions} type={"primary"}>Salvar Dados</Button>
            </React.Fragment>
        )
    }

    const saveDataFromActionAfterChangeStep = () => {
        if (actionStep > -1) {
            // const action = actions[actionStep];
            const formData = formAction.getFieldsValue();
            const data = formData
            onUpdateActionFromAutomation({step: actionStep, data})
        }
    }

    const handlerChangeActionCurrentStep = (step) => {
        saveDataFromActionAfterChangeStep();
        onChangeActionCurrentStep(step)
    }

    let defaultActiveKey = "2";
    if (tab) {
        switch (tab) {
            case "basic-data":
                defaultActiveKey = "1";
                break;
            case "actions":
                defaultActiveKey = "2";
                break;
            case "logs":
                defaultActiveKey = "3";
                break;
            default:
                defaultActiveKey = "1";
                break;
        }
    }

    return (
        <>
            <ProCard loading={loading}>
                <Tabs defaultActiveKey={defaultActiveKey}>
                    <Tabs.TabPane tab="Configurações Básicas" key="1">
                        {!loading && automationData &&
                            <Form
                                {...formItemLayout}
                                name="register"
                                scrollToFirstError
                                autoComplete="off"
                                initialValues={initialValues}
                                onFinish={(values) => onUpdateBasicDataAutomation(automationData.id, values)}
                            >
                                <Form.Item
                                    name="name"
                                    label="Nome da Automação"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor insira um nome para esta automação!",
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    label="Descrição da Automação"
                                    tooltip={"Qual o proposito dessa automação"}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Por favor insira uma descrição dessa automação, não pode terminar em espaço em brancho!",
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item
                                    name="active"
                                    label="Ativado"
                                    tooltip={"Defina se automação está ativa ou não!"}
                                >
                                    <Select>
                                        <Select.Option value={true}>Sim</Select.Option>
                                        <Select.Option value={false}>Não</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormItemLayout}>

                                    <Button type="default" htmlType="reset">
                                        Limpar
                                    </Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button type="primary" htmlType="submit">
                                        Salvar
                                    </Button>
                                </Form.Item>
                            </Form>}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Ações da Automação" key="2">
                        <ProCard
                            split={'vertical'}
                            bordered
                            style={{height: "100%"}}
                            extra={renderBtnSaveActions()}
                        >
                            <ProCard colSpan={6} title={"Ações"}>
                                <div style={{height: '100%', display: 'flex', justifyContent: 'center'}}>
                                    <Timeline>
                                        <Timeline.Item
                                            dot={<BsFillPlayCircleFill/>}
                                            onClick={() => handlerChangeActionCurrentStep(-1)}
                                            style={{cursor: "pointer"}}
                                            color={(-1 === actionStep) ? "green" : 'gray'}
                                        >
                                            <Typography.Text style={{color: (-1 === actionStep) ? "green" : null}}>
                                                Gatilho
                                            </Typography.Text>
                                        </Timeline.Item>
                                        {-1 === actionStep && timelineAddAction}
                                        {renderStepTimeline()}
                                    </Timeline>
                                </div>

                            </ProCard>
                            <ProCard title={
                                actionStep === -1 ?
                                    getTitleStep() :
                                    <EditTitleAction
                                        title={getTitleStep()}
                                        onChange={(value) => onUpdateNameActionFromAutomation(actionStep, value)}
                                    />
                            }
                                     subTitle={getSubtitleStep()}
                                     colSpan={18}
                                     extra={getExtraStep()}>
                                {handleRenderStep()}
                            </ProCard>
                        </ProCard>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Historico de Execução" key="3">
                        {!loading && automationData && <AutomationsLog automation={automationData} />}
                    </Tabs.TabPane>
                </Tabs>
            </ProCard>
            <ModalAddAction isModalOpen={isOpenModalAddAction} hideModal={hideModal}
                            saveDataFromActionAfterChangeStep={saveDataFromActionAfterChangeStep}/>
            <Snippets/>
        </>
    );
};
const mapStateToProps = ({automation}) => ({
    automation: automation,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSaveAutomationActionsAndTrigger: () => dispatch(saveAutomationActionsAndTrigger()),
        onChangeActionCurrentStep: (step) => dispatch(changeActionCurrentStep(step)),
        onRemoveActionFromAutomation: (step) => dispatch(removeActionFromAutomation(step)),
        onUpdateNameActionFromAutomation: (step, name) => dispatch(updateNameActionFromAutomation(step, name)),
        onUpdateBasicDataAutomation: (id, {name, description, active}) => dispatch(updateBasicDataAutomation(id, {
            name,
            description,
            active
        })),
        onUpdateActionFromAutomation: ({step, data}) => dispatch(updateActionFromAutomation({step, data}))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowBuilder);
