import axios from "axios";

/**
 * Services
 */
import getApiUrl from "services/getApiUrl";

/**
 * Todas atividades
 */
export const allActivities = async () => {
  return await axios
    .get(`${getApiUrl()}/atividades/todas`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Todas atividades de Formulários
 */
export const allActivitiesForms = async ({form = null, action = null, entity = null, createdBy = null, createdAt = null, page = 1, pageSize= 10, sorter = null}) => {
    return await axios
        .post(`${getApiUrl()}/atividades/formularios/todas`,{
                form,
                action,
                entity,
                createdBy,
                createdAt,
                page,
                pageSize,
                sorter
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

/**
 * Reverter atividade
 * @param {*} objects
 * @returns
 */
export const revertActivity = async (objects) => {
  const {
    formName,
    activitieId,
    fatherActivitieId,
    page,
    action,
    ids,
    useBin,
    useActivitie,
    isImport,
    isObjDataUpdate,
    isObjDataRecover,
    createdBy,
  } = objects;
  return await axios
    .post(`${getApiUrl()}/atividades/reverter?device=web&network=online`, {
      formName,
      activitieId,
      fatherActivitieId,
      page,
      action,
      ids,
      useBin,
      useActivitie,
      isImport,
      isObjDataUpdate,
      isObjDataRecover,
      createdBy,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
