// import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// /**
//  * Components
//  */
// import {
//   StyledContainer,
//   StyledTitle,
//   StyledSubTitle,
//   Avatar,
//   StyledButton,
//   ButtonGroup,
// } from "components/Styles";
// import Logo from "assets/images/logo.png";

const Greetings = ({ isAuth }) => {
  const history = useHistory();
  if (!isAuth) {
    history.push("/iniciar-sessao")
  } else {
    history.push("/bem-vindo")
  }
}

// const Greetings = ({ isAuth }) => {
//   const history = useHistory();
//   useEffect(() => {
//     // verifica se está logado
//     if (!isAuth) {
//       history.push("/iniciar-sessao");
//     }
//   }, []);
//   return (
//     <StyledContainer>
//       <div>
//         <div
//           style={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             backgroundColor: "transparent",
//             width: "100%",
//             padding: "15px",
//             display: "flex",
//             justifyContent: "flex-start",
//           }}
//         >
//           <Avatar image={Logo} />
//         </div>
//         <StyledTitle size={65}>Área do membro CLM</StyledTitle>
//         <StyledSubTitle size={27}>
//           Aqui você membro CLM confere as novidades
//         </StyledSubTitle>
//         <ButtonGroup>
//           <StyledButton to="/iniciar-sessao">Entrar na Central</StyledButton>
//           <StyledButton to="/esqueci-senha">Esqueceu a senha?</StyledButton>
//         </ButtonGroup>
//       </div>
//     </StyledContainer>
//   );
// };

export default Greetings;
