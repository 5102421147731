import ptBRIntl from "antd/lib/locale/pt_BR";
import {ConfigProvider, Switch} from "antd";
import {BetaSchemaForm, ProCard} from "@ant-design/pro-components";
import React, {useEffect, useState} from "react";
import Loader from "react-loader-spinner";
import _ from "lodash";
import {actionAcceptLoop} from "../constants/actions";

export default function ActionRender({action, formAction, saveDataFromActionAfterChangeStep}) {
    const [loading, setLoading] = useState(false);
    const [propsInput, setPropsInput] = useState([])

    useEffect(() => {
        const fetchPropsInput = async () => {
            setLoading(true);
            const props = action.propsInput;
            setPropsInput(props);
            setLoading(false);

            let initialValues = action.config ? action.config : null;
            if (initialValues) {
                if(actionAcceptLoop.includes(action.type)){
                    if(initialValues.loopData === undefined){
                        initialValues.loopData = {
                            active: false,
                            maxLoop: null,
                            valueReference: null
                        }
                    }
                }
                formAction.setFieldsValue(initialValues);
            }else{
                formAction.resetFields();
            }
        }
        fetchPropsInput();
    }, [action, formAction]);


    function handleOnChangeValue(values) {
        saveDataFromActionAfterChangeStep()
    }

    return (
        <React.Fragment>

            {loading &&
                <Loader type={"Circles"}></Loader>
            }

            <ConfigProvider locale={ptBRIntl}>
                {
                    !loading &&
                    action &&
                    <BetaSchemaForm
                        layout={{
                            labelCol: {span: 8},
                            wrapperCol: {span: 16},
                        }}
                        layoutType={"Form"}
                        form={formAction}
                        columns={propsInput}
                        submitter={false}
                        onValuesChange={handleOnChangeValue}
                    >
                    </BetaSchemaForm>
                }
            </ConfigProvider>
        </React.Fragment>
    )
}
