import {combineReducers} from "redux";
import {sessionReducer} from "redux-react-session";

// Reducers
import notificationReducer from "ducks/notification";
import notificationCenterReducer from "../../ducks/notification-center";
import automationCenterReducer from "../../ducks/automation";
import formDataReducer from "ducks/dataOfForm";
import exportFormReducer from "ducks/exportForm";
import reportReducer from "../../ducks/report";

const rootReducer = combineReducers({
    session: sessionReducer,
    records: formDataReducer,
    exportRecords: exportFormReducer,
    notification: notificationReducer,
    notificationCenter: notificationCenterReducer,
    automation: automationCenterReducer,
    report: reportReducer,
});

export default rootReducer;
