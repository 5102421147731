import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {
  Layout,
  Row,
  Col,
  Menu,
  Badge,
  Dropdown,
  Avatar,
  Popover,
  Input,
  AutoComplete,
  Typography,
} from "antd";
import {
  UserOutlined,
  SearchOutlined,
  DownOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import _ from "lodash";

/**
 * Actions
 */
import { logoutUser } from "auth/actions/userActions";
import {
  allFormsOfSavedObjects,
  savedDataInObj,
} from "auth/actions/objActions";

/**
 * Components
 */
import { CompanyLogo } from "components/NavBar/styles";
import { Logo } from "assets/icons";
import "reactjs-popup/dist/index.css";
import NotificationCenter from "../../NotificationCenter";

/**
 * Misc
 */
const { Header } = Layout;
const { Text } = Typography;

function TopBar({ user }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const renderTitle = (title) => <span>{title}</span>;
  const renderItem = (title, count) => ({
    key: Math.random(),
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        <span>
          <FileTextOutlined /> {count}
        </span>
      </div>
    ),
  });

  const [forms, setForms] = useState([]);
  const [options, setOptions] = useState([]);
  const theme = {
    header: {
      fontFamily: "inherit",
      backgroundColor: "#FFFFFF",
      textColor: "#12B398",
      fontSize: "15px",
      borderRadius: "0px",
    },
    notification: {
      default: {
        fontSize: "14px",
        borderRadius: "0px",
        margin: "8px",
        backgroundColor: "#FFFFFF",
        textColor: "#3A424D",
        hover: { backgroundColor: "#ECECEC" },
        fontFamily: "inherit",
      },
      unread: {
        backgroundColor: "#F6F6F6",
        textColor: "#3A424D",
        hover: { backgroundColor: "#ECECEC" },
        state: { color: "#12B398" },
        borderRadius: "0px",
      },
      unseen: {
        backgroundColor: "#F8F5FF",
        textColor: "#3A424D",
        hover: { backgroundColor: "#ECECEC" },
        state: { color: "#12B398" },
      },
    },
    icon: { borderColor: "#12B398", width: "24px" },
    unseenBadge: { backgroundColor: "#12B398" },
    banner: {
      backgroundColor: "#F8F5FF",
      textColor: "#3A424D",
      fontSize: "14px",
    },
    dialog: {
      backgroundColor: "#F5F5F5",
      textColor: "#323232",
      accentColor: "#12B398",
    },
    footer: {
      fontFamily: "inherit",
      backgroundColor: "#FFFFFF",
      textColor: "#12B398",
      fontSize: "15px",
      borderRadius: "0px",
    },
  };

  /**
   * Pesquisa palavra chave nos dados salvos
   * @param {*} param0
   * @returns
   */
  const search = async ({ forms, keyword }) => {
    // ToDo: Implementar paginação
    return savedDataInObj({ forms }).then((items) => {
      let saved = [];
      // filtra os resultados
      _.uniq(_.flatten(items.message))
        .map((obj) => _.omit(obj, ["createdAt"]))
        .forEach((values) => {
          var name = "";
          for (var item in values) {
            if (typeof values[item] === "object") {
              var x = "";
              if (Array.isArray(values[item])) x = "Array";
              else x = "object";
              name = item;
              switch (x) {
                case "Array":
                  saved.push({
                    key: values[item].map((obj) => {
                      if (obj.hasOwnProperty("_id")) {
                        return Math.random();
                      } else {
                        return Math.random();
                      }
                    }),
                    form: name,
                    value: values[item].map((obj) => {
                      if (obj.hasOwnProperty("_id")) {
                        return obj.value;
                      } else {
                        return obj;
                      }
                    }),
                    label: renderTitle("Formulário"),
                    // eslint-disable-next-line no-loop-func
                    options: values[item].map((obj) => {
                      if (obj.hasOwnProperty("_id")) {
                        return renderItem(name, obj.value);
                      } else {
                        return renderItem(name, obj);
                      }
                    }),
                  });
                  break;
                case "Object":
                  saved.push({
                    key: Math.random(),
                    form: name,
                    value: [values[item].value],
                    label: renderTitle("Formulário"),
                    options: [renderItem(name, values[item].value)],
                  });
                  break;
                default:
                  break;
              }
              // console.log(item + " is " + x);
            } else {
              // console.log(item + " is2 " + typeof values[item]);
            }
          }
        });
      return _.uniqWith(saved, _.isEqual);
    });
  };

  useEffect(() => {
    allFormsOfSavedObjects().then((items) => {
      setForms(items.message);
    });
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={() => false}>Perfil</a>
      </Menu.Item>
      <Menu.Item>
        <a href={() => false} onClick={() => logoutUser(history, dispatch)}>
          Sair
        </a>
      </Menu.Item>
    </Menu>
  );

  /**
   * Pesquisa em dados salvos
   * @param {*} searchText
   */
  const onSearch = (searchText) => {
    search({
      keyword: searchText,
      forms: forms.map((values) => values.name),
    }).then((result) => {
      const term = searchText,
        options = result.reduce((r, { key, form, value, label, options }) => {
          let o = value.filter((obj) => obj.includes(term));
          if (o && o.length)
            r.push({ key, form, value: [...o], label, options });
          return r;
        }, []);
      setOptions(options);
    });
  };

  const showInfoLabel = (process.env.NODE_ENV !== 'production');
  return (
      <Fragment>
        {
            showInfoLabel && (<div
                style={{
                  textAlign: "center",
                  padding: "10px 0",
                  background: "#000f1e",
                }}
            >
              <Text
                  style={{
                    color: "#607d8b",
                  }}
              >
                <InfoCircleOutlined style={{marginRight: 5}}/> Você está utilizando
                uma versão de teste portanto algumas funcionalidades ainda estão em
                desenvolvimento.
              </Text>
            </div>)
        }
      <Header
        style={{
          textAlign: "center",
          padding: "0 20px",
        }}
      >
        <CompanyLogo>
          <a href="/dashboard/resumo" title={process.env.REACT_APP_NAME}>
            <Logo  style={{
              marginTop: -5,
              marginRight: 10,
              height:30
            }} />
          </a>
        </CompanyLogo>
        <Row type="flex" justify="end" align="middle">
          <Col span={24}>
            <div className="search-topbar">
              <AutoComplete
                style={{
                  display: "block",
                }}
                //onSearch={onSearch}
                //onSelect={onSelect}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    history.push({
                      pathname: "/resultados/pesquisa/formularios",
                      state: { typed: e.target.value },
                    });
                    window.location.reload(true);
                  }
                }}
                options={options}
              >
                <Input
                  placeholder="Animais, recursos, relatórios (/)"
                  prefix={
                    <>
                      <SearchOutlined />
                      <span
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        Pesquisar
                      </span>
                    </>
                  }
                />
              </AutoComplete>
            </div>
          </Col>
          <Col
            style={{
              marginTop: -50,
              marginRight: 15,
            }}
            span={1.5}
          >
            <Badge>
              <a href={"#"}>Central de ajuda</a>
            </Badge>
          </Col>
          <Col
            style={{
              marginTop: -35,
              marginLeft: 6,
            }}
            span={1}
          >
            {Object.keys(user).length > 0 ? <NotificationCenter/> : null}
          </Col>
          <Col
            style={{
              marginTop: -50,
              marginRight: 5,
            }}
            span={1}
          >
            <Dropdown overlay={menu}>
              <a href={() => false}>
                <Avatar
                  style={{ verticalAlign: "middle" }}
                  icon={<UserOutlined />}
                />{" "}
                <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
    </Fragment>
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps)(TopBar);
