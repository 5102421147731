import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Alert,
  notification,
  Checkbox,
} from "antd";
import Marquee from "react-fast-marquee";

/**
 * Actions
 */
import { allCategoriesObj, addObject } from "auth/actions/objActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import { Docs } from "assets/icons";
import { QuickStartDecorator, PageHeaderDecorator } from "assets/icons";
import {useSelector} from "react-redux";


const { Content } = Layout;
const { Paragraph } = Typography;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function NewForm() {
  const user = useSelector((state) => state.session.user);

  const [form] = Form.useForm();
  const history = useHistory();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    allCategoriesObj().then((items) => {
      setCategories(items.message);
    });
  }, []);

  const IconLink = ({ Src= "", text, link }) => {
    return (
        <a
            style={{ display: "inline-flex", verticalAlign: "middle",marginTop: -3, marginRight: 16, lineHeight: 2.5, cursor:"pointer"}}
            href={link}
            target="_blank"
            rel="noreferrer"
        >
          {typeof Src === "string" ? (
              <img
                  className="example-link-icon"
                  src={Src}
                  alt={text}
                  style={{ marginRight: 8 }}
                  width={24}
              />
          ) : (
              <Src className="example-link-icon" alt={text} style={{ marginRight: 8 }} width={24} />
          )}

          <label style={{cursor:"pointer"}}>{text}</label>
        </a>
    );
  };
  const routes = [
    {
      breadcrumbName: "formulários",
      href: "/formularios/todos",
    },
    {
      breadcrumbName: "novo",
    },
  ];
  const content = (
    <>
      <Paragraph style={{paddingBottom: "2rem"}}>
        Use formulários padrões ou personalizados para coletar dados e salvar em
        sua biblioteca.
      </Paragraph>
      <div>
        <IconLink
          Src={QuickStartDecorator}
          text="Como começar"
          link="https://cantoporto.gitbook.io/sac/guides/criando-seu-primeiro-formulario"
        />
        <IconLink
          Src={Docs}
          text="Documentação"
          link={`${process.env.REACT_APP_API_PROD}/docs`}
        />
      </div>
    </>
  );
  const ContentAlternative = ({ children, extraContent }) => (
    <Row>
      <div style={{ flex: 1 }}>{children}</div>
      <div className="image">{extraContent}</div>
    </Row>
  );

  const onCheckName = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Adiciona novo formulário
   * @param {*} values
   */
  const onFinish = (values) => {
    addObject({
      formName: values.name.trim(),
      needConfirmation: !!values.needConfirmation,
      createdBy: user.uuid
    })
      .then(async (response) => {
        if (response.success) {
          notification["success"]({
            message: "Sucesso",
            description: response.message
              ? response.message
              : "Algo está errado, tente novamente!",
          });
          await sleep(2000);
          history.push("/formularios/todos");
        } else {
          notification["error"]({
            message: "Erro",
            description: response.message
              ? response.message
              : "Algo está errado, tente novamente!",
          });
        }
      })
      .catch((err) => {
        notification["error"]({
          message: "Erro",
          description: err.message
            ? err.message
            : "Algo está errado, tente novamente!",
        });
      });
  };

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
            <PageHeader
              className="site-page-header"
              style={{ margin: "16px 0 24px 0", background: "#fff" }}
              title="Novo formulário"
              subTitle="Crie seus formulários personalizados."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            >
              <ContentAlternative extraContent={<PageHeaderDecorator  alt="content" width="100%"/>}>
                {content}
              </ContentAlternative>
            </PageHeader>

            <Content
              className="site-layout-background"
              style={{
                padding: 46,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="name"
                      label="Nome"
                      tooltip="Ex: dados_basicos"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor insira um nome para este formulário!",
                          whitespace: true,
                        },
                        {
                          message: "Este nome não é permitido!",
                          validator: (_, value) => {
                            const trimmedValue = value.trim();
                            if (
                              trimmedValue === "user" ||
                              trimmedValue === "operation" ||
                              trimmedValue === "permission" ||
                              trimmedValue === "reports" ||
                              trimmedValue === "role" ||
                              trimmedValue === "session"
                            ) {
                              return Promise.reject();
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                        {
                          validator: (_, value) => {
                            if (value.endsWith(" ")) {
                              return Promise.reject("O nome não deve terminar com um espaço!");
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="needConfirmation"
                      label="Confirmaçāo"
                      valuePropName="checked"
                      tooltip="Se selecionado ao criar registro através aplicativo é necessário confirmar antes de enviar"
                    >
                      <Checkbox defaultChecked={false} />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                      <Button type="primary" htmlType="submit">
                        Adicionar formulário
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}
