import React, { useState, useEffect } from 'react';
import moment from "moment";
import { Input, DatePicker, TimePicker, Modal, Button, Space } from "antd";
import IconTriggerModal from "../../Snippets/IconTriggerModal";

export const DateInput = ({ type = "date", value, onChange, fieldName, fieldLabel }) => {
    const [inputValue, setInputValue] = useState(value);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    const handleDateChange = (date) => {
        const newValue = date ? date.format(type === "date" ? 'DD/MM/YYYY' : 'HH:mm') : '';
        setInputValue(newValue);
        onChange(newValue);
        setIsModalVisible(false); // Fecha o modal após a seleção
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Input
                    style={{ width: "calc(100% - 140px)" }}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={type === "date" ? "DD/MM/AAAA" : "HH:mm"}
                />
                <Button onClick={showModal} style={{ margin: "0 5px" }}>
                    Selecionar {type === "date" ? "data" : "hora"}
                </Button>
                <IconTriggerModal fieldLabel={fieldLabel} fieldName={fieldName} withButton />
            </div>
            <Modal
                title={`Selecione uma ${type === "date" ? "Data" : "Hora"}`}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                {type === "date" ? (
                    <DatePicker
                        format="DD/MM/YYYY"
                        value={moment(inputValue, 'DD/MM/YYYY', true).isValid() ? moment(inputValue, 'DD/MM/YYYY') : null}
                        onChange={handleDateChange}
                        allowClear={false}
                        style={{ width: '100%' }}
                    />
                ) : (
                    <TimePicker
                        format="HH:mm"
                        value={moment(inputValue, 'HH:mm:', true).isValid() ? moment(inputValue, 'HH:mm') : null}
                        onChange={handleDateChange}
                        allowClear={false}
                        style={{ width: '100%' }}
                    />
                )}
            </Modal>
        </div>
    );
};
