const colors = {
  primary: "#fff",
  theme: "#12b398",
  light1: "#F3F4F6",
  light2: "#E5E7EB",
  dark1: "#1F2937",
  dark2: "#4B5563",
  dark3: "#9CA3AF",
  red: "#f25933",
};

export default colors;
