import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Core
import Greetings from "pages/Greetings";
import Welcome from "pages/Welcome";
import Dashboard from "pages/Dashboard";

// Auth
import SignIn from "pages/SignIn";
import ForgotPass from "pages/ForgotPass";
import ChangePass from "pages/ChangePass";

// State and events
import AuthRoute from "components/Services/AuthRoute";
import BasicRoute from "components/Services/BasicRoute";

// Users
import NewUser from "pages/Users/New";
import AllUsers from "pages/Users/All";
import EditarUser from "pages/Users/Editar";

// Forms
import NewForm from "pages/Forms/New";
import DetailsForm from "pages/Forms/Details";
import AllForms from "pages/Forms/All";
import NewField from "pages/Forms/Fields/New";
import Preview from "pages/Forms/Preview";
import RecordForms from "pages/Forms/Records";
import BinOfForm from "pages/Forms/Records/Bin";
import ImportStatus from "pages/Forms/Import/Status";

// Reports

import EditReport from "pages/Reports/Edit";
import ViewReport from "pages/Reports/View";
import AllReports from "pages/Reports/All";

// Activities
import AllActivities from "pages/Activities/All";

// Search
import NewFormCategory from "pages/Forms/Category/New";
import AllFormCategories from "pages/Forms/Category/All";
import FormSearchResults from "pages/Search/Results";
import EditFormCategory from "pages/Forms/Category/Edit";

// Misc
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "theme.css";
import AllActivitiesForms from "./pages/ActivitiesForms/All";
import AllAutomations from "./pages/Automations/All";
import NewAutomation from "./pages/Automations/New";
import EditAutomation from "./pages/Automations/Edit";

const App = ({ authenticated, checked }) => {
  return (
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      {checked && (
        <Switch>
          <Route path="/" exact>
            <Greetings isAuth={authenticated} />
          </Route>
          <BasicRoute path="/iniciar-sessao">
            <SignIn />
          </BasicRoute>
          <BasicRoute path="/esqueci-senha">
            <ForgotPass />
          </BasicRoute>
          <AuthRoute path="/alterar-senha">
            <ChangePass />
          </AuthRoute>
          <AuthRoute path="/bem-vindo">
            <Welcome />
          </AuthRoute>
          <AuthRoute path="/resultados/pesquisa/formularios">
            <FormSearchResults />
          </AuthRoute>
          <AuthRoute path="/dashboard/resumo">
            <Dashboard />
          </AuthRoute>
          <AuthRoute path="/usuarios/todos">
            <AllUsers />
          </AuthRoute>
          <AuthRoute path="/usuarios/editar">
            <EditarUser />
          </AuthRoute>
          <AuthRoute path="/usuarios/novo">
            <NewUser />
          </AuthRoute>
          <AuthRoute path="/formularios/novo">
            <NewForm />
          </AuthRoute>
          <AuthRoute path="/formularios/detalhes/:name">
            <DetailsForm />
          </AuthRoute>
          <AuthRoute path="/formularios/preview/:name">
            <Preview />
          </AuthRoute>
          <AuthRoute path="/formularios/todos">
            <AllForms />
          </AuthRoute>
          <AuthRoute path="/formularios/registros/:name">
            <RecordForms />
          </AuthRoute>
          <AuthRoute path="/formularios/lixeira/:name">
            <BinOfForm />
          </AuthRoute>
          <AuthRoute path="/formularios/campo/novo">
            <NewField />
          </AuthRoute>
          <AuthRoute path="/formularios/categoria/nova">
            <NewFormCategory />
          </AuthRoute>
          <AuthRoute path="/formularios/categoria/todas">
            <AllFormCategories />
          </AuthRoute>
          <AuthRoute exact path="/formularios/categoria/editar/:id">
            <EditFormCategory />
          </AuthRoute>
          <AuthRoute path="/formularios/importar/detalhes/:id">
            <ImportStatus />
          </AuthRoute>
          <AuthRoute path="/automacoes/nova">
            <NewAutomation />
          </AuthRoute>
          <AuthRoute path="/automacoes/editar/:id/:tab?">
            <EditAutomation />
          </AuthRoute>
          <AuthRoute path="/automacoes">
            <AllAutomations />
          </AuthRoute>
          <AuthRoute path="/relatorios/editar/:id">
            <EditReport />
          </AuthRoute>
          <AuthRoute path="/relatorios/visualizar/:id">
            <ViewReport />
          </AuthRoute>
          <AuthRoute path="/relatorios">
            <AllReports />
          </AuthRoute>

          <AuthRoute path="/atividades/todas">
            <AllActivities />
          </AuthRoute>
          <AuthRoute path="/atividades/formularios/todas">
            <AllActivitiesForms />
          </AuthRoute>
        </Switch>
      )}
    </Router>
  );
};

const mapStateToProps = ({ session }) => ({
  checked: session.checked,
  authenticated: session.authenticated,
});

export default connect(mapStateToProps)(App);
