import {automationAPI} from "../services/actions/automations";
import {actions_types, getPropsFromAction,} from "../pages/Automations/Edit/Flow/constants/actions";
import {getPropsFromTrigger, triggers_type} from "../pages/Automations/Edit/Flow/constants/triggers";
import _ from "lodash";
// Types
const START_GET_AUTOMATION = 'core/automation/START_GET_AUTOMATION';
const START_REMOVE_AUTOMATION = 'core/automation/START_REMOVE_AUTOMATION';
const REMOVE_AUTOMATION_ERROR = 'core/automation/REMOVE_AUTOMATION_ERROR';
const END_REMOVE_AUTOMATION = 'core/automation/END_REMOVE_AUTOMATION';
const START_SAVE_AUTOMATION = 'core/automation/START_SAVE_AUTOMATION';
const END_SAVE_AUTOMATION = 'core/automation/END_SAVE_AUTOMATION';
const SAVE_AUTOMATION_ERROR = 'core/automation/SAVE_AUTOMATION_ERROR';
const GET_AUTOMATION = 'core/automation/GET_AUTOMATION';
const GET_AUTOMATION_ERROR = 'core/automation/GET_AUTOMATION_ERROR';
const UPDATE_BASIC_DATA_AUTOMATION_ERROR = 'core/automation/UPDATE_BASIC_DATA_AUTOMATION_ERROR';
const ADD_ACTION_TO_AUTOMATION = 'core/automation/ADD_ACTION_TO_AUTOMATION';
const REMOVE_ACTION_FROM_AUTOMATION = 'core/automation/REMOVE_ACTION_FROM_AUTOMATION';
const UPDATE_ACTION_CUSTOM_NAME_FROM_AUTOMATION = 'core/automation/UPDATE_ACTION_CUSTOM_NAME_FROM_AUTOMATION';
const UPDATE_BASIC_DATA_AUTOMATION = 'core/automation/UPDATE_BASIC_DATA_AUTOMATION';
const START_UPDATE_BASIC_DATA_AUTOMATION = 'core/automation/START_UPDATE_BASIC_DATA_AUTOMATION';
const CHANGE_CURRENT_ACTION_STEP = 'core/automation/CHANGE_CURRENT_ACTION_STEP';
const UPDATE_TRIGGER_FROM_AUTOMATION = 'core/automation/UPDATE_TRIGGER_FROM_AUTOMATION';
const UPDATE_TYPE_TRIGGER_FROM_AUTOMATION = 'core/automation/UPDATE_TYPE_TRIGGER_FROM_AUTOMATION';
const OPEN_MODAL_SNIPPETS = 'core/automation/OPEN_MODAL_SNIPPETS';
const CLOSE_MODAL_SNIPPETS = 'core/automation/CLOSE_MODAL_SNIPPETS';
const UPDATE_ACTION_FROM_AUTOMATION = 'core/automation/UPDATE_ACTION_FROM_AUTOMATION';
const UPDATE_TRIGGER_SNIPPETS = 'core/automation/UPDATE_TRIGGER_SNIPPETS';
const UPDATE_ACTION_SNIPPETS = 'core/automation/UPDATE_ACTION_SNIPPETS';
const UPDATE_ALL_ACTION_SNIPPETS = 'core/automation/UPDATE_ALL_ACTION_SNIPPETS';

// Reducer
const initialState = {
    loading: false,
    automation: null,
    trigger: null,
    actions: [],
    actionStep: -1,
    error: null,
    snippets: {
        field: {
            label: null,
            name: null,
        },
        data: [],
        showModal: false,
    },
};

export default function automationCenterReducer(state = initialState, action) {
    switch (action.type) {
        case START_REMOVE_AUTOMATION:
        case START_SAVE_AUTOMATION:
        case START_UPDATE_BASIC_DATA_AUTOMATION:
        case START_GET_AUTOMATION:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_AUTOMATION:
            const {snippets:initialSnippetsData} = action.payload;
            initialSnippetsData[-1] = action.payload.triggerSnippets
            return {
                ...state,
                automation: _.omit(action.payload,[
                    'triggerSnippets',
                    'snippets'
                ]),
                trigger: action.payload.trigger || null,
                actions: action.payload.actions,
                loading: false,
                snippets: {
                    ...state.snippets,
                    data: initialSnippetsData
                }
            };
        case ADD_ACTION_TO_AUTOMATION:
            const newActions = [...state.actions];
            const insertIndex = state.actionStep + 1;
            newActions.splice(insertIndex, 0, action.payload);
            return {
                ...state,
                actions: newActions,
                actionStep: insertIndex
            };
        case CHANGE_CURRENT_ACTION_STEP:
            let stepToChange = action.payload;
            if (action[stepToChange] !== null || stepToChange === -1) {
                return {
                    ...state,
                    actionStep: stepToChange,
                }
            } else {
                return state
            }
        case REMOVE_ACTION_FROM_AUTOMATION:
            let stepToRemove = action.payload;
            const actionsToRemoved = [...state.actions];
            actionsToRemoved.splice(stepToRemove, 1);
            const snippetsDataToRemoved = [...state.snippets.data];
            snippetsDataToRemoved.splice(stepToRemove, 1);
            return {
                ...state,
                actions: actionsToRemoved,
                actionStep: stepToRemove - 1,
                snippets: {
                    ...state.snippets,
                    data: snippetsDataToRemoved
                }
            }
        case UPDATE_ACTION_CUSTOM_NAME_FROM_AUTOMATION:
            const {step, name} = action.payload;
            const newActionsUpdated = [...state.actions];
            newActionsUpdated[step].name = name;
            return {
                ...state,
                actions: newActionsUpdated
            }
        case UPDATE_BASIC_DATA_AUTOMATION:
            return {
                ...state,
                automation: {
                    ...state.automation,
                    name: action.payload.name,
                    description: action.payload.description,
                    active: action.payload.active
                },
                error: null,
                loading: false
            }
        case REMOVE_AUTOMATION_ERROR:
        case GET_AUTOMATION_ERROR:
        case UPDATE_BASIC_DATA_AUTOMATION_ERROR:
        case SAVE_AUTOMATION_ERROR:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            }
        case UPDATE_TRIGGER_FROM_AUTOMATION:
            return {
                ...state,
                trigger: {
                    ...state.trigger,
                    config: {
                        ...state.trigger.config,
                        ...action.payload
                    }
                },
            }
        case UPDATE_TYPE_TRIGGER_FROM_AUTOMATION:
            return {
                ...state,
                trigger: {
                    type: action.payload,
                    config: null
                }
            }
        case OPEN_MODAL_SNIPPETS:
            return {
                ...state,
                snippets: {
                    ...state.snippets,
                    field: {
                        ...state.snippets.field,
                        label: action.payload.label,
                        name: action.payload.name,
                        isCode: action.payload.isCode,
                        acceptEntryLoop:action.payload.acceptEntryLoop,
                    },
                    showModal: true
                },
            }
        case CLOSE_MODAL_SNIPPETS:
            return {
                ...state,
                snippets: {
                    ...state.snippets,
                    field: {
                        ...state.snippets.field,
                        label: null,
                        name: null,
                        isCode: false,
                        acceptEntryLoop:true
                    },
                    showModal: false
                }
            }
        case UPDATE_ACTION_FROM_AUTOMATION:
            const {data, stepUpdated} = action.payload;
            const updatedActions = state.actions.map((action, index) => {
                if (index === stepUpdated) {
                    return {
                        ...action,
                        config: {
                            ...action.config,
                            ...data
                        }
                    };
                }
                return action;
            });

            return {
                ...state,
                actions: updatedActions
            };
        case UPDATE_TRIGGER_SNIPPETS:
            const newDataSnippets = state.snippets.data;
            newDataSnippets[-1] = action.payload;
            return {
                ...state,
                snippets: {
                    ...state.snippets,
                    data: newDataSnippets
                }
            }
        case UPDATE_ACTION_SNIPPETS:
            const {actionStep, snippets} = action.payload;
            const newDataActionSnippets = state.snippets.data;
            newDataActionSnippets[actionStep] = snippets;
            return {
                ...state,
                snippets: {
                    ...state.snippets,
                    data: newDataActionSnippets
                }
            }
        case END_SAVE_AUTOMATION:
            return {
                loading: false,
                ...state
            }
        case UPDATE_ALL_ACTION_SNIPPETS:
            const {snippets:snippetsData} = action.payload;
            return {
                ...state,
                snippets: {
                    ...state.snippets,
                    data: snippetsData
                }
            }
        case END_REMOVE_AUTOMATION:
            return initialState;
        default:
            return state;
    }
}

export const getAutomationById = (id,history) => async (dispatch) => {
    try {
        dispatch({type: START_GET_AUTOMATION})
        const {status, data} = await automationAPI.getOne(id);
        if (status === 200 && data) {
            const type = data.trigger && data.trigger.type ? data.trigger.type : null;
            if(type){
                const triggerData = triggers_type.find((trigger) => trigger.type === type);
                if(triggerData){
                    data.trigger = {
                        ...data.trigger,
                        ...triggerData
                    }
                }
            }
            if(data.actions && data.actions.length > 0){
                data.actions = data.actions.map((action) => {
                    if(action.type){
                        const actionData= actions_types.find((item) => item.type === action.type);
                        if(actionData){
                            return {
                                ...action,
                                ...actionData
                            }
                        }else{
                            return action;
                        }

                    }
                    return action;
                })
            }
            data.snippets = await Promise.all(data.actions.map((item) => getPropsFromAction(item)));
            data.triggerSnippets =  await getPropsFromTrigger(data.trigger);
            dispatch({type: GET_AUTOMATION, payload: data});
        } else {
            dispatch({type: GET_AUTOMATION_ERROR, payload: data.message});
            history.push('/automacoes');
        }
    } catch (error) {
        dispatch({type: GET_AUTOMATION_ERROR, payload: error.message});
        history.push('/automacoes');
    }
};

export const addActionToAutomaion = (action) => async (dispatch) => {
    dispatch({type: ADD_ACTION_TO_AUTOMATION, payload: action});
};

export const changeActionCurrentStep = (step) => async (dispatch) => {
    dispatch({type: CHANGE_CURRENT_ACTION_STEP, payload: step});
};

export const removeActionFromAutomation = (step) => async (dispatch) => {
    dispatch({type: REMOVE_ACTION_FROM_AUTOMATION, payload: step});
};

export const updateNameActionFromAutomation = (step, name) => async (dispatch) => {
    dispatch({
        type: UPDATE_ACTION_CUSTOM_NAME_FROM_AUTOMATION, payload: {
            step,
            name
        }
    });
};

export const updateBasicDataAutomation = (id, {name, description, active}) => async (dispatch) => {
    try {
        dispatch({type: START_UPDATE_BASIC_DATA_AUTOMATION})
        const {status, data} = await automationAPI.updateOne(id, {name, description, active});
        if (status === 200 && data) {
            dispatch({type: UPDATE_BASIC_DATA_AUTOMATION, payload: {name, description, active}});
        } else {
            dispatch({type: UPDATE_BASIC_DATA_AUTOMATION_ERROR, payload: data.message});
        }
    } catch (error) {
        dispatch({type: UPDATE_BASIC_DATA_AUTOMATION_ERROR, payload: error.message});
    }
};

export const updateTriggerConfigAutomation = ({config}) => async (dispatch, getState) => {
    dispatch({type: UPDATE_TRIGGER_FROM_AUTOMATION, payload: config});
    const state = getState();
    const trigger = state.automation.trigger
    dispatch(updateTriggerSnippets(trigger))
};

export const updateTypeTriggerAutomation = ({type}) => async (dispatch, getState) => {
    dispatch({type: UPDATE_TYPE_TRIGGER_FROM_AUTOMATION, payload: type});
    const state = getState();
    const trigger = state.automation.trigger
    dispatch(updateTriggerSnippets(trigger))
};

export const updateTriggerSnippets = (trigger) => async (dispatch) => {
    const props = await getPropsFromTrigger(trigger);
    dispatch({type: UPDATE_TRIGGER_SNIPPETS, payload: props});
};

export const openOrCloseModalSnippets = ({action, fieldName = null, fieldLabel = null,isCode = false,acceptEntryLoop = true}) => async (dispatch) => {
    if (action) {
        dispatch({type: OPEN_MODAL_SNIPPETS, payload: {name: fieldName, label: fieldLabel,isCode,acceptEntryLoop}});
    } else {
        dispatch({type: CLOSE_MODAL_SNIPPETS});
    }

};

export const updateActionFromAutomation = ({step, data}) => async (dispatch) => {
    dispatch({
        type: UPDATE_ACTION_FROM_AUTOMATION, payload: {data, stepUpdated: step}
    });
    dispatch(updateActionSnippets({step}))
};

export const saveAutomationActionsAndTrigger = () => async (dispatch, getState) => {
    const { automation: { trigger, actions, automation } } = getState();
    dispatch({ type: START_SAVE_AUTOMATION });
    try {
        if (trigger) {
            const { config = null, type = null } = trigger;
            const configTrigger = omitPropsFromTrigger(type, config);

            const { status: statusTrigger, data: dataTrigger } = await automationAPI.saveTrigger(automation.id, configTrigger, type);
            if (![200, 201].includes(statusTrigger)) throw new Error(dataTrigger);
        }

        if(actions){
            const actionsData = actions.map((item, index) => ({
                step: index,
                type: item.type,
                config: omitPropsFromActions(item.type, item.config),
            }));

            const { status: statusActions, data: dataActions } = await automationAPI.saveActions(automation.id, actionsData);
            if (![200, 201].includes(statusActions)) throw new Error(dataActions);
        }

        dispatch({ type: END_SAVE_AUTOMATION });
        dispatch(getAutomationById(automation.id));

    } catch (error) {
        dispatch({ type: SAVE_AUTOMATION_ERROR, payload: error.message });
    }
};


export const deleteAutomation = (id, history) => async (dispatch) => {
    dispatch({type: START_REMOVE_AUTOMATION});
    try {
        const {status, data} = await automationAPI.deleteOne(id);
        if (200 === status) {
            dispatch({type: END_SAVE_AUTOMATION});
            history.push('/automacoes');
        } else {
            throw new Error(data);
        }
    } catch (error) {
        dispatch({type: REMOVE_AUTOMATION_ERROR, payload: error.message});
    }
}

export const updateActionSnippets = ({step}) => async (dispatch,getState) => {
    const state = getState();
    const {actions} = state.automation;
    const snippets = await getPropsFromAction(actions[step]);
    dispatch({type: UPDATE_ACTION_SNIPPETS, payload: {actionStep:step, snippets}});
};

const omitPropsFromActions = (type,config) => {
    let props  = [
        "propsInput",
        "propsOutput",
        "icon",
        "description"
    ];
    switch (type) {
        // case "send_mail":
        // case "create_row":
        // case "update_row":
        // case "delete_row":
        // case "condition":
        // case "script":
        // case "delay":
        // case "query":
        // case "start_automation":
        //  break;
        default:
            break;
    }
    return _.omit(config,props);
}

const omitPropsFromTrigger = (type,config) => {
    let props  = [
    ];
    switch (type) {
        // case "row_created":
        // case "row_updated":
        // case "row_deleted":
        case "webhook":
            props.push("webhookData");
        // case "cron_trigger":
        //  break;
        default:
            break;
    }
    return _.omit(config,props);
}
