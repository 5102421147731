import {BsJournalBookmarkFill, BsJournalPlus, BsJournalX} from "react-icons/bs";
import {FieldTimeOutlined, SendOutlined} from "@ant-design/icons";
import {allFieldsById} from "../../../../../auth/actions/objActions";
import {getFieldType} from "../../../../../utils/get-field-type";
import React from "react";

const iconTriggerStyle = {fontSize: '16px'};

export const triggers_type = [
    {
        name: "Criação de Registro",
        type: "row_created",
        icon: <BsJournalPlus style={iconTriggerStyle}/>,
        description: "Disparado quando um novo registro é criado em um formulário.",
        propsOutput: []
    },
    {
        name: "Atualização de Registro",
        type: "row_updated",
        icon: <BsJournalBookmarkFill style={iconTriggerStyle}/>,
        description: "Disparado quando um registro existente é atualizado em um formulário.",
        propsOutput: []
    },
    {
        name: "Remoção de Registro",
        type: "row_deleted",
        icon: <BsJournalX style={iconTriggerStyle}/>,
        description: "Disparado quando um registro é excluído de um formulário.",
        propsOutput: []
    },
    {
        name: "Webhook",
        type: "webhook",
        icon: <SendOutlined style={iconTriggerStyle}/>,
        description: "Disparado por uma chamada de webhook.",
        propsOutput: []
    },
    {
        name: "Gatilho Cronometrado",
        type: "cron_trigger",
        icon: <FieldTimeOutlined style={iconTriggerStyle}/>,
        description: "Disparado em intervalos de tempo especificados.",
        propsOutput: []
    }
]

export const getPropsFromTrigger = async (trigger) => {
    const defaultProps = [

    ];

    if (trigger) {
        switch (trigger.type) {
            case  "row_created":
            case  "row_updated":
            case  "row_deleted":
                let dynamicallyPropsRow = [];
                if (trigger.config && trigger.config.form && trigger.config.form.id) {
                    dynamicallyPropsRow = await getPropsDynamic(trigger.config.form.id)
                }
                return [
                    ...defaultProps,
                    ...dynamicallyPropsRow
                ];
            case "cron_trigger":
                return [
                    ...defaultProps,
                    ...generatePropsFromTriggerCron()
                ]
            case "webhook":
                const specificPropsWebhook = [{
                    field: "body",
                    type: "Object",
                    fieldtype: "objetc",
                }]
                let dynamicallyPropsWebhook = [];
                if (trigger.config && trigger.config.webhookData) {
                    dynamicallyPropsWebhook = generatePropsFromTriggerWebhook(trigger.config.webhookData)
                }
                return [
                    ...defaultProps,
                    ...specificPropsWebhook,
                    ...dynamicallyPropsWebhook
                ];
            default:
                return defaultProps
        }
    }
    return defaultProps


}

export const getPropsDynamic = async (formId, path = "") => {
    try {
        const props = await allFieldsById(formId)
        return props.message.map(item => {
            return {
                ...item,
                type: getFieldType(item.fieldtype),
            }
        });
    } catch (e) {
        return []
    }

}

export const generatePropsFromTriggerWebhook = (jsonObject) => {
    let snippets = [];

    for (const key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            const value = jsonObject[key];
            let fieldType = typeof value;
            let type = "Texto";

            if (Array.isArray(value)) {
                fieldType = 'array';
            } else if (value === null) {
                fieldType = 'null';
            }

            switch (fieldType) {
                case 'string':
                    type = 'Texto';
                    break;
                case 'number':
                    type = 'Número';
                    break;
                case 'boolean':
                    type = 'Booleano';
                    break;
                case 'object':
                    type = 'Objeto';
                    break;
                case 'array':
                    type = 'Lista';
                    break;
                case 'null':
                    type = 'Nulo';
                    break;
                default:
                    type = 'Texto';
                    break;
            }

            snippets.push({
                field: key,
                type: type,
                fieldtype: fieldType,
            });
        }
    }

    return snippets;
}

export const generatePropsFromTriggerCron = () => {
    return [{
        field: "timestamp",
        type: "Number",
        fieldtype: "numerico",
    }];
}
