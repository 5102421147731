import { useSelector, useDispatch } from 'react-redux';
import { notification } from 'antd';

/**
 * Actions
 */
import { hideNotification } from 'ducks/notification';

const Notification = () => {
  const { message, type, visible } = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const closeNotification = () => {
    dispatch(hideNotification());
  };

  if (visible) {
    notification[type]({
      message: message,
      onClose: closeNotification,
    });
  }

  return null;
};

export default Notification;
