import React, {useEffect, useRef, useState} from "react";
import CodeMirror, {basicSetup} from '@uiw/react-codemirror';
import {javascript, javascriptLanguage} from '@codemirror/lang-javascript';
import {materialLight} from '@uiw/codemirror-theme-material';
import {autocompletion} from '@codemirror/autocomplete';
import * as formulajs from '@formulajs/formulajs';
import {functions} from "./functions";
import {Tabs, Collapse, List, Typography, Table, Button} from 'antd';
import _ from "lodash";
import axios from "axios";
import getApiUrl from "../../../../../services/getApiUrl";
import {validateFormula} from "../../../../../ducks/dataOfForm";
import {allFormsOfSavedObjectsWithFields} from "../../../../../auth/actions/objActions";
import {CopyOutlined} from "@ant-design/icons";

export const Formula = ({fields = [], code = "", form, onChange, formName, id, field, getFieldType}) => {
    const [forms, setForms] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await allFormsOfSavedObjectsWithFields();
                setForms(data.message);
            } catch (error) {
                console.error("Error fetching data: ", error);
                // Set state in error case as needed
            }
        };

        fetchData();
    }, []);

    const editorRef = useRef();

    const {Panel} = Collapse;

    const formulaFunctions = Object.keys(formulajs);

    const [formFields, setFormFields] = useState(fields);
    const [value, setValue] = useState(code);
    const [loading, setLoading] = useState(false);

    const completions = (context) => {
        let word = context.matchBefore(/\w*/);
        if (!word) return null;
        return {
            from: word.from,
            options: [
                ...formulaFunctions.map((func) => ({
                    label: func,
                    type: 'keyword',
                })),
                ...formFields.map(({field}) => ({
                    label: `[${field}]`,
                    type: 'variable',
                })),
                ...forms.map(({name}) => ({
                    label: `"${name.replace("obj_","")}"`,
                    type: 'variable',
                })),
                ..._.flatten(forms.map(({fields}) => fields.map(({field}) => ({
                    label: `"${field}"`,
                    type: 'variable',
                })))),
            ],
        };
    };

    const functionsCollapseRender = () => {
        const groupedFunctions = _.groupBy(functions, "category");
        const defaultActiveKey = Object.entries(groupedFunctions)[0][0];
        return (
            <Collapse defaultActiveKey={defaultActiveKey}>
                {
                    _.map(
                        groupedFunctions,
                        (value, categoryName) => {
                            const columns = [
                                {
                                    title: 'Nome',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'Descrição',
                                    dataIndex: 'description',
                                    key: 'description',
                                },
                                {
                                    title: 'Parâmetros',
                                    dataIndex: 'params',
                                    key: 'params',
                                    render: params => {
                                        return (params && _.isArray(params) ? params.map(param => <p><Typography.Text
                                            code>{param.name}</Typography.Text> - {param.description}</p>) : '-');
                                    }
                                },
                                {
                                    title: 'Exemplo',
                                    dataIndex: 'example',
                                    key: 'example',
                                },
                                {
                                    title: 'Resultado',
                                    dataIndex: 'result',
                                    key: 'result',
                                },
                            ];

                            return (
                                <Panel header={categoryName} key={categoryName} ghost>
                                    <Table
                                        dataSource={value}
                                        columns={columns}
                                        size='small' scroll={{y: 200, x: 200}}
                                        pagination={false}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onDoubleClick: event => {
                                                    const {name, params} = record;  // obtém o nome da função e os parâmetros
                                                    if (editorRef.current) {
                                                        const editor = editorRef.current.view;
                                                        const insertPos = editor.state.selection.main.head;
                                                        const functionNameWithParentheses = `${name}()`;
                                                        const transaction = editor.state.update({
                                                            changes: {
                                                                from: insertPos,
                                                                insert: functionNameWithParentheses
                                                            },
                                                        });
                                                        editor.dispatch(transaction);

                                                        let cursorPos;
                                                        if (params && params.length > 0) {
                                                            cursorPos = insertPos + functionNameWithParentheses.length - 1;
                                                        } else {
                                                            cursorPos = insertPos + functionNameWithParentheses.length;
                                                        }
                                                        editor.dispatch({
                                                            selection: {anchor: cursorPos, head: cursorPos},
                                                        });
                                                        editor.focus();
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Panel>
                            );
                        })
                }
            </Collapse>)
    }

    const fieldsRender = () => {
        const columns = [
            {
                title: 'Nome do Campo',
                dataIndex: 'field',
                key: 'field',
            },
            {
                title: 'Tipo do Campo',
                dataIndex: 'fieldtype',
                key: 'fieldtype',
            }
        ];
        return (<Table
            columns={columns}
            dataSource={formFields}
            size='small'
            scroll={{y: 200, x: 200}}
            pagination={false}
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: event => {
                        const {field} = record;  // obtém o nome da função e os parâmetros
                        if (editorRef.current) {
                            const editor = editorRef.current.view;
                            const insertPos = editor.state.selection.main.head;
                            const functionNameWithParentheses = `[${field}]`;
                            const transaction = editor.state.update({
                                changes: {from: insertPos, insert: functionNameWithParentheses},
                            });
                            editor.dispatch(transaction);

                            let cursorPos = insertPos + functionNameWithParentheses.length;
                            editor.dispatch({
                                selection: {anchor: cursorPos, head: cursorPos},
                            });
                            editor.focus();
                        }
                    }
                }
            }}
        />)
    }

    const formsRender = () => {
        return (
            <Collapse defaultActiveKey={['1']}>
                {
                    _.map(
                        forms,
                        (value) => {
                            const columns = [
                                {
                                    title: 'Nome do Campo',
                                    dataIndex: 'field',
                                    key: 'field',
                                },
                                {
                                    title: 'Tipo do Campo',
                                    dataIndex: 'fieldtype',
                                    key: 'fieldtype',
                                    render: (text) => getFieldType(text)
                                }
                            ];
                            return (
                                <Panel header={
                                    <div>
                                        {
                                            value.name.replace("obj_", "")
                                        }
                                        <CopyOutlined onClick={(event) => {
                                            event.preventDefault()
                                            if (editorRef.current) {
                                                const editor = editorRef.current.view;
                                                const insertPos = editor.state.selection.main.head;
                                                const functionNameWithParentheses = `"${value.name.replace("obj_", "")}"`;
                                                const transaction = editor.state.update({
                                                    changes: {
                                                        from: insertPos,
                                                        insert: functionNameWithParentheses
                                                    },
                                                });
                                                editor.dispatch(transaction);

                                                let cursorPos = insertPos + functionNameWithParentheses.length;
                                                ;

                                                editor.dispatch({
                                                    selection: {anchor: cursorPos, head: cursorPos},
                                                });
                                                editor.focus();
                                            }
                                        }}></CopyOutlined>
                                    </div>
                                } key={value.name} ghost>
                                    <Table
                                        dataSource={value.fields}
                                        columns={columns}
                                        size='small' scroll={{y: 200, x: 200}}
                                        pagination={false}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onDoubleClick: event => {
                                                    const {field} = record;  // obtém o nome da função e os parâmetros
                                                    if (editorRef.current) {
                                                        const editor = editorRef.current.view;
                                                        const insertPos = editor.state.selection.main.head;
                                                        const functionNameWithParentheses = `"${field}"`;
                                                        const transaction = editor.state.update({
                                                            changes: {
                                                                from: insertPos,
                                                                insert: functionNameWithParentheses
                                                            },
                                                        });
                                                        editor.dispatch(transaction);

                                                        let cursorPos = insertPos + functionNameWithParentheses.length;
                                                        ;

                                                        editor.dispatch({
                                                            selection: {anchor: cursorPos, head: cursorPos},
                                                        });
                                                        editor.focus();
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Panel>
                            );
                        })
                }
            </Collapse>
        )
        // return (<Table
        //     columns={columns}
        //     dataSource={forms}
        //     size='small'
        //     scroll={{y: 200, x: 200}}
        //     pagination={false}
        //     onRow={(record, rowIndex) => {
        //         return {
        //             onDoubleClick: event => {
        //                 const {name} = record;  // obtém o nome da função e os parâmetros
        //                 if (editorRef.current) {
        //                     const editor = editorRef.current.view;
        //                     const insertPos = editor.state.selection.main.head;
        //                     const functionNameWithParentheses = `{${name}}`;
        //                     const transaction = editor.state.update({
        //                         changes: {from: insertPos, insert: functionNameWithParentheses},
        //                     });
        //                     editor.dispatch(transaction);
        //
        //                     let cursorPos = insertPos + functionNameWithParentheses.length;
        //                     editor.dispatch({
        //                         selection: {anchor: cursorPos, head: cursorPos},
        //                     });
        //                     editor.focus();
        //                 }
        //             }
        //         }
        //     }}
        // />)
    }

    async function validateFormulaHandler() {
        setLoading(true)
        const data = await validateFormula(value, formName);
        setLoading(false)
        if (data.error) {
            let errors = [data.error];
            if (data.data) {
                errors = [...errors, data.data.join(", ")];
            }
            form.setFields([{
                name: 'formula',
                errors,
            }]);
        }

    }

    return (
        <div style={{display: 'flex', flexDirection: "column"}}>
            <div style={{flex: 1, padding: "5px"}}>
                <CodeMirror
                    ref={editorRef}
                    theme={materialLight}
                    value={code}
                    height="70px"
                    extensions={[
                        javascript(),
                        basicSetup(),
                        autocompletion({
                            override: [completions],
                        }),
                    ]}
                    onChange={(value) => {
                        form.setFieldsValue({formula: value})
                        onChange(value);
                        setValue(value)
                    }}
                />
                {(code && <Button disabled={loading} loading={loading} style={{marginTop: '5px'}} type={"primary"}
                                  onClick={validateFormulaHandler}>Validar fórmula</Button>)}


            </div>
            <div style={{flex: 1, padding: "5px"}}>
                <Tabs>
                    <Tabs.TabPane tab="Campos" key="item-1">
                        {fieldsRender()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Formulários" key="item-2">
                        {formsRender()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Funções" key="item-3">
                        {functionsCollapseRender()}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}
