import React, { useState } from "react";
import { useField } from "formik";
import { FiEyeOff, FiEye } from "react-icons/fi";

import {
  StyledTextInput,
  StyledLabel,
  StyledIcon,
  ErrorMsg,
} from "../../Styles";
import "assets/css/popup.css";
import "reactjs-popup/dist/index.css";

export const TextInput = ({ onMetaChange, icon, ...props }) => {
  const [field, meta] = useField(props);
  const [showpass, setShowpass] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
      {props.type === "email" && (
        <StyledTextInput
          invalid={meta.touched && meta.error}
          {...field}
          {...props}
        />
      )}
      {props.type === "password" && (
        <StyledTextInput
          invalid={meta.touched && meta.error}
          {...field}
          {...props}
          type={showpass ? "text" : "password"}
        />
      )}
      <StyledIcon>{icon}</StyledIcon>
      {props.type === "password" && (
        <StyledIcon onClick={() => setShowpass(!showpass)} right>
          {showpass ? <FiEye /> : <FiEyeOff />}
        </StyledIcon>
      )}
      {meta.touched && meta.error ? (
        <ErrorMsg>
          {" "}
          <div meta={meta.error}>{meta.error}</div>{" "}
        </ErrorMsg>
      ) : (
        <ErrorMsg meta="test" style={{ visibility: "hidden" }}>
          .
        </ErrorMsg>
      )}
    </div>
  );
};
