import React, { useEffect, useState } from 'react';
import { defineDataType, JsonViewer } from '@textea/json-viewer';
import { ConfigProvider, Pagination } from 'antd';
import ptBRIntl from "antd/lib/locale/pt_BR";
import { openDB } from 'idb';

const DB_NAME = 'jsonViewerDB';
const STORE_NAME = 'jsonViewerStore';

const largeArrayType = defineDataType({
    is: (value, path) => Array.isArray(value) && value.length > 100 && path.length === 1,

    Component: ({ value }) => {
        const [storageKey] = useState(`json_array_large_pagination_${Math.random().toString(36).substring(2, 15)}`);
        const [currentPage, setCurrentPage] = useState(1);
        const itemsPerPage = 50;
        const totalItems = value.length;

        useEffect(() => {
            const saveToIndexedDB = async () => {
                const db = await openDB(DB_NAME, 1, {
                    upgrade(db) {
                        db.createObjectStore(STORE_NAME);
                    },
                });
                await db.put(STORE_NAME, value, storageKey);
            };

            saveToIndexedDB();

            return async () => {
                const db = await openDB(DB_NAME, 1);
                await db.delete(STORE_NAME, storageKey);
            };
        }, [value, storageKey]);

        const [storedValue, setStoredValue] = useState([]);

        useEffect(() => {
            const loadFromIndexedDB = async () => {
                const db = await openDB(DB_NAME, 1);
                const data = await db.get(STORE_NAME, storageKey);
                setStoredValue(data || []);
            };

            loadFromIndexedDB();
        }, [storageKey]);

        const currentItems = storedValue.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );

        const pagination = (
            <Pagination
                responsive={true}
                size={'small'}
                hideOnSinglePage
                showLessItems
                current={currentPage}
                total={totalItems}
                pageSize={itemsPerPage}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
                style={{
                    margin: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            />
        );

        return (
            <ConfigProvider locale={ptBRIntl}>
                {pagination}
                <JsonViewer value={currentItems} maxDisplayLength={100} rootName={"items"} />
                {pagination}
            </ConfigProvider>
        );
    },
});

const PaginatedJsonView = ({ data }) => {
    return (
        <JsonViewer value={data} valueTypes={[largeArrayType]} />
    );
};

export default PaginatedJsonView;
