import React, {useState, useCallback, useEffect} from 'react';
import {Col, Row, Select, Input, Button} from 'antd';
import { SelectForm } from '../SelectForm';
import { openOrCloseModalSnippets } from '../../../../../../../ducks/automation';
import { connect } from 'react-redux';
import IconTriggerModal from '../../../Snippets/IconTriggerModal';
import {SearchOutlined} from "@ant-design/icons";
import {QueryBuilderDrawer} from "./QueryBuilderDrawer";

const QueryBuilder = ({ value, onChange, onBlur,fieldName , fieldLabel }) => {

    const [fieldsValues, setFieldsValue] = useState([]);
    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    }

    const onFinish = (values) =>{
        const payload = { ...value, filter: values };
        handleChange(payload);
    }

    // Opções de ordenação
    const sortOrderOptions = [
        { label: 'Nenhuma', value: null },
        { label: 'Ascendente', value: 'asc' },
        { label: 'Descendente', value: 'desc' }
    ];

    // Função para atualizar o payload
    const handleChange = useCallback((payloadState) => {
        const payload = {
            form: payloadState.form,
            sortOrder: payloadState.sortOrder,
            sortColumn: payloadState.sortColumn,
            limit: payloadState.limit,
            filter:payloadState.filter ?? null
        };
        onChange(payload);
    }, [fieldsValues, onChange]);

    const transformedFields = (fields) => {
        return [
            { label: 'Nenhuma', value: null },
            ...fields.map(field => ({ label: field.field, value: field._id }))
        ];
    }

    // Manipulador para alterações no formulário
    const handleChangeFormSelect = useCallback((valueChange) => {
        if (valueChange) {
            const { fields, id, name } = valueChange;
            const fieldsValuesData = transformedFields(fields);
            const payload = {
                ...value,
                form: { id, name },
                sortColumn: null,
            };
            setFieldsValue(fieldsValuesData)
            handleChange(payload);
        }
    }, [handleChange, value]);

    // Manipulador para alterações na ordem de classificação
    const handleChangeSortOrderSelect = useCallback((valueChange) => {
        const payload = { ...value, sortOrder: valueChange };
        handleChange(payload);
    }, [handleChange, value]);

    // Manipulador para alterações na coluna de ordenação
    const handleChangeSortColumnSelect = useCallback((valueChange,obj) => {
        const payload = { ...value}
        if(obj){
            if(obj.value === null){
                payload.sortColumn =  null;
            }else{
                payload.sortColumn = {
                    name: obj.label,
                    id: obj.value
                };
            }
        }

        handleChange(payload);
    }, [handleChange, value]);

    // Manipulador para alterações no limite
    const handleChangeLimit = useCallback((e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        const payload = { ...value, limit: inputValue };
        handleChange(payload);
    }, [handleChange, value]);

    const handleChangeFilter = (valueChangeFilter) => {
        const payload = { ...value, filter: valueChangeFilter };
        handleChange(payload);
    }

    const getFirstOptionsFields = (fields) => {
        const fieldsValuesData = transformedFields(fields);
        setFieldsValue(fieldsValuesData)
    }

    return (
        <Row gutter={24}>
            <Col span={24}>
                <label>Selecione o formulário:</label>
                <SelectForm
                    onBlur={onBlur}
                    withFields
                    onChange={handleChangeFormSelect}
                    value={value && value.form ? value.form : null}
                    getFirstOptionsFields={getFirstOptionsFields}
                />
            </Col>
            <Col span={24}>
                <label>Ordenar por:</label>
                <Select
                    onBlur={onBlur}
                    options={fieldsValues}
                    value={value && value.sortColumn ? {label: value.sortColumn.name, value: value.sortColumn.id} : null}
                    onChange={handleChangeSortColumnSelect}
                />
            </Col>
            <Col span={24}>
                <label>Classificar por:</label>
                <Select
                    onBlur={onBlur}
                    options={sortOrderOptions}
                    value={value && value.sortOrder ? value.sortOrder : null}
                    onChange={handleChangeSortOrderSelect}
                />
            </Col>
            <Col span={24}>
                <label>Limite:</label>
                <Input
                    onBlur={onBlur}
                    value={value && value.limit ? value.limit : null}
                    onChange={handleChangeLimit}
                    placeholder="Digite o limite"
                    addonAfter={<IconTriggerModal fieldName={`${fieldName}.limit`} fieldLabel="Limite"/>}
                    style={{width: '100%'}}
                />
            </Col>
            <br/>
            <br/>
            <br/>
            <Col span={24}>
                <Button icon={<SearchOutlined/>} onClick={() => setOpen(true)} style={{width: '100%'}} type={"primary"}> Configurar Filtros</Button>
                <QueryBuilderDrawer fields={fieldsValues}  handleChangeFilter={handleChangeFilter} value={value && value.filter ? value.filter : {}} open={open}  fieldName={`${fieldName}.filter`} fieldLabel="Filtros" onFinish={onFinish} onClose={onClose}/>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ automation }) => ({
    actions: automation.actions,
    actionStep: automation.actionStep,
    trigger: automation.trigger,
    snippets: automation.snippets,
});

const mapDispatchToProps = (dispatch) => ({
    onOpenOrCloseModalSnippets: ({ action, fieldName, fieldLabel }) => dispatch(openOrCloseModalSnippets({
        action,
        fieldName,
        fieldLabel,
    })),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryBuilder);
