import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {
    Layout,
    PageHeader,
    Breadcrumb,
} from "antd";
import {useHistory, useParams} from "react-router-dom";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import {LayoutTopSide} from "components/NavBar/styles";
import FlowBuilder from "./Flow";
import Loader from "react-loader-spinner";
import colors from "../../../components/Colors";
import {deleteAutomation, getAutomationById} from "../../../ducks/automation";

function EditAutomation({automation}) {

    const {id, tab} = useParams();
    const {error,loading} = automation
    const history = useHistory();
    const dispatch = useDispatch();

    const routes = [
        {
            breadcrumbName: "Automações",
            href: "/automacoes",
        },
        {
            breadcrumbName: "Editar",
            href: `/automacoes/editar/${id}`,
        },
    ];

    useEffect(() => {
        dispatch(getAutomationById(id, history));
    },[id]);

    return (
        <Layout>
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout
                        style={{
                            padding: "0 24px 24px",
                            minHeight: "100vh",
                        }}
                    >
                        <PageHeader
                            style={{margin: "16px 0 5px 0"}}
                            title="Editar Automação"
                            onBack={() => window.history.back()}
                            breadcrumb={
                                <Breadcrumb>
                                    {routes.map((a) =>
                                        a.hasOwnProperty("href") ? (
                                            <Breadcrumb.Item key={Math.random()}>
                                                <a href onClick={() => history.push(a.href)}>
                                                    {a.breadcrumbName}
                                                </a>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                                        )
                                    )}
                                </Breadcrumb>
                            }
                        />
                        <div style={{
                            width: '100%',
                            height: '100%',
                            background: "#fff",
                        }}>
                            {(loading) && <Loader
                                type="ThreeDots"
                                color={colors.theme}
                                height={49}
                                width={100}
                            />}
                            {(error) && <div>Erro ao carregar dados: {error}</div>}
                            {(!error && !loading && automation) && <FlowBuilder tab={tab} />}
                        </div>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}

const mapStateToProps = ({automation}) => ({
    automation: automation,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps,mapDispatchToProps)(EditAutomation);
