import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Typography,
  Row,
  Col,
  Space,
  Modal,
  Select,
  Tooltip,
  Empty,
  Button,
  DatePicker,
  ConfigProvider,
  message,
  Avatar,
  Badge,
  List,
  Collapse,
} from "antd";
import { ProTable } from "@ant-design/pro-components";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  FileSyncOutlined,
  GlobalOutlined,
  DesktopOutlined,
  MobileOutlined,
  CloudServerOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import ptBRIntl from "antd/lib/locale/pt_BR";
import datePickerLocale from "antd/lib/locale/pt_BR";

import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import { allActivities, revertActivity } from "auth/actions/activitieActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Utils
 */
import sleep from "components/Utils/sleep";

/**
 * Misc
 */
const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { confirm } = Modal;

const actionMap = {
  POST: {
    registro: {
      action: "POST",
      page: "lixeira",
      useBin: true,
      useActivitie: true,
      isImport: false,
      isObjDataUpdate: false,
      isObjDataRecover: false,
      // styles
      showDetails: true,
      showRevertBtn: true,
    },
    importacao: {
      action: "DELETE",
      page: "importaçāo",
      useBin: false,
      useActivitie: false,
      isImport: true,
      isObjDataUpdate: false,
      isObjDataRecover: false,
      // styles
      showDetails: false,
      showRevertBtn: true,
    },
    lixeira: {
      action: "DELETE",
      page: "lixeira",
      useBin: false,
      useActivitie: false,
      isImport: false,
      isObjDataUpdate: false,
      isObjDataRecover: true,
      // styles
      showDetails: true,
      showRevertBtn: true,
    },
    // Adicione mais mapeamentos aqui para outras combinações de 'action' e 'page' conforme necessário.
  },
  PUT: {
    registro: {
      action: "PUT",
      page: "registro",
      useBin: false,
      useActivitie: false,
      isImport: false,
      isObjDataUpdate: true,
      isObjDataRecover: false,
      // styles
      showDetails: true,
      showRevertBtn: true,
    },
  },
  DELETE: {
    registro: {
      // styles
      showDetails: true,
      showRevertBtn: false,
    },
    lixeira: {
      // styles
      showDetails: true,
      showRevertBtn: false,
    },
  },
  // Adicione mais mapeamentos aqui para outras combinações de 'action' e 'page' conforme necessário.
};

const getActionText = (text) => {
  const actions = {
    POST: "Adiçāo",
    PUT: "Ediçāo",
    DELETE: "Remoçāo",
  };
  return actions[text] || text;
};

const getDeviceText = (text) => {
  const devices = {
    web: "Computador",
    app: "Aplicativo",
  };
  return devices[text] || "Computador";
};

function AllActivities({ user }) {
  const history = useHistory();
  const location = useLocation();
  const [activities, setActivities] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);

  // Filtros
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  async function fetchActivities() {
    try {
      const items = await allActivities();
      if (items && _.size(items.message) > 0) {
        setActivities(items.message);
      } else {
        setActivities([]);
        // message.warning("Nenhuma atividade encontrada com os filtros selecionados.");
      }
    } catch (error) {
      console.error("Erro ao buscar atividades:", error);
      message.error("Ocorreu um erro ao buscar as atividades.");
    }
  }

  useEffect(() => {
    fetchActivities();
  }, [selectedDay, selectedType, selectedPage, selectedForm, selectedAuthor]);

  useEffect(() => {
    const data = location.state;
    if (data) {
      setActivities(data.activities);
    }
  }, [location.state, activities]);

  const routes = [
    {
      breadcrumbName: "atividades",
      href: "/atividades/todas",
    },
    {
      breadcrumbName: "todas",
    },
  ];
  const columns = [
    {
      title: "Formulário",
      dataIndex: "form",
      key: "form",
      render: (text, record) => (
        <>
          <Avatar
            size="small"
            icon={<FileSyncOutlined />}
            style={{
              backgroundColor: record.createdWith ? "darkviolet" : "#12b398",
            }}
          />
          <Button
            type="link"
            style={{
              color: record.createdWith ? "darkviolet" : "#12b398",
            }}
            onClick={() => history.push(`/formularios/registros/obj_${text}`)}
          >
            {text.replace("obj_", "")}
          </Button>
        </>
      ),
    },
    {
      title: (
        <>
          Origem
          <Tooltip placement="top" title="">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </>
      ),
      dataIndex: "page",
      key: "page",
      render: (text) => {
        const firstLetterUppercase =
          text.charAt(0).toUpperCase() + text.slice(1);
        return firstLetterUppercase;
      },
    },
    {
      title: "Dispositivo",
      dataIndex: "device",
      key: "device",
      render: (text) => {
        switch (text) {
          case "web":
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <DesktopOutlined style={{ marginRight: "8px" }} />
                <Text>Computador</Text>
              </div>
            );
          case "app":
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <MobileOutlined style={{ marginRight: "8px" }} />
                <Text>Aplicativo</Text>
              </div>
            );
          case "server":
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CloudServerOutlined style={{ marginRight: "8px" }} />
                <Text>Servidor</Text>
              </div>
            );
          default:
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <GlobalOutlined style={{ marginRight: "8px" }} />
                <Text>API</Text>
              </div>
            );
        }
      },
    },
    {
      title: "Conexāo",
      dataIndex: "network",
      key: "network",
      valueEnum: {
        offline: {
          text: "Offline",
          status: "Error",
        },
        online: {
          text: "Online",
          status: "Success",
        },
      },
    },
    {
      title: "Açāo",
      dataIndex: "action",
      key: "action",
      render: (text) => {
        switch (text) {
          case "POST":
            return <Text type="success">{getActionText(text)}</Text>;
          case "PUT":
            return <Text type="warning">{getActionText(text)}</Text>;
          case "DELETE":
            return <Text type="danger">{getActionText(text)}</Text>;
          default:
            return text;
        }
      },
    },
    {
      title: "Detalhes",
      dataIndex: "changes",
      key: "changes",
      render: (text, record) => {
        if (record.changes && record.changes.length > 0) {
          switch (record.page) {
            case "importaçāo":
            case "registro":
            case "lixeira":
              const size = _.size(_.uniqBy(_.map(record.changes, "id")));
              const isPut = record?.action === "PUT";
              return `${size === 0
                ? isPut
                  ? "alteraçāo"
                  : "registro"
                : size === 1
                  ? isPut
                    ? "1 alteraçāo"
                    : "1 registro"
                  : isPut
                    ? `${size} alterações`
                    : `${size} registros`
                }`;
            default:
              return "N/A";
          }
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Autor",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: (
        <>
          Criado em
          <Tooltip placement="top" title="Data da atividade">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD/MM/YYYY HH:mm:ss"),
      sorter: (a, b) =>
        moment(a.createdAt, "DD/MM/YYYY HH:mm:ss").unix() -
        moment(b.createdAt, "DD/MM/YYYY HH:mm:ss").unix(),
    },
    {
      title: "Opções",
      key: "actions",
      render: (text, record) => {
        const { page, action } = record;
        const normalizedPage = page
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""); // Normalizar e remover acentos
        const config =
          (actionMap[action] && actionMap[action][normalizedPage]) || {};
        return (
          <Space size="middle">
            {config.showRevertBtn && (
              <Button
                type={record?.isReverted ? "link" : "danger"}
                onClick={() => handleRevert(record)}
                disabled={record?.isReverted || !config.showRevertBtn}
              >
                {record?.isReverted ? "Revertido" : "Reverter"}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  /**
   * Reverte atividade
   * @param {*} record
   */
  function handleRevert(record) {
    const { page, action } = record;
    const normalizedPage = page
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, ""); // Normalizar e remover acentos
    const config =
      (actionMap[action] && actionMap[action][normalizedPage]) || {};
    confirm({
      title: `Você realmente deseja reverter?`,
      icon: <ExclamationCircleOutlined />,
      content: "Essa ação não pode ser desfeita.",
      okText: "Confirmar",
      onOk() {
        revertActivity({
          formName: record.form,
          activitieId: record._id,
          fatherActivitieId: record.createdWith,
          page: config.page || page,
          action: config.action || action,
          ids: record.changes,
          useBin: config.useBin || false,
          useActivitie: config.useActivitie || false,
          isImport: config.isImport || false,
          isObjDataUpdate: config.isObjDataUpdate || false,
          isObjDataRecover: config.isObjDataRecover || false,
          createdBy: user.uuid,
        }).then(async (res) => {
          await sleep(1000);
          window.location.reload();
        });
      },
      cancelText: "Cancelar",
    });
  }

  /**
   * Limpando campos de filtro
   */
  function handleClear() {
    setSelectedDay(null);
    setSelectedType(null);
    setSelectedPage(null);
    setSelectedForm(null);
    setSelectedAuthor(null);
    setSelectedDevice(null);
    setSelectedNetwork(null);
  }

  // Obter valores únicos para cada filtro
  const getUniqueValues = (key) => {
    let uniqueValues = _.uniqBy(activities, key);
    if (key === "form") {
      // Filtrar apenas o campo 'form' dos objetos únicos para evitar duplicação
      uniqueValues = _.uniqBy(activities, "form");
    }
    return uniqueValues.map((activity) => activity[key]);
  };

  // Filtrar atividades com base nos filtros selecionados
  const filterActivities = () => {
    let filteredActivities = [...activities];
    const filteredResults = [];

    if (selectedDay && selectedDay[0] && selectedDay[1]) {
      filteredActivities = filteredActivities.filter((activity) =>
        moment(activity.createdAt).isBetween(
          selectedDay[0].startOf("day"),
          selectedDay[1].endOf("day")
        )
      );
    }

    if (selectedType) {
      filteredActivities = filteredActivities.filter(
        (activity) => activity.action === selectedType
      );
    }

    if (selectedPage) {
      filteredActivities = filteredActivities.filter(
        (activity) => activity.page === selectedPage
      );
    }

    if (selectedForm) {
      filteredActivities = filteredActivities.filter(
        (activity) => activity.form === selectedForm
      );
    }

    if (selectedAuthor) {
      filteredActivities = filteredActivities.filter(
        (activity) => activity.createdBy === selectedAuthor
      );
    }

    if (selectedDevice) {
      filteredActivities = filteredActivities.filter(
        (activity) => activity.device === selectedDevice
      );
    }

    if (selectedNetwork) {
      filteredActivities = filteredActivities.filter(
        (activity) => activity.network === selectedNetwork
      );
    }

    // Criar campo de parentes
    filteredActivities.forEach((obj) => {
      const parentId = obj.createdWith;
      if (!parentId) {
        filteredResults.push({ ...obj, children: [] });
      }
    });

    // Popula com os parentes da atividade
    filteredActivities.forEach((obj) => {
      const parentId = obj.createdWith;
      if (parentId) {
        const parentIndex = filteredResults.findIndex(
          (parent) => parent._id === parentId
        );
        if (parentIndex !== -1) {
          filteredResults[parentIndex].children.push(obj);
        }
      }
    });
    return filteredResults;
  };

  const expandedRowRender = (record) => {

    const size = _.size(record.children);
    // Agrupar as mudanças por ID e field usando Lodash
    const groupedChanges = _.groupBy(
      record.changes,
      (change) => `${change.id}_${change.field}`
    );

    // Transformar os valores dos campos "multipla" e "referenciado" em um único valor com vírgula
    for (const key in groupedChanges) {
      const values = groupedChanges[key].map((item) => item.value);
      const uniqueValues = _.uniq(values);
      groupedChanges[key] = {
        ...groupedChanges[key][0], // Manter apenas um item para o campo no grupo
        value: uniqueValues.map(item => renderValueIfDate(item)).join(", "), // Juntar os valores com vírgula
      };
    }

    const uniqueIds = _.uniqBy(record.changes, "id");

    const renderChangesList = (changes, action) => {
      return (
        <List
          grid={{ gutter: 6, column: 3 }}
          dataSource={changes}
          renderItem={(item) => {
            const fieldName = (item.field) ? (item.field === "createdAt" ? "Criado em" : item.field.charAt(0).toUpperCase() + item.field.slice(1)) : null;
            const oldValue = !_.isNumber(item.oldValue) ? (_.size(item.oldValue) > 0 ? item.oldValue : "N/A") : _.toString(item.oldValue);
            const newValue = !_.isNumber(item.oldValue) ? (_.size(item.value) > 0 ? item.value : "N/A") : _.toString(item.value);
            const showOldAndNewValues = action === "PUT";
            return (
              <List.Item style={{ marginBottom: 3 }}>
                {fieldName && <Typography.Text strong>• {fieldName}:</Typography.Text>}{" "}
                {showOldAndNewValues ? (
                  <>
                    <Typography.Text code>{renderValueIfDate(oldValue)}</Typography.Text> para{" "}
                    <Typography.Text code>{renderValueIfDate(newValue)}</Typography.Text>
                  </>
                ) : (
                  <Typography.Text code>{renderValueIfDate(item.value) || "N/A"}</Typography.Text>
                )}
              </List.Item>
            );
          }}
        />
      );
    };

    const RenderByFieldValue = () => {
      if (!record.children || _.size(record.children) === 0) {
        return (
          <div
            style={{
              maxHeight: "260px",
              overflowY: "auto",
              padding: "0 15px 0 15px",
            }}
          >
            {uniqueIds.map((item) => (
              <React.Fragment key={item.id}>
                {item.id && <Typography.Text strong>ID: {item.id}</Typography.Text>}
                {renderChangesList(
                  Object.keys(groupedChanges)
                    .filter((key) => groupedChanges[key].id === item.id)
                    .map((key) => groupedChanges[key]),
                  "POST"
                )}
              </React.Fragment>
            ))}
          </div>
        );
      } else {
        return (
          <Typography.Text>
            {size === 1
              ? `${size} atividade relacionada`
              : `${size} atividades relacionadas`}
          </Typography.Text>
        );
      }
    };

    const actions = {
      POST: <RenderByFieldValue />,
      PUT: (
        <div style={{
          maxHeight: "260px",
          overflowY: "auto",
          padding: "0 15px 0 15px",
        }}>
          {uniqueIds.map((item) => (
            <React.Fragment key={item.id}>
              {(item.id) && <Typography.Text strong>ID: {item.id}</Typography.Text>}
              {renderChangesList(
                Object.keys(groupedChanges)
                  .filter((key) => groupedChanges[key].id === item.id)
                  .map((key) => groupedChanges[key]),
                "PUT"
              )}
            </React.Fragment>
          ))}
        </div>
      ),
      DELETE: <RenderByFieldValue />,
    };

    if (actions[record.action] && _.size(record.changes) > 0) {
      return actions[record.action];
    } else {
      return (
        <Empty
          description="Nenhuma ação foi realizada nesta atividade."
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }
  };

  const rowExpandable = (record) => {
    const { page, action } = record;
    const normalizedPage = page
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, ""); // Normalizar e remover acentos

    const config =
      (actionMap[action] && actionMap[action][normalizedPage]) || {};
    // Retorna a propriedade 'actionMap' com 'action' da atividade
    return config.showDetails;
  };

  const onExpandIconClick = (key) => {
    let newActiveKeys = [...activeKeys];
    if (newActiveKeys.includes(key)) {
      newActiveKeys = newActiveKeys.filter((k) => k !== key);
    } else {
      newActiveKeys.push(key);
    }
    setActiveKeys(newActiveKeys);
  };

  const renderValueIfDate = (value) => {
    if (moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
      return moment.utc(value, 'YYYY-MM-DDTHH:mm:ssZ').format('DD/MM/YYYY HH:mm:ss')
    } else {
      return value
    }
  };

  const customExpandIcon = (panelProps) => {
    const { isActive, panelKey } = panelProps;
    return (
      <span>
        {isActive ? (
          <>
            <Button onClick={() => onExpandIconClick(panelKey)} type="primary">
              Filtros <CaretDownOutlined />
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => onExpandIconClick(panelKey)} type="primary">
              Filtros <CaretRightOutlined />
            </Button>
          </>
        )}
      </span>
    );
  };

  return (
    <Layout>
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout
            style={{
              padding: "0 24px 24px",
              minHeight: "100vh",
            }}
          >
            <PageHeader
              style={{ margin: "16px 0 5px 0" }}
              title="Lista de atividades"
              subTitle="Acompanhe o lançamento, edição e exclusão de qualquer formulário ou campo."
              onBack={() => window.history.back()}
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: "fit-content",
                background: "white",
              }}
            >
              <Row gutter={16}>
                {/* Começo dos filtros */}
                <Collapse
                  activeKey={activeKeys}
                  expandIcon={customExpandIcon}
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    background: "#f7f7f700",
                    border: "0px",
                    borderRadius: "2px",
                  }}
                >
                  <Panel
                    header={
                      <Row gutter={16}>
                        <Col span={13}>
                          <ConfigProvider locale={datePickerLocale}>
                            <RangePicker
                              value={selectedDay}
                              onChange={(dates) => setSelectedDay(dates)}
                              style={{ width: "100%" }}
                              allowClear
                            />
                          </ConfigProvider>
                        </Col>
                        <Col span={11}>
                          <Select
                            placeholder="Formulário"
                            value={selectedForm}
                            onChange={setSelectedForm}
                            style={{ width: "100%" }}
                            allowClear
                          >
                            {getUniqueValues("form").map((form) => (
                              <Option key={form} value={form}>
                                {form.replace("obj_", "")}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                    }
                    extra={
                      <span>
                        {selectedDay ||
                          selectedType ||
                          selectedPage ||
                          selectedForm ||
                          selectedAuthor ||
                          selectedDevice ||
                          selectedNetwork ? (
                          <div style={{ display: 'flex', gap: '16px' }}>
                          <>
                              <Button type="primary"
                                onClick={() => history.push("/formularios")}
                              >
                                Formulários
                              </Button>
                            </>
                            <>
                              <Button
                                type="primary"
                                onClick={() => handleClear()}
                              >
                                Limpar filtros
                              </Button>
                            </>
                          </div>
                        ) : (
                            <div style={{ display: 'flex', gap: '16px' }}>
                              <Button type="primary"
                                onClick={() => history.push("formularios/todas")}
                              >
                                Formulários
                              </Button>
                              <Button type="primary" disabled>
                                Limpar filtros
                              </Button>
                            </div>
                        )}
                      </span>
                    }
                    key="1"
                  >
                    <Row gutter={16}>
                      <Col span={3}>
                        <Select
                          placeholder="Açāo"
                          value={selectedType}
                          onChange={setSelectedType}
                          style={{ width: "100%" }}
                          allowClear
                        >
                          {getUniqueValues("action").map((type) => (
                            <Option key={type} value={type}>
                              {getActionText(type)}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={3}>
                        <Select
                          placeholder="Origem"
                          value={selectedPage}
                          onChange={setSelectedPage}
                          style={{ width: "100%" }}
                          allowClear
                        >
                          {getUniqueValues("page").map((page) => (
                            <Option key={page} value={page}>
                              {page.charAt(0).toUpperCase() + page.slice(1)}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={3}>
                        <Select
                          placeholder="Dispositivo"
                          value={selectedDevice}
                          onChange={setSelectedDevice}
                          style={{ width: "100%" }}
                          allowClear
                        >
                          <Option value="web">
                            <span>
                              <DesktopOutlined style={{ marginRight: 8 }} />
                              Computador
                            </span>
                          </Option>
                          <Option value="app">
                            {" "}
                            <span>
                              <MobileOutlined style={{ marginRight: 8 }} />
                              Aplicativo
                            </span>
                          </Option>
                          <Option value="server">
                            <span>
                              <CloudServerOutlined style={{ marginRight: 8 }} />
                              Servidor
                            </span>
                          </Option>
                          <Option value="api">
                            <span>
                              <GlobalOutlined style={{ marginRight: 8 }} />
                              API
                            </span>
                          </Option>
                        </Select>
                      </Col>
                      <Col span={3}>
                        <Select
                          placeholder="Conexão"
                          value={selectedNetwork}
                          onChange={setSelectedNetwork}
                          style={{ width: "100%" }}
                          allowClear
                        >
                          <Option value="online">
                            <span>
                              <Badge status="success" />
                              Online
                            </span>
                          </Option>
                          <Option value="offline">
                            <span>
                              <Badge status="error" />
                              Offline
                            </span>
                          </Option>
                        </Select>
                      </Col>
                      <Col span={4}>
                        <Select
                          placeholder="Autor"
                          value={selectedAuthor}
                          onChange={setSelectedAuthor}
                          style={{ width: "100%" }}
                          allowClear
                        >
                          {getUniqueValues("createdBy").map((author) => (
                            <Option key={author} value={author}>
                              {author}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
                <Col span={24}>
                  <ConfigProvider locale={ptBRIntl}>
                    <ProTable
                      id="core-table"
                      style={{
                        marginTop: "1rem",
                        background: "white",
                      }}
                      headerTitle={`${activities.length} ${activities.length === 1 ? "atividade" : "atividades"
                        } ${activities.length === 1 ? "encontrada" : "encontradas"
                        }`}
                      tooltip="Acompanhe seus formulários, campos e dispositivos."
                      columns={columns}
                      dataSource={filterActivities()}
                      rowKey="_id"
                      cardBordered
                      search={false}
                      scroll={{ x: true }}
                      expandable={{
                        expandedRowRender,
                        rowExpandable,
                      }}
                      options={{
                        reload: false,
                      }}
                      locale={{
                        emptyText: (
                          <Empty description="Nenhuma atividade adicionada." />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps)(AllActivities);
