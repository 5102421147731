import axios from "axios";
import { createStandaloneToast } from "@chakra-ui/react";

/**
 * Services
 */
import getApiUrl from "services/getApiUrl";
import convertToIndex from "components/Utils/convertToIndex";
import _ from "lodash";

/**
 * Cria formulário.
 * @param {*} items
 * @returns response
 */
export const addObject = async (items) => {
  const { formName, needConfirmation, createdBy } = items;
  return await axios
    .post(`${getApiUrl()}/objetos/novo`, {
      formName,
      needConfirmation: !!needConfirmation,
      createdBy
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Busca por formulário existente.
 * @param {*} items
 * @returns
 */
export const findObject = async (items) => {
  const { name } = items;
  return await axios
    .post(`${getApiUrl()}/objetos/pesquisar`, {
      name,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Lista de todos formulários sem filtro.
 * @returns response
 */
export const allObjects = async () => {
  return await fetch(`${getApiUrl()}/objetos/todos`).then((response) =>
    response.json()
  );
};

/**
 * Lista de todos formulários com filtro ${name}.
 * @returns response
 */
export const allFormsOfSavedObjects = async (formName = null) => {
  let url = `${getApiUrl()}/objetos/formularios/dados/todos`;

  if(formName) {
    url += `?formName=${encodeURIComponent(formName)}`;
  }
  return await fetch(url).then(
    (response) => response.json()
  );
};


export const allFormsOfSavedObjectsPagination = async ({formName = null, pageSize = 20, page = 1}) => {
    let url = `${getApiUrl()}/objetos/formularios/dados/todos/paginado`;

    const params = new URLSearchParams();
    if (formName) {
        params.append('formName', formName);
    }
    params.append('pageSize', pageSize);
    params.append('page', page);

    if (params.toString()) {
        url += `?${params.toString()}`;
    }

    return await fetch(url).then(
        (response) => response.json()
    );
};

/**
 * Lista de todos formulários com campos.
 * @returns response
 */
export const allFormsOfSavedObjectsWithFields = async () => {
  return await fetch(
    `${getApiUrl()}/objetos/formularios/categorias/dados/todos`
  ).then((response) => response.json());
};

export const allFormsOfSavedObjectsWithFieldsSearch = async (categories) => {
  return await axios
    .post(`${getApiUrl()}/objetos/formularios/categorias/dados/pesquisar`, {
      categories,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Adiciona novo campo ao formulário.
 * @param {*} objects
 * @returns response
 */
export const addFields = async (objects) => {
  const { formName, fields, createdBy } = objects;
  const { toast } = createStandaloneToast();
  return await axios
    .post(`${getApiUrl()}/objetos/campos/novo`, {
      formName,
      fields,
      createdBy
    })
    .then(() => {
      toast({
        title: "Adição",
        position: "top-right",
        description: `Campo(s) adicionado com sucesso no formulário: ${convertToIndex(formName.replace("obj_", ""))}`,
        isClosable: false,
        status: "success",
        duration: 3500,
      });
    })
    .catch((err) => {
      toast({
        title: "Exclusão",
        position: "top-right",
        description: err.response.data.message || "Algo está errado tente novamente!",
        isClosable: false,
        status: "error",
        duration: 2500,
      });
      return err.response.data;
    });
};

/**
 * Obtém todos campos de um formulário.
 * @param {*} items
 * @returns
 */
export const allFieldsByName = async (items) => {
  const { formName } = items;
  return await axios
    .get(`${getApiUrl()}/objetos/${formName}/campos/todos?withRef=true`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
/**
 * Obtém metadados de um formulário.
 * @param {*} items
 * @returns
 */
export const getMetadataOfObj = async (items) => {
  const { formName } = items;
  return await axios
    .get(`${getApiUrl()}/objetos/${formName}/metadata/obter`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Obtém campos de vários formulário.
 * @param {*} ids
 * @returns
 */
export const allFieldsByNames = async (ids) => {
  return await axios
    .post(`${getApiUrl()}/objetos/formularios/campos/todos`, {
      ids,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Obtém campos de um formulário pelo seu id.
 * @param {*} formId
 * @returns
 */
export const allFieldsById = async (formId) => {
    return await axios
        .get(`${getApiUrl()}/objetos/formularios/campos/todos/${formId}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
        });
};


/**
 * Exclui um formulario.
 * @param {*} table
 * @param {*} history
 * @returns
 */
export const dropObj = async (table, deletedBy) => {
  const { toast } = createStandaloneToast();
  return await axios
    .post(`${getApiUrl()}/objetos/remover`, { table: table,deletedBy })
    .then((response) => {
      toast({
        title: "Exclusão",
        position: "top-right",
        description: "Formulario excluído com sucesso",
        isClosable: false,
        status: "success",
        duration: 1300,
      });

      return response.data;
    })
    .catch((err) => {
      toast({
        title: "Exclusão",
        position: "top-right",
        description: err.response.data.message || "Algo está errado tente novamente!",
        isClosable: false,
        status: "error",
        duration: 2500,
      });
      return err.response.data;
    });
};

/**
 * Edita o campo de um formulario.
 * @param {*} table
 * @param {*} info
 * @returns
 */
export const editfieldObj = async (table, info, updatedBy) => {
  const { toast } = createStandaloneToast();
  return await axios
    .post(`${getApiUrl()}/objetos/campos/editar`, {
      table: table,
      _id: info._id,
      index: info.index,
      field: info.field,
      checkBox: info.checkBox,
      refFields: info.refFields,
      getValueOf: info.getValueOf,
      checkValueIn: info.checkValueIn,
      isChanged: info.isChanged,
      oldProps: info.oldProps,
      dica: info.dica,
      sufixo: info.sufixo,
      type: info.fieldtype,
      autoFill: info.autoFill,
      marker: info.marker,
      requiredField: info.requiredField,
      updatedBy,
      formula: info.formula ?? "",
      returntype: info.returntype ?? ""
    })
    .then((response) => {
      toast({
        title: "Edição",
        position: "top-right",
        description: "Formulário editado com sucesso",
        isClosable: false,
        status: "success",
        duration: 3300,
      });
      return response.data;
    })
    .catch((err) => {
      toast({
        title: "Edição",
        position: "top-right",
        description: err.response.data.message || "Algo está errado tente novamente!",
        isClosable: false,
        status: "error",
        duration: 2500,
      });
      return err.response.data;
    });
};

/**
 * Exclui campo de um formulario.
 * @param {*} table
 * @param {*} id
 * @param archivedBy
 * @returns
 */
export const dropObjField = async (table, id,archivedBy) => {
  const { toast } = createStandaloneToast();
  return await axios
    .post(`${getApiUrl()}/objetos/campos/remover`, {
      table: table,
      _id: id,
      isPermanent: false,
      archivedBy
    })
    .then((response) => {
      toast({
        title: "Exclusão",
        position: "top-right",
        description: "Campo excluído com sucesso.",
        isClosable: false,
        status: "success",
        duration: 2000,
      });
      return response.data;
    })
    .catch((err) => {
      toast({
        title: "Exclusão",
        position: "top-right",
        description: err.response.data.message,
        isClosable: false,
        status: "error",
        duration: 2500,
      });
      return err.response.data;
    });
};

/**
 * Exclui campo arquivado de um formulario.
 * @param {*} table
 * @param {*} id
 * @param {*} history
 * @returns
 */
export const dropArchivedObjField = async (objects) => {
  const { formName, recordId, fields,deletedBy } = objects;
  const { toast } = createStandaloneToast();
  return await axios
    .post(`${getApiUrl()}/objetos/${formName}/metadata/adicionar`, {
      data: fields,
      isPermanent: true,
      _id: recordId,
      deletedBy
    })
    .then((response) => {
      toast({
        title: "Exclusão arquivados",
        position: "top-right",
        description: "Dados da coluna e campo excluido com sucesso.",
        isClosable: false,
        status: "success",
        duration: 1300,
      });
      return response.data;
    })
    .catch((err) => {
      toast({
        title: "Exclusão arquivados",
        position: "top-right",
        description: err.response.data.message,
        isClosable: false,
        status: "error",
        duration: 1300,
      });
      return err.response.data;
    });
};

/**
 * Altera a posição de exibição dos campos do objeto
 * @param {*} objects
 * @returns
 */
export const alterpos = async (objects) => {
  const { formName, fields, sortedBy } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/alterpos`, {
      formName,
      fields,
      sortedBy
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Edição",
        position: "top-right",
        description: err.response.data.message,
        isClosable: false,
        status: "error",
        duration: 3300,
      });
      return err.response.data;
    });
};
export const updateMetadataOfObj = async (objects) => {
  const { formName, fields } = objects;
  const { toast } = createStandaloneToast();
  return await axios
    .post(`${getApiUrl()}/objetos/${formName}/metadata/adicionar`, {
      data: fields,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast({
        title: "Edição",
        position: "top-right",
        description: err.response.data.message,
        isClosable: false,
        status: "error",
        duration: 3300,
      });
      return err.response.data;
    });
};
/**
 * Obtém todos dados salvados em um ou mais objetos.
 * @param {*} forms
 * @returns
 */
export const savedDataInObj = async ({ forms, ids, useMatchAll, useBin }) => {
  return await axios
    .post(`${getApiUrl()}/objetos/campos/todos/dados`, {
      forms,
      ids,
      useMatchAll,
      useBin,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Cria categoria para formulário.
 * @param {*} items
 * @returns response
 */
export const addCategoryObj = async (items) => {
  const { categoryName, categoryColor,createdBy } = items;
  return await axios
    .post(`${getApiUrl()}/objetos/categoria/nova`, {
      categoryName,
      categoryColor,
      createdBy
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Busca por categoria do formulário existente.
 * @param {*} items
 * @returns
 */
export const findCategoryObj = async (items) => {
  const { id } = items;
  return await axios
    .get(`${getApiUrl()}/objetos/categoria/${id}/pesquisar`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Edita categoria do formulário.
 * @param {*} objects
 * @returns
 */
export const editCategoryObj = async (objects) => {
  const { id, name, color,updatedBy } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/categoria/editar/${id}`, {
      name,
      color,
      updatedBy
    })
    .then((response) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Ediçao",
        position: "top-right",
        description: "Categoria editada com sucesso",
        isClosable: false,
        status: "success",
        duration: 3300,
      });
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Lista todas categorias dos formulários.
 * @returns
 */
export const allCategoriesObj = async () => {
  return await fetch(`${getApiUrl()}/objetos/categoria/todas`).then(
    (response) => response.json()
  );
};

/**
 * Remove categoria.
 * @param {*} objects
 * @returns
 */
export const removeCategoryObj = async (objects) => {
  const { _id, deletedBy } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/categoria/remover`, {
      id: _id,
      deletedBy
    })
    .then((response) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Ediçao",
        position: "top-right",
        description: "Categoria removida com sucesso",
        isClosable: false,
        status: "success",
        duration: 3300,
      });
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Obtém todas categorias de um formulário.
 * @param {*} objects
 * @returns
 */
export const allCategoriesOfObj = async (objects) => {
  const { formName } = objects;
  return await axios
    .get(`${getApiUrl()}/objetos/formularios/categorias/dados/${formName}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Obtém todos formulario com categorias.
 * @param {*} objects
 * @returns
 */
export const allCategoriesWithObj = async () => {
  return await axios
    .get(`${getApiUrl()}/objetos/formularios/categorias/dados/pesquisar/todos`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Adiciona categoria em um formulário.
 * @param {*} objects
 * @returns
 */
export const pushCategoryInObj = async (objects) => {
  const { formName, categories, updatedBy } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/formularios/categorias/push/${formName}`, {
      categories: categories,
      updatedBy
    })
    .then((response) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Edição",
        position: "top-right",
        description: "Categoria adicionada com sucesso!",
        isClosable: false,
        status: "success",
        duration: 3300,
      });
      return response.data;
    })
    .catch((err) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Edição",
        position: "top-right",
        description: err.response.data.message,
        isClosable: false,
        status: "error",
        duration: 3300,
      });
      return err.response.data;
    });
};

/**
 * Remove categoria de um formulário.
 * @param {*} objects
 * @returns
 */
export const pullCategoryInObj = async (objects) => {
  const { formName, categories,updatedBy } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/formularios/categorias/pull/${formName}`, {
      categories: categories,
      updatedBy
    })
    .then((response) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Edição",
        position: "top-right",
        description: "Categoria removida com sucesso!",
        isClosable: false,
        status: "success",
        duration: 3300,
      });
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Atualiza confirmaçāo de um formulário.
 * @param {*} objects
 * @returns
 */
export const changeConfirmationInObj = async (objects) => {
  const { id, needConfirmation, updatedBy } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/formularios/confirmacao/alterar`, {
      id,
      needConfirmation,
      updatedBy
    })
    .then((response) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Confirmaçāo",
        position: "top-right",
        description: "Atualizado com sucesso!",
        isClosable: false,
        status: "success",
        duration: 3300,
      });
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Adiciona registro
 * @param {*} objects
 * @returns
 */
export const addRecords = async (objects) => {
  const { formName, record, createdBy,acceptEmptyValues = false} = objects;
  return await axios
    .post(
      `${getApiUrl()}/objetos/formularios/dados/novo?device=web&network=online`,
      {
        formName,
        [`record${(_.isArray(record)) ?'s':''}`]: record,
        createdBy,
        acceptEmptyValues
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Restaura registros da lixeira
 * @param {*} objects
 * @returns
 */
export const restoreRecords = async (objects) => {
  const { formName, ids, createdBy } = objects;
  return await axios
    .post(
      `${getApiUrl()}/objetos/formularios/dados/restaurar?device=web&network=online`,
      {
        formName,
        ids,
        createdBy,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Atualiza registros salvos
 * @param {*} objects
 * @returns
 */
export const updateRecords = async (objects) => {
  const { formName, ids, records, forRemove, createdBy } = objects;
  return await axios
    .post(
      `${getApiUrl()}/objetos/formularios/dados/alterar?device=web&network=online`,
      {
        formName,
        ids,
        records,
        forRemove,
        createdBy,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Atualiza registros salvos
 * @param {*} objects
 * @returns
 */
export const deleteRecords = async (objects) => {
  const { formName, ids, records, useBin, isObjDataDelete, createdBy } =
    objects;
  return await axios
    .post(
      `${getApiUrl()}/objetos/formularios/dados/remover?device=web&network=online`,
      {
        formName,
        ids,
        useBin,
        isObjDataDelete,
        createdBy,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Adiciona novo hit na coleçāo
 * @param {*} objects
 * @returns
 */
export const addHitByObj = async (objects) => {
  const { index, id } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/buscador/novo/hit`, {
      index,
      id,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Adiciona formulário como favorito
 * @param {*} objects
 * @returns
 */
export const pushObjInFavorite = async (objects) => {
  const { formId, userId, type } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/formularios/favoritos/push/${formId}`, {
      userId,
      type,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};


/**
 * Adiciona formulário como favorito
 * @param {*} objects
 * @returns
 */
export const pullObjInFavorite = async (objects) => {
  const { formId, userId, type } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/formularios/favoritos/pull/${formId}`, {
      userId,
      type,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Pesquisa em todos os formulários
 * @param {*} objects
 * @returns
 */
export const searchAllByObj = async (objects) => {
  const { q } = objects;
  return await axios
    .post(`${getApiUrl()}/objetos/buscador/formularios/todos`, {
      q,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getRefFieldsById = async ({ fields }) => {
  return await axios
    .post(`${getApiUrl()}/objetos/campos/pesquisar/id`, {
      fields,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getRefFieldsByData = async ({ fields, withCreatedAt, onlyCreatedAt }) => {
  return await axios
    .post(`${getApiUrl()}/objetos/buscador/dados/campos`, {
      fields,
      withCreatedAt,
      onlyCreatedAt,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Obtém detalhes de um registro
 * com nome do form e id do registro
 * @param {*} param0
 * @returns
 */
export const getFieldsDataById = async ({ table, id }) => {
  return await axios
    .post(`${getApiUrl()}/objetos/buscador/formularios/dados/detalhes`, {
      table,
      id,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Obtém detalhes de um registro
 * com nome id do registro
 * @param {*} param0
 * @returns
 */
export const getFieldsDataByDocId = async ({ id, viewAsField }) => {
  return await axios
    .post(`${getApiUrl()}/objetos/buscador/formularios/registro/detalhes`, {
      id,
      viewAsField,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Obtém detalhes de registro
 * com lista de ids
 * @param {*} ids
 * @returns
 */
export const getFieldsDataByDocIds = async ({ ids, viewAsField }) => {
  return await axios
    .post(`${getApiUrl()}/objetos/buscador/formularios/registros/detalhes`, {
      ids,
      viewAsField,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Verifica a opçāo selecionada no campo lookup
 * @param {*} param0
 * @returns
 */
export const getFieldsDataByLookup = async ({ table, id, fieldId, checkIn }) => {
  return await axios
    .post(`${getApiUrl()}/objetos/buscador/formularios/dados/lookup/detalhes`, {
      table,
      id,
      fieldId,
      checkIn,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Verifica a opçāo selecionada no campo lookup
 * @returns
 * @param data
 */
export const verifyCanDeleteField = async (data = {}) => {
  return await axios
      .post(`${getApiUrl()}/objetos/campos/remove/verify`, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
};
