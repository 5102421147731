import axios from "axios";
import { createStandaloneToast } from "@chakra-ui/react";

/**
 * Services
 */
import getApiUrl from "services/getApiUrl";

export const importSingleFile = async (items) => {
  const { formName, file, queueId, createdBy } = items;
  const formData = new FormData();
  formData.append("file", file);

  return await axios
    .post(`${getApiUrl()}/importar/registros/csv`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        formName,
        queueId,
        createdBy,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const filesHistoryByForm = async (form) => {
  return await axios
    .get(
      `${getApiUrl()}/importar/registros/historico/formulario?formName=${form}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const filesHistoryById = async (id) => {
  return await axios
    .get(`${getApiUrl()}/importar/registros/historico/formulario?id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const filesHistoryErrosMsgByForm = async (form, id) => {
  return await axios
    .get(
      `${getApiUrl()}/importar/registros/historico/formulario/erros/mensagens?formName=${form}&queueId=${id}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/**
 * Atualiza registros salvos
 * @param {*} objects
 * @returns
 */
export const filesHistoryErrorRemove = async (objects) => {
  const { id, formName, errorIds, createdBy } = objects;
  return await axios
    .post(
      `${getApiUrl()}/importar/registros/remover/erro/formulario?id=${id}`,
      {
        formName,
        errorIds,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
