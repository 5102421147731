function validateCSVColumns (csvData, validColumns) {
  // Extract the header row from the CSV data
  const headerRow = csvData[0];
  // console.log("CSV Header:", csvData[0]);
  // console.log("Coluna válidas:", validColumns);

  // Check if the header row contains all the valid columns
  const missingColumns = validColumns.filter(
    (column) => !headerRow.includes(column)
  );

  // Return an array of missing columns
  return missingColumns;
};

export {
  validateCSVColumns
};
