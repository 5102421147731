export const functions = [
    {
        "category": "SEARCH",
        "name": "LOOKUPDB",
        "example": 'LOOKUPDB("value", "indexName", "lookupField", "returnField")',
        "result": "[...]",
        "description": "Executa uma busca no index especificado com base no valor de lookup e retorna um array com os valores do campo de retorno.",
        "params": [
            {"name": "lookupValue", "description": "O valor para a busca"},
            {"name": "indexName", "description": "O nome do índice de busca"},
            {"name": "lookupField", "description": "O campo no qual a busca deve ser realizada"},
            {"name": "returnField", "description": "O campo que deve ser retornado na busca"}
        ]
    },
    {
        "category": "SEARCH",
        "name": "MAXROW",
        "example": 'MAXROW("indexName", "fieldName")',
        "result": "{...}",
        "description": "Retorna o registro com o maior valor no campo especificado do índice especificado.",
        "params": [
            {"name": "indexName", "description": "O nome do índice de busca"},
            {"name": "fieldName", "description": "O campo pelo qual a ordenação deve ser feita"}]
    }, {
        "category": "SEARCH",
        "name": "MINROW",
        "example": 'MINROW("indexName", "fieldName")',
        "result": "{...}",
        "description": "Retorna o registro com o menor valor no campo especificado do índice especificado.",
        "params": [
            {
                "name": "indexName",
                "description": "O nome do índice de busca"
            },
            {
            "name": "fieldName",
            "description": "O campo pelo qual a ordenação deve ser feita"
            }]
    }, {
        "category": "SEARCH",
        "name": "SELECT",
        "example": 'SELECT("indexName", {...}, 10)',
        "result": "[...]",
        "description": "Executa uma query no índice especificado e retorna até o limite de registros.",
        "params": [
            {
                "name": "indexName", "description": "O nome do índice de busca"
            },
            {
                "name": "query",
                "description": "A query de busca 'text'"
            },
            {
                "name": "limit",
                "description": "O número máximo de resultados a retornar"
            }]
    }, {
        "category": "SEARCH",
        "name": "SELECTALL",
        "example": 'SELECTALL("indexName", {...}, 10000)',
        "result": "[...]",
        "description": "Executa uma query no índice especificado e retorna todos registros encontrados até o limite especificado.",
        "params": [
            {
                "name": "indexName",
                "description": "O nome do índice de busca"
            },
            {
                "name": "query",
                "description": "A query de busca"
            },
            {
                "name": "limit",
                "description": "O número máximo de resultados a retornar"
            }]
    }, {
        "category": "UTILITIES",
        "name": "ORDERBY",
        "example": 'ORDERBY([...], {"field": "asc"})',
        "result": "[...]",
        "description": "Ordena os dados com base nas chaves de classificação fornecidas.",
        "params": [
            {
                "name": "data",
                "description": "Os dados a serem ordenados"
            },
            {
            "name": "sortingKeys",
            "description": "Um objeto com as chaves para ordenação e a direção correspondente"
        }
        ]
    },
    {
        "category": "DATE",
        "name": "DATE",
        "example": "DATE(2008, 7, 8)",
        "result": "Tue Jul 08 2008 00:00:00 GMT-0700 (PDT)",
        "description": "Retorna o número serial de uma data específica.",
        "params": [
            {
                "name": "ano",
                "description": "Ano"
            },
            {
                "name": "mês",
                "description": "Mês"
            },
            {
                "name": "dia",
                "description": "Dia"
            }
        ]
    }, {
        "category": "DATE",
        "name": "DATEVALUE",
        "example": "DATEVALUE('8/22/2011')",
        "result": "Mon Aug 22 2011 00:00:00 GMT-0700 (PDT)",
        "description": "Converte uma data no formato de texto para um número serial.",
        "params": [
            {
                "name": "date_text",
                "description": "Texto que representa uma data em um formato de data do Excel, ou uma referência a um valor que contém texto que representa uma data em um formato de data do Excel."
            }
        ]
    }, {
        "category": "DATE",
        "name": "DAY",
        "example": "DAY('15-Apr-11')",
        "result": "15",
        "description": "Converte um número serial para um dia do mês.",
        "params": [
            {
                "name": "serial_number",
                "description": "A data do dia que está procurando."
            }
        ]
    }, {
        "category": "DATE",
        "name": "DAYS",
        "example": "DAYS('3/15/11', '2/1/11')",
        "result": "42",
        "description": "Retorna o número de dias entre duas datas.",
        "params": [
            {
                "name": "end_date",
                "description": "A data inicial e a data final são as duas datas entre as quais você deseja saber o número de dias."
            },
            {
                "name": "start_date",
                "description": "A data inicial e a data final são as duas datas entre as quais você deseja saber o número de dias."
            }
        ]
    }, {
        "category": "DATE",
        "name": "DAYS360",
        "example": "DAYS360('1-Jan-11', '31-Dec-11')",
        "result": "360",
        "description": "Calcula o número de dias entre duas datas com base em um ano de 360 dias.",
        "params": [
            {
                "name": "start_date",
                "description": "Uma data que representa a data de início. Se a start_date ocorrer após a end_date, a função DAYS360 retorna um número negativo."
            },
            {
                "name": "end_date",
                "description": "Uma data que representa a data final."
            },
            {
                "name": "method",
                "description": "Opcional. Um valor lógico que especifica se deve usar o método dos EUA ou europeu no cálculo."
            }
        ]
    }, {
        "category": "DATE",
        "name": "EDATE",
        "example": "EDATE('1/15/11', -1)",
        "result": "Wed Dec 15 2010 00:00:00 GMT-0800 (PST)",
        "description": "Retorna o número serial da data que é o número indicado de meses antes ou depois da data de início.",
        "params": [
            {
                "name": "start_date",
                "description": "Uma data que representa a data de início."
            },
            {
                "name": "months",
                "description": "O número de meses antes ou depois da data de início. Um valor positivo para meses produz uma data futura; um valor negativo produz uma data passada."
            }
        ]
    }, {
        "category": "DATE",
        "name": "EOMONTH",
        "example": "EOMONTH('1/1/11', -3)",
        "result": "Sun Oct 31 2010 00:00:00 GMT-0700 (PDT)",
        "description": "Retorna o número serial do último dia do mês antes ou depois de um número especificado de meses.",
        "params": [
            {
                "name": "start_date",
                "description": "Uma data que representa a data de início."
            },
            {
                "name": "months",
                "description": "O número de meses antes ou depois da data de início. Um valor positivo para meses produz uma data futura; um valor negativo produz uma data passada."
            }
        ]
    }, {
        "category": "DATE",
        "name": "HOUR",
        "example": "HOUR('7/18/2011 7:45:00 AM')",
        "result": "7",
        "description": "Converte um número serial em uma hora.",
        "params": [
            {
                "name": "serial_number",
                "description": "A hora que contém a hora que você quer encontrar. Os horários podem ser inseridos como strings de texto entre aspas (por exemplo, '6:45 PM'), como números decimais (por exemplo, 0.78125, que representa 6:45 PM), ou como resultados de outras fórmulas ou funções (por exemplo, TIMEVALUE('6:45 PM'))."
            }
        ]

    }, {
        "category": "DATE",
        "name": "MINUTE",
        "example": "MINUTE('2/1/2011 12:45:00 PM')",
        "result": "45",
        "description": "Converte um número serial em um minuto.",
        "params": [
            {
                "name": "serial_number",
                "description": "O tempo que contém o minuto que você quer encontrar. Os horários podem ser inseridos como strings de texto entre aspas (por exemplo, '6:45 PM'), como números decimais (por exemplo, 0.78125, que representa 6:45 PM), ou como resultado de outras fórmulas ou funções (por exemplo, TIMEVALUE('6:45 PM'))."
            }
        ]
    }, {
        "category": "DATE",
        "name": "ISOWEEKNUM",
        "example": "ISOWEEKNUM('3/9/2012')",
        "result": "10",
        "description": "Retorna o número da semana ISO do ano para uma data específica.",
        "params": [
            {
                "name": "date",
                "description": "Date é o código de data-hora usado pelo Excel para cálculo de data e hora."
            }
        ]
    }, {
        "category": "DATE",
        "name": "MONTH",
        "example": "MONTH('15-Apr-11')",
        "result": "4",
        "description": ""
    }, {
        "category": "DATE",
        "name": "NETWORKDAYS",
        "example": "NETWORKDAYS('10/1/2012', '3/1/2013', ['11/22/2012'])",
        "result": "109",
        "description": ""
    }, {
        "category": "DATE",
        "name": "NETWORKDAYSINTL",
        "example": "NETWORKDAYSINTL('1/1/2006', '2/1/2006', 7, ['1/2/2006'])",
        "result": "23",
        "description": ""
    }, {
        "category": "DATE",
        "name": "NOW",
        "example": "NOW()",
        "result": "Thu Feb 20 2020 23:02:55 GMT+0100 (Central European Standard Time)",
        "description": ""
    }, {
        "category": "DATE",
        "name": "SECOND",
        "example": "SECOND('2/1/2011 4:48:18 PM')",
        "result": "18",
        "description": ""
    }, {
        "category": "DATE",
        "name": "TIME",
        "example": "TIME(16, 48, 10)",
        "result": "0.7001157407407408",
        "description": ""
    }, {
        "category": "DATE",
        "name": "TIMEVALUE",
        "example": "TIMEVALUE('22-Aug-2011 6:35 AM')",
        "result": "0.2743055555555556",
        "description": ""
    }, {
        "category": "DATE",
        "name": "TODAY",
        "example": "TODAY()",
        "result": "Thu Feb 20 2020 23:02:55 GMT+0100 (Central European Standard Time)",
        "description": ""
    }, {
        "category": "DATE",
        "name": "WEEKDAY",
        "example": "WEEKDAY('2/14/2008', 3)",
        "result": "3",
        "description": ""
    }, {
        "category": "DATE",
        "name": "YEAR",
        "example": "YEAR('7/5/2008')",
        "result": "2008",
        "description": ""
    }, {
        "category": "DATE",
        "name": "WEEKNUM",
        "example": "WEEKNUM('3/9/2012', 2)",
        "result": "11",
        "description": ""
    }, {
        "category": "DATE",
        "name": "WORKDAY",
        "example": "WORKDAY('10/1/2008', 151, ['11/26/2008', '12/4/2008'])",
        "result": "Mon May 04 2009 00:00:00 GMT-0700 (PDT)",
        "description": ""
    }, {
        "category": "DATE",
        "name": "WORKDAYINTL",
        "example": "WORKDAYINTL('1/1/2012', 30, 17)",
        "result": "Sun Feb 05 2012 00:00:00 GMT-0800 (PST)",
        "description": ""
    }, {
        "category": "DATE",
        "name": "YEARFRAC",
        "example": "YEARFRAC('1/1/2012', '7/30/2012', 3)",
        "result": "0.5780821917808219",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "ACCRINT",
        "example": "ACCRINT('01/01/2011', '02/01/2011', '07/01/2014', 0.1, 1000, 1, 0)",
        "result": "350",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "CUMIPMT",
        "example": "CUMIPMT(0.1/12, 30*12, 100000, 13, 24, 0)",
        "result": "-9916.77251395708",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "CUMPRINC",
        "example": "CUMPRINC(0.1/12, 30*12, 100000, 13, 24, 0)",
        "result": "-614.0863271085149",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "DB",
        "example": "DB(1000000, 100000, 6, 1, 6)",
        "result": "159500",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "DDB",
        "example": "DDB(1000000, 100000, 6, 1, 1.5)",
        "result": "250000",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "DOLLARDE",
        "example": "DOLLARDE(1.1, 16)",
        "result": "1.625",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "DOLLARFR",
        "example": "DOLLARFR(1.625, 16)",
        "result": "1.1",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "EFFECT",
        "example": "EFFECT(0.1, 4)",
        "result": "0.10381289062499977",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "FV",
        "example": "FV(0.1/12, 10, -100, -1000, 0)",
        "result": "2124.874409194097",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "FVSCHEDULE",
        "example": "FVSCHEDULE(100, [0.09,0.1,0.11])",
        "result": "133.08900000000003",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "IPMT",
        "example": "IPMT(0.1/12, 6, 2*12, 100000, 1000000, 0)",
        "result": "928.8235718400465",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "IRR",
        "example": "IRR([-75000,12000,15000,18000,21000,24000], 0.075)",
        "result": "0.05715142887178447",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "ISPMT",
        "example": "ISPMT(0.1/12, 6, 2*12, 100000)",
        "result": "-625",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "MIRR",
        "example": "MIRR([-75000,12000,15000,18000,21000,24000], 0.1, 0.12)",
        "result": "0.07971710360838036",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "NOMINAL",
        "example": "NOMINAL(0.1, 4)",
        "result": "0.09645475633778045",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "NPER",
        "example": "NPER(0.1/12, -100, -1000, 10000, 0)",
        "result": "63.39385422740764",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "NPV",
        "example": "NPV(0.1, -10000, 2000, 4000, 8000)",
        "result": "1031.3503176012546",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "PDURATION",
        "example": "PDURATION(0.1, 1000, 2000)",
        "result": "7.272540897341714",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "PMT",
        "example": "PMT(0.1/12, 2*12, 100000, 1000000, 0)",
        "result": "-42426.08563793503",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "PPMT",
        "example": "PPMT(0.1/12, 6, 2*12, 100000, 1000000, 0)",
        "result": "-43354.909209775076",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "PV",
        "example": "PV(0.1/12, 2*12, 1000, 10000, 0)",
        "result": "-29864.950264779152",
        "description": ""
    }, {
        "category": "FINANCIAL",
        "name": "RATE",
        "example": "RATE(2*12, -1000, -10000, 100000, 0, 0.1)",
        "result": "0.06517891177181533",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BIN2DEC",
        "example": "BIN2DEC(101010)",
        "result": "42",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BIN2HEX",
        "example": "BIN2HEX(101010)",
        "result": "2a",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BIN2OCT",
        "example": "BIN2OCT(101010)",
        "result": "52",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BITAND",
        "example": "BITAND(42, 24)",
        "result": "8",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BITLSHIFT",
        "example": "BITLSHIFT(42, 24)",
        "result": "704643072",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BITOR",
        "example": "BITOR(42, 24)",
        "result": "58",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BITRSHIFT",
        "example": "BITRSHIFT(42, 2)",
        "result": "10",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "BITXOR",
        "example": "BITXOR(42, 24)",
        "result": "50",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "COMPLEX",
        "example": "COMPLEX(3, 4)",
        "result": "3+4i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "CONVERT",
        "example": "CONVERT(64, 'kibyte', 'bit')",
        "result": "524288",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "DEC2BIN",
        "example": "DEC2BIN(42)",
        "result": "101010",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "DEC2HEX",
        "example": "DEC2HEX(42)",
        "result": "2a",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "DEC2OCT",
        "example": "DEC2OCT(42)",
        "result": "52",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "DELTA",
        "example": "DELTA(42, 42)",
        "result": "1",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "ERF",
        "example": "ERF(1)",
        "result": "0.8427007929497149",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "ERFC",
        "example": "ERFC(1)",
        "result": "0.1572992070502851",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "GESTEP",
        "example": "GESTEP(42, 24)",
        "result": "1",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "HEX2BIN",
        "example": "HEX2BIN('2a')",
        "result": "101010",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "HEX2DEC",
        "example": "HEX2DEC('2a')",
        "result": "42",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "HEX2OCT",
        "example": "HEX2OCT('2a')",
        "result": "52",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMABS",
        "example": "IMABS('3+4i')",
        "result": "5",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMAGINARY",
        "example": "IMAGINARY('3+4i')",
        "result": "4",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMARGUMENT",
        "example": "IMARGUMENT('3+4i')",
        "result": "0.9272952180016122",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMCONJUGATE",
        "example": "IMCONJUGATE('3+4i')",
        "result": "3-4i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMCOS",
        "example": "IMCOS('1+i')",
        "result": "0.8337300251311491-0.9888977057628651i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMCOSH",
        "example": "IMCOSH('1+i')",
        "result": "0.8337300251311491+0.9888977057628651i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMCOT",
        "example": "IMCOT('1+i')",
        "result": "0.21762156185440265-0.8680141428959249i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMCSC",
        "example": "IMCSC('1+i')",
        "result": "0.6215180171704283-0.3039310016284264i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMCSCH",
        "example": "IMCSCH('1+i')",
        "result": "0.3039310016284264-0.6215180171704283i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMDIV",
        "example": "IMDIV('1+2i', '3+4i')",
        "result": "0.44+0.08i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMEXP",
        "example": "IMEXP('1+i')",
        "result": "1.4686939399158851+2.2873552871788423i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMLN",
        "example": "IMLN('1+i')",
        "result": "0.3465735902799727+0.7853981633974483i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMLOG10",
        "example": "IMLOG10('1+i')",
        "result": "0.1505149978319906+0.3410940884604603i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMLOG2",
        "example": "IMLOG2('1+i')",
        "result": "0.5000000000000001+1.1330900354567985i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMPOWER",
        "example": "IMPOWER('1+i', 2)",
        "result": "1.2246063538223775e-16+2.0000000000000004i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMPRODUCT",
        "example": "IMPRODUCT('1+2i', '3+4i', '5+6i')",
        "result": "-85+20i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMREAL",
        "example": "IMREAL('3+4i')",
        "result": "3",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMSEC",
        "example": "IMSEC('1+i')",
        "result": "0.4983370305551868+0.591083841721045i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMSECH",
        "example": "IMSECH('1+i')",
        "result": "0.4983370305551868-0.591083841721045i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMSIN",
        "example": "IMSIN('1+i')",
        "result": "1.2984575814159773+0.6349639147847361i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMSINH",
        "example": "IMSINH('1+i')",
        "result": "0.6349639147847361+1.2984575814159773i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMSQRT",
        "example": "IMSQRT('1+i')",
        "result": "1.0986841134678098+0.45508986056222733i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMSUB",
        "example": "IMSUB('3+4i', '1+2i')",
        "result": "2+2i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMSUM",
        "example": "IMSUM('1+2i', '3+4i', '5+6i')",
        "result": "9+12i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "IMTAN",
        "example": "IMTAN('1+i')",
        "result": "0.2717525853195117+1.0839233273386946i",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "OCT2BIN",
        "example": "OCT2BIN('52')",
        "result": "101010",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "OCT2DEC",
        "example": "OCT2DEC('52')",
        "result": "42",
        "description": ""
    }, {
        "category": "ENGINEERING",
        "name": "OCT2HEX",
        "example": "OCT2HEX('52')",
        "result": "2a",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "AND",
        "example": "AND(true, false, true)",
        "result": "false",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "false",
        "example": "FALSE()",
        "result": "false",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "IF",
        "example": "IF(true, 'Hello!', 'Goodbye!')",
        "result": "Hello!",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "IFS",
        "example": "IFS(false, 'Hello!', true, 'Goodbye!')",
        "result": "Goodbye!",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "IFERROR",
        "example": "IFERROR('#DIV/0!', 'Error')",
        "result": "Error",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "IFNA",
        "example": "IFNA('#N/A', 'Error')",
        "result": "Error",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "NOT",
        "example": "NOT(true)",
        "result": "false",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "OR",
        "example": "OR(true, false, true)",
        "result": "true",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "SWITCH",
        "example": "SWITCH(7, 9, 'Nine', 7, 'Seven')",
        "result": "Seven",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "true",
        "example": "TRUE()",
        "result": "true",
        "description": ""
    }, {
        "category": "LOGICAL",
        "name": "XOR",
        "example": "XOR(true, false, true)",
        "result": "false",
        "description": ""
    },
    {
        "category": "MATH",
        "name": "ABS",
        "example": "ABS(-4)",
        "result": "4",
        "description": "Retorna o valor absoluto de um número.",
        "params": [
            {
                "name": "número",
                "description": "O número real cujo valor absoluto você deseja."
            }
        ]
    },
    {
        "category": "MATH",
        "name": "ACOS",
        "example": "ACOS(-0.5)",
        "result": "2.0943951023931957",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ACOSH",
        "example": "ACOSH(10)",
        "result": "2.993222846126381",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ACOT",
        "example": "ACOT(2)",
        "result": "0.46364760900080615",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ACOTH",
        "example": "ACOTH(6)",
        "result": "0.16823611831060645",
        "description": ""
    }, {
        "category": "MATH",
        "name": "AGGREGATE",
        "example": "AGGREGATE(9, 4, [-5,15], [32,'Hello World!'])",
        "result": "10,32",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ARABIC",
        "example": "ARABIC('MCMXII')",
        "result": "1912",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ASIN",
        "example": "ASIN(-0.5)",
        "result": "-0.5235987755982988",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ASINH",
        "example": "ASINH(-2.5)",
        "result": "-1.6472311463710965",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ATAN",
        "example": "ATAN(1)",
        "result": "0.7853981633974483",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ATAN2",
        "example": "ATAN2(-1, -1)",
        "result": "-2.356194490192345",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ATANH",
        "example": "ATANH(-0.1)",
        "result": "-0.10033534773107562",
        "description": ""
    }, {
        "category": "MATH",
        "name": "BASE",
        "example": "BASE(15, 2, 10)",
        "result": "0000001111",
        "description": ""
    }, {
        "category": "MATH",
        "name": "CEILING",
        "example": "CEILING(-5.5, 2, -1)",
        "result": "-6",
        "description": ""
    }, {
        "category": "MATH",
        "name": "CEILINGMATH",
        "example": "CEILINGMATH(-5.5, 2, -1)",
        "result": "-6",
        "description": ""
    }, {
        "category": "MATH",
        "name": "CEILINGPRECISE",
        "example": "CEILINGPRECISE(-4.1, -2)",
        "result": "-4",
        "description": ""
    }, {
        "category": "MATH",
        "name": "COMBIN",
        "example": "COMBIN(8, 2)",
        "result": "28",
        "description": ""
    }, {
        "category": "MATH",
        "name": "COMBINA",
        "example": "COMBINA(4, 3)",
        "result": "20",
        "description": ""
    }, {
        "category": "MATH",
        "name": "COS",
        "example": "COS(1)",
        "result": "0.5403023058681398",
        "description": ""
    }, {
        "category": "MATH",
        "name": "COSH",
        "example": "COSH(1)",
        "result": "1.5430806348152437",
        "description": ""
    }, {
        "category": "MATH",
        "name": "COT",
        "example": "COT(30)",
        "result": "-0.15611995216165922",
        "description": ""
    }, {
        "category": "MATH",
        "name": "COTH",
        "example": "COTH(2)",
        "result": "1.0373147207275482",
        "description": ""
    }, {
        "category": "MATH",
        "name": "CSC",
        "example": "CSC(15)",
        "result": "1.5377805615408537",
        "description": ""
    }, {
        "category": "MATH",
        "name": "CSCH",
        "example": "CSCH(1.5)",
        "result": "0.46964244059522464",
        "description": ""
    }, {
        "category": "MATH",
        "name": "DECIMAL",
        "example": "DECIMAL('FF', 16)",
        "result": "255",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ERF",
        "example": "ERF(1)",
        "result": "0.8427007929497149",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ERFC",
        "example": "ERFC(1)",
        "result": "0.1572992070502851",
        "description": ""
    }, {"category": "MATH", "name": "EVEN", "example": "EVEN(-1)", "result": "-2", "description": ""}, {
        "category": "MATH",
        "name": "EXP",
        "example": "EXP(1)",
        "result": "2.718281828459045",
        "description": ""
    }, {"category": "MATH", "name": "FACT", "example": "FACT(5)", "result": "120", "description": ""}, {
        "category": "MATH",
        "name": "FACTDOUBLE",
        "example": "FACTDOUBLE(7)",
        "result": "105",
        "description": ""
    }, {
        "category": "MATH",
        "name": "FLOOR",
        "example": "FLOOR(-3.1)",
        "result": "-4",
        "description": ""
    }, {
        "category": "MATH",
        "name": "FLOORMATH",
        "example": "FLOORMATH(-4.1, -2, -1)",
        "result": "-4",
        "description": ""
    }, {
        "category": "MATH",
        "name": "FLOORPRECISE",
        "example": "FLOORPRECISE(-3.1, -2)",
        "result": "-4",
        "description": ""
    }, {
        "category": "MATH",
        "name": "GCD",
        "example": "GCD(24, 36, 48)",
        "result": "12",
        "description": ""
    }, {"category": "MATH", "name": "INT", "example": "INT(-8.9)", "result": "-9", "description": ""}, {
        "category": "MATH",
        "name": "ISEVEN",
        "example": "ISEVEN(-2.5)",
        "result": "true",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ISOCEILING",
        "example": "ISOCEILING(-4.1, -2)",
        "result": "-4",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ISODD",
        "example": "ISODD(-2.5)",
        "result": "false",
        "description": ""
    }, {
        "category": "MATH",
        "name": "LCM",
        "example": "LCM(24, 36, 48)",
        "result": "144",
        "description": ""
    }, {
        "category": "MATH",
        "name": "LN",
        "example": "LN(86)",
        "result": "4.454347296253507",
        "description": ""
    }, {"category": "MATH", "name": "LOG", "example": "LOG(8, 2)", "result": "3", "description": ""}, {
        "category": "MATH",
        "name": "LOG10",
        "example": "LOG10(100000)",
        "result": "5",
        "description": ""
    }, {"category": "MATH", "name": "MOD", "example": "MOD(3, -2)", "result": "-1", "description": ""}, {
        "category": "MATH",
        "name": "MROUND",
        "example": "MROUND(-10, -3)",
        "result": "-9",
        "description": ""
    }, {
        "category": "MATH",
        "name": "MULTINOMIAL",
        "example": "MULTINOMIAL(2, 3, 4)",
        "result": "1260",
        "description": ""
    }, {"category": "MATH", "name": "ODD", "example": "ODD(-1.5)", "result": "-3", "description": ""}, {
        "category": "MATH",
        "name": "POWER",
        "example": "POWER(5, 2)",
        "result": "25",
        "description": ""
    }, {
        "category": "MATH",
        "name": "PRODUCT",
        "example": "PRODUCT(5, 15, 30)",
        "result": "2250",
        "description": ""
    }, {
        "category": "MATH",
        "name": "QUOTIENT",
        "example": "QUOTIENT(-10, 3)",
        "result": "-3",
        "description": ""
    }, {
        "category": "MATH",
        "name": "RADIANS",
        "example": "RADIANS(180)",
        "result": "3.141592653589793",
        "description": ""
    }, {
        "category": "MATH",
        "name": "RAND",
        "example": "RAND()",
        "result": "[Random real number greater between 0 and 1]",
        "description": ""
    }, {
        "category": "MATH",
        "name": "RANDBETWEEN",
        "example": "RANDBETWEEN(-1, 1)",
        "result": "[Random integer between bottom and top]",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ROUND",
        "example": "ROUND(626.3, -3)",
        "result": "1000",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ROUNDDOWN",
        "example": "ROUNDDOWN(-3.14159, 2)",
        "result": "-3.14",
        "description": ""
    }, {
        "category": "MATH",
        "name": "ROUNDUP",
        "example": "ROUNDUP(-3.14159, 2)",
        "result": "-3.15",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SEC",
        "example": "SEC(45)",
        "result": "1.9035944074044246",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SECH",
        "example": "SECH(45)",
        "result": "5.725037161098787e-20",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SIGN",
        "example": "SIGN(-0.00001)",
        "result": "-1",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SIN",
        "example": "SIN(1)",
        "result": "0.8414709848078965",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SINH",
        "example": "SINH(1)",
        "result": "1.1752011936438014",
        "description": ""
    }, {"category": "MATH", "name": "SQRT", "example": "SQRT(16)", "result": "4", "description": ""}, {
        "category": "MATH",
        "name": "SQRTPI",
        "example": "SQRTPI(2)",
        "result": "2.5066282746310002",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUBTOTAL",
        "example": "SUBTOTAL(9, [-5,15], [32,'Hello World!'])",
        "result": "10,32",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUM",
        "example": "SUM(-5, 15, 32, 'Hello World!')",
        "result": "42",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUMIF",
        "example": "SUMIF([2,4,8,16], '>5')",
        "result": "24",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUMIFS",
        "example": "SUMIFS([2,4,8,16], [1,2,3,4], '>=2', [1,2,4,8], '<=4')",
        "result": "12",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUMPRODUCT",
        "example": "SUMPRODUCT([[1,2],[3,4]], [[1,0],[0,1]])",
        "result": "5",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUMSQ",
        "example": "SUMSQ(3, 4)",
        "result": "25",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUMX2MY2",
        "example": "SUMX2MY2([1,2], [3,4])",
        "result": "-20",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUMX2PY2",
        "example": "SUMX2PY2([1,2], [3,4])",
        "result": "30",
        "description": ""
    }, {
        "category": "MATH",
        "name": "SUMXMY2",
        "example": "SUMXMY2([1,2], [3,4])",
        "result": "8",
        "description": ""
    }, {
        "category": "MATH",
        "name": "TAN",
        "example": "TAN(1)",
        "result": "1.5574077246549023",
        "description": ""
    }, {
        "category": "MATH",
        "name": "TANH",
        "example": "TANH(-2)",
        "result": "-0.9640275800758168",
        "description": ""
    }, {
        "category": "MATH",
        "name": "TRUNC",
        "example": "TRUNC(-8.9)",
        "result": "-8",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "AVEDEV",
        "example": "AVEDEV([2,4], [8,16])",
        "result": "4.5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "AVERAGE",
        "example": "AVERAGE([2,4], [8,16])",
        "result": "7.5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "AVERAGEA",
        "example": "AVERAGEA([2,4], [8,16])",
        "result": "7.5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "AVERAGEIF",
        "example": "AVERAGEIF([2,4,8,16], '>5', [1, 2, 3, 4])",
        "result": "3.5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "AVERAGEIFS",
        "example": "AVERAGEIFS([2,4,8,16], [1,2,3,4], '>=2', [1,2,4,8], '<=4')",
        "result": "6",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "BETADIST",
        "example": "BETADIST(2, 8, 10, true, 1, 3)",
        "result": "0.6854705810117458",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "BETAINV",
        "example": "BETAINV(0.6854705810117458, 8, 10, 1, 3)",
        "result": "1.9999999999999998",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "BINOMDIST",
        "example": "BINOMDIST(6, 10, 0.5, false)",
        "result": "0.205078125",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "CORREL",
        "example": "CORREL([3,2,4,5,6], [9,7,12,15,17])",
        "result": "0.9970544855015815",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COUNT",
        "example": "COUNT([1,2], [3,4])",
        "result": "4",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COUNTA",
        "example": "COUNTA([1, null, 3, 'a', '', 'c'])",
        "result": "4",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COUNTBLANK",
        "example": "COUNTBLANK([1, null, 3, 'a', '', 'c'])",
        "result": "2",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COUNTIF",
        "example": "COUNTIF(['Caen', 'Melbourne', 'Palo Alto', 'Singapore'], 'a')",
        "result": "3",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COUNTIFS",
        "example": "COUNTIFS([2,4,8,16], [1,2,3,4], '>=2', [1,2,4,8], '<=4')",
        "result": "2",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COUNTUNIQUE",
        "example": "COUNTUNIQUE([1,1,2,2,3,3])",
        "result": "3",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COVARIANCEP",
        "example": "COVARIANCEP([3,2,4,5,6], [9,7,12,15,17])",
        "result": "5.2",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "COVARIANCES",
        "example": "COVARIANCES([2,4,8], [5,11,12])",
        "result": "9.666666666666668",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "DEVSQ",
        "example": "DEVSQ([2,4,8,16])",
        "result": "115",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "EXPONDIST",
        "example": "EXPONDIST(0.2, 10, true)",
        "result": "0.8646647167633873",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "FDIST",
        "example": "FDIST(15.2069, 6, 4, false)",
        "result": "0.0012237917087831735",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "FINV",
        "example": "FINV(0.01, 6, 4)",
        "result": "0.10930991412457851",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "FISHER",
        "example": "FISHER(0.75)",
        "result": "0.9729550745276566",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "FISHERINV",
        "example": "FISHERINV(0.9729550745276566)",
        "result": "0.75",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "FORECAST",
        "example": "FORECAST(30, [6,7,9,15,21], [20,28,31,38,40])",
        "result": "10.607253086419755",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "FREQUENCY",
        "example": "FREQUENCY([79,85,78,85,50,81,95,88,97], [70,79,89])",
        "result": "1,2,4,2",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "GAMMA",
        "example": "GAMMA(2.5)",
        "result": "1.3293403919101043",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "GAMMALN",
        "example": "GAMMALN(10)",
        "result": "12.801827480081961",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "GAUSS",
        "example": "GAUSS(2)",
        "result": "0.4772498680518208",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "GEOMEAN",
        "example": "GEOMEAN([2,4], [8,16])",
        "result": "5.656854249492381",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "GROWTH",
        "example": "GROWTH([2,4,8,16], [1,2,3,4], [5])",
        "result": "32.00000000000003",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "HARMEAN",
        "example": "HARMEAN([2,4], [8,16])",
        "result": "4.266666666666667",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "HYPGEOMDIST",
        "example": "HYPGEOMDIST(1, 4, 8, 20, false)",
        "result": "0.3632610939112487",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "INTERCEPT",
        "example": "INTERCEPT([2,3,9,1,8], [6,5,11,7,5])",
        "result": "0.04838709677419217",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "KURT",
        "example": "KURT([3,4,5,2,3,4,5,6,4,7])",
        "result": "-0.15179963720841627",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "LARGE",
        "example": "LARGE([3,5,3,5,4,4,2,4,6,7], 3)",
        "result": "5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "LINEST",
        "example": "LINEST([1,9,5,7], [0,4,2,3], true, true)",
        "result": "2,1",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "LOGNORMDIST",
        "example": "LOGNORMDIST(4, 3.5, 1.2, true)",
        "result": "0.0390835557068005",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "LOGNORMINV",
        "example": "LOGNORMINV(0.0390835557068005, 3.5, 1.2, true)",
        "result": "4.000000000000001",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "MAX",
        "example": "MAX([0.1,0.2], [0.4,0.8], [true, false])",
        "result": "0.8",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "MAXA",
        "example": "MAXA([0.1,0.2], [0.4,0.8], [true, false])",
        "result": "1",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "MEDIAN",
        "example": "MEDIAN([1,2,3], [4,5,6])",
        "result": "3.5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "MIN",
        "example": "MIN([0.1,0.2], [0.4,0.8], [true, false])",
        "result": "0.1",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "MINA",
        "example": "MINA([0.1,0.2], [0.4,0.8], [true, false])",
        "result": "0",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "MODEMULT",
        "example": "MODEMULT([1,2,3,4,3,2,1,2,3])",
        "result": "2,3",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "MODESNGL",
        "example": "MODESNGL([1,2,3,4,3,2,1,2,3])",
        "result": "2",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "NORMDIST",
        "example": "NORMDIST(42, 40, 1.5, true)",
        "result": "0.9087887802741321",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "NORMINV",
        "example": "NORMINV(0.9087887802741321, 40, 1.5)",
        "result": "42",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "NORMSDIST",
        "example": "NORMSDIST(1, true)",
        "result": "0.8413447460685429",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "NORMSINV",
        "example": "NORMSINV(0.8413447460685429)",
        "result": "1.0000000000000002",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PEARSON",
        "example": "PEARSON([9,7,5,3,1], [10,6,1,5,3])",
        "result": "0.6993786061802354",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PERCENTILEEXC",
        "example": "PERCENTILEEXC([1,2,3,4], 0.3)",
        "result": "1.5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PERCENTILEINC",
        "example": "PERCENTILEINC([1,2,3,4], 0.3)",
        "result": "1.9",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PERCENTRANKEXC",
        "example": "PERCENTRANKEXC([1,2,3,4], 2, 2)",
        "result": "0.4",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PERCENTRANKINC",
        "example": "PERCENTRANKINC([1,2,3,4], 2, 2)",
        "result": "0.33",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PERMUT",
        "example": "PERMUT(100, 3)",
        "result": "970200",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PERMUTATIONA",
        "example": "PERMUTATIONA(4, 3)",
        "result": "64",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PHI",
        "example": "PHI(0.75)",
        "result": "0.30113743215480443",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "POISSONDIST",
        "example": "POISSONDIST(2, 5, true)",
        "result": "0.12465201948308113",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "PROB",
        "example": "PROB([1,2,3,4], [0.1,0.2,0.2,0.1], 2, 3)",
        "result": "0.4",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "QUARTILEEXC",
        "example": "QUARTILEEXC([1,2,3,4], 1)",
        "result": "1.25",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "QUARTILEINC",
        "example": "QUARTILEINC([1,2,3,4], 1)",
        "result": "1.75",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "RANKAVG",
        "example": "RANKAVG(4, [2,4,4,8,8,16], false)",
        "result": "4.5",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "RANKEQ",
        "example": "RANKEQ(4, [2,4,4,8,8,16], false)",
        "result": "4",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "RSQ",
        "example": "RSQ([9,7,5,3,1], [10,6,1,5,3])",
        "result": "0.4891304347826088",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "SKEW",
        "example": "SKEW([3,4,5,2,3,4,5,6,4,7])",
        "result": "0.3595430714067974",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "SKEWP",
        "example": "SKEWP([3,4,5,2,3,4,5,6,4,7])",
        "result": "0.303193339354144",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "SLOPE",
        "example": "SLOPE([1,9,5,7], [0,4,2,3])",
        "result": "2",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "SMALL",
        "example": "SMALL([3,5,3,5,4,4,2,4,6,7], 3)",
        "result": "3",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "STANDARDIZE",
        "example": "STANDARDIZE(42, 40, 1.5)",
        "result": "1.3333333333333333",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "STDEVA",
        "example": "STDEVA([2,4], [8,16], [true, false])",
        "result": "6.013872850889572",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "STDEVP",
        "example": "STDEVP([2,4], [8,16], [true, false])",
        "result": "5.361902647381804",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "STDEVPA",
        "example": "STDEVPA([2,4], [8,16], [true, false])",
        "result": "5.489889697333535",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "STDEVS",
        "example": "STDEVS([2,4], [8,16], [true, false])",
        "result": "6.191391873668904",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "STEYX",
        "example": "STEYX([2,3,9,1,8,7,5], [6,5,11,7,5,4,4])",
        "result": "3.305718950210041",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "TDIST",
        "example": "TDIST(60, 1, true)",
        "result": "0.9946953263673741",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "TINV",
        "example": "TINV(0.9946953263673741, 1)",
        "result": "59.99999999996535",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "TRIMMEAN",
        "example": "TRIMMEAN([4,5,6,7,2,3,4,5,1,2,3], 0.2)",
        "result": "3.7777777777777777",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "VARA",
        "example": "VARA([2,4], [8,16], [true, false])",
        "result": "36.16666666666667",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "VARP",
        "example": "VARP([2,4], [8,16], [true, false])",
        "result": "28.75",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "VARPA",
        "example": "VARPA([2,4], [8,16], [true, false])",
        "result": "30.13888888888889",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "VARS",
        "example": "VARS([2,4], [8,16], [true, false])",
        "result": "38.333333333333336",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "WEIBULLDIST",
        "example": "WEIBULLDIST(105, 20, 100, true)",
        "result": "0.9295813900692769",
        "description": ""
    }, {
        "category": "STATISTICAL",
        "name": "ZTEST",
        "example": "ZTEST([3,6,7,8,6,5,4,2,1,9], 4)",
        "result": "0.09057419685136381",
        "description": ""
    }, {"category": "TEXT", "name": "CHAR", "example": "CHAR(65)", "result": "A", "description": ""}, {
        "category": "TEXT",
        "name": "CLEAN",
        "example": "CLEAN('Monthly report')",
        "result": "Monthly report",
        "description": ""
    }, {"category": "TEXT", "name": "CODE", "example": "CODE('A')", "result": "65", "description": ""}, {
        "category": "TEXT",
        "name": "CONCATENATE",
        "example": "CONCATENATE('Andreas', ' ', 'Hauser')",
        "result": "Andreas Hauser",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "EXACT",
        "example": "EXACT('Word', 'word')",
        "result": "false",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "FIND",
        "example": "FIND('M', 'Miriam McGovern', 3)",
        "result": "8",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "LEFT",
        "example": "LEFT('Sale Price', 4)",
        "result": "Sale",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "LEN",
        "example": "LEN('Phoenix, AZ')",
        "result": "11",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "LOWER",
        "example": "LOWER('E. E. Cummings')",
        "result": "e. e. cummings",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "MID",
        "example": "MID('Fluid Flow', 7, 20)",
        "result": "Flow",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "NUMBERVALUE",
        "example": "NUMBERVALUE('2.500,27', ',', '.')",
        "result": "2500.27",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "PROPER",
        "example": "PROPER('this is a TITLE')",
        "result": "This Is A Title",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "REGEXEXTRACT",
        "example": "REGEXEXTRACT('Palo Alto', 'Alto')",
        "result": "Alto",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "REGEXMATCH",
        "example": "REGEXMATCH('Palo Alto', 'Alto')",
        "result": "true",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "REGEXREPLACE",
        "example": "REGEXREPLACE('Sutoiku', 'utoiku', 'TOIC')",
        "result": "STOIC",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "REPLACE",
        "example": "REPLACE('abcdefghijk', 6, 5, '*')",
        "result": "abcde*k",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "REPT",
        "example": "REPT('*-', 3)",
        "result": "*-*-*-",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "RIGHT",
        "example": "RIGHT('Sale Price', 5)",
        "result": "Price",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "ROMAN",
        "example": "ROMAN(499)",
        "result": "CDXCIX",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "SEARCH",
        "example": "SEARCH('margin', 'Profit Margin')",
        "result": "8",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "SPLIT",
        "example": "SPLIT('A,B,C', ',')",
        "result": "A,B,C",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "SUBSTITUTE",
        "example": "SUBSTITUTE('Quarter 1, 2011', '1', '2', 3)",
        "result": "Quarter 1, 2012",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "T",
        "example": "T('Rainfall')",
        "result": "Rainfall",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "TRIM",
        "example": "TRIM(' First Quarter Earnings ')",
        "result": "First Quarter Earnings",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "UNICHAR",
        "example": "UNICHAR(66)",
        "result": "B",
        "description": ""
    }, {
        "category": "TEXT",
        "name": "UNICODE",
        "example": "UNICODE('B')",
        "result": "66",
        "description": ""
    }, {"category": "TEXT", "name": "UPPER", "example": "UPPER('total')", "result": "TOTAL", "description": ""}]
