import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "react-loader-spinner";
import { FiLock } from "react-icons/fi";
import "assets/css/popup.css";
import "reactjs-popup/dist/index.css";

/**
 * Components
 */
import colors from "components/Colors";
import {
  StyledContainer,
  StyledFormArea,
  StyledFormButton,
  Avatar,
  StyledTitle,
  ButtonGroup,
  TextLink,
  CopyrightText,
} from "components/Styles";
import { TextInput } from "components/Forms/SignIn/FormLib";
import Logo from "assets/images/logo.png";

/**
 * Actions
 */
import { alterasenha } from "auth/actions/userActions";

const ChangePass = ({ alterasenha, user }) => {
  const history = useHistory();
  const token = user.JWtoken;
  return (
    <StyledContainer>
      <div>
        <StyledFormArea>
          <Avatar image={Logo} />
          <StyledTitle color={colors.theme} size={30}>
            Alteração de senha
          </StyledTitle>
          <Formik
            initialValues={{
              email: user.email,
              password: "",
            }}
            validationSchema={Yup.object({
              password: Yup.string()
                .min(8, "senha é muito curta")
                .max(30, "senha é muito longa")
                .required("O campo senha não pode ser vazio"),
              confirmpassword: Yup.string()
                .oneOf(
                  [Yup.ref("password"), null],
                  "As senhas devem ser iguais"
                )
                .min(8, "Erro")
                .required("Campos não se coincidem"),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              // console.log(user.mail);
              alterasenha(values, setFieldError, setSubmitting, history, token);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <StyledTitle color={"black"} size={15}>
                  Vamos alterar sua senha,{user.userName}
                </StyledTitle>
                <TextInput
                  name="password"
                  type="password"
                  label="Senha"
                  placeholder="***********"
                  icon={<FiLock />}
                />
                <TextInput
                  name="confirmpassword"
                  type="password"
                  label="Confirme a senha"
                  placeholder="*******"
                  icon={<FiLock />}
                />
                <ButtonGroup>
                  {!isSubmitting && (
                    <StyledFormButton type="submit">
                      Alterar & Entrar
                    </StyledFormButton>
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={colors.theme}
                      height={49}
                      width={100}
                    />
                  )}
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </StyledFormArea>
        <CopyrightText>
          Todos os direitos reservados &copy; {new Date().getFullYear()}
        </CopyrightText>
      </div>
    </StyledContainer>
  );
};

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps, { alterasenha })(ChangePass);
