import React, {Fragment, useEffect, useState} from "react";
import {
  Layout,
  Breadcrumb,
  Row,
  Typography, Spin,
} from "antd";
import {
  FileAddOutlined,
  ProjectOutlined,

} from "@ant-design/icons";
import "react-grid-layout/css/styles.css";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Actions
 */
import {ProCard} from "@ant-design/pro-components";
import {reportAPI} from "../../services/actions/reports-v2";

/**
 * Misc
 */
const { Content, Footer } = Layout;
const { Paragraph, Text } = Typography;

const  Dashboard  = () => {

  const fetchReport = async () => {
    try {
      const { status, data } = await reportAPI.getEmbedDefaultReport();
      if (status === 200 || status === 201) {
        setReport(data);
      } else {
        throw new Error("Failed to fetch report");
      }
    } catch (error) {
      setError("Ocorreu um erro ao buscar o relatório.");
    } finally {
      setLoading(false);
    }
  };

  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchReport();
  },[])
    return (
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", height: "100vh" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>resumo</Breadcrumb.Item>
            </Breadcrumb>

            <div
              style={{
                background: "white",
                display: "flex",
              }}
            >

            </div>
            <Row
              style={{
                background: "white",
              }}
            >
              <ProCard span={24} >
                <div className="desc">
                  <Paragraph>
                    <Text
                        strong
                        style={{
                          fontSize: 16,
                        }}
                    >
                      Está com dúvidas?
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    <FileAddOutlined className="site-result-demo-error-icon"/>{" "}
                    Como criar relatórios. <a href={''}>Instruções &gt;</a>
                  </Paragraph>
                  <Paragraph>
                    <ProjectOutlined className="site-result-demo-error-icon"/>{" "}
                    Como criar relatórios privados e compartilhados. {" "}
                    <a href={"/relatorios"}>Gerar Relatório &gt;</a>
                  </Paragraph>
                </div>
                <ProCard.Divider/>
                {loading ? (
                    <Spin tip="Carregando relatório..." />
                ) : error ? (
                    <Paragraph>{error}</Paragraph>
                ) : (
                    report  && !loading? (
                        <iframe
                            src={`${process.env.REACT_APP_URL}/metabase/embed/dashboard/${report}#bordered=true&titled=true`}
                            title="Relatório"
                            style={{ width: "100%", height: "70vh", border:"none" }}
                        />
                    ) : (<Paragraph style={{ textAlign: "center" }}>Não há relatório padrão definido.</Paragraph>)
                )}
              </ProCard>
            </Row>
            <Footer style={{textAlign: "center"}}>
              Cow Lifetime Management ©{new Date().getFullYear()} Versão {process.env.REACT_APP_VERSION}
            </Footer>
          </Layout>
        </Layout>
      </LayoutTopSide>
    );
}

export default Dashboard;

