import {ProCard} from "@ant-design/pro-components";
import {ReloadOutlined, ThunderboltOutlined} from '@ant-design/icons';
import {Col, Row, Select, Form, Input, Button, Switch, Space} from "antd";
import React, {useEffect} from "react";
import {useForm} from "antd/es/form/Form";
import IconTriggerModal from "../../Snippets/IconTriggerModal";

const {Option} = Select;
export const LoopActionInput = ({value, onChange, onBlur, fieldName, fieldLabel}) => {


    const onValueChange = (valueChange, field) => {
        const newValue = {
            ...value,
            [field]: valueChange,
        }
        onChange(newValue)
    }

    const onChangeActive = (valueChange) => {
        const newValue = {
            active: valueChange,
            maxLoop: null,
            valueReference: null
        }
        onChange(newValue)
    }

    const style = value && value.active ? {} : {
        padding:   "10px",
    }

    return (
        <ProCard
            bodyStyle={style}
            title={"Configuração de Repetição"}
            style={{background: "#f8f8f8"}}
            extra={
                <Space>
                    <label>Ativar</label>
                    <Switch checked={value && value.active ? value.active : false}
                            onChange={onChangeActive}
                            checkedChildren={"Sim"}
                            unCheckedChildren={"Não"}
                            style={{margin: '5px'}}
                    />
                </Space>
            }
        >
            {value && value.active && (
                <Row gutter={24}>
                    <Col span={24}>
                        <label>Valor de Referência:</label>
                        <Input
                            onBlur={onBlur}
                            value={value && value.valueReference ? value.valueReference : null}
                            onChange={(valueChange) => onValueChange(valueChange.target.value, 'valueReference')}
                            placeholder="Digite o valor de referência"
                            addonAfter={<IconTriggerModal fieldName={`${fieldName}.valueReference`}
                                                          fieldLabel="Valor de Referência"
                                                          isCode
                                                          acceptEntryLoop={false}
                            />}
                            style={{width: '100%'}}
                        />
                    </Col>
                    <Col span={24}>
                        <label>Máximo de repetições do laço:</label>
                        <Input
                            onBlur={onBlur}
                            value={value && value.maxLoop ? value.maxLoop : null}
                            onChange={(valueChange) => onValueChange(valueChange.target.value, 'maxLoop')}
                            placeholder="Digite o limite máximo de repetições"
                            addonAfter={<IconTriggerModal fieldName={`${fieldName}.maxLoop`}
                                                          fieldLabel="Máximo de repetições do laço"
                                                          acceptEntryLoop={false}
                            />
                        }
                            style={{width: '100%'}}
                        />
                    </Col>
                </Row>
            )}
        </ProCard>
    )
}
