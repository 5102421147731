import React, { useEffect, useState } from "react";
import { Select, Input, Row, Col } from "antd";
import { getRefFieldsById } from "../../../../../../auth/actions/objActions";
import { fetchRefData } from "../../../../../../components/Utils/formColumns";
import _ from "lodash";
import IconTriggerModal from "../../Snippets/IconTriggerModal";
import uuid from "react-uuid";

const { Option } = Select;

export const RefInput = ({ value,onChange, mode, fieldName, fieldLabel, field }) => {

    const [data, setData] = useState([]);
    const [fieldsRef, setFieldsRef] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 20,
        final: false,
    });
    const [inputType, setInputType] = useState((value && value.type) ? value.type : "value");
    const [inputValue, setInputValue] = useState('');
    const [valueSelect,setValueSelect] = useState([]);

    const fetchOptions = async (searchValue, currentPage, refFields = null) => {
        refFields = refFields || fieldsRef;
        setLoading(true);
        try {
            const query = {
                refFields: refFields,
                keyWords: searchValue,
                page: currentPage,
                pageSize: pagination.pageSize,
            };

            let options = await fetchRefData(query);
            const refFieldByName = _.keyBy(refFields, 'fieldName');
            options = options.map(opt => {
               const fieldId = refFieldByName[opt.field]._id;
                opt.value =  `${opt.value}@${fieldId}`;
                return opt;
            })

            setData(prevData =>
                currentPage === 1 ? options : [...prevData, ...options]
            );

            setPagination(prev => ({
                ...prev,
                currentPage: currentPage,
                final: options && options.length === 0
            }));

        } catch (error) {
            console.error("Error fetching reference fields:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fields = mode === "refLookup" ? field.getValueOf : field.refFields;
                const { message } = await getRefFieldsById({ fields });
                const data = message.map((obj) => ({
                    form: obj._index,
                    fieldName: obj.field,
                    fieldType: obj.fieldtype,
                    _id: obj._id
                }));
                setFieldsRef(data);
                await fetchOptions('', 1, data);
            } catch (error) {
                console.error("Error fetching reference fields:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if(value && value.value){
            setInputValue(value.value);
            setValueSelect(value.value);
        }
    }, [value]);

    const handleSearchTermChange = value => {
        setSearchTerm(value);
        setPagination(prev => ({ ...prev, final: false, currentPage: 1 }));
        fetchOptions(value, 1);
    };

    const handleScroll = event => {
        const { target } = event;
        if (
            target.scrollTop + target.offsetHeight >= target.scrollHeight - 100 &&
            !loading &&
            !pagination.final
        ) {
            const nextPage = pagination.currentPage + 1;
            setPagination(prev => ({ ...prev, currentPage: nextPage }));
            fetchOptions(searchTerm, nextPage);
        }
    };

    const handleInputTypeChange = valueChange => {
        setInputType(valueChange);
        setValueSelect(null)
        setInputValue(null)
        onChange({
            ...value,
            type:valueChange,
            value: mode === "refUniqueSelection" ? null : [],
            fieldType: field.fieldtype,
        });
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
        onChange({
            fieldType: field.fieldtype,
            type: inputType,
            value: e.target.value,
        });
    };

    const handleSelectChange = (valueChange) => {
        setValueSelect(valueChange);
        onChange({
            ...value,
            fieldType: field.fieldtype,
            type: inputType,
            value: valueChange,
        });
    }

    const modeSelect =  (mode ==='refMultipleSelection') ? "multiple" : undefined;

    return (
        <Row gutter={24} align="middle">
            <Col span={5}>
                <Select
                    style={{ width: "100%" }}
                    onChange={handleInputTypeChange}
                    value={(value && value.type) ? value.type : "value"}
                >
                    <Option value="value">Valor</Option>
                    <Option value="binding">Anotação</Option>
                </Select>
            </Col>
            <Col flex="auto" span={19}>
                {inputType === 'value' ? (
                    <Select
                        mode={modeSelect}
                        value={valueSelect}
                        loading={loading}
                        style={{ width: '100%' }}
                        onSearch={handleSearchTermChange}
                        onPopupScroll={handleScroll}
                        options={data}
                        filterOption={false}
                        onChange={handleSelectChange}
                    />
                ) : (
                    <Input
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={mode ==='refMultipleSelection' || mode === "refUniqueSelection" ? "Edite o valor com o editor" : ""}
                        disabled={mode ==='refMultipleSelection' || mode === "refUniqueSelection"}
                        style={{ width: '100%' }}
                        addonAfter={<IconTriggerModal fieldName={`${fieldName}.value`} fieldLabel={fieldLabel} isCode={mode ==='refMultipleSelection' || mode === "refUniqueSelection"}/>}
                    />
                )}
            </Col>
        </Row>
    );
};
