import { Editor } from "@tinymce/tinymce-react";
import {openOrCloseModalSnippets} from "../../../../../../ducks/automation";
import {connect} from "react-redux";

const RichTextEditor = ({onChange,value,onBlur,fieldName,fieldLabel,onOpenOrCloseModalSnippets}) =>  {

    const handleEditorChange = (content, editor) => {
        if (onChange) {
            console.log(content)
            onChange(content);
        }
    };

    const handleBlur = (event, editor) => {
        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <>
            <Editor
                value={value ?? ""}
                init={{
                    license_key:"gpl",
                    language: 'pt_BR',
                    selector: 'textarea',
                    plugins: [
                        'lists',
                        'link',
                        'image',
                        'table',
                        'code',
                        'paste',
                        'autoresize',
                        'fullscreen',
                        'preview',
                        'emoticons',
                        'charmap',
                        'textcolor',
                        'advlist'
                    ],
                    toolbar: 'snippet_editor | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code fullscreen preview',
                    skin_url: '/tinymce/skins/ui/oxide',
                    content_css: '/tinymce/skins/content/default/content.css',
                    base_url: '/tinymce',
                    setup: (editor) => {
                        editor.ui.registry.addButton('snippet_editor', {
                            text: 'Abrir editor avançado',
                            onAction: () => {
                                onOpenOrCloseModalSnippets({action: true, fieldName, fieldLabel})
                            }
                        });
                    },
                    entity_encoding: 'raw',
                }}
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onEditorChange={handleEditorChange} // Função para lidar com alterações no conteúdo do editor
                onBlur={handleBlur}
            />
        </>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        onOpenOrCloseModalSnippets: ({action, fieldName, fieldLabel}) => dispatch(openOrCloseModalSnippets({
            action,
            fieldName,
            fieldLabel,
        })),
    };
};

export default connect(null, mapDispatchToProps)(RichTextEditor)
