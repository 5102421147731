import {Button, Dropdown, Space, Menu, Form} from "antd";
import {CheckOutlined, PlusCircleOutlined} from "@ant-design/icons";
import uuid from "react-uuid";
import {BetaSchemaForm} from "@ant-design/pro-components";
import {
    buildSchemaColumnsAutomation
} from "../../../../../../../components/Utils/formColumns";
import {useEffect} from "react";

export const RecordForm = ({value, handleChangeAll, fields = [], disable = false,fieldName}) => {
    const [form] = Form.useForm();

    const handleClickMenu = (field) => {
        if (value) {
            const fields = (value.fields) ? [...value.fields] : [];
            const index = checkIfisAdded(field,true)
            if (index > -1) {
                fields.splice(index, 1);
            } else {
                const item = {
                    form: field.fieldProps._index,
                    id: field.fieldProps._id,
                    name: field.fieldProps.field,
                    value: null
                }
                fields.push(item);
            }
            handleChangeAll('fields', fields);
        }

    }

    const checkIfisAdded = (field, returnIndex = false) => {
        if (value && value.fields) {
            const index = value.fields.findIndex(item => item.id === field.value);

            if (returnIndex) {
                return index;
            } else {
                return index > -1;
            }
        }
        return false;
    };

    const menu = (fields || []).filter(value => value && value.fieldProps && ( !value.fieldProps.hasOwnProperty('isReadOnly') || !value.fieldProps.isReadOnly))
        .map((value) => {
        return (<Menu.Item key={uuid()} onClick={() => handleClickMenu(value)}
                           icon={checkIfisAdded(value) ? <CheckOutlined/> : null}>
            {value.label} ({value.typeText}) - {!value.require ? "Não" : ""} Obrigatorio
        </Menu.Item>)
    });

    useEffect(() => {
        if(value && value.fields){
            const values = value.fields.reduce((acc, item, index) => {
                if(item.hasOwnProperty('value')) {
                    acc[item.name] = item.value;
                }
                return acc;
            }, {});
            form.setFieldsValue(values);
        }
    }, [form, value]);

    const getValues = () =>{
        if(value && value.fields && fields && fields.length > 0){
            return  fields.map(field => {
                if(checkIfisAdded(field)){
                    return field.fieldProps;
                }
            }).filter(item => item);
        }
        return []
    }

    const columns = buildSchemaColumnsAutomation(form,getValues(), `${fieldName}.fields`)

    function handleOnChangeValue() {
        if (!value) return;

        const values = form.getFieldsValue();
        const fields = value.fields;

        const updatedFields = fields.map(item => {
            const newValue = values[item.name];
            if (newValue !== item.value) {
                return { ...item, value: newValue };
            }
            return item;
        });

        if (updatedFields.some((item, index) => item.value !== value.fields[index].value)) {
            handleChangeAll('fields', updatedFields);
        }
    }


    return (<div style={{padding: "10px 0"}}>
        <div style={{padding: "10px 0"}}>
            {columns.length > 0 ? <p>Campos:</p> : null}
            <BetaSchemaForm
                form={form}
                layout={{
                    labelCol: {span: 8},
                    wrapperCol: {span: 16},
                }}
                layoutType={"Form"}
                columns={columns}
                submitter={false}
                onValuesChange={handleOnChangeValue}
            >
            </BetaSchemaForm>
        </div>
        <Dropdown
            disabled={disable}
            placement="bottomCenter"
            arrow
            overlay={<Menu>{menu}</Menu>}
        >
            <Button type={"primary"} style={{width: "100%"}} icon={<PlusCircleOutlined/>}>Adicionar Campos</Button>
        </Dropdown>
    </div>)
}
