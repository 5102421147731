import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  SnippetsOutlined,
  UserOutlined,
  FormOutlined,
  UnorderedListOutlined,
  FolderAddOutlined,
  FolderViewOutlined,
  PlusCircleOutlined,
  TableOutlined,
  AreaChartOutlined,
  ExperimentOutlined,
  PieChartOutlined,
  FileTextOutlined,
  TagOutlined,
  RobotOutlined
} from "@ant-design/icons";

const { Sider } = Layout;

function get(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("colp-69")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function save(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "colp-69",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

const KEY_MAP = {
  0: "/dashboard/resumo",
  1: "/usuarios/novo",
  2: "/usuarios/todos",
  3: "/formularios/novo",
  4: "/formularios/todos",
  5: "/formularios/campo/novo",
  6: "/formularios/categoria/nova",
  7: "/formularios/categoria/todas",
  8: "/relatorios",
  9: "/relatorios/todos",
  10: "/relatorios/gerar",
  11: "/atividades/todas",
  12: "/automacoes"
};

export default function DrawerSider() {
  const history = useHistory();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(get("collapsed") || false);
  }, []);

  const handleOnSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    if (KEY_MAP[key] !== location.pathname) {
      handleSelectKey(key);
    } else {
      setSelectedKey("");
    }
  };

  const handleSelectKey = function (key) {
    setSelectedKey(key);
    const historyString = KEY_MAP[key];
    setTimeout(() => (history.push(historyString)) , 0);
  };

  const handleCollapse = (collapsed) => {
    setCollapsed(!collapsed);
    save("collapsed", !collapsed);
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Dashboard", "0", <HomeOutlined />),
    getItem("Usuários", "2", <UserOutlined />),
    // getItem("Usuários", "sub2", <UserOutlined />, [
    //     getItem("Adicionar usuário", "1", <PlusCircleOutlined />),
    //     getItem("Todos usuários", "1", <TableOutlined />),
    // ]),
    getItem("Categorias", "7", <TagOutlined />),
    // getItem("Categorias", "sub5", <FolderAddOutlined />, [
    //   getItem("Adicionar categoria", "6", <PlusCircleOutlined />),
    // ]),
    getItem("Formulários", "4", <FileTextOutlined />),
    // getItem("Formulários", "sub3", <FormOutlined />, [
    //   getItem("Adicionar formulário", "3", <PlusCircleOutlined />)]),
    // getItem("Campos", "sub4", <UnorderedListOutlined />, [
    //   getItem("Adicionar campo", "5", <PlusCircleOutlined />),
    // ]),
    getItem("Relatórios", 8, <AreaChartOutlined />
      // ,
      //   [
      // getItem("Adicionar relatórios", "8", <PlusCircleOutlined />),
      // getItem("Seus relatórios", "9", <PieChartOutlined />),
      // getItem("Gerar relatório", "10", <ExperimentOutlined />),
      // ]
    ),
    getItem("Atividades de dados", "11", <SnippetsOutlined />),
    getItem("Automações", "12", <RobotOutlined/>),
  ];

  return (
    <Sider
      className="site-layout-background"
      collapsible
      collapsed={!collapsed}
      onCollapse={handleCollapse}
      theme="light"
    >
      <Menu
        style={{ height: "100%", borderRight: 0 }}
        selectedKeys={selectedKey}
        defaultSelectedKeys={selectedKey}
        onSelect={handleOnSelect}
        onDeselect={() => {
          setSelectedKey();
        }}
        mode="inline"
        items={items}
      ></Menu>
    </Sider>
  );
}
