import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ConfigProvider,
  Layout,
  PageHeader,
  Row,
  Col,
  Select,
  Empty,
  Button,
  Dropdown,
  Menu,
  Tooltip,
  DatePicker,
  Input,
  Space,
  Modal,
  Form,
  TimePicker,
  Upload,
  message,
  notification,
  Typography,
  Spin,
  Tabs,
  Card,
} from "antd";
import { ProTable, BetaSchemaForm } from "@ant-design/pro-components";
import {
  QuestionCircleOutlined,
  ClearOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  FileSyncOutlined,
  CalendarOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import ptBRIntl from "antd/lib/locale/pt_BR";
import uuid from "react-uuid";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import {
  addRefFieldsFormData,
  deleteAllFormData,
  deleteAllRefFieldsFormData,
  fetchFormData,
} from "ducks/dataOfForm";
import { importSingleFile } from "auth/actions/importActions";
import {
  addRecords,
  deleteRecords,
  getFieldsDataByDocIds,
  getFieldsDataById,
  updateRecords,
} from "auth/actions/objActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import ImportRecordsByCSV from "./import";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Utils
 */
import buildColumns, {
  buildSchemaColumns,
  getLookupData,
} from "components/Utils/formColumns";
import buildTableData from "components/Utils/formData";
import convertToIndex from "components/Utils/convertToIndex";
import sleep from "components/Utils/sleep";
import { validateCSVColumns } from "utils/validate-csv-columns";
import Notification from "components/Notifications";
import { exportFormData } from "ducks/exportForm";
import { showNotification } from "ducks/notification";
import mountRecords from "components/Utils/buildRecords";
import removeUndefined from "components/Utils/removeUndefined";
import { getFieldValueForTable } from "components/Utils/getFieldValue";

/**
 * Misc
 */
const { confirm } = Modal;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

function RecordForms(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { name } = useParams();
  const currentForm = decodeURIComponent(name);
  // Tabela
  const columns = useMemo(
    () =>
      buildColumns(
        props.formData.columns,
        props.formData.fields,
        props.formData.refFields,
        true
      ),
    [props.formData.columns, props.formData.fields, props.formData.refFields]
  );
  const [query, setQuery] = useState({});
  const [sorterBy, setSorterBy] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [checkedRowsData, setCheckedRowsData] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkedRowsRef, setCheckedRowsRef] = useState({});
  const [loadings, setLoadings] = useState([]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 600);
  };

  // Formulário (adição, edição única e múltipla)
  const recordFormRef = useRef();
  const [recordFormAction, setRecordFormAction] = useState("add");
  const recordFormColumns = useMemo(
    () =>
      buildSchemaColumns(
        props.formData.columns,
        props.formData.fields,
        props.formData.refFields,
        checkedRowsRef,
        recordFormRef,
        recordFormAction,
        null,
        dispatch
      ),
    [
      props.formData.columns,
      props.formData.fields,
      props.formData.refFields,
      checkedRowsRef,
    ]
  );

  let recordFormColumnsFinal;
  if (recordFormAction !== "multipleEdit") {
    recordFormColumnsFinal = recordFormColumns;
  } else {
    const recordFormColumnsTemp  = _.cloneDeep(recordFormColumns);
    recordFormColumnsFinal = recordFormColumnsTemp.map(field => {
      if (_.isObject(field.formItemProps) && _.isArray(field.formItemProps.rules)) {
        for (let i = 0; i < field.formItemProps.rules.length; i++) {
          if (field.formItemProps.rules[i].hasOwnProperty('required')) {
            field.formItemProps.rules[i].required = false;
          }
        }
      }
      return field;
    });

    recordFormColumnsFinal.push({
      key: "acceptEmptyValues",
      name: "acceptEmptyValues",
      title: "Considerar valores vazios para atualização de campos dos registros selecionado?",
      valueType: "switch"
    })
  }


  // Adição
  const [openForm, setOpenForm] = useState(false);
  const [loadingSend,setLoadingSend]  = useState(false);

  // Importação
  const [importRecords, setImportRecords] = useState(false);
  const [disableImport, setDisableImport] = useState(false);
  const [resetUploader, setResetUploader] = useState(false);
  const [queueId, setQueueId] = useState(uuid());

  // useEffect(() => {
  //   // dispatch(fetchFormData(name, currentPage, pageSize));
  // }, [dispatch, name, currentPage, pageSize]);

  /**
   * Utilidades e ações para tabela
   * @param {*} key
   */
  const utilsTable = (key) => {
    switch (key) {
      case 1:
        openImport();
        break;
      case 2:
        const data = [];
        const allColumns = _.chain(props.formData.fields)
          .map("field")
          .filter((item) => item !== "_id")
          .concat(["createdAt"])
          .value();

        const csvData = Papa.unparse(
          { fields: allColumns, data: data },
          { delimiter: ";" }
        );
        const csvBlob = new Blob([csvData], {
          type: "text/csv;charset=utf-8;",
        });
        const csvUrl = URL.createObjectURL(csvBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = csvUrl;
        downloadLink.setAttribute(
          "download",
          `${convertToIndex(currentForm.replace("obj_", ""))}-modelo.csv`
        );
        downloadLink.click();
        break;
      case 3:
        confirm({
          title: "Iniciar exportação?",
          icon: <FileTextOutlined />,
          content: "Por favor, esteja ciente de que se o formulário contiver um grande número de registros, o processo pode levar algum tempo.",
          onOk() {
            // Dispara ação para exportação via CSV
            dispatch(
              exportFormData(
                name, // nome do formuláro
                query.current,
                query.pageSize,
                query.createdAt, // campo com a data de lançamento do registro
                query.queryParams, // campos com os valores do formuláro
                props.user.uuid,
                sorterBy
              )
            );
            // console.log("query", query);
          },
        });
        break;
    }
  };
  const utilsItemsTable = [
    {
      key: "1",
      label: "Importar",
      onClick: () => utilsTable(1),
    },
    {
      key: "2",
      label: "Exportar modelo",
      onClick: () => utilsTable(2),
    },
    {
      key: "3",
      label: "Exportar CSV",
      onClick: () => utilsTable(3),
    },
  ];
  const renderUtilsTable = (
    <Menu>
      {utilsItemsTable.map((item) => {
        _.map(item.data, (item) => {
          if (Array.isArray(item.updatedAt)) {
            item.updatedAt = "";
          }
          return item;
        });
        if (item.isCSVLink) {
          return (
            <Menu.Item key={item.key}>
              <CSVLink
                filename={item.filename}
                separator={";"}
                data={item.data}
                onClick={() => {}}
              >
                {item.label}
              </CSVLink>
            </Menu.Item>
          );
        } else {
          return (
            <Menu.Item key={item.key} onClick={item.onClick}>
              {item.label}
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );

  /**
   * Manipula o processo de upload de arquivo CSV para importação de dados
   * @param {File} file - O arquivo CSV a ser importado.
   */
  const openImport = () => {
    setImportRecords(true);
  };
  const closeImport = () => {
    setImportRecords(false);
  };
  const handleImportFileUpload = (file) => {
    // Desabilita temporariamente a importação
    setDisableImport(true);

    // Cria um FileReader para ler o conteúdo do arquivo CSV
    const reader = new FileReader();

    // Define o manipulador de evento para ser executado quando o arquivo é carregado
    reader.onload = async (e) => {
      // Divide o conteúdo do arquivo em linhas, colunas e remove a primeira linha (cabeçalho)
      const csvData = e.target.result
        .replace(/\r/g, "")
        .split("\n")
        .map((row) => row.split(";"))
        .filter((item) => item[0] !== "_id");

      // Define a lista de colunas válidas
      const validColumns = _.chain(props.formData.fields)
        .map("field")
        .filter((item) => item !== "_id")
        .concat(["createdAt"])
        .value();

      // Valida as colunas do arquivo CSV
      const missingColumns = validateCSVColumns(csvData, validColumns);

      if (missingColumns.length > 0) {
        // Exibe uma mensagem de erro para as colunas ausentes
        message.error(
          `As colunas a seguir estão faltando no arquivo CSV: ${missingColumns.join(
            ", "
          )}`
        );
        setDisableImport(false);
        setResetUploader(true);
      } else {
        // Verifica se há colunas extras no arquivo CSV
        const extraColumns = csvData[0].filter(
          (column) => !validColumns.includes(column) && column !== ""
        );
        if (extraColumns.length > 0) {
          // Exibe uma mensagem de erro para as colunas extras
          message.error(
            `O arquivo CSV contém colunas extras: ${extraColumns.join(", ")}`
          );
          setDisableImport(false);
          setResetUploader(true);
        } else {
          // Verifique se o CSV está vazio
          if (csvData.length <= 1) {
            // Só tem a linha de cabeçalho
            message.error(
              "O CSV está vazio. Por favor, forneça um CSV com pelo menos 1 dado."
            );
            setDisableImport(false);
            return;
          }

          // Verifique se o CSV tem mais de 10.000 registros
          if (csvData.length > 9999) {
            message.error(
              "O CSV tem mais de 10.000 registros. O limite é de 10.000 registros."
            );
            setDisableImport(false);
            return;
          }

          setDisableImport(false);
          setResetUploader(true);
          setImportRecords(false);
          const keyImportNotification = `open${Date.now()}`;
          // Exibe uma notificação de sucesso para o início da importação
          notification["success"]({
            message: "Importação iniciada.",
            description:
              'Os registros que você enviou estão sendo processados. Para acompanhar o andamento da importação, clique no botão "Visualizar" abaixo.',
            btn: (
              <Button
                type="primary"
                size="small"
                onClick={async () => {
                  notification.close(keyImportNotification);
                  await sleep(2000);
                  history.push(
                    `/formularios/importar/detalhes/${queueId}?form=${name}`
                  );
                }}
              >
                Visualizar
              </Button>
            ),
            key: keyImportNotification,
          });

          // Inicia o processamento do arquivo se as colunas forem válidas
          importSingleFile({
            file: file,
            formName: name,
            queueId,
            createdBy: props.user.uuid,
          })
              .then((response) => {
              notification.destroy();
              const key = `open${Date.now()}`;
              // Exibe uma notificação de sucesso para a conclusão da importação
              notification["success"]({
                duration:5000,
                message: "Importação concluída.",
                description:
                  "Os registros que você enviou foram importados com sucesso, atualize a página para visualizar!",
                key,
                btn: (
                    <Button
                        type="primary"
                        size="small"
                        onClick={async () => {
                          notification.close(key);
                          // Abre uma nova aba para visualizar o status da fila de importação
                          window.open(
                              `/formularios/importar/detalhes/${queueId}?form=${name}`,
                              "_blank"
                          );
                        }}
                    >
                      Visualizar
                    </Button>
                ),
                onClose: async () =>{
                  window.open(
                      `/formularios/importar/detalhes/${queueId}?form=${name}`,
                      "_blank"
                  );
                }
              });
            })
            .catch((error) => {
              console.error(error);
              // Exibe uma mensagem de erro se ocorrer um erro na importação
              message.error(`${file.name} não foi enviado, tente novamente!`);
            });
        }
      }
    };

    // Lê o arquivo como texto
    reader.readAsText(file);
  };

  /**
   * Remove registros
   */
  const deleteConfirm = () => {
    var ids = checkedRows.map((values) => values._id) || [];
    confirm({
      title: "Deseja excluir esses registros?",
      icon: <ExclamationCircleOutlined />,
      content: "Se confirmado e necessário, acesse a área de arquivamento dentro deste formulário para recuperar os registros.",
      onOk() {
        deleteRecords({
          formName: name,
          ids,
          useBin: false,
          isObjDataDelete: true,
          createdBy: props.user.uuid,
        })
          .then(async (res) => {
            notification['success']({
              message: res.message
            })
            await sleep(1000);
            window.location.reload(false);
          })
          .catch((err) => notification['error']({
            message: "Algo está errado, tente novamente!"
          }));
      },
    });
  };

  const checkIfIsEmpty  = (data) => {
    return data && _.every(data, (item) => _.isEmpty(item.id) && (_.isNil(item.value) || (!_.isNumber(item.value) && _.isEmpty(item.value))))
  }

  /**
   * Salva registros
   * @param {*} data
   * @param {*} action
   */
  const trySaveRecords = (data, action) => {
    if(loadingSend){
      return;
    }
    // Extrai os IDs dos registros selecionados
    var ids = checkedRows.map((values) => values._id);
    // Combina resultados buscando campos de retorno com nomes "duplicados"
    const keys = Object.keys(data);
    const uniqueKeys = new Set(keys);
    const haveDuplicateField = keys.length !== uniqueKeys.size;
    if (data && !_.isEmpty(data)) {
      if (haveDuplicateField) {
        notification['error']({
          message: "Não é permitido anexar campos de retornos com nomes duplicados. Por favor, remova os campos com nome duplicados dos campos de consulta."
        })
      } else {
        if (action && action === "add") {
          // Envia os registros para adição
          addRecords({
            formName: currentForm,
            record: data,
            createdBy: props.user.uuid,
          })
            .then(async (res) => {
              // Exibe uma notificação de sucesso
              notification["success"]({
                message: res.message
              })
              setOpenForm(false)
              // Aguarda 1 segundo antes de recarregar a página
              await sleep(1000);
              window.location.reload(false);
            })
            .catch((err) => {
              // Exibe uma notificação de erro em caso de falha na adição dos registros
              notification['error']({
                message: "Algo está errado, tente novamente!"
              })
            });
        }
        else if (action && action === "edit") {
          // Envia os registros para edição
          confirm({
            title: "Realmente deseja alterar?",
            icon: <ExclamationCircleOutlined />,
            content: "Você está enviando alterações dos registros salvos.",
            onOk() {
              // Atualiza os registros no banco de dados
              updateRecords({
                formName: currentForm,
                ids,
                records: data,
                forRemove: [],
                createdBy: props.user.uuid,
              })
                .then(async (res) => {
                  // Exibe uma notificação de sucesso
                  notification['success']({
                    message: res.message,
                  });

                  setOpenForm(false)
                  // Aguarda 1 segundo antes de recarregar a página
                  await sleep(1000);
                  window.location.reload(false);
                })
                  .catch((err) => {
                        notification['error']({
                          message: "Algo está errado, tente novamente!"
                        });
                      }
                  );
            },
          });
        }
        else if (action && action === "multipleEdit") {
          const requiredFields = props.formData.fields.filter(item => !!item.requiredField).map(item => item.field);
          const emptyFields = Object.entries(data).filter(([key, value]) => checkIfIsEmpty(value)).map(([key]) => key)
          const fields = _.keyBy(props.formData.fields, 'field');

          let intersection = _.intersection(requiredFields, emptyFields);
          for (let i = 0; i < requiredFields.length; i++) {
            const field = fields[requiredFields[i]]
            if (field.fieldtype === "refLookup" && _.includes(intersection,field.field)) {
              for (let j = 0; j < field.getValueOf.length; j++) {
                const item = field.getValueOf[j];
                intersection = [...intersection, ...[item.fieldName]];
              }
            }
          }
          data = _.omit(data, intersection);

          // Envia os registros para edição
          confirm({
            title: "Realmente deseja alterar?",
            icon: <ExclamationCircleOutlined />,
            content: "Você está enviando alterações dos registros salvos.",
            onOk() {
              // Atualiza os registros no banco de dados
              updateRecords({
                formName: currentForm,
                ids,
                records: data,
                forRemove: [],
                createdBy: props.user.uuid,
              })
                  .then(async (res) => {
                    setOpenForm(false)
                    // Exibe uma notificação de sucesso
                    notification['success']({
                      message: res.message
                    });
                    // Aguarda 1 segundo antes de recarregar a página
                    await sleep(1000);
                    window.location.reload(false);
                  })
                  .catch((err) =>
                      // Exibe uma notificação de erro em caso de falha na atualização dos registros
                      notification['error']({
                        message: "Algo está errado, tente novamente!"
                      })
                  );
            },
          });
        }
      }
    } else {
      // Notificação de erro se nenhum campo for preenchido
      notification['error']({
        message: "Você precisa preencher algum dos campos para confirmar o envio."
      })
    }
  };

  return (
    <Layout>
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 53px 53px", minHeight: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0px 5px", paddingLeft: 0 }}
              title="Registros do formulário"
              onBack={() => window.history.back()}
            />
            <Card>
              <ConfigProvider locale={ptBRIntl}>
                {/* Todos os registros */}
                <ProTable
                  rowKey="_id"
                  type="table"
                  request={async (params = {}, sorter, filter) => {
                    // Extrai as chaves e valores de "current" e "pageSize" do objeto params
                    const { current, pageSize, createdAt, ...queryParams } = params;

                    // Dispara a ação de busca dos dados do formulário
                    dispatch(
                      fetchFormData(
                        name, // nome do formuláro
                        current,
                        pageSize,
                        createdAt,
                        queryParams, // campos com valores do formuláro
                        sorter
                      )
                    );

                    // Aguarda um curto período de tempo para garantir que os dados estejam disponíveis
                    await new Promise((resolve) => setTimeout(resolve, 500));

                    // Guarda os parametros da pesquisa
                    setQuery({ current, pageSize, createdAt, queryParams });
                    setSorterBy(sorter)
                    // Constroi dados para tabela com props.formData.records
                    return buildTableData(props.formData.records);
                  }}
                  columns={columns}
                  dataSource={buildTableData(props.formData.records)}
                  toolbar={{
                    actions: [
                      <Button
                        type="link"
                        onClick={() =>
                          history.push(`/formularios/lixeira/${name}`)
                        }
                      >
                        Arquivados
                      </Button>,
                      <Button
                        type="primary"
                        loading={loadings[1]}
                        onClick={async () => {
                          if (
                            props.formData.total === 0 &&
                            props.formData.columns.length === 0
                          ) {
                            notification['info']({
                              message: "Você precisa adicionar novos campos ao formulário antes de adicionar um novo registro."
                            })
                          } else {
                            // Limpa os campos
                            recordFormRef.current.resetFields();
                            // Espera alguns instantes
                            enterLoading(1);
                            await sleep(700);
                            // Ação (CRUD)
                            setRecordFormAction("add");
                            // Abre formulário
                            setOpenForm(true);
                          }
                        }}
                        disabled={
                          props.formData.loading || checkedRows.length > 0
                        }
                      >
                        Adicionar
                      </Button>,
                      <Dropdown.Button
                        overlay={renderUtilsTable}
                        disabled={props.formData.loading}
                      >
                        Ações
                      </Dropdown.Button>,
                    ],
                  }}
                  pagination={{
                    pageSize,
                    total: props.formData.total,
                    current: currentPage,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    },
                  }}
                  headerTitle={`${props.formData.total} ${
                    props.formData.total === 0 ? "registro" : "registros"
                  } ${
                    props.formData.total === 0 ? "encontrado" : "encontrados"
                  }`}
                  tooltip={`${props.formData.totalInIndex} ${
                    props.formData.totalInIndex === 0 ? "registro" : "registros"
                  } no total`}
                  columnsState={{
                    persistenceKey: "clm-pro-table-all-records",
                    persistenceType: "localStorage",
                  }}
                  options={{
                    reload: true,
                  }}
                  tableAlertRender={({
                    selectedRowKeys,
                    selectedRows,
                    onCleanSelected,
                  }) => {
                    setCheckedRows(selectedRows);
                    return (
                      <Space size={24}>
                        <span>
                          Selecionado {selectedRowKeys.length} registros
                          <a
                            style={{ marginInlineStart: 8 }}
                            onClick={() => {
                              onCleanSelected();
                            }}
                          >
                            cancelar seleção
                          </a>
                        </span>
                      </Space>
                    );
                  }}
                  tableAlertOptionRender={() => {
                    return (
                      <Space size={16}>
                        <a
                          href={() => false}
                          onClick={async () => {
                            // Limpa os campos
                            recordFormRef.current.resetFields();
                            // Verifica se é seleção única ou múltipla
                            if (checkedRows.length > 1) {
                              // Ação (CRUD)
                              // Espera alguns instantes
                              await sleep(700);
                              // Ação (CRUD)
                              setRecordFormAction("multipleEdit");
                              // Abre formulário
                              setOpenForm(true);
                            } else {
                              // Ação (CRUD)
                              setRecordFormAction("edit");
                              // Tenta obter o registro
                              const nativeSelectedRecord =
                                await getFieldsDataById({
                                  table: currentForm.replace("obj_", ""),
                                  id: checkedRows[0]._id,
                                });
                              const { message: selectedRecordMessage } =
                                nativeSelectedRecord;
                              // Verifica se encontrou o registro selecionado
                              if (selectedRecordMessage) {
                                // Salva o registro em cache para ser utilizado em mountRecords()
                                setCheckedRowsData(selectedRecordMessage);
                              }
                              // Procura por campos referenciados e lookups
                              const onlyRefLkpFields =
                                props.formData.fields.filter(
                                  (e) =>
                                    e.fieldtype === "refLookup" ||
                                    e.fieldtype === "refUniqueSelection" ||
                                    e.fieldtype === "refMultipleSelection"
                                );
                              const onlyLkpFields =
                                props.formData.fields.filter(
                                  (e) => e.fieldtype === "refLookup"
                                ) || [];
                              // Remove campos referenciados e lookups
                              const withoutRefLkp =
                                _.omit(
                                  selectedRecordMessage,
                                  _.map(onlyRefLkpFields, "field")
                                ) || {};
                              // Verifica se registro não foi excluido
                              if (
                                selectedRecordMessage &&
                                !_.isEmpty(selectedRecordMessage)
                              ) {
                                const onlyRefLkp = _.pick(
                                  selectedRecordMessage,
                                  _.map(onlyRefLkpFields, "field")
                                );
                                const transformedOnlyRefLkp = {};
                                // Intera sobre os campos referenciados e lookups
                                for (const key in onlyRefLkp) {
                                  if (onlyRefLkp.hasOwnProperty(key)) {
                                    if (onlyRefLkp[key].length > 0) {
                                      const genId = `${onlyRefLkp[key]
                                        .map((obj) => obj._id)
                                        .join(",")}_rng@record_${uuid()}`;
                                      const currentValue = onlyRefLkp[key]
                                        .map((obj) => {
                                          let temp =  obj.value;
                                          if(moment(temp, "YYYY-MM-DDTHH:mm:ss[Z]", true).isValid()){
                                            temp = moment(temp, "YYYY-MM-DDTHH:mm:ss[Z]", true).format("DD/MM/YYYY")
                                          }
                                          return temp;
                                        })
                                        .join(", ");


                                      let currentValueDate = null;
                                      if(moment(currentValue, "YYYY-MM-DDTHH:mm:ss[Z]", true).isValid()){
                                        currentValueDate = moment(currentValue, "YYYY-MM-DDTHH:mm:ss[Z]", true).format("DD/MM/YYYY")
                                      }

                                      // Verifica se existe valor no campo
                                      if (!genId.startsWith("_r")) {
                                        // Extrai as propriedades _id e value, une com vírgulas e armazena
                                        transformedOnlyRefLkp[key] = [
                                          {
                                            _id: genId,
                                            value: currentValueDate || currentValue,
                                          },
                                        ];
                                        dispatch(
                                          addRefFieldsFormData(key, [
                                            {
                                              value: genId,
                                              label: currentValue,
                                            },
                                          ])
                                        );
                                        // Tenta preencher o formulário com os registros de campos referenciados e lookups
                                        recordFormRef.current.setFieldsValue({
                                          [key]: genId,
                                        });
                                      }
                                    }
                                  }
                                }
                                // Prepara o cache para os campos referenciados e lookups
                                setCheckedRowsRef(transformedOnlyRefLkp);
                                // Prepara os dados para ser preenchido na tabela
                                let recordForEdit = getFieldValueForTable(
                                  [withoutRefLkp],
                                  _.concat(
                                    props.formData.fields,
                                    props.formData.refFields
                                  ).filter((y) => y.isReadOnly === undefined)
                                );
                                // Verifica se existe campos de lookups
                                if (onlyLkpFields && onlyLkpFields.length > 0) {
                                  onlyLkpFields.forEach(async (obj) => {
                                    if (
                                      selectedRecordMessage[obj.field] &&
                                      selectedRecordMessage[obj.field].length >
                                        0
                                    ) {
                                      if (
                                        selectedRecordMessage[obj.field][0]
                                          ._id !== ""
                                      ) {
                                        // Obtém os dados do campo de retorno e preenche o formulário
                                        await getLookupData({
                                          tableRef: recordFormRef,
                                          selectedField: obj,
                                          selectedOpts: `${
                                            selectedRecordMessage[obj.field][0]
                                              ._id
                                          }_rng@record_${uuid()}`,
                                          dispatch,
                                        });
                                      }
                                    }
                                  });
                                }
                                const dateFields = props.formData.fields.filter((e) => e.fieldtype === "Date") || []

                                for (const dateField of dateFields) {
                                  if(recordForEdit[dateField.field] && moment(recordForEdit[dateField.field],'DD/MM/YYYY', true).isValid()) {
                                      recordForEdit[dateField.field] = moment(recordForEdit[dateField.field],'DD/MM/YYYY', true);
                                  }else {
                                    recordForEdit[dateField.field] = null;
                                  }
                                }

                                // Tenta preencher os campos simples
                                recordFormRef.current.setFieldsValue(
                                  // Remove campos referenciados e lookups
                                  _.omit(
                                    recordForEdit,
                                    _.map(onlyRefLkpFields, "field")
                                  )
                                );
                              }
                              // Espera alguns instantes
                              await sleep(700);
                              // Abre formulário
                              setOpenForm(true);
                            }
                          }}
                        >
                          Editar
                        </a>
                        <a href={() => false} onClick={deleteConfirm}>
                          Excluir
                        </a>
                      </Space>
                    );
                  }}
                  locale={{
                    emptyText: (
                      <Empty description="Nenhum registro adicionado." />
                    ),
                    triggerDesc: "Ordem decrescente",
                    triggerAsc: "Ordem crescente",
                  }}
                  dateFormatter={"number"}
                  scroll={{ x: true }}
                  style={{ padding: 0 }}
                  rowSelection={{}}
                  search={{
                    // collapseRender: true,
                    layout: "vertical",
                    ...formItemLayout,
                  }}
                  cardBordered={true}
                />
                {/* Formulário */}
                <BetaSchemaForm
                  formRef={recordFormRef}
                  visible={openForm}
                  layoutType={"ModalForm"}
                  modalProps={{
                    onCancel: async () => {
                      // recordFormRef.current.resetFields();
                      // Limpa os campos referenciados e lookups
                      dispatch(deleteAllRefFieldsFormData());
                      // Limpa os campos de retorno
                      dispatch(deleteAllFormData());
                      if (recordFormAction === "edit" || recordFormAction ==="multipleEdit") {
                        // Limpa seleção do registro para edição
                        setCheckedRowsData([]);
                      }
                      // Fecha o formulário
                      setOpenForm(false);
                      setRecordFormAction("add");
                    },
                    okText: "Salvar registro",
                  }}
                  columns={recordFormColumnsFinal}
                  labelCol={{
                    span: 24,
                  }}
                  onFinish={async (formValues) => {
                    setLoadingSend(true)
                    // Mantém os dados do formulário em cache
                    let submitedData = formValues;
                    // Filtra campos por tipo
                    const onlyFieldsName =
                      _.map(
                        props.formData.fields.filter(
                          (e) =>
                            e.fieldtype === "Text" ||
                            e.fieldtype === "Numeric" ||
                            e.fieldtype === "Date" ||
                            e.fieldtype === "Time" ||
                            e.fieldtype === "uniqueSelection" ||
                            e.fieldtype === "multipleSelection" ||
                            e.fieldtype === "refUniqueSelection" ||
                            e.fieldtype === "refMultipleSelection" ||
                            e.fieldtype === "refLookup" ||
                            e.fieldtype === "formula"
                        ),
                        "field"
                      ) || [];
                    // Verifica se existe campos no formulário
                    if (onlyFieldsName && onlyFieldsName.length > 0) {
                      // Se encontrar tenta fixar erro do onClear()
                      onlyFieldsName.forEach((item) => {
                        // Verifica se o campo não existe
                        if (!submitedData.hasOwnProperty(item)) {
                          // Adiciona campo com valor vazio
                          submitedData[item] = "";
                        }
                      });
                    }
                    // Tenta converter os dados para API
                    const result = await mountRecords(
                      currentForm,
                      submitedData,
                      props.formData.fields,
                      props.formData.refValues,
                      props.formData.lkpValues,
                      checkedRowsData,
                      recordFormAction
                    );
                    // Remove campos vazios
                    var finalResult =
                      removeUndefined(_.assign({}, ...result)) || {};
                    // Tenta enviar dados para API
                     trySaveRecords(finalResult, recordFormAction);
                    setLoadingSend(false)
                  }}
                />
              </ConfigProvider>
            </Card>
          </Layout>
          {/* Importação */}
          <ImportRecordsByCSV
            importRecords={importRecords}
            closeImport={closeImport}
            disableImport={disableImport}
            resetUploader={resetUploader}
            handleImportFileUpload={handleImportFileUpload}
          />
          <Notification />
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

const mapStateToProps = (all) => {
  // console.log("🚀 ~ file: index.js:186 ~ mapStateToProps ~ all:", all)
  return {
    user: all.session.user,
    formData: all.records,
  };
};

export default connect(mapStateToProps)(RecordForms);
