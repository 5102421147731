import getApiUrl from "services/getApiUrl";
import axios from 'axios';

const apiClient = axios.create({
    baseURL:`${getApiUrl()}/automations`,
});

export  const automationAPI = {
    getAll: async (params) => apiClient.get('/',{params}),

    getOne: async (id) =>  apiClient.get(`/${id}`),

    deleteOne: async (id) =>  apiClient.delete(`/${id}`),

    updateOne: async (id, data) =>  apiClient.patch(`/${id}`, data),

    getWebhookDataModel: async (id) =>  apiClient.get(`/${id}/webhook/scheme`),

    saveTrigger: async (id,config,type) =>  apiClient.post(`/${id}/trigger/save`,{config,type}),

    saveActions: async (id,actions) =>  apiClient.post(`/${id}/actions/save`,{actions})
};
