import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Typography,
  Spin,
} from "antd";
import { reportAPI } from "../../../services/actions/reports-v2";

/**
 * Components
 */
import { LayoutTopSide } from "components/NavBar/styles";
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";

const { Content } = Layout;
const { Paragraph } = Typography;

export default function EditReport() {
  const history = useHistory();
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchReport = async () => {
    try {
      const { status, data } = await reportAPI.getReportById(id);
      if (status === 200 || status === 201) {
        setReport(data);
      } else {
        throw new Error("Failed to fetch report");
      }
    } catch (error) {
      setError("Ocorreu um erro ao buscar o relatório.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, [id]);

  const routes = [
    {
      breadcrumbName: "Relatórios",
      href: "/relatorios/todos",
    },
    {
      breadcrumbName: "Editar",
    },
  ];

  return (
      <Layout style={{ height: "auto" }}>
        <LayoutTopSide>
          <TopBar />
          <Layout>
            <DrawerSider />
            <Layout style={{ padding: "0 24px 24px", height: "100vh" }}>
              <PageHeader
                  className="site-page-header"
                  style={{ margin: "16px 0 24px 0", background: "#fff" }}
                  title="Gerar relatório"
                  subTitle="Você pode editar qualquer relatório."
                  breadcrumb={
                    <Breadcrumb>
                      {routes.map((a) =>
                          a.href ? (
                              <Breadcrumb.Item key={a.href}>
                                <a href onClick={() => history.push(a.href)}>
                                  {a.breadcrumbName}
                                </a>
                              </Breadcrumb.Item>
                          ) : (
                              <Breadcrumb.Item key={a.breadcrumbName}>
                                {a.breadcrumbName}
                              </Breadcrumb.Item>
                          )
                      )}
                    </Breadcrumb>
                  }
              />
              <Content
                  className="site-layout-background"
                  style={{
                    padding: 0,
                    margin: 0,
                    minHeight: 280,
                    background: "white",
                  }}
              >
                {loading ? (
                    <Spin tip="Carregando relatório..." />
                ) : error ? (
                    <Paragraph>{error}</Paragraph>
                ) : (
                    report && (
                        <iframe
                            src={`${process.env.REACT_APP_URL}/metabase/dashboard/${report.id}`}
                            title="Relatório"
                            style={{ width: "100%", height: "70vh", border:"none" }}
                        />
                    )
                )}
              </Content>
            </Layout>
          </Layout>
        </LayoutTopSide>
      </Layout>
  );
}
