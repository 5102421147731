import React, { useEffect, useState } from "react";
import { Tree, Card, Row, Col, Divider, Empty } from "antd";
import uuid from "react-uuid";
import _, { set } from "lodash";

/**
 * Actions
 */
import { allFormsOfSavedObjectsWithFields } from "auth/actions/objActions";
import { filterTree, renderTreeNodes } from "./utils.js";

export default function TreeTransfer(props) {
  const [clean, setClean] = useState([]);
  const [data, setData] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState([]);

  useEffect(() => {
    onGet();
  }, []);

  useEffect(() => {
    if (props.refresh) {
      onGet();
    }
  }, [props]);

  const onGet = () => {
    /**
     * Todos os campos com formName
     */
    allFormsOfSavedObjectsWithFields().then((items) => {
      setClean(items.message);
      // organiza os campos
      var filtered = items.message
        .map((obj) => ({
          title: obj.name.replace("obj_", ""),
          key: uuid(),
          children: obj.fields
            .filter((field) => field.fieldtype === props.type)
            .map((field) => {
              let entries = Object.entries(field.checkBox);
              return {
                title: field.field,
                key: field._id,
                children: entries.map(([key, val]) => {
                  return {
                    title: val,
                    key: `${val}-${uuid()}`,
                  };
                }),
              };
            }),
        }))
        .filter((obj) => _.size(obj.children) > 0);
      // carrega os campos
      setData(filtered);
      // verifica se existe campos selecionado
      if (props.selected) {
        var selectedFrm = [],
          selectedFld = [],
          selectedOpt = [];
        // forms
        _.at(
          _.keyBy(filtered, "title"),
          items.message
            .map((form) => ({
              form: form.name,
              fields: _.compact(
                _.at(
                  _.keyBy(form.fields, "_id"),
                  _.map(props.selected, "fieldId")
                )
              ),
            }))
            .filter((obj) => obj.fields.length > 0)
            .map((obj) => obj.form.replace("obj_", ""))
        ).map((obj) => selectedFrm.push(obj.key));
        // campos e opcoes
        _.flattenDeep(
          filtered.map((obj) =>
            _.compact(
              _.at(
                _.keyBy(obj.children, "key"),
                _.map(props.selected, "fieldId")
              )
            )
          )
        ).forEach((field) => {
          selectedFld.push(field.key);
          _.at(
            _.keyBy(field.children, "title"),
            _.at(
              _.keyBy(
                _.flattenDeep(
                  items.message
                    .map((form) =>
                      _.compact(
                        _.at(
                          _.keyBy(form.fields, "_id"),
                          _.map(props.selected, "fieldId")
                        )
                      ).map((obj) => ({
                        _id: obj._id,
                        name: obj.field,
                        children: Object.entries(obj.checkBox)
                          .filter(([key, value]) =>
                            _.at(
                              _.keyBy(props.selected, "fieldId"),
                              obj._id
                            )[0].options.includes(key)
                          )
                          .map(([key, value]) => value),
                      }))
                    )
                    .filter((obj) => obj.length > 0)
                ),
                "_id"
              ),
              field.key
            )[0].children
          ).forEach((checkbox) => selectedOpt.push(checkbox.key));
        });
        // seleciona as opcoes
        const filteredTree = filterTree(
          selectedOpt,
          selectedFrm.concat(selectedFld),
          filtered
        );
        var info = {
          halfCheckedKeys: selectedFrm.concat(selectedFld),
          data: filtered,
        };
        // onCheck(selectedOpt, info);
        setCheckedNodes(filteredTree);
      }
    });
  };

  const onCheck = (selectedKeys, info) => {
    const filteredTree = filterTree(
      selectedKeys,
      info.halfCheckedKeys,
      props.selected ? (info.data ? info.data : data) : data
    );
    var fields = _.uniq(_.flatten(clean.map((obj) => obj.fields))),
      filtered = _.uniq(_.flatten(_.map(filteredTree, "children"))).map(
        (obj) => ({
          fieldId: obj.key,
          options: _.uniq(
            _.flatten(
              obj.children.map((child) =>
                _.entries(_.find(fields, { _id: obj.key }).checkBox)
                  .filter(([key, value]) => value === child.title)
                  .map(([key, value]) => key)
              )
            )
          ),
        })
      );
    props.onChange(filtered);
    setCheckedNodes(filteredTree);
  };

  const onRemove = (selectedKeys, info) => {
    const filteredTree = filterTree(selectedKeys, info.halfCheckedKeys, data);
    // remove as opcoes do campo
    function filterByOption(data, ids) {
      data.forEach((item) => {
        if (item.children) {
          for (let i = 0; i < item.children.length; i++) {
            let child = item.children[i];
            _.remove(child.children, (obj) => ids.includes(obj.key));
          }
        }
      });
      return data;
    }
    var a = filterByOption(checkedNodes, selectedKeys);
    console.log(filteredTree, a);
  };

  return (
    <div>
      <div>
        <Card>
          <Tree
            checkable
            defaultExpandAll
            onCheck={onCheck}
            height={200}
            disabled={props.disabled ? true : false}
          >
            {renderTreeNodes(data)}
          </Tree>
        </Card>
      </div>
      <Divider plain>⇅</Divider>
      <div>
        <Card>
          {checkedNodes.length > 0 ? (
            <Tree
              defaultExpandAll
              height={200}
              disabled={props.disabled ? true : false}
            >
              {renderTreeNodes(checkedNodes)}
            </Tree>
          ) : (
            <Empty description="Nenhum item selecionado..." />
          )}
        </Card>
      </div>
    </div>
  );
}
