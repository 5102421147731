import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Layout,
  PageHeader,
  Tabs,
  Descriptions,
  List,
  Modal,
  Avatar,
  Skeleton,
  Button,
  Typography,
  Empty,
} from "antd";
import _ from "lodash";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Actions
 */
import {
  allFieldsByName,
  allFormsOfSavedObjectsWithFields,
} from "auth/actions/objActions";

/**
 * Misc
 */
const { TabPane } = Tabs;
const { Title, Paragraph, Text, Link } = Typography;

function Preview() {
  const { name } = useParams();
  const [loading, setLoading] = useState({
    allFields: true,
  });
  const [selectedForm, setSelectedForm] = useState([]);
  const [fieldsOfForm, setFieldsOfForm] = useState([]);

  const [optModal, setOptModal] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState([]);
  const [refModal, setRefModal] = useState(false);
  const [selectedRef, setSelectedRef] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  const showOptModal = (selected) => {
    /**
     * Todos os campos com formName
     */
    allFormsOfSavedObjectsWithFields().then((items) => {
      setSelectedOpt(
        items.message
          .map((form) => ({
            form:
              form.name.replace("obj_", "").charAt(0).toUpperCase() +
              form.name.replace("obj_", "").slice(1),
            fields: _.compact(
              _.at(_.keyBy(form.fields, "_id"), _.map([selected], "fieldId"))
            ).map((obj) => ({
              _id: obj._id,
              name: obj.field,
              children: Object.entries(obj.checkBox).map(
                ([key, value]) => value
              ),
            })),
          }))
          .filter((obj) => obj.fields.length > 0)
      );
      setOptModal(true);
    });
  };
  const handleOnCancelOpt = () => {
    setOptModal(false);
  };
  const handleOnCancel = () => {
    setRefModal(false);
  };

  useEffect(() => {
    let mounted = true;
    let loadingStates = loading;
    try {
      if (selectedForm.length && fieldsOfForm.length > 0) {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    /**
     * Campos do formulario selecionado
     */
    allFieldsByName({ formName: name }).then((items) => {
      if (mounted) {
        setFieldsOfForm(items.message);
        loadingStates.allFields = false;
        setLoading(false);
      }
    });
    return () => (mounted = false);
  }, [selectedForm]);

  /**
   * Detalhes básicos do formulário
   * @param {*} column
   * @returns
   */
  const renderContent = (column = 2) => (
    <Descriptions size="small" column={column}>
      <Descriptions.Item label="Registrado como">
        <a>{name}</a>
      </Descriptions.Item>
    </Descriptions>
  );

  /**
   * Todos os Preview do formulário
   * @param {*} param
   * @returns
   */
  const Content = ({ children, extra }) => (
    <div className="content">
      <div className="main">{children}</div>
      <div className="extra">{extra}</div>
    </div>
  );

  /**
   * Filtra tipo do campo
   * @param {*} type
   * @returns
   */
  function filterTypeOfField(type) {
    switch (type) {
      case "Numeric":
        return "Numérico";
      case "Text":
        return "Texto";
      case "uniqueSelection":
        return "Seleção única";
      case "multipleSelection":
        return "Múltipla seleção";
      case "refMultipleSelection":
        return "Múltipla seleção referenciada";
      case "refUniqueSelection":
        return "Seleçāo única referenciada";
      case "refLookup":
        return "Consulta";
      case "formula":
        return "Fórmula";
      default:
        return "Padrão";
    }
  }

  return (
    <Layout>
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0 5px 0", backgroundColor: "white" }}
              onBack={() => window.history.back()}
              title="Pré-visualização"
              subTitle="Acompanhe seu formulário em tempo real."
              footer={
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Campos ativos" key="1">
                    <div
                      style={{
                        paddingTop: 15,
                        paddingBottom: 15,
                      }}
                    >
                      <Skeleton loading={loading.allFields} active avatar>
                        <List
                          dataSource={fieldsOfForm}
                          renderItem={(item) => (
                            <List.Item
                              key={item._id}
                              actions={
                                item.fieldtype === "multipleSelection" ||
                                item.fieldtype === "uniqueSelection"
                                  ? [
                                      <Button
                                        key="list-refMultipleSelection"
                                        type="link"
                                        loading={loadings[1]}
                                        onClick={() => {
                                          switch (item.fieldtype) {
                                            case "multipleSelection":
                                              enterLoading(1);
                                              showOptModal({
                                                fieldId: item._id,
                                                options: item.checkBox,
                                              });
                                              break;
                                            case "uniqueSelection":
                                              enterLoading(1);
                                              showOptModal({
                                                fieldId: item._id,
                                                options: item.checkBox,
                                              });
                                              break;
                                            default:
                                              break;
                                          }
                                        }}
                                      >
                                        Detalhes
                                      </Button>,
                                    ]
                                  : null
                              }
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    style={{
                                      color: "#ffff",
                                      backgroundColor: "#12b398",
                                    }}
                                  >
                                    {filterTypeOfField(item.fieldtype).charAt(
                                      0
                                    )}
                                  </Avatar>
                                }
                                title={<a>{item.field}</a>}
                                description={filterTypeOfField(item.fieldtype)}
                              />
                            </List.Item>
                          )}
                        />
                      </Skeleton>
                    </div>
                  </TabPane>
                </Tabs>
              }
            ></PageHeader>
          </Layout>
        </Layout>
        {/* Modal com detalhes dos campos referenciados */}
        <Modal
          title="Lista dos campos"
          visible={refModal}
          onCancel={handleOnCancel}
          footer={null}
        >
          {selectedRef.length > 0 ? (
            selectedRef.map((obj) => (
              <>
                <Title level={2}>{obj.form}</Title>
                {obj.fields.map((field) => (
                  <>
                    <Paragraph>{field.name}</Paragraph>
                    <Paragraph>
                      <ul>
                        {field.children.map((child) => (
                          <li>
                            <Link>{child}</Link>
                          </li>
                        ))}
                      </ul>
                    </Paragraph>
                  </>
                ))}
              </>
            ))
          ) : (
            <Empty description="Nada encontrado, tente novamente..." />
          )}
        </Modal>
        {/* Modal com detalhes dos campos seleçāo unica e multipla */}
        <Modal
          title="Lista das opções"
          visible={optModal}
          onCancel={handleOnCancelOpt}
          footer={null}
        >
          {selectedOpt.length > 0 ? (
            selectedOpt.map((obj) =>
              obj.fields.map((field) => (
                <>
                  <Paragraph>{field.name}</Paragraph>
                  <Paragraph>
                    <ul>
                      {field.children.map((child) => (
                        <li>
                          <Link>{child}</Link>
                        </li>
                      ))}
                    </ul>
                  </Paragraph>
                </>
              ))
            )
          ) : (
            <Empty description="Nada encontrado, tente novamente..." />
          )}
        </Modal>
      </LayoutTopSide>
    </Layout>
  );
}

export default Preview;
