import moment from "moment";
import "moment/locale/pt-br";
import checkValueIsDate from "./checkValueIsDate";
import _ from "lodash";

// Função auxiliar para extrair o 'valor' com base no tipo de campo
export default function getFieldValue(obj) {
  const value = (function (obj) {
    // Verifica se o objeto é indefinido (undefined) ou nulo (null)
    if (obj === undefined || obj === null) {
      return "";
    }
    // Verifica se o objeto tem uma propriedade 'value' definida
    else if (obj.value !== undefined) {
      return obj.value;
    }
    // Verifica se o objeto é uma string
    else if (typeof obj === "string" || typeof obj === "number") {
      return obj;
    }
    // Verifica se o objeto é um array com apenas um elemento
    else if (Array.isArray(obj) && obj.length === 1) {
      const singleItem = obj[0];
      // Retorna o valor se for uma string ou a propriedade 'value' do objeto se estiver definida
      return typeof singleItem === "string" || typeof singleItem === "number"  ? singleItem : singleItem.value || "";
    }
    // Verifica se o objeto é um array com mais de um elemento
    else if (Array.isArray(obj) && obj.length > 0) {
      // Mapeia os elementos do array para extrair seus valores ou propriedades 'value'
      const values = obj.map((item) => {
        let temp = typeof item === "string" || typeof item === "number" ? item : item.value;
        if (moment(temp, "YYYY-MM-DDTHH:mm:ss[Z]", true).isValid()) {
          return moment(temp, "YYYY-MM-DDTHH:mm:ss[Z]", true).format("DD/MM/YYYY")
        }
        return temp;
      });
      // Retorna os valores concatenados com uma vírgula e espaço entre eles
      return values.join(", ");
    }
    // Caso nenhuma das condições seja atendida, retorna uma string vazia
    else {
      return "";
    }
  })(obj)
  if (value === "") {
    return null
  } else {
    return value;
  }
}

export function getFieldValueForTable(data, fields) {
  let finalData = {};
  fields.forEach((obj) => {
    switch (obj.fieldtype) {
      case "Text":
      case "Numeric":
        if (data && data.length > 0) {
          if (data[0][obj.field]) {
            finalData[obj.field] = data[0][obj.field][0].value;
          } else {
            finalData[obj.field] = "";
          }
        } else {
          finalData[obj.field] = "";
        }
        break;
      case "Date":
      case "Time":
        if (data && data.length > 0) {
          if (data[0][obj.field]) {
            if (obj.isReadOnly) {
              // Data
              if (checkValueIsDate(data[0][obj.field][0].value, "YYYY-MM-DDTHH:mm:ss[Z]")) {
                finalData[obj.field] = moment(data[0][obj.field][0].value, "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY");
              }
              // Tempo
              if (checkValueIsDate(data[0][obj.field][0].value, "HH:mm")) {
                finalData[obj.field] = data[0][obj.field][0].value;
              }
            } else {
              // Data
              if (checkValueIsDate(data[0][obj.field][0].value, "YYYY-MM-DDTHH:mm:ss[Z]")) {
                finalData[obj.field] = moment(data[0][obj.field][0].value, "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY");
              }
              // Tempo
              if (checkValueIsDate(data[0][obj.field][0].value, "HH:mm")) {
                finalData[obj.field] = data[0][obj.field][0].value;
              }
            }
          } else {
            finalData[obj.field] = Date.now();
          }
        } else {
          finalData[obj.field] = Date.now();
        }
        break;
      case "uniqueSelection":
      case "multipleSelection":
        if (data && data.length > 0) {
          if (data[0][obj.field]) {
            if (obj.isReadOnly) {
              finalData[obj.field] = data[0][obj.field].map((x) => x.value);
            } else {
              finalData[obj.field] = data[0][obj.field].filter(item => !_.isEmpty(item.value));
            }
          } else {
            finalData[obj.field] = [];
          }
        } else {
          finalData[obj.field] = [];
        }
        break;
      case "refUniqueSelection":
      case "refMultipleSelection":
      case "refLookup":
        if (data && data.length > 0) {
          if (data[0][obj.field]) {
            if (obj.isReadOnly) {
              finalData[obj.field] = data[0][obj.field]
                .map((x) => x.value)
                .join(", ");
            } else {
              finalData[obj.field] = data[0][obj.field].map((x) => x._id);
            }
          } else {
            finalData[obj.field] = "";
          }
        } else {
          finalData[obj.field] = "";
        }
      default:
        break;
    }
  });
  return finalData;
}
