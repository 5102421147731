/* eslint-disable default-case */
import _ from "lodash";
import uuid from "react-uuid";
import moment from "moment";
import "moment/locale/pt-br";
import { getFieldsDataByDocIds } from "auth/actions/objActions";
import getFieldValueForDB from "./getFieldValueForDB";
import checkValueIsDate from "./checkValueIsDate";

/**
 * Organiza os dados do campo de leitura
 * @param {*} data
 * @param {*} fieldName
 * @returns
 */
const convertReadOnlyForDB = (data, fieldName) => {
  let nowConverted = [];
  data[fieldName].forEach((item) => {
    const keys = item._id.split(",");
    const values = item.value.split(", ");
    // Se tiver mais valores em "value" do que "_id" em "_id", assume que cada valor pertence ao mesmo "_id"
    if (values.length > keys.length) {
      values.forEach((value, index) => {
        nowConverted.push({
          _id: keys[0], // Utiliza o primeiro "_id" como pai
          value: value,
          isLock: true,
        });
      });
    } else {
      keys.forEach((key, index) => {
        nowConverted.push({
          _id: key,
          value: values[index] || "",
          isLock: true,
        });
      });
    }
  });
  // Percorre os valores procurando por tipos especificos de campos
  if (nowConverted && nowConverted.length > 0) {
    nowConverted = nowConverted.map((doc) => {
      // Data

      if (doc.value && checkValueIsDate(doc.value, "DD/MM/YYYY")) {
        return {
          ...doc,
          value: moment(doc.value, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss[Z]"),
        };
      }

      if (doc.value && checkValueIsDate(doc.value, "MM/DD/YYYY")) {
        return {
          ...doc,
          value: moment(doc.value, "MM/DD/YYYY").format("YYYY-MM-DDTHH:mm:ss[Z]"),
        };
      }

      // Numerico
      if (doc.value && _.isNumber(doc.value)) {
        return {
          ...doc,
          value: doc.value.toString(),
        };
      }
      return {
        ...doc,
      };
    });
  }

  return {
    [fieldName]: nowConverted || [{ _id: "", value: "" }],
  };
};

/**
 * Organiza os dados
 * @param {*} formName
 * @param {*} formData
 * @param {*} fields
 * @param {*} action
 * @returns
 */
export default async function mountRecords(
  formName,
  formData,
  fields,
  refData,
  lkpData,
  editData,
  action
) {
  let result = [];
  const processKey = async (key, selected) => {
    let item = {
      _id: uuid(),
      value: formData[key] || "",
    };
    let emptyItem = {
      _id: "",
      value: "",
    };
    switch (selected.fieldtype) {
      case "formula":
      case "Text":
      case "Numeric":
        // Verifica se o campo é de leitura
        if (selected.isReadOnly) {
          // Verifica se o campo foi preenchido
          if (lkpData && _.has(lkpData, selected.field)) {
            result.push(convertReadOnlyForDB(lkpData, selected.field));
          } else {
            // Verifica se está editando o registro
            if (action === "edit" || action === "multipleEdit") {
              result.push({
                [selected.field]: [emptyItem],
              });
            }
          }
        } else {
          // Verifica se está editando o registro
          if (action === "edit" || action === "multipleEdit") {
            // Verifica se o campo sofreu alteração
            if (
              formData[key] &&
              (formData[key] !== "" ||
                _.isNumber(formData[key]) ||
                !_.isEmpty(formData[key]))
            ) {
              result.push({
                [selected.field]: [
                  {
                    _id:
                      _.has(editData, selected.field) &&
                        editData[selected.field][0]._id !== ""
                        ? editData[selected.field][0]._id
                        : uuid(),
                    value: formData[key] || "",
                  },
                ],
              });
            } else {
              result.push({
                [selected.field]: [emptyItem],
              });
            }
          } else {
            result.push({
              [selected.field]: [item],
            });
          }
        }
        break;
      case "Date":
        // Verifica se o campo é de leitura
        if (selected.isReadOnly) {
          // Verifica se o campo foi preenchido
          if (lkpData && _.has(lkpData, selected.field)) {
            result.push(convertReadOnlyForDB(lkpData, selected.field));
          } else {
            // Verifica se está editando o registro
            if (action === "edit" || action === "multipleEdit") {
              result.push({
                [selected.field]: [{ _id: "", value: "" }],
              });
            }
          }
        } else {
          // Verifica se a string de data está no formato "MM/DD/YYYY" ou "DD/MM/YYYY"
          var formatMDY = moment(formData[key], "MM/DD/YYYY", true);
          var formatDMY = moment(formData[key], "DD/MM/YYYY", true);
          let dateValueId = uuid();
          // Verifica se está editando o registro
          if (action === "edit" || action === "multipleEdit") {
            dateValueId = _.has(editData, selected.field)
              ? editData[selected.field]._id
              : uuid();
          }
          // Verifica se o campo for preenchido
          if (formData[key] && formData[key] !== "") {
            // Tenta determinar o formato correto
            if (formatMDY.isValid() && formatDMY.isValid()) {
              result.push({
                [selected.field]: [{
                  _id: dateValueId || uuid(),
                  value: formatDMY.startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
                }],
              });
            }else if (formatMDY.isValid() && !formatDMY.isValid()) {
              result.push({
                [selected.field]: [{
                  _id: dateValueId || uuid(),
                  value: formatMDY.startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
                }],
              });
            } else if (!formatMDY.isValid() && formatDMY.isValid()) {
              result.push({
                [selected.field]: [{
                  _id: dateValueId || uuid(),
                  value: formatDMY.startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
                }],
              });
            }
            else {
              // console.log("A data não está em nenhum formato reconhecido.");
              if (!_.isEmpty(formData[key])) {
                result.push({
                  [selected.field]: [{
                    _id: dateValueId || uuid(),
                    value: formData[key] || moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
                  }],
                });
              } else {
                result.push({
                  [selected.field]: [{
                    _id: dateValueId || uuid(),
                    value: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
                  }],
                });
              }
            }
          } else {
            result.push({
              [selected.field]: [{
                _id: "",
                value: "",
              }],
            });
          }
        }
        break;
      case "Time":
        // Verifica se o campo é de leitura
        if (selected.isReadOnly) {
          // Verifica se o campo foi preenchido
          if (lkpData && _.has(lkpData, selected.field)) {
            result.push(convertReadOnlyForDB(lkpData, selected.field));
          } else {
            // Verifica se está editando o registro
            if (action === "edit" || action === "multipleEdit") {
              result.push({
                [selected.field]: [{ _id: "", value: "" }],
              });
            }
          }
        } else {
          let timeValueId = uuid();
          // Verifica se está editando o registro
          if (action === "edit" || action === "multipleEdit") {
            timeValueId = _.has(editData, selected.field)
              ? editData[selected.field]._id
              : uuid();
          }
          // Verifica se o campo foi preenchido
          if (formData[key] && !_.isEmpty(formData[key])) {
            // Verifica se campo está no formato milisegundos
            if (formData[key] && _.isNumber(formData[key])) {
              var timeValue = moment(formData[key]);
              // Verifica se campo é tempo válido
              if (timeValue.isValid()) {
                // Formate para "HH:mm"
                result.push({
                  [selected.field]: [{
                    _id: timeValueId || uuid(),
                    value: timeValue.format("HH:mm"),
                  }],
                });
              } else {
                // console.log("Os milissegundos não são válidos.");
                result.push({
                  [selected.field]: [{
                    _id: timeValueId || uuid(),
                    value: formData[key] || moment().format("HH:mm"),
                  }],
                });
              }
            } else {
              result.push({
                [selected.field]: [{
                  _id: timeValueId || uuid(),
                  value: formData[key],
                }],
              });
            }
          } else {
            result.push({
              [selected.field]: [{
                _id: "",
                value: "",
              }],
            });
          }
        }
        break;
      case "multipleSelection":
        let optionsIndex = Object.entries(selected.checkBox).reduce((prev, [key, value]) => {
          return { ...prev, ...{ [value]: key } };
        }, {});
        // Verifica se o campo é de leitura
        if (selected.isReadOnly) {
          // Verifica se o campo foi preenchido
          if (lkpData && _.has(lkpData, selected.field)) {
            result.push(convertReadOnlyForDB(lkpData, selected.field));
          }
          else {
            // Verifica se está editando o registro
            if (action === "edit" || action === "multipleEdit") {
              result.push({
                [selected.field]: [{ _id: "", value: "" }],
              });
            }
          }
        } else {
          // Verifica se está editando o registro
          if (action === "edit" || action === "multipleEdit") {
            // Verifica se o campo sofreu alteração
            if (
              formData[key] &&
              formData[key] !== "" &&
              formData[key].length > 0
            ) {
              if (_.every(formData[key], (item) => _.isString(item))) {
                result.push({
                  [selected.field]: _.compact(formData[key]).map(item => {
                    return {
                      _id: optionsIndex[item] ?? "",
                      value: item
                    }
                  }),
                });
              }
              else {
                result.push({
                  [selected.field]: _.compact(formData[key]).map(item => {
                    const hasValue = !_.isEmpty(item._id) && !_.isEmpty(item.value);
                    if (hasValue) {
                      return {
                        _id: item._id,
                        value: item.value
                      }
                    } else {
                      return {
                        _id: "",
                        value: ""
                      }
                    }

                  }),
                });
              }
            } else {
              result.push({
                [selected.field]: [emptyItem],
              });
            }
          }
          else {
            if (
              ![formData[key]].includes(undefined) &&
              formData[key].length > 0
            ) {
              result.push({
                [selected.field]: _.compact(formData[key])
                  .map(item => {
                    return {
                      _id: optionsIndex[item] ?? "",
                      value: item
                    };
                  })
              });

            } else {
              result.push({
                [selected.field]: [emptyItem],
              });
            }
          }
        }
        break;
      case "uniqueSelection":
        let optionIndex = Object.entries(selected.checkBox).reduce((prev, [key, value]) => {
          return { ...prev, ...{ [value]: key } };
        }, {});
        // Verifica se o campo é de leitura
        if (selected.isReadOnly) {
          // Verifica se o campo foi preenchido
          if (lkpData && _.has(lkpData, selected.field)) {
            result.push(convertReadOnlyForDB(lkpData, selected.field));
          } else {
            // Verifica se está editando o registro
            if (action === "edit" || action === "multipleEdit") {
              result.push({
                [selected.field]: [emptyItem],
              });
            }
          }
        } else {
          // Verifica se está editando o registro
          if (action === "edit" || action === "multipleEdit") {
            if (formData[key] || formData[key].length > 0) {
              if (_.isEqual(formData[key], [""])) {
                result.push({ [selected.field]: [{ _id: "", value: "" }] });
              } else {
                if (_.isString(formData[key])) {
                  result.push({ [selected.field]: [{ _id: optionIndex[formData[key]], value: formData[key] }] });
                } else if (_.isArray(formData[key]) && _.size(formData[key]) > 0) {
                  result.push({ [selected.field]: _.compact(formData[key]) });
                } else {
                  result.push({ [selected.field]: [{ _id: "", value: "" }] });
                }
              }
            } else {
              result.push({
                [selected.field]: [emptyItem],
              });
            }
          } else {
            if (![formData[key]].includes(undefined)) {
              if (_.isEqual(formData[key], [])) {
                result.push({ [selected.field]: [{ _id: uuid(), value: formData[key] }] });
              } else {
                if (_.isArray(formData[key]) && _.size(formData[key]) > 0) {
                  const value = _.compact(formData[key])[0];
                  result.push({ [selected.field]: [{ _id: optionIndex[value], value: value }] });
                } else {
                  result.push({ [selected.field]: [{ _id: optionIndex[formData[key]] ?? "" , value: formData[key] }] });
                }
              }
            } else {
              result.push({
                [selected.field]: [emptyItem],
              });
            }
          }
        }
        break;
      case "refMultipleSelection":
      case "refUniqueSelection":
      case "refLookup":
        let customEmptyItem = {
          ...emptyItem,
          value: "",
          _id: ""
        };
        try {
          // Verifica se o campo é de leitura
          if (selected.isReadOnly) {
            // Verifica se o campo foi preenchido
            if (lkpData && _.has(lkpData, selected.field)) {
              result.push(convertReadOnlyForDB(lkpData, selected.field));
            } else {
              // Verifica se está editando o registro
              if (action === "edit" || action === "multipleEdit") {
                result.push({
                  [selected.field]: [customEmptyItem],
                });
              }
            }
          } else {
            const refValues = refData[key];
            const fieldIsEmpty = _.isEmpty(refData[key]);
            // Verifica se está editando o registro
            if (action === "edit" || action === "multipleEdit") {
              if (
                (refValues && refValues.length > 0 && refValues[0] !== "")
              ) {
                // Verifica se o campo está vazio
                if (fieldIsEmpty) {
                  result.push({
                    [selected.field]: [customEmptyItem],
                  });
                } else {
                  const refValuesOfEdit = refValues.map((doc) => {
                    // Remove (DD/MM/YYYY HH:mm:ss) (i++) das opções selecionadas
                    let updatedDocValue = doc.label.replace(/\s*\(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}\)(?:\s*\(\d+\))?/, "") || "";
                    if (moment(updatedDocValue, "DD/MM/YYYY", true).isValid()) {
                      updatedDocValue = moment(updatedDocValue, "DD/MM/YYYY", true).format("YYYY-MM-DDT00:00:00[Z]")
                    }
                    return {
                      // Remove rng das opções selecionadas
                      _id: doc.value.replace(/_rng@record_.*/, ""),
                      value: updatedDocValue.trim(),
                    };
                  });
                  // Verifica se opção selecionada é multipla
                  result.push({
                    [selected.field]: refValuesOfEdit || [customEmptyItem],
                  });
                  // Verifica se opção selecionada é multipla
                  result.push({
                    [selected.field]: refValuesOfEdit || [customEmptyItem],
                  });
                }
              } else {
                result.push({
                  [selected.field]: [customEmptyItem],
                });
              }
            } else {
              // Verifica se o campo foi preenchido
              if (
                (refValues && refValues.length > 0 && refValues[0] !== "")
              ) {
                // Verifica se o campo está vazio
                if (fieldIsEmpty) {
                  result.push({
                    [selected.field]: [customEmptyItem],
                  });
                } else {
                  const refValuesForSave = refValues.map((doc) => {
                    // Remove (DD/MM/YYYY HH:mm:ss) (i++) das opções selecionadas
                    let updatedDocValue = doc.label.replace(/\s*\(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}\)(?:\s*\(\d+\))?/, "") || "";
                    if (moment(updatedDocValue, "DD/MM/YYYY", true).isValid()) {
                      updatedDocValue = moment(updatedDocValue, "DD/MM/YYYY", true).format("YYYY-MM-DDT00:00:00[Z]")
                    }
                    return {
                      // Remove rng das opções selecionadas
                      _id: doc.value.replace(/_rng@record_.*/, ""),
                      value: updatedDocValue.trim(),
                    };
                  });
                  // Verifica se opção selecionada é multipla
                  result.push({
                    [selected.field]: refValuesForSave || [customEmptyItem],
                  });
                }
              } else {
                result.push({
                  [selected.field]: [customEmptyItem],
                });
              }
            }
          }
        } catch (error) {
          result.push({
            [selected.field]: [customEmptyItem],
          });
        }
        break;
    }
  };

  for (let key in formData) {
    let currentField = _.find(fields, { field: key });
    if (currentField) {
      await processKey(key, currentField); // Aguarda a resolução de cada processKey
    }
  }

  if (action === "multipleEdit") {
    result.push({ 'acceptEmptyValues': formData.acceptEmptyValues || false })
  }

  return result;
}
