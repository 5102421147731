import {EditOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import {useState} from "react";

export const EditTitleAction = ({title, onChange}) => {
    const [name, setName] = useState(title)
    const [isEdit, setIsEdit] = useState(false)

    const handleChange = (e) => {
        setIsEdit(false);
        onChange(name)
    }
    if (isEdit) {
        return <Input type="text"
                      placeholder="Ente com o nome da ação!"
                      value={name}
                      onChange={(e) => setName(e.target.value.replace(/[^a-zA-Z0-9_ ]/g, ''))}
                      onPressEnter={handleChange}
                      onBlur={handleChange}
                      allowClear={true}
        >
        </Input>
    }
    return <Button type="text" onClick={() => setIsEdit(true)}> {title} <EditOutlined/></Button>
}
