import {ProCard, ProTable} from "@ant-design/pro-components";
import {ConfigProvider, Tooltip} from 'antd';
import {CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import ptBRIntl from "antd/lib/locale/pt_BR";
import moment from "moment";
import axios from 'axios';
import getApiUrl from "../../../../../services/getApiUrl";
import {LogViewer} from "./LogViewer";


export const AutomationsLog = ({automation}) => {

    const [automationLogId, setAutomationLogId] = useState(null);

    const formatTimeAgo = (date) => {
        const now = moment();
        const logDate = moment(date);
        const duration = moment.duration(now.diff(logDate));
        return "Há " + duration.humanize(false);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            copyable: true,
            ellipsis: true,
            sorter: false,
            width: "auto",
            search: false,
            render: (dom, record, index, action, schema) => {
                const text = record[schema.dataIndex];
                return (<Tooltip placement="bottom" title={text}>{text.substring(0, 10) + "..."}</Tooltip>);
            },
        },
        {
            title: 'Data',
            dataIndex: 'startDate',
            key: 'startDate',
            valueType: "dateRange",
            fieldProps: {
                format: "DD/MM/YYYY HH:mm",
                showTime: true,
                style: {
                    height: "100%"
                }
            },
            render: (dom, record, index, action, schema) => {
                const date = record[schema.dataIndex];
                return (
                    <span>
                        {moment(date).format('DD/MM/YYYY HH:mm:ss')}
                        <br/>
                        <span style={{fontSize: '0.9em', color: 'gray'}}>
                            {formatTimeAgo(date)}
                        </span>
                    </span>
                );
            },
            sorter: true,
            width: "auto",
            search: {
                transform: (dates) => {
                    const items = dates.map(item => {
                        if (moment(item, 'DD/MM/YYYY HH:mm', true).isValid()) {
                            return moment(item, 'DD/MM/YYYY HH:mm:ss').toISOString()
                        }
                        return null;
                    })
                    if (items.filter(item => item).length > 0) {
                        return {
                            date: items
                        }
                    } else {
                        return {
                            date: null
                        }
                    }

                },
            }
        },
        {
            title: 'Sucesso',
            dataIndex: 'success',
            valueType: "select",
            valueEnum: {
                all: "Todos",
                error: "Com erro",
                success: "Sem erro"
            },
            key: 'success',
            render: (dom, record, index, action, schema) => {
                const success = record[schema.dataIndex];
                return (
                    <span style={{
                        'display': 'flex',
                        'alignItems': "center",
                        'alignContent': 'center',
                        'flexDirection': 'column'
                    }}>
                        {success ? <CheckCircleOutlined style={{color: 'green'}}/> :
                            <CloseCircleOutlined style={{color: 'red'}}/>}
                    </span>
                );
            },
            sorter: true,
            search: {
                transform: (value) => ({success: value === 'all' ? null : value === 'success' ? 1 : 0}),
            },
            width: "auto",
        },
        {
            title: 'Erro',
            dataIndex: 'error',
            key: 'error',
            sorter: true,
            search: false,
            width: "auto",
        },
    ];

    return (
        <ProCard colSpan={24}  split={'vertical'} bordered style={{height: "100%"}}>
            <ProCard colSpan={16} title={"Histórico"}>
                <ConfigProvider locale={ptBRIntl}>
                    <ProTable
                        params={{
                            automationId: automation.id
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setAutomationLogId(record.id); // Salva o log selecionado no estado
                                },
                                style: { cursor: 'pointer' },
                            };
                        }}
                        scroll={{x: true}}
                        columns={columns}
                        rowKey="id"
                        request={async (params = {}, sorter, filter) => {
                            const {current, pageSize, date, success, automationId} = params;
                            const query = {
                                success,
                                page: current || 1,
                                perPage: pageSize || 10,
                                automationId
                            };

                            if (date && date[0] && date[1]) {
                                query.startDate = date[0];
                                query.endDate = date[1];
                            }

                            if (sorter && sorter.field && sorter.field) {
                                query.orderByField = sorter.field;
                                query.orderBySort = sorter.order === 'descend' ? 'desc' : 'asc';
                            }


                            const response = await axios.get(`${getApiUrl()}/automations/logs`, {
                                params: query
                            });

                            const {data, total} = response.data;
                            return {
                                data,
                                success: true,
                                total,
                            };
                        }}
                        style={{height: '100%', marginTop: "15px", padding: 0}}
                        toolBarRender={false}
                        search={{
                            showHiddenNum: true,
                            style: {
                                height: "100%",
                            }
                        }}
                    />
                </ConfigProvider>
            </ProCard>
            <LogViewer automation={automation} logId={automationLogId}/>
        </ProCard>
    );
};
