import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Upload,
  Tabs,
} from "antd";
import { useLocation, useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
/**
 * Actions
 */
import {
  editaUsuario,
  alterasenha,
} from "auth/actions/userActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

const { Content } = Layout;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function EditarUser() {
  const [editedUser, seteditedUser] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.session);
  const token = user.user.JWtoken;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  /**
   * Salva as alterações no usuário
   * @param {*} newvalues
   */
  const onFinishUserInfo = async () => {
    try {
      const values = await form.validateFields();
      // verifica
      if (values) {
        var forSend = editedUser;
        delete forSend.thumbUrl;
        // monta novos campos
        Object.assign(forSend, {
          firstName: values.firstName,
          lastName: values.lastName,
          userName: values.userName,
          // password: values.password,
          email: values.email,
          fone: values.fone,
          image: fileList[0]?.thumbUrl,
        });
        // salva os dados
        editaUsuario(forSend, history);
      }
    } catch (errorInfo) {
      console.log("🚀 ~ file: index.js:103 ~ onFinishUserInfo ~ errorInfo:", errorInfo);
    }
  };

  const onFinishUserAuth = async () => {
    try {
      setIsSubmitting(true);
      const values = await form.validateFields();
      if (values) {
        alterasenha(values, setFieldError, setIsSubmitting, history, token, user);
      }
    } catch (errorInfo) {
      console.log("🚀 ~ file: index.js:115 ~ onFinishUserAuth ~ errorInfo:", errorInfo);
    } finally {
      setIsSubmitting(false);
    }
  };

  const routes = [
    {
      breadcrumbName: "usuários",
      href: "/usuarios/todos",
    },
    {
      breadcrumbName: "editar",
    },
  ];

  useEffect(() => {
    if (Object.keys(editedUser).length > 0) {
      return;
    } else {
      // Aqui recebemos a informação.Acessamos com o mesmo nome que setamos no componente anterior.
      seteditedUser(location.state);
      const imgList = location.state;
      imgList.thumbUrl = imgList.image;
      const imgList2 = {
        ...imgList,
        thumbUrl: Buffer.from(location.state?.image, "base64").toString(
          "binary"
        ),
      };
      setFileList([imgList2]);
    }
  }, [editedUser]);

  const validateTextLength = (_, value) => {
    if (value && value.length > 50) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onChangeimg = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);
    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", height: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0 5px 0" }}
              title="Editar usuário"
              subTitle="Você pode editar um perfil de usuário para cada colaborador com permissões especiais."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Dados" key="1">
                      <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={onFinishUserInfo}
                        scrollToFirstError
                        initialValues={{
                          firstName: location.state.firstName,
                          lastName: location.state.lastName,
                          userName: location.state.userName,
                          email: location.state.email,
                          fone: location.state.fone,
                          profilePic: location.state.image,
                        }}
                      >
                        <Form.Item
                          style={{
                            marginTop: "-2%",
                            marginBottom: "2%",
                            marginLeft: "50%",
                            borderRadius: 90000,
                          }}
                          name="profilePic"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, selecione uma imagem!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  value.fileList === undefined ||
                                  value.fileList.length > 0
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Por favor, selecione uma imagem!")
                                );
                              },
                            }),
                          ]}
                        >
                          <Upload
                            action={Buffer.from(
                              location.state.image,
                              "base64"
                            ).toString("binary")}
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChangeimg}
                            onPreview={onPreview}
                          >
                            {fileList.length < 1 && "+ Imagem"}
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          name="firstName"
                          label="Nome"
                          tooltip="Qual o nome?"
                          rules={[
                            {
                              required: true,
                              message: "Por favor insira o nome!",
                              whitespace: false,
                            },
                            {
                              validator: validateTextLength,
                              message: "Nome não pode conter mais de 50 caracteres",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="lastName"
                          label="Sobrenome"
                          tooltip="Qual o sobrenome?"
                          rules={[
                            {
                              required: true,
                              message: "Por favor insira o sobrenome!",
                              whitespace: false,
                            },
                            {
                              validator: validateTextLength,
                              message: "Sobrenome não pode conter mais de 50 caracteres",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="userName"
                          label="Apelido"
                          tooltip="Do que você quer que os outros chamem você?"
                          rules={[
                            {
                              required: true,
                              message: "Por favor insira seu apelido!",
                              whitespace: false,
                            },
                            {
                              validator: validateTextLength,
                              message: "Apelido não pode conter mais de 50 caracteres",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="email"
                          label="E-mail"
                          rules={[
                            {
                              type: "email",
                              message:
                                "E-mail que você digitou não é um e-mail válido!",
                            },
                            {
                              required: true,
                              message: "Por favor insira seu e-mail!",
                            },
                            {
                              validator: validateTextLength,
                              message: "E-mail não pode conter mais de 50 caracteres",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...formItemLayout}
                          name="fone"
                          label="Tel/Cel"
                          rules={[
                            {
                              required: true,
                              message: "Por favor insira seu numero!",
                            },
                          ]}
                        >
                          <InputMask mask="+99(99)99999-9999">
                            <Input />
                          </InputMask>
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                          <Button type="primary" htmlType="submit">
                            Salvar Alterações
                          </Button>
                        </Form.Item>
                      </Form>
                    </Tabs.TabPane>

                    {/* TAB 2 */}

                    <Tabs.TabPane tab="Autenticação" key="2">
                      <Form
                        id="authentication"
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={onFinishUserAuth}
                        scrollToFirstError
                      >
                        <Form.Item
                          {...formItemLayout}
                          name="password"
                          label="Senha"
                          rules={[
                            {
                              required: true,
                              message: "Por favor insira uma senha!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password placeholder="*********" />
                        </Form.Item>
                        <Form.Item
                          name="confirmpassword"
                          label="Confirme: "
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Por favor, confirme a senha!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "As duas senhas que você digitou não coincidem!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password placeholder="*********" />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitting}
                          >
                            Salvar Alterações
                          </Button>
                        </Form.Item>
                      </Form>
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}
