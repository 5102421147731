// Types
const SHOW_NOTIFICATION = 'core/notification/SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'core/notification/HIDE_NOTIFICATION';

// Reducer
const initialState = {
  message: '',
  type: '',
  visible: false,
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
        visible: true,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        visible: false,
      };
    default:
      return state;
  }
}

// Ação para mostrar uma notificação
export const showNotification = (message, type) => ({
  type: SHOW_NOTIFICATION,
  payload: { message, type },
});

// Ação para esconder uma notificação
export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});
