import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PinInput } from "react-input-pin-code";
import Loader from "react-loader-spinner";
import { FiMail, FiLock } from "react-icons/fi";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "assets/css/popup.css";

/**
 * Actions
 */
import { loginUser } from "auth/actions/userActions";
import { TextInput } from "components/Forms/SignIn/FormLib";

/**
 * Components
 */
import colors from "components/Colors";
import {
  StyledContainer,
  StyledFormArea,
  StyledFormButton,
  Avatar,
  StyledTitle,
  ButtonGroup,
  ExtraText,
  TextLink,
  CopyrightText,
} from "components/Styles";
import Logo from "assets/images/logo.png";

const SignIn = ({ loginUser }) => {
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const [Token, setToken] = useState(["", "", "", "", "", ""]);
  return (
    <StyledContainer>
      <div>
        <StyledFormArea>
          <Avatar image={Logo} />
          <StyledTitle color={colors.theme} size={30}>
            Bem-vindo!
          </StyledTitle>
          <Formik
            initialValues={{
              email: "",
              password: "",
              Token: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Email inválido.")
                .required("O campo email não pode ser vazio."),
              password: Yup.string()
                .min(8, "Senha é muito curta.")
                .max(30, "Senha é muito longa.")
                .required("O campo senha não pode ser vazio."),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              loginUser(
                values,
                Token,
                history,
                setFieldError,
                setSubmitting,
                setShowPopup
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextInput
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="eu@exemplo.com.br"
                  icon={<FiMail />}
                />
                <TextInput
                  name="password"
                  type="password"
                  label="Senha"
                  placeholder="*******"
                  icon={<FiLock />}
                />
                <ButtonGroup>
                  {!isSubmitting && (
                    <StyledFormButton type="submit">
                      Iniciar sessão
                    </StyledFormButton>
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={colors.theme}
                      height={49}
                      width={100}
                    />
                  )}
                </ButtonGroup>
                {/* CONTEUDO DA POPUP */}
                <Popup
                  open={showPopup}
                  onClose={() => setShowPopup(false)}
                  modal
                >
                  {(close) => (
                    <div className="modal">
                      <div className="closearea">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <div className="header">
                          <h1>Verificação de dois passos</h1>
                          <hr />
                        </div>
                        <div className="Texto">
                          <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Sequi ullam ex harum omnis sunt earum corporis
                            ad repellendus distinctio assumenda quae atque
                            laborum aut aperiam nulla adipisci, sed error
                            pariatur.Lorem Lorem ipsum dolor sit amet
                            consectetur adipisicing elit.
                          </p>
                        </div>
                        <Form>
                          <PinInput
                            initialValue=""
                            values={Token}
                            length={6}
                            secret
                            onChange={(value, index, values) =>
                              setToken(values)
                            }
                          />
                          <ButtonGroup>
                            {!isSubmitting && (
                              <StyledFormButton type="submit">
                                Iniciar sessão
                              </StyledFormButton>
                            )}
                            {isSubmitting && (
                              <Loader
                                type="ThreeDots"
                                color={colors.theme}
                                height={49}
                                width={100}
                              />
                            )}
                          </ButtonGroup>
                        </Form>
                      </div>
                    </div>
                  )}
                </Popup>
              </Form>
            )}
          </Formik>
          <ExtraText>
            Esqueceu a senha?{" "}
            <TextLink to="/esqueci-senha">Clique aqui</TextLink>
          </ExtraText>
        </StyledFormArea>
        <CopyrightText>
          Todos os direitos reservados &copy; {new Date().getFullYear()}
        </CopyrightText>
      </div>
    </StyledContainer>
  );
};

export default connect(null, { loginUser })(SignIn);
