import React from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  notification,
} from "antd";
import { Colorpicker } from "antd-colorpicker";

/**
 * Actions
 */
import { addCategoryObj } from "auth/actions/objActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import { Docs } from "assets/icons";
import { QuickStartDecorator, PageHeaderDecorator } from "assets/icons";
import {useSelector} from "react-redux";

const { Content } = Layout;
const { Paragraph } = Typography;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function NewFormCategory() {
  const [form] = Form.useForm();
  const history = useHistory();
  const user = useSelector((state) => state.session.user);

  const IconLink = ({ Src="", text, link }) => {
    return (
        <a
            style={{ display: "inline-flex", verticalAlign: "middle",marginTop: -3, marginRight: 16, lineHeight: 2.5, cursor:"pointer"}}
            href={link}
            target="_blank"
            rel="noreferrer"
        >
          {typeof Src === "string" ? (
              <img
                  className="example-link-icon"
                  src={Src}
                  alt={text}
                  style={{ marginRight: 8 }}
                  width={24}
              />
          ) : (
              <Src className="example-link-icon" alt={text} style={{ marginRight: 8 }} width={24} />
          )}

          <label style={{cursor:"pointer"}}>{text}</label>
        </a>
    );
  };

  const routes = [
    {
      breadcrumbName: "categorias",
      href: "/formularios/categoria/todas",
    },
    {
      breadcrumbName: "nova",
    },
  ];
  const content = (
    <>
      <Paragraph style={{paddingBottom: "2rem"}}>
        Crie categorias para agrupar formulários e personalizar a visualização
        para seus usuários.
      </Paragraph>
      <div>
        <IconLink
          Src={QuickStartDecorator}
          text="Como começar"
          link="https://cantoporto.gitbook.io/sac/guides/criando-seu-primeiro-formulario"
        />
        <IconLink
          Src={Docs}
          text="Documentação"
          link={`${process.env.REACT_APP_API_PROD}/docs`}
        />
      </div>
    </>
  );
  const ContentAlternative = ({ children, extraContent }) => (
    <Row>
      <div style={{ flex: 1 }}>{children}</div>
      <div className="image">{extraContent}</div>
    </Row>
  );

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Adiciona nova categoria
   * @param {*} values
   */
  const onFinish = (values) => {
    addCategoryObj({
      categoryName: values.name,
      categoryColor: values.color,
      createdBy: user.uuid
    })
      .then(async (response) => {
        if (response.success) {
          notification["success"]({
            message: "Sucesso",
            description: response.message
              ? response.message
              : "Algo está errado, tente novamente!",
          });
          await sleep(1000);
          history.push("/formularios/categoria/todas");
        } else {
          notification["error"]({
            message: "Erro",
            description: response.message
              ? response.message
              : "Algo está errado, tente novamente!",
          });
        }
      })
      .catch((err) =>
        notification["error"]({
          message: "Erro",
          description: err.message
            ? err.message
            : "Algo está errado, tente novamente!",
        })
      );
  };

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
            <PageHeader
              className="site-page-header"
              style={{ margin: "16px 0 24px 0", background: "#fff" }}
              title="Nova categoria"
              subTitle="Crie categorias para seus formulários."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            >
              <ContentAlternative extraContent={<PageHeaderDecorator alt="content" width="100%"/>}>
                {content}
              </ContentAlternative>
            </PageHeader>
            <Content
              className="site-layout-background"
              style={{
                padding: 46,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="newCategory"
                    initialValues={{
                      color: {
                        hsl: {
                          h: 198.67219917012446,
                          s: 0.9757085020242916,
                          l: 0.4843137254901961,
                          a: 1,
                        },
                        hex: "#03a9f4",
                        rgb: {
                          r: 3,
                          g: 169,
                          b: 244,
                          a: 1,
                        },
                        hsv: {
                          h: 198.67219917012446,
                          s: 0.9877049180327869,
                          v: 0.9568627450980393,
                          a: 1,
                        },
                        oldHue: 122.42424242424241,
                        source: "hex",
                      },
                    }}
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="name"
                      label="Nome"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor insira um nome para este formulário!",
                          whitespace: true,
                        },
                      ]}
                      normalize={(value) =>
                        value
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (y) => y.charAt(0).toUpperCase() + y.substring(1)
                          )
                          .join(" ")
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="color" label="Cor">
                      <Colorpicker
                        picker={"SwatchesPicker"}
                        blockStyles={{
                          width: "30px",
                          height: "30px",
                        }}
                        popup
                      />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                      <Button type="primary" htmlType="submit">
                        Adicionar categoria
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}
