import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Button,
  Card,
  Tabs,
  Spin, Tooltip,
} from "antd";
import {SyncOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { MyReports } from "./MyReports";
import { SharedReports } from "./SharedReports";
import { reportAPI } from "../../../services/actions/reports-v2";
import NewReport from "../../Reports/All/NewReport";
import _ from "lodash";
import moment from "moment";

function Reports({ user, report }) {
  const history = useHistory();
  const routes = [
    {
      breadcrumbName: "Relatórios",
      href: "/relatorios",
    },
    {
      breadcrumbName: "todos",
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [myReports, setMyReports] = useState([]);
  const [sharedReports, setSharedReports] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      search: false,
      sorter: true,
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (dom, record, index, action, schema) => {
        const text = record[schema.dataIndex];
        if (text) {
          return (
              <Button
                  type="link"
                  style={{
                    color: "#12b398",
                  }}
                  onClick={() => history.push(`/relatorios/editar/${record.id}`)}
              >
                {text}
              </Button>
          );
        }

      },
      width: "auto",
      ellipsis: true,
      valueType: "text",
      fieldProps: {
        placeholder: "",
      },
    },
    {
      sorter: true,
      title: "Descriçao",
      dataIndex: "description",
      key: "description",
      valueType: "text",
      fieldProps: {
        placeholder: "",
      },
      render: (dom, record, index, action, schema) => {
        const text = record[schema.dataIndex];
        if (text) {
          return (<Tooltip placement="bottom" title={text}>
            <Button>{text.substring(0, 35) + "..."}</Button>
          </Tooltip>)
        }

      },
      width: "auto",
      ellipsis: true,
      search: false
    },
    {
      search: false,
      sorter: true,
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (dom, record, index, action, schema) => {
          return (
              <Button
                  type="primary"
                  onClick={() => history.push(`/relatorios/visualizar/${record.id}`)}
              >
                Visualizar
              </Button>
          );

      },
      width: "auto",
      ellipsis: true,
    },
    {
      sorter: true,
      title: "Criado em",
      dataIndex: "created_at",
      key: "created_at",
      render: (dom, entity, index, action, schema) => {
        if (action) {
          return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
        }
      },
      width: "auto",
      ellipsis: true,
      search: false
    },
    {
      sorter: true,
      title: "Atualizado em",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (dom, entity, index, action, schema) => {
        if (action) {
          return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
        }
      },
      width: "auto",
      ellipsis: true,
      search: false
    }

  ];

  const getReports = async () => {
    setLoading(true); // Inicia o loading
    const responses = await Promise.all([
      reportAPI.getSharedReports(),
      reportAPI.getMyReports(),
    ]);
    const existError = responses.filter(({ status }) => status !== 200);
    if (existError.length === 0 && report && report.user) {
      let [shared, mine] = responses.map(({ data }) => data);
      const all = _.uniqBy([...shared, ...mine],'id');
      setMyReports(all.filter(({collection_id}) => collection_id === report.user.personal_collection_id));
      setSharedReports(all.filter(({collection_id}) => collection_id !== report.user.personal_collection_id));
    }
    setLoading(false); // Finaliza o loading
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
      <Layout>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
            <PageHeader
                style={{ margin: "16px 0 5px 0" }}
                title="Lista de Relatórios"
                onBack={() => window.history.back()}
                breadcrumb={
                  <Breadcrumb>
                    {routes.map((a) =>
                        a.hasOwnProperty("href") ? (
                            <Breadcrumb.Item key={Math.random()}>
                              <a href onClick={() => history.push(a.href)}>
                                {a.breadcrumbName}
                              </a>
                            </Breadcrumb.Item>
                        ) : (
                            <Breadcrumb.Item key={Math.random()}>{a.breadcrumbName}</Breadcrumb.Item>
                        )
                    )}
                  </Breadcrumb>
                }
                extra={<Button loading={loading} icon={<SyncOutlined />} onClick={getReports}>Atualizar Listagem</Button>}
            />
            <Card>
              {loading ? ( // Exibe o carregamento se true
                  <Spin size="large" />
              ) : (
                  <Tabs defaultActiveKey={"0"}>
                    <Tabs.TabPane tab="Meus Relatórios" key="0">
                      <MyReports columns={columns} report={report} data={myReports} setIsModalVisible={setIsModalVisible} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Relatórios Compartilhados" key="1">
                      <SharedReports columns={columns} report={report} data={sharedReports} setIsModalVisible={setIsModalVisible} />
                    </Tabs.TabPane>
                  </Tabs>
              )}
            </Card>
            <NewReport isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} getReports={getReports}/>
          </Layout>
        </Layout>
      </Layout>
  );
}

const mapStateToProps = ({ session,report }) => ({
  user: session.user,
  report: report,
});

export default connect(mapStateToProps)(Reports);
