import React from 'react';
import { Modal, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const    ImportRecordsByCSV = ({ importRecords, closeImport, disableImport, resetUploader, handleImportFileUpload }) => {
  return (
    <Modal
      title="Importar registros"
      visible={importRecords}
      onCancel={closeImport}
      footer={null}
    >
      <Upload.Dragger
        key={resetUploader ? Date.now() : null}
        accept=".csv"
        multiple={false}
        disabled={disableImport}
        beforeUpload={() => false}
        onChange={(info) => {
          const { file } = info.fileList[0];
          if (info.fileList.length > 0) {
            handleImportFileUpload(info.fileList[0].originFileObj);
          } else {
            const fileName = file ? file.name : "Unknown File";
            message.error(`Algo aconteceu de errado ao tentar enviar seu arquivo, tente novamente!`);
          }
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Clique ou arraste o arquivo para esta área para fazer o envio.
        </p>
      </Upload.Dragger>
    </Modal>
  );
};

export default ImportRecordsByCSV;
