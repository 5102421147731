import moment from "moment";
import "moment/locale/pt-br";

export default function checkValueIsDate(dataString, pattern) {
  // Utiliza o formato 'DD/MM/YYYY' ao analisar a string
  const data = moment(dataString, pattern, true);

  // Verifica se o momento é válido e se a string coincide com o formato DD/MM/YYYY
  return data.isValid() && data.format(pattern) === dataString;
}
