import styled from "styled-components";

export const CompanyLogoContainer = styled.div`
  display: "flex";
	width: "100%";
	justify-content: "flex-start";
	background-color: "transparent";
`;

export const CompanyLogoImage = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 50px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  margin: auto;
`;

export const AvatarHolder = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

export const AvatarHolderImage = styled.img`
  width: 104px;
  height: 104px;
  margin-bottom: 20px;
`;

export const AvatarHolderTitle = styled.div`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export const AvatarHolderSubTitle = styled.div``;

export const Workspaces = styled.div`
  font-weight: 500;
`;

export const WorkspacesTitle = styled.div`
  margin-bottom: 12px;
  font-weight: 500;
`;
