import _ from "lodash";

/**
 * Remove propriedades indefinidas de um objeto e filtra arrays removendo elementos nulos.
 * @param {Object} object - O objeto a ser processado.
 * @returns {Object} - O objeto resultante sem propriedades indefinidas ou arrays com elementos nulos.
 */
export default function removeUndefined(object) {
  const result = {};
  Object.entries(object).forEach(([key, value]) => {
    // Verifica se o array possui strng [""] -> formato do campo de seleção
    // Estudar alterar o formato do campo de seleção para que se adeque aos demais campos
    const hasString = _.some(value, (item) => typeof item === "string");
    if (Array.isArray(value) && !hasString) {
      const filteredArray = value.filter(Boolean);
      if (filteredArray.length > 0) {
        result[key] = filteredArray;
      }
    } else if (value !== undefined) {
      result[key] = value;
    }
  });
  return result;
}
