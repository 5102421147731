import React, {useState, useEffect} from 'react';
import {Select} from 'antd';
import {allFormsOfSavedObjectsPagination} from '../../../../../auth/actions/objActions';
import _ from "lodash";

export default function BaseRowAction ({trigger,onChange}) {

    const [value, setValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
    });

    const fetchOptions = async (searchValue, currentPage) => {
        setLoading(true);
        const response = await allFormsOfSavedObjectsPagination({
            formName: searchValue,
            page: currentPage,
            perPage: pagination.pageSize,
        });

        const transformedData = transformData(response.message);
        setData(prevData =>
            currentPage === 1 ? transformedData : [...prevData, ...transformedData]
        );

        setPagination(prev => ({
            ...prev,
            currentPage: response.pagination.currentPage,
            totalPages: response.pagination.totalPages,
        }));

        setLoading(false);
    };

    useEffect(() => {
        fetchOptions(searchTerm, 1);
    }, []);

    useEffect(() => {
        if(trigger.config && trigger.config.form){
            setValue({
                label: trigger.config.form.name,
                value: trigger.config.form.id,
            })
        }else{
            setValue(null)
        }
    }, [trigger]);

    const handleSearchTermChange = value => {
        setSearchTerm(value);
        setPagination({...pagination, currentPage: 1});
        fetchOptions(value, 1);
    };

    const handleScroll = event => {
        const {target} = event;
        if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 100 && !loading && pagination.currentPage < pagination.totalPages) {
            const nextPage = pagination.currentPage + 1;
            setPagination(prev => ({...prev, currentPage: nextPage}));
            fetchOptions(searchTerm, nextPage);
        }
    };

    const transformData = function (data) {
        return data.map(item => ({
            label: item.name,
            value: item.id,
        }));
    };

    const handleSelectChange = (value, option) => {
        const item = data.find(item => item.value === value);
        setSelectedOption(item);
        if(item){
            onChange({
                form:{
                    name: item.label,
                    id: item.value,
                }
            })
        }
    };

    const options = [
        {
            value: '',
            label: 'Pesquise um formulário',
            disabled: true,
        },
        ..._.uniqBy(data,'value'),
    ];

    return (
        <div style={{paddingTop: '10px'}}>
            <p>Selecione o formulário</p>
            <Select
                loading={loading}
                style={{width: '100%'}}
                showSearch
                onSearch={handleSearchTermChange}
                onChange={handleSelectChange}
                onPopupScroll={handleScroll}
                options={options}
                filterOption={false}
                value={value}
            >
            </Select>
        </div>
    );
}
