import * as React from "react";
const SvgDocs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M12 24C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zm0-1c6.1 0 11-4.9 11-11S18.1 1 12 1 1 5.9 1 12s4.9 11 11 11z"
      style={{
        fill: "#12b398",
      }}
    />
    <path
      d="M14.2 5H8.1C7.5 5 7 5.5 7 6.1v11.6c0 .6.5 1.1 1.1 1.1h8.1c.6 0 1.1-.5 1.1-1.1V8.5L14.2 5zm-.3 4.4h2.4v8.2H8V6.1H12.9v2.4c0 .5.4.9 1 .9zm.1-1V6.2l1.9 2.1H14v.1zm-4.8 1.7c-.3 0-.5.2-.5.5s.2.5.5.5h3.4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H9.2zm-.5 3c0 .3.2.5.5.5h5.9c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H9.2c-.3 0-.5.3-.5.5zm3.9 2.1H9.2c-.3 0-.5.2-.5.5s.2.5.5.5h3.4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#12b398",
      }}
    />
  </svg>
);
export default SvgDocs;
