import React, {useEffect, useState} from "react";
import {allFormsOfSavedObjectsPagination, getMetadataOfObj} from "../../../../../../auth/actions/objActions";
import {Button, Select,Input} from "antd";
import IconTriggerModal from "../../Snippets/IconTriggerModal";
import {getAutomations} from "../../../../../../auth/actions/automationsActions";

export const SelectAutomation = ({value,onChange,onBlur, withFields = false,getFirstOptionsFields = null}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
    });

    const fetchOptions = async (searchValue, currentPage) => {
        setLoading(true);
        const params = {
            active:1,
            page: currentPage,
            perPage: pagination.pageSize
        };

        if (searchValue) {
            params.name = searchValue;
        }

        const response = await getAutomations(params);

        const transformedData = transformData(response.data);
        setData(prevData =>
            currentPage === 1 ? transformedData : [...prevData, ...transformedData]
        );

        setPagination(prev => ({
            ...prev,
            currentPage: response.page,
            totalPages: response.total,
        }));

        setLoading(false);
    };

    useEffect(() => {
        if(!(value && selectedOption && (value.name === selectedOption.label && value.id === selectedOption.value))) {
            fetchOptions(searchTerm, 1);
        }
    }, [value]);

    const handleSearchTermChange = value => {
        setSearchTerm(value);
        setPagination({...pagination, currentPage: 1});
        fetchOptions(value, 1);
    };

    const handleScroll = event => {
        const {target} = event;
        if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 100 && !loading && pagination.currentPage < pagination.totalPages) {
            const nextPage = pagination.currentPage + 1;
            setPagination(prev => ({...prev, currentPage: nextPage}));
            fetchOptions(searchTerm, nextPage);
        }
    };

    const transformData = data => {
        return data.map(item => ({
            label: item.name,
            value: item.id,
        }));
    };

    const handleSelectChange = async (value, option) => {
        const item = data.find(item => item.value === value);
        setSelectedOption(item);
        if (item) {
            onChange({
                name: item.label,
                id: item.value,
            })
        }
    };

    const options = [
        {
            value: '',
            label: 'Pesquise um formulário',
            disabled: true,
        },
        ...data,
    ];

    const payload =  value ? {
        label: value.name,
        value: value.id,
    } : {};
    return (
        <Input.Group compact style={{display: 'flex', alignItems:"center"}}>
            <Select
                onBlur={ (e) => (onBlur) ? onBlur(e): null }
                loading={loading}
                style={{width: '100%'}}
                showSearch
                onSearch={handleSearchTermChange}
                onChange={handleSelectChange}
                value={payload}
                onPopupScroll={handleScroll}
                options={options}
                filterOption={false}
                searchValue={searchTerm}
            >
            </Select>
        </Input.Group>

    );
}
