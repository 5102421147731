import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PinInput } from "react-input-pin-code";
import Loader from "react-loader-spinner";
import Popup from "reactjs-popup";
import { FiMail } from "react-icons/fi";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import { envmailesqueci, validamailesqueci } from "auth/actions/userActions";

/**
 * Components
 */
import colors from "components/Colors";
import {
  StyledContainer,
  StyledFormArea,
  StyledFormButton,
  Avatar,
  StyledTitle,
  ButtonGroup,
  ExtraText,
  TextLink,
  CopyrightText,
} from "components/Styles";
import { TextInput } from "components/Forms/SignIn/FormLib";
import Logo from "assets/images/logo.png";

const ForgotPass = ({ envmailesqueci, validamailesqueci }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [Token, setToken] = useState(["", "", "", "", "", ""]);
  const history = useHistory();
  return (
    <StyledContainer>
      <div>
        <StyledFormArea>
          <Avatar image={Logo} />
          <StyledTitle color={colors.theme} size={30}>
            Esqueceu sua senha?
          </StyledTitle>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Email inválido")
                .required("O campo email não pode ser vazio"),
            })}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              // console.log(values)
              if (showPopup === false) {
                envmailesqueci(
                  values,
                  setFieldError,
                  setSubmitting,
                  setShowPopup
                );
              } else {
                validamailesqueci(
                  values,
                  setFieldError,
                  setSubmitting,
                  Token,
                  setShowPopup,
                  history
                );
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextInput
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="eu@exemplo.com.br"
                  icon={<FiMail />}
                />
                <ButtonGroup>
                  {!isSubmitting && (
                    <StyledFormButton type="submit">
                      Redefinir senha
                    </StyledFormButton>
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={colors.theme}
                      height={50}
                      width={100}
                    />
                  )}
                </ButtonGroup>
                <Popup
                  open={showPopup}
                  onClose={() => setShowPopup(false)}
                  modal
                >
                  {(close) => (
                    <div className="modal">
                      <div className="closearea">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <div className="header">
                          <h1>Codigo email</h1>
                          <hr />
                        </div>
                        <div className="Texto">
                          <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Sequi ullam ex harum omnis sunt earum corporis
                            ad repellendus distinctio assumenda quae atque
                            laborum aut aperiam nulla adipisci, sed error
                            pariatur.Lorem Lorem ipsum dolor sit amet
                            consectetur adipisicing elit. Corrupti error qui
                            iusto in, amet voluptatem totam neque quod? Id
                            distinctio numquam ipsam quisquam quasi omnis.
                            Perferendis inventore consectetur quo aperiam.
                          </p>
                        </div>
                        <Form>
                          <PinInput
                            initialValue=""
                            values={Token}
                            length={6}
                            secret
                            onChange={(value, index, values) =>
                              setToken(values)
                            }
                          />
                          <ButtonGroup>
                            {!isSubmitting && (
                              <StyledFormButton type="submit">
                                Validar
                              </StyledFormButton>
                            )}
                            {isSubmitting && (
                              <Loader
                                type="ThreeDots"
                                color={colors.theme}
                                height={49}
                                width={100}
                              />
                            )}
                          </ButtonGroup>
                        </Form>
                      </div>
                    </div>
                  )}
                </Popup>
              </Form>
            )}
          </Formik>
          <ExtraText>
            Lembrou a senha?{" "}
            <TextLink to="/iniciar-sessao">Iniciar sessão</TextLink>
          </ExtraText>
        </StyledFormArea>
        <CopyrightText>
          Todos os direitos reservados &copy; {new Date().getFullYear()}
        </CopyrightText>
      </div>
    </StyledContainer>
  );
};

export default connect(null, { envmailesqueci, validamailesqueci })(ForgotPass);
