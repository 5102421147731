import {ThunderboltOutlined} from "@ant-design/icons";
import React from "react";
import {openOrCloseModalSnippets} from "../../../../../ducks/automation";
import {connect} from "react-redux";
import {Button} from "antd";

const IconTriggerModal = ({fieldName, fieldLabel, onOpenOrCloseModalSnippets, withButton = false, isCode = false, acceptEntryLoop = true}) => {

    const icon = (<ThunderboltOutlined style={{cursor: "pointer"}} onClick={() => onOpenOrCloseModalSnippets({
        action: true,
        fieldName,
        fieldLabel,
        isCode,
        acceptEntryLoop
    })}/>);
    if (withButton) {
        return (
            <Button onClick={() => onOpenOrCloseModalSnippets({action: true, fieldName, fieldLabel,isCode,acceptEntryLoop})}>
                {
                    icon
                }
            </Button>
        )
    }
    return (icon)
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenOrCloseModalSnippets: ({action, fieldName, fieldLabel,isCode,acceptEntryLoop}) => dispatch(openOrCloseModalSnippets({
            action,
            fieldName,
            fieldLabel,
            isCode,
            acceptEntryLoop
        })),
    };
};
const mapStateToProps = ({}) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(IconTriggerModal);
