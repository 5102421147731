import getApiUrl from "../../services/getApiUrl";
import axios from "axios";

/**
 * Pesquisa por automações
 * @returns
 * @param data
 */
export const getAutomations = async (data = {}) => {
    return await axios.get(`${getApiUrl()}/automations`, {
        params:data
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

export const createAutomation = async (data = {}) => {
    return await axios.post(`${getApiUrl()}/automations`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

export const getAutomation = async (id) => {
    return await axios.get(`${getApiUrl()}/automations/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};
