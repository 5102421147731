import {notificationAPI} from "../services/actions/notifications";
// Types
const READ_NOTIFICATION = 'core/notification/READ_NOTIFICATION';
const UNREAD_NOTIFICATION = 'core/notification/UNREAD_NOTIFICATION';
const DELETE_NOTIFICATION = 'core/notification/DELETE_NOTIFICATION';
const UPDATE_NOTIFICATIONS = 'core/notification/UPDATE_NOTIFICATIONS';
const GET_NOTIFICATIONS = 'core/notification/GET_NOTIFICATIONS';
const NEXT_PAGE_NOTIFICATIONS = 'core/notification/NEXT_PAGE_NOTIFICATIONS';
const READ_ALL_NOTIFICATION = 'core/notification/READ_ALL_NOTIFICATION';
const ERROR_NOTIFICATION = 'core/notification/ERROR_NOTIFICATION';


// Reducer
const initialState = {
    page: 1,
    totalPages: 1,
    notifications: [],
    unreadCount: 0
};

export default function notificationCenterReducer(state = initialState, action) {
    switch (action.type) {
        case READ_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.id === action.payload.id
                        ? {...notification, read: true}
                        : notification
                )
            };

        case UNREAD_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.id === action.payload.id
                        ? {...notification, read: false}
                        : notification
                )
            };

        case DELETE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.id !== action.payload.id
                )
            };
        case UPDATE_NOTIFICATIONS:
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.notifications,
                page: action.payload.page || state.page,
                unreadCount: action.payload.unreadCount || state.unreadCount,
                totalPages: action.payload.totalPages || state.totalPages,
            };

        case NEXT_PAGE_NOTIFICATIONS:
            return {
                ...state,
                notifications: [...state.notifications,...action.payload.notifications,],
                page: action.payload.page || state.page,
                unreadCount: action.payload.unreadCount || state.unreadCount,
                totalPages: action.payload.totalPages || state.totalPages,
            };
        case READ_ALL_NOTIFICATION: {
            return {
                ...state,
                notifications: state.notifications.map(notification => ({...notification, read: true})),
                unreadCount: 0
            };
        }
        default:
            return state;
    }
}

export const readNotificationById = (id) => async (dispatch) => {
    try {
        const {status, data} = await notificationAPI.markAsRead(id);
        if (status === 200 && data && data.success) {
            dispatch({type: READ_NOTIFICATION, payload: {id}});
        } else {
            dispatch({type: ERROR_NOTIFICATION, payload: data.message});
        }
    } catch (error) {
        dispatch({type: ERROR_NOTIFICATION, payload: error.message});
    }
};

export const readAllNotificationByUserId = (user_id) => async (dispatch) => {
    try {
        const {status, data} = await notificationAPI.markAllAsRead(user_id);
        if (status === 200 && data && data.success) {
            dispatch({type: READ_ALL_NOTIFICATION});
        } else {
            dispatch({type: ERROR_NOTIFICATION, payload: data.message});
        }
    } catch (error) {
        dispatch({type: ERROR_NOTIFICATION, payload: error.message});
    }
};

export const unreadNotificationById = (id) => async (dispatch) => {
    try {
        const {status, data} = await notificationAPI.markAsUnread(id);
        if (status === 200 && data && data.success) {
            dispatch({type: UNREAD_NOTIFICATION, payload: {id}});
        } else {
            dispatch({type: ERROR_NOTIFICATION, payload: data.message});
        }
    } catch (error) {
        dispatch({type: ERROR_NOTIFICATION, payload: error.message});
    }
};

export const deleteNotificationById = (id) => async (dispatch) => {
    try {
        const {status, data} = await notificationAPI.deleteOne(id);
        if (status === 200 && data && data.success) {
            dispatch({type: DELETE_NOTIFICATION, payload: {id}});
        } else {
            dispatch({type: ERROR_NOTIFICATION, payload: data.message});
        }
    } catch (error) {
        dispatch({type: ERROR_NOTIFICATION, payload: error.message});
    }
};

export const getAllNotifications = (params) => async (dispatch) => {
    try {
        const {status, data} = await notificationAPI.getAll({...params});
        if (status === 200 && data && data.success) {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: {
                    notifications: data.data.result,
                    page: data.data.pagination.currentPage,
                    unreadCount: data.data.unreadCount,
                    totalPages: data.data.pagination.totalPages
                }
            });
        } else {
            dispatch({type: ERROR_NOTIFICATION, payload: data.message});
        }
    } catch (error) {
        dispatch({type: ERROR_NOTIFICATION, payload: error.message});
    }
};

export const nextPageNotifications = (params) => async (dispatch) => {
    try {
        const {status, data} = await notificationAPI.getAll({...params});
        if (status === 200 && data && data.success) {
            dispatch({
                type: NEXT_PAGE_NOTIFICATIONS,
                payload: {
                    notifications: data.data.result,
                    page: data.data.pagination.currentPage,
                    unreadCount: data.data.unreadCount,
                    totalPages: data.data.pagination.totalPages
                }
            });
        } else {
            dispatch({type: ERROR_NOTIFICATION, payload: data.message});
        }
    } catch (error) {
        dispatch({type: ERROR_NOTIFICATION, payload: error.message});
    }
};
