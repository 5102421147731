import * as React from "react";
const SvgLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="logo_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 60 27.24"
    {...props}
  >
    <defs>
      <style>
        {".logo_svg__cls-1{fill:#fff}.logo_svg__cls-2{fill:#17e5c2}"}
      </style>
    </defs>
    <path
      d="m16.75 10.75-.35 1.6h-.2c-.6-1.28-2.8-1.89-4.24-1.89-6.28 0-7.11 5.81-7.11 7.37 0 4 2.81 5 4.56 5a8 8 0 0 0 4.88-1.44h.22l-.41 1.74c-.45 1.88-2.52 3.8-6.29 3.8-1.21 0-7.81-.87-7.81-8.75C0 15.93 1.88 6.44 11.87 6.44c1.88 0 4.91 1.09 4.91 3.58a2.08 2.08 0 0 1-.03.73ZM59.71 15.54l-2.33 11h-1.56c-1.78 0-2.52-.74-2.52-2a5.66 5.66 0 0 1 .1-1.06l1.6-7.37a12.88 12.88 0 0 0 .32-2.84 2.51 2.51 0 0 0-2.74-2.84c-3.23 0-4.21 2.55-4.66 4.79l-2.43 11.32H44c-1.54 0-2.49-.48-2.49-2a4.64 4.64 0 0 1 .16-1.11l1.75-8.2a9.42 9.42 0 0 0 .22-1.79c0-2.29-1.37-3-2.8-3-3 0-4.12 2.33-4.66 4.85l-2.4 11.25h-1.53c-1.69 0-2.55-.7-2.55-2.14a5.7 5.7 0 0 1 .09-.95l1.82-8.52c1-4.72 4.15-8.55 9.57-8.55 2.71 0 5 1.47 5.55 3.8a7.87 7.87 0 0 1 6.57-3.8c2.52 0 6.7.74 6.7 6.54a13.69 13.69 0 0 1-.29 2.62Z"
      className="logo_svg__cls-1"
    />
    <path
      d="M25.93 22.78c-1.62 0-3.06-.26-3.06-2.05a4.88 4.88 0 0 1 .1-.95l3.47-16.27a5.52 5.52 0 0 0 .16-1.21A2.07 2.07 0 0 0 24.4 0h-7.33a2.59 2.59 0 0 0-2.72 2.27L14 3.8h7.75l-3.47 16.39a7.67 7.67 0 0 0-.19 2c0 3 2.1 4.53 4.81 4.53 2 0 3.58-.6 4-2.58l.25-1.34h-1.22ZM50.3 1.63a2.66 2.66 0 0 1-2.39 2.2h-7.59l.35-1.53A2.57 2.57 0 0 1 43.38.03h7.34Z"
      className="logo_svg__cls-2"
    />
    <path
      d="M22 27.24c-3.07 0-5-1.78-5-4.69a8.37 8.37 0 0 1 .19-2l3.38-16.21h-7.75l.42-1.72a2.71 2.71 0 0 1 2.84-2.4h7.52a2.21 2.21 0 0 1 2.17 2.42 5 5 0 0 1-.16 1.29l-3.47 16.26a4.4 4.4 0 0 0-.1.93c0 1.56 1.15 1.88 2.9 1.88h1.38L26 24.53c-.32 1.82-1.66 2.71-4 2.71ZM21 3.99v.22l-3.48 16.4a7.62 7.62 0 0 0-.19 2c-.07 2.65 1.67 4.32 4.58 4.32 2.24 0 3.48-.79 3.8-2.45l.22-1.15h-1c-1.65 0-3.22-.26-3.22-2.2a3.5 3.5 0 0 1 .13-1l3.49-16.2a5 5 0 0 0 .12-1.18 1.92 1.92 0 0 0-2-2.14h-7.37a2.45 2.45 0 0 0-2.56 2.14l-.31 1.34h7.52ZM47 4.72h-7.8L39.62 3A2.7 2.7 0 0 1 42.46.61H50l-.46 1.82A2.85 2.85 0 0 1 47 4.72Zm-7.4-.32H47a2.56 2.56 0 0 0 2.25-2.07l.35-1.4h-7.11a2.43 2.43 0 0 0-2.55 2.13Z"
      className="logo_svg__cls-1"
    />
  </svg>
);
export default SvgLogo;
