import React from "react";
import _ from "lodash";

import { Form, Button, Space, Input } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

// @ATT: this was created to make nested dynamic elements!
const CheckBoxForm = (props) => {
  if (props.hasOwnProperty("fieldKey")) {
    return (
      <>
        <Form.List
          name={[props.fieldKey, "checkBox"]}
          initialValue={_.values(props.data).map((value) => ({
            currentValue: value,
          }))}
        >
          {(checkBox, { add, remove }) => {
            return (
              <div>
                {checkBox.map((currentValue, index2) => (
                  <Space
                    key={currentValue.key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="start"
                  >
                    <Form.Item
                      {...currentValue}
                      name={[currentValue.name, "currentValue"]}
                      fieldKey={[currentValue.fieldKey, "currentValue"]}
                      key={index2}
                      // noStyle
                      rules={[
                        {
                          required: true,
                          message: "Este campo é obrigatório",
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.includes(",")) {
                              return Promise.reject(
                                "O texto não pode conter vírgulas!"
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                        {
                              validator: (_, value) => {
                                  if (
                                      value &&
                                      value.includes(";")
                                  ) {
                                      return Promise.reject(
                                          "O texto não pode conter pontos e vírgulas!"
                                      );
                                  }
                                  return Promise.resolve();
                              },
                          },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{
                        paddingTop: 8,
                      }}
                      onClick={() => {
                        remove(currentValue.name);
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Adicionar item
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </>
    );
  } else {
    return <></>;
  }
};

export default CheckBoxForm;
