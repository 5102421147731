import axios from "axios";
import { InputNumber, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import uuid from "react-uuid";
import moment from "moment";
import "moment/locale/pt-br";

// Services
import getApiUrl from "services/getApiUrl";
import getFieldValue from "./getFieldValue";
import { getFieldsDataByLookup } from "auth/actions/objActions";

// Actions
import {
  addFormData,
  addRefFieldsFormData,
  deleteFormData,
} from "ducks/dataOfForm";

// Utils
import checkValueIsDate from "./checkValueIsDate";
import IconTriggerModal from "../../pages/Automations/Edit/Flow/Snippets/IconTriggerModal";
import React from "react";
import QueryBuilder from "../../pages/Automations/Edit/Flow/Actions/utils/QueryBuilder";
import {DateInput} from "../../pages/Automations/Edit/Flow/Actions/utils/DateInput";
import {SelectorInput} from "../../pages/Automations/Edit/Flow/Actions/utils/SelectInput";
import {RefInput} from "../../pages/Automations/Edit/Flow/Actions/utils/RefInput";

// Função para obter as opções para campos referenciados
export const fetchRefData = async ({ refFields, keyWords, page, pageSize }) => {
  const records = await axios.post(
    `${getApiUrl()}/objetos/formularios/campos/dados/referenciados`,
    {
      fields: refFields,
      keyword: keyWords,
      page,
      pageSize,
    }
  );
  const { message: recordsData } = records.data;
  const options = [];

  const labelCount = new Map(); // Mapa para contar rótulos repetidos

  if (recordsData) {
    recordsData.forEach((record) => {
      for (const key in record) {
        if (Object.hasOwnProperty.call(record, key)) {
          const element = record[key];
          if (key !== "_id" && key !== "createdAt") {
            let  valueElement = getFieldValue(element)

            if(!_.isNumber(valueElement) && (_.isEmpty(valueElement) || _.isNull(valueElement))){
              continue;
            }

            if(checkValueIsDate(valueElement,'YYYY-MM-DDTHH:mm:ss[Z]')){
               valueElement =  moment(valueElement,"YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY")
            }

            const label = `${valueElement} (${
                moment(record.createdAt[0].value,"YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm")
            })`;
            const value = `${record._id}`;

            // Verificar se o rótulo já existe no mapa de contagem
            if (labelCount.has(label)) {
              const count = labelCount.get(label);
              labelCount.set(label, count + 1);
            } else {
              labelCount.set(label, 1);
            }

            // Adicionar o contador ao rótulo se necessário
            const finalLabel =
              labelCount.get(label) > 1
                ? `${label} (${labelCount.get(label)})`
                : label;
            // Adiciona nova opção
            options.push({
              label: finalLabel,
              value: value,
              field: key
            });
          }
        }
      }
    });
  }
  return options;
};

// Função para obter opções a partir de pesquisas em campos referenciados
const getRefData = async (params) => {
  const {
    fieldName,
    allFields,
    refFields,
    keyWords,
    page,
    pageSize,
    action,
    fromEdit,
  } = params;
  const query = {
    refFields,
    keyWords,
    page,
    pageSize,
  };

  // Tenta adicionar nome e tipo do campo ao campos
  refFields.forEach((obj) => {
    const fieldToFind = obj.field;
    // Obtém nome do campo referenciado
    const matchingField = allFields.find((item1) => item1._id === fieldToFind);
    if (matchingField) {
      obj.fieldName = matchingField.field;
      obj.fieldType = matchingField.fieldtype;
    }
  });

  // Tenta obter os dados a partir da pesquisa na caixa de seleção
  const options = await fetchRefData(query);
  const optionsFromRef = [];

  if (action === "edit") {
    if (fromEdit && fromEdit[fieldName] && fromEdit[fieldName].length > 0) {
      optionsFromRef.push({
        _id: fromEdit[fieldName].map((obj) => obj._id).join(","),
        value: fromEdit[fieldName].map((obj) => obj.value).join(", "),
      });
    }
  }

  // Organiza os dados para retornar para campo de seleção
  if (Array.isArray(options) && options.length > 0) {
    // Remove opções vazias " (DD/MM/YYYY HH:mm:ss)"
    const nonEmptyCreatedAtOpts = options.filter(
      (item) => !item.label.startsWith(" (")
    );
    if (optionsFromRef && optionsFromRef.length > 0) {
      return _.concat(
        optionsFromRef.map((opt) => ({
          label: opt.value,
          value: opt._id,
        })),
        nonEmptyCreatedAtOpts.map((opt) => ({
          label: opt.label,
          value: `${opt.value}_rng@record_${uuid()}`,
        }))
      );
    } else {
      return nonEmptyCreatedAtOpts.map((opt) => ({
        label: opt.label,
        value: `${opt.value}_rng@record_${uuid()}`,
      }));
    }
  } else {
    return [];
  }
};

// Função para obter registros a partir de pesquisas em campos de lookup
export const getLookupData = async (params) => {
  const { tableRef, tableAction, selectedField, selectedOpts, dispatch } =
    params;
  if (selectedOpts) {
    // Remove rng das opções selecionadas
    // TODO: trabalhar com ${uuid},${uuid}
    const cleanedFromRng = selectedOpts.substring(
      0,
      selectedOpts.indexOf("_rng@record_")
    );

    // Tenta obter os resultados da consulta
    for (const childObj of selectedField.checkValueIn) {
      const options = await getFieldsDataByLookup({
        table: selectedField?.getValueOf[0].form.replace("obj_", ""),
        id: cleanedFromRng,
        fieldId: childObj.field,
        checkIn: [childObj],
      });

      if (options.success && options.message && options.message.length > 0) {
        const childFieldName = options.message[0].fieldName;
        const childRecordId = options.message[0].id;
        let childFieldValue = options.message[0].value;

        // Verifica se o valor retornado é do tipo data
        if (
          childFieldValue &&
          checkValueIsDate(childFieldValue, "YYYY-MM-DDTHH:mm:ss[Z]")
        ) {
          childFieldValue = moment(childFieldValue, "YYYY-MM-DDTHH:mm:ss[Z]").format(
            "MM/DD/YYYY"
          );
        }

        // Verifica se o valor retornado é do tipo numérico
        if (childFieldValue && _.isNumber(childFieldValue)) {
          childFieldValue = childFieldValue.toString();
        }

        if (tableAction === undefined && childFieldName && childFieldValue) {
          // Atualiza os campos com os valores
          tableRef.current.setFieldsValue({
            [`${childFieldName}`]: childFieldValue,
          });
        }

        dispatch(
          addFormData(childFieldName, {
            _id: childRecordId,
            value: childFieldValue,
          })
        );
      }
    }
  }
};

const createColumnTitle = (title, tooltip) => (
  <>
    {title}
    <Tooltip placement="top" title={tooltip}>
      <QuestionCircleOutlined style={{ marginLeft: 4 }} />
    </Tooltip>
  </>
);

const columnConfigurations = {
  _id: {
    title: createColumnTitle("ID", "Identificação do registro"),
    dataIndex: "_id",
    key: "_id",
    copyable: true,
    hideInSearch: true,
    hideInForm: true,
    fieldProps: {
      placeholder: "",
    },
    width: "auto", // Define a largura como automática
    ellipsis: true, // Ativa o corte automático de texto
    sorter: false,
  },
  createdAt: {
    title: createColumnTitle("Criado em", "Data do registro salvo"),
    dataIndex: "createdAt",
    key: "createdAt",
    hideInForm: true,
    valueType: "dateTime",
    renderText: (text) => {
      if (text) {
        return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]").valueOf();
      }
    },
    fieldProps(form, config) {
      return {
        ...config,
        format: "DD/MM/YYYY HH:mm",
        placeholder: "",
      };
    },
    sorter: (a, b) =>
      moment(a.createdAt, "YYYY-MM-DDTHH:mm:ss[Z]").unix() -
      moment(b.createdAt, "YYYY-MM-DDTHH:mm:ss[Z]").unix(),
    width: "auto", // Define a largura como automática
    ellipsis: true, // Ativa o corte automático de texto
  },
  updatedAt: {
    title: createColumnTitle("Atualizado em", "Data do registro atualizado"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    hideInSearch: true,
    hideInForm: true,
    valueType: "date",
    renderText: (text) => {
      if (text) {
        return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]").valueOf();
      }
    },
    fieldProps(form, config) {
      return {
        ...config,
        format: "DD/MM/YYYY HH:mm",
        placeholder: "",
      };
    },
    sorter: (a, b) => {
      const timeA =
        a.updatedAt && moment(a.updatedAt, "YYYY-MM-DDTHH:mm:ss[Z]").isValid()
          ? moment(a.updatedAt, "YYYY-MM-DDTHH:mm:ss[Z]").unix()
          : -Infinity;
      const timeB =
        b.updatedAt && moment(b.updatedAt, "YYYY-MM-DDTHH:mm:ss[Z]").isValid()
          ? moment(b.updatedAt, "YYYY-MM-DDTHH:mm:ss[Z]").unix()
          : -Infinity;
      return timeA - timeB;
    },
    width: "auto", // Define a largura como automática
    ellipsis: true, // Ativa o corte automático de texto
  },
};

const clearForm = (fields, selectedField, selectedValue, ref, dp, action) => {
  const selectedFieldIds = selectedField.checkValueIn.map(
    (field) => field.field
  );
  const clearFields = fields.filter((item) =>
    selectedFieldIds.includes(item._id)
  );
  clearFields.forEach((obj) => {
    // Verifica se está editando o registro
    if (action === "edit" || action === "multipleEdit") {
      // Verifica se campo está vazio
      if (selectedValue === undefined) {
        // Possibilita gerar campos vazio em mountRecords()
        ref.current.setFieldsValue({
          [selectedField.field]: "", // campo pai
        });
      }
      // Verifica se o campo é do tipo data ou tempo
      if (obj.fieldtype === "Date" || obj.fieldtype === "Time") {
        ref.current.setFieldsValue({
          [obj.field]: moment(),
        });
      } else {
        ref.current.setFieldsValue({
          [obj.field]: "",
        });
      }
    } else {
      ref.current.setFieldsValue({
        [obj.field]: null,
      });
    }
  });
  dp(deleteFormData(_.map(clearFields, "field")));
};

const refLkpOnChange = async (
  allFields,
  field,
  currentValue,
  nativeCurrentValue,
  ref,
  dp,
  action
) => {
  dp(
    addRefFieldsFormData(
      field.field,
      _.compact(
        _.map(nativeCurrentValue ? [nativeCurrentValue] : [], (obj) =>
          _.isEmpty(obj) ? null : obj
        )
      )
    )
  );
  // Limpa os campos de retorno no formulário
  clearForm(allFields, field, currentValue, ref, dp, action);
  // Obtém os dados do campo de retorno e preenche o formulário
  await getLookupData({
    tableRef: ref,
    selectedField: field,
    selectedOpts: currentValue,
    dispatch: dp,
  });
};

export default function buildColumns(columns, fields, refFields,sorter = false) {
  if (columns && _.size(columns) > 0 && fields && _.size(fields) > 0) {

    return ["_id", ...columns, "createdAt", "updatedAt"].map((x) => {
      const selectedField = fields.find((y) => y.field === x);

      if (selectedField) {
        const { _id: fieldId, fieldtype: fieldType } = selectedField;
        if (fieldType !== "refLookup") {
          const father = fields.find(
            (h) =>
              h.fieldtype === "refLookup" &&
              h.checkValueIn.find((z) => z.field === fieldId)
          );
          selectedField.isLookup = !!father;

        }

        if(selectedField.checkValueIn){
          selectedField.father = selectedField.checkValueIn.map(value => {
            const refField = refFields.find(ref => ref._id ===  value.field)
            if(refField){
              return refField;
            }else{
              return null
            }
          }).filter(item => item)
        }

      }

      if (columnConfigurations[x]) {
        const column = {...columnConfigurations[x]};
        if (sorter && x !== "_id") {
          column.sorter = sorter;
        }
        if (selectedField) {
          column.hideInSearch = selectedField.isLookup;
        }
        return column;
      }
      // Verifica qual tipo do campo e cria a coluna
      if (
        selectedField &&
        selectedField.fieldtype === "Text" &&
        !selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          hideInSearch: selectedField.isLookup,
          fieldProps: {
            placeholder: "",
          },
          renderText: (text) => {
            if (text) {
              if (_.isEmpty(selectedField.sufixo)) {
                return text;
              } else {
                return `${text} ${selectedField.sufixo}`;
              }
            }
          },
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }
      if (
        selectedField &&
        selectedField.fieldtype === "Numeric" &&
        !selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          hideInSearch: selectedField.isLookup,
          valueType: "number",
          fieldProps: {
            placeholder: "",
          },
          renderText: (text) => {
            if (text) {
              if (_.isEmpty(selectedField.sufixo)) {
                return text;
              } else {
                return `${text} ${selectedField.sufixo}`;
              }
            }
          },
          renderFormItem: () => (
            <InputNumber formatter={null} keyboard={true} />
          ),
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }
      if (
        selectedField &&
        selectedField.fieldtype === "Date" &&
        !selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "date",
          hideInSearch: selectedField.isLookup,
          renderText: (text) => {
            if (text) {
              return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]").valueOf();
            }
          },
          fieldProps(form, config) {
            return {
              ...config,
              format: "DD/MM/YYYY",
              placeholder: "",
            };
          },
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }
      if (
        selectedField &&
        selectedField.fieldtype === "Time" &&
        !selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "time",
          hideInSearch: selectedField.isLookup,
          renderText: (text) => {
            if (text) {
              return moment(text, "HH:mm").valueOf();
            }
          },
          fieldProps(form, config) {
            return {
              ...config,
              format: "HH:mm",
              placeholder: "",
            };
          },
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }
      if (
        selectedField &&
        selectedField.fieldtype === "uniqueSelection" &&
        !selectedField.isLookup
      ) {

        const options = Object.keys(selectedField.checkBox).map((key) => ({
          label: selectedField.checkBox[key],
          value: selectedField.checkBox[key],
        }));

        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "select",
          hideInSearch: selectedField.isLookup,
          renderText: (opt) => {
            if (opt) {
              const text = (_.isArray(opt) ? opt : _.isString(opt) ? opt.split(",") : []);
              if(text){
                return <span>{text}</span>
              }
              return "";
            } else {
              return "";
            }
          },
          fieldProps: {
            mode: "tags",
            placeholder: "",
            options
          },
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }
      if (
        selectedField &&
        selectedField.fieldtype === "multipleSelection" &&
        !selectedField.isLookup
      ) {
        const options = Object.keys(selectedField.checkBox).map((key) => ({
          label: selectedField.checkBox[key],
          value: selectedField.checkBox[key],
        }));

        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "select",
          renderText: (opt) => {
            if (opt) {
              return (_.isArray(opt) ? opt : _.isString(opt) ? opt.split(",") : []).join(",")
            } else {
              return "";
            }
          },
          fieldProps: {
            mode: "tags",
            placeholder: "",
            options
          },
          hideInSearch: selectedField.isLookup,
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }
      if (
        selectedField &&
        selectedField.fieldtype === "refUniqueSelection" &&
        !selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "text",
          fieldProps: {
            placeholder: "",
          },
          renderText: (text) => {
            if (text) {
              if(moment(text,'YYYY-MM-DDTHH:mm:ss[Z]',true).isValid()){
                return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]",true).format("DD/MM/YYYY")
              }else if(moment(text,"YYYY-MM-DDTHH:mm:ss[Z]",true).isValid()){
                return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]",true).format("DD/MM/YYYY")
              }
              return text;
            }
          },
          hideInSearch: selectedField.isLookup,
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }
      if (
        selectedField &&
        selectedField.fieldtype === "refMultipleSelection" &&
        !selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "text",
          fieldProps: {
            placeholder: "",
          },
          renderText: (text) => {
            if (text) {
              if(moment(text,'YYYY-MM-DDTHH:mm:ss[Z]',true).isValid()){
                return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]",true).format("DD/MM/YYYY")
              }
              return text;
            }
          },
          hideInSearch: selectedField.isLookup,
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }


      if (
        selectedField &&
        selectedField.fieldtype === "refLookup" &&
        !selectedField.isLookup &&
        selectedField.father && selectedField.father.length > 0 && selectedField.father[0].fieldtype === "multipleSelection"
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "select",
          fieldProps(form, config) {
            return {
              ...config,
              placeholder: "",
              mode:"tags"
            };
          },
          // hideInSearch: true,
          placeholder: "",
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          renderText: (text) => {
            if (text) {
              if(moment(text,"YYYY-MM-DDTHH:mm:ss[Z]",true).isValid()){
                return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]",true).format("DD/MM/YYYY")
              }
               return text;
            }
          },
          sorter,
        };
      }

      if (
          selectedField &&
          selectedField.fieldtype === "refLookup" &&
          !selectedField.isLookup &&
          selectedField.father && selectedField.father.length > 0 && selectedField.father[0].fieldtype === "Date"
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "date",
          hideInSearch: selectedField.isLookup,
          fieldProps(form, config) {
            return {
              ...config,
              format: "DD/MM/YYYY",
              placeholder: "",
            };
          },
          // hideInSearch: true,
          placeholder: "",
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          renderText: (text) => {
            if (text) {
              if(moment(text,"YYYY-MM-DDTHH:mm:ss[Z]",true).isValid()){
                return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]",true).valueOf()
              }
            }
          },
          sorter,
        };
      }

      if (
          selectedField &&
          selectedField.fieldtype === "refLookup" &&
          !selectedField.isLookup &&
          selectedField.father && selectedField.father.length > 0 && selectedField.father[0].fieldtype === "Time"
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "time",
          hideInSearch: selectedField.isLookup,
          renderText: (text) => {
            if (text) {
              return moment(text, "HH:mm").valueOf();
            }
          },
          fieldProps(form, config) {
            return {
              ...config,
              format: "HH:mm",
              placeholder: "",
            };
          },
          // hideInSearch: true,
          placeholder: "",
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }

      if (
          selectedField &&
          selectedField.fieldtype === "refLookup" &&
          !selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          fieldProps(form, config) {
            return {
              ...config,
              placeholder: "",
            };
          },
          // hideInSearch: true,
          placeholder: "",
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          renderText: (text) => {
            if (text) {
              if(moment(text,"YYYY-MM-DDTHH:mm:ss[Z]",true).isValid()){
                return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]",true).format("DD/MM/YYYY")
              }
              return text;
            }
          },
          sorter,
        };
      }

      if (
          selectedField &&
          selectedField.fieldtype === "Date" &&
          selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "date",
          // hideInSearch: selectedField.isLookup,
          renderText: (text) => {
            if (text) {
              if(moment(text,"YYYY-MM-DDTHH:mm:ss[Z]",true).isValid()){
                return moment(text, "YYYY-MM-DDTHH:mm:ss[Z]",true).valueOf()
              }
              return text;
            }
          },
          fieldProps(form, config) {
            return {
              ...config,
              format: "DD/MM/YYYY",
              placeholder: "",
            };
          },
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }


      if (
          selectedField &&
          selectedField.fieldtype === "multipleSelection" &&
          selectedField.isLookup
      ) {

        return {
          valueType: "select",
          title: x,
          dataIndex: x,
          key: x,
          fieldProps(form, config) {
            return {
              ...config,
              placeholder: "",
              mode:"tags",
              options:Object.entries(selectedField.checkBox).map(([key,value]) => ({value:value,label:value}))
            };
          },
          // hideInSearch: selectedField.isLookup,
          placeholder: "",
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }

      if (
          selectedField &&
          selectedField.fieldtype === "uniqueSelection" &&
          selectedField.isLookup
      ) {
        return {
          valueType: "select",
          title: x,
          dataIndex: x,
          key: x,
          fieldProps(form, config) {
            return {
              ...config,
              placeholder: "",
              mode:"tags",
              options:Object.entries(selectedField.checkBox).map(([key,value]) => ({value:value,label:value}))
            };
          },
          // hideInSearch: selectedField.isLookup,
          placeholder: "",
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }

      if (
          selectedField &&
          selectedField.fieldtype === "Time" &&
          selectedField.isLookup
      ) {
        return {
          title: x,
          dataIndex: x,
          key: x,
          valueType: "time",
          // hideInSearch: selectedField.isLookup,
          renderText: (text) => {
            if (text) {
              return moment(text, "HH:mm").valueOf();
            }
          },
          fieldProps(form, config) {
            return {
              ...config,
              format: "HH:mm",
              placeholder: "",
            };
          },
          width: "auto", // Define a largura como automática
          ellipsis: true, // Ativa o corte automático de texto
          sorter,
        };
      }

      return {
        title: x,
        dataIndex: x,
        key: x,
        fieldProps(form, config) {
          return {
            ...config,
            placeholder: "",
          };
        },
        renderText: (text) => {
          if( moment(text,["YYYY-MM-DDTHH:mm:ss[Z]","YYYY-MM-DDTHH:mm:ss.sss[Z]"],true).isValid()){
            return moment(text, ["YYYY-MM-DDTHH:mm:ss[Z]","YYYY-MM-DDTHH:mm:ss.sss[Z]"],true).format("DD/MM/YYYY HH:mm")
          }
          return  text;
        },
        // hideInSearch: selectedField.isLookup,
        placeholder: "",
        width: "auto", // Define a largura como automática
        ellipsis: true, // Ativa o corte automático de texto
        sorter,
      };
    });
  } else {
    return [];
  }
}

export function buildSchemaColumns(
  columns,
  fields,
  refFields,
  fromEdit,
  formRef,
  action,
  isImport,
  dispatch,
) {
  const onlyFormFields =
    action === "new" || isImport == true
      ? _.map(
          fields.filter((obj) => obj.isDeleted !== true),
          "field"
        )
      : columns.filter((obj) => obj !== "_id" || "createdAt" || "updatedAt");
  if (columns && _.size(columns) > 0 && fields && _.size(fields) > 0) {
    return onlyFormFields
      .sort((a, b) => a.index - b.index)
      .map((x) => {
        const selectedField = fields.find((y) => y.field === x);
        if (selectedField) {

          if (selectedField.fieldtype === "Text") {
            return {
              title: x,
              dataIndex: x,
              key: x,
              initialValue: "",
              formItemProps:{
                rules: [
                  {
                    whitespace: !!selectedField.requiredField,
                    required: !!selectedField.requiredField,
                    message: `${x} é obrigatório.`,
                  },
                ],
              },
              fieldProps: {
                style: {
                  width: "100%",
                },
                disabled: selectedField.isReadOnly,
                placeholder: selectedField.isReadOnly ?? "",
                suffix: selectedField.sufixo ? selectedField.sufixo : null,
                allowClear: _.isEmpty(selectedField.sufixo) ? true : false,
              },
            };
          }
          else if (selectedField.fieldtype === "Numeric") {
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "digit",
              initialValue: "",
              formItemProps:{
                rules: [
                  {
                    required: !!selectedField.requiredField,
                    message: `${x} é obrigatório.`,
                  },
                ],
              },
              fieldProps: {
                style: {
                  width: "100%",
                },
                disabled: selectedField.isReadOnly,
                placeholder: selectedField.isReadOnly ?? "",
              },
              renderFormItem: () => (
                <InputNumber
                  formatter={null}
                  keyboard={true}
                  prefix={selectedField.sufixo ? selectedField.sufixo : null}
                />
              ),
            };
          }
          else if (selectedField.fieldtype === "Date") {
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "date",
              initialValue: selectedField.isReadOnly === undefined ? !!selectedField.requiredField ?  moment(): null : null,
              fieldProps(form, config) {
                return {
                  ...config,
                  format: "DD/MM/YYYY",
                  disabled: selectedField.isReadOnly,
                  placeholder: selectedField.isReadOnly ?? "",
                  style: {
                    width: "100%",
                  },
                };
              },
              formItemProps:
               !!selectedField.requiredField
                  ? {
                      rules: [
                        {
                          required: true,
                          message: `${x} é obrigatório.`,
                        },
                      ],
                    }
                  : {},
            };
          }
          else if (selectedField.fieldtype === "Time") {
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "time",
              initialValue: selectedField.isReadOnly === undefined ? !!selectedField.requiredField ?  moment(): null : null,
              fieldProps(form, config) {
                return {
                  ...config,
                  format: "HH:mm",
                  disabled: selectedField.isReadOnly,
                  placeholder: selectedField.isReadOnly ?? "",
                  style: {
                    width: "100%",
                  },
                };
              },
              formItemProps:
                 !!selectedField.requiredField
                  ? {
                      rules: [
                        {
                          required: true,
                          message: `${x} é obrigatório.`,
                        },
                      ],
                    }
                  : {},
              colProps: {
                span: 24,
              },
            };
          }
          else if (selectedField.fieldtype === "uniqueSelection") {
            let opts = [];
            Object.keys(selectedField.checkBox).reduce((enumObj, key) => {
              opts.push({
                label: selectedField.checkBox[key],
                value: selectedField.checkBox[key],
              });
            }, {});
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "select",
              // valueEnum,
              fieldProps: {
                disabled: selectedField.isReadOnly,
                placeholder: selectedField.isReadOnly ?? "",
                options: opts,
              },
              colProps: {
                span: 24,
              },
              formItemProps:{
                rules: [
                  {
                    required: !!selectedField.requiredField,
                    message: `${x} é obrigatório.`,
                  },
                ],
              },
            };
          }
          else if (selectedField.fieldtype === "multipleSelection") {
            let opts = [];
            Object.keys(selectedField.checkBox).reduce((enumObj, key) => {
              opts.push({
                label: selectedField.checkBox[key],
                value: selectedField.checkBox[key],
              });
            }, {});
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "select",
              fieldProps: {
                mode: "multiple",
                options: opts,
                disabled: selectedField.isReadOnly,
                placeholder: selectedField.isReadOnly ?? "",
              },
              formItemProps:{
                rules: [
                  {
                    required: !!selectedField.requiredField,
                    message: `${x} é obrigatório.`,
                  },
                ],
              },
              colProps: {
                span: 24,
              },
            };
          }
          else if (selectedField.fieldtype === "refUniqueSelection") {
            const uniqueRefFields = _.filter(refFields, (item) =>
              _.map(selectedField.refFields, "field").includes(item._id)
            ).map((obj) => {
              return ({
                form: obj._index,
                fieldName: obj.field,
                fieldType: obj.fieldtype
              });
            });
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "select",
              request: selectedField.isReadOnly ? {} : getRefData,
              params: {
                allFields: fields,
                fieldName: selectedField.field,
                refFields: uniqueRefFields || [],
                page: 1,
                pageSize: 100,
                fromEdit,
                action,
              },
              fieldProps: {
                mode: "single",
                showSearch: true,
                disabled: selectedField.isReadOnly,
                placeholder: selectedField.isReadOnly ?? "",
                onChange: async (current, nativeCurrent) => {
                  dispatch(
                    addRefFieldsFormData(
                      selectedField.field,
                      _.compact(
                        _.map(nativeCurrent ? [nativeCurrent] : [], (obj) =>
                          _.isEmpty(obj) ? null : obj
                        )
                      )
                    )
                  );
                },
              },
              formItemProps:{
                rules: [
                  {
                    required: !!selectedField.requiredField,
                    message: `${x} é obrigatório.`,
                  },
                ],
              },
            };
          }
          else if (selectedField.fieldtype === "refMultipleSelection") {
            const multipleRefFields = _.filter(refFields, (item) =>
              _.map(selectedField.refFields, "field").includes(item._id)
            ).map((obj) => ({
              form: obj._index,
              fieldName: obj.field,
              fieldType: obj.fieldtype
            }));
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "select",
              request: selectedField.isReadOnly ? {} : getRefData,
              params: {
                allFields: fields,
                fieldName: selectedField.field,
                refFields: multipleRefFields,
                page: 1,
                pageSize: 1000,
                fromEdit,
                action,
              },
              fieldProps: {
                mode: "multiple",
                showSearch: true,
                disabled: selectedField.isReadOnly,
                placeholder: selectedField.isReadOnly ?? "",
                onChange: async (current, nativeCurrent) => {
                  dispatch(
                    addRefFieldsFormData(
                      selectedField.field,
                      _.compact(
                        _.map(nativeCurrent, (obj) =>
                          _.isEmpty(obj) ? null : obj
                        )
                      )
                    )
                  );
                }
              },
              formItemProps:{
                rules: [
                  {
                    required: !!selectedField.requiredField,
                    message: `${x} é obrigatório.`,
                  },
                ],
              },
            };
          }
          else if (selectedField.fieldtype === "refLookup") {
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "select",
              request: getRefData,
              params: {
                allFields: fields,
                fieldName: selectedField.field,
                refFields: selectedField.getValueOf,
                page: 1,
                pageSize: 1000,
                fromEdit,
                action,
              },
              fieldProps: {
                showSearch: true,
                placeholder: "",
                onChange: async (current, nativeCurrent) =>
                  refLkpOnChange(
                    fields,
                    selectedField,
                    current,
                    nativeCurrent,
                    formRef,
                    dispatch,
                    action
                  ),
                onClear: () => {
                  //clearForm(fields, selectedField, formRef, dispatch, action);
                },
              },
              formItemProps:{
                rules: [
                  {
                    required: !!selectedField.requiredField,
                    message: `${x} é obrigatório.`,
                  },
                ],
              },
            };
          }
          else if (selectedField.fieldtype === "formula") {
            return {
              title: x,
              dataIndex: x,
              key: x,
              valueType: "text",
              fieldProps: {
                showSearch: true,
                placeholder: selectedField.isReadOnly ? "" : "Campo calculado",
                disabled: true
              }
            };
          }
        }
        // Se campo não for encontrado
        return {
          title: x,
          dataIndex: x,
          key: x,
          colProps: {
            span: 24,
          },
          fieldProps: {
            disabled: selectedField.isReadOnly,
            placeholder: selectedField.isReadOnly ?? "",
          },
        };
      });
  } else {
    return [];
  }
}

export function buildSchemaColumnsAutomation(form,columns = [],fieldName) {

  return columns
      .map((selectedField,index) => {
        const x = selectedField.field;
        const {fieldtype, requiredField, sufixo, checkBox, refFields, field} = selectedField;
        const commonProps = {
          title: x,
          dataIndex: x,
          key: x,
          fieldProps: {
            placeholder: "",
            style: {width: "100%"},
          },
        };

        switch (fieldtype) {
          case "Text":
            return {
              ...commonProps,
              initialValue: "",
              fieldProps: {
                ...commonProps.fieldProps,
                suffix: sufixo ?? null,
                allowClear: _.isEmpty(sufixo),
                addonAfter:<IconTriggerModal fieldName={`${fieldName}.${index}.value`} fieldLabel={x}/>
              },
            };
          case "Numeric":
            return {
              ...commonProps,
              valueType: "digit",
              initialValue: "",
              renderFormItem: (dom) => {
                const {title} = dom;
                return (<InputNumber
                    formatter={null}
                    keyboard={true}
                    prefix={sufixo ? sufixo : null}
                    addonAfter={<IconTriggerModal fieldName={`${fieldName}.${index}.value`} fieldLabel={title}/>}
                />)
              },
            };
          case "Date":
            return {
              ...commonProps,
              fieldProps: {
                ...commonProps.fieldProps,
              },
              renderFormItem: () => <DateInput type={'date'} fieldName={`${fieldName}.${index}.value`} fieldLabel={x}/>,
            };
          case "Time":
            return {
              ...commonProps,
              fieldProps: {
                ...commonProps.fieldProps,
              },
              renderFormItem: () => <DateInput type={'time'} fieldName={`${fieldName}.${index}.value`} fieldLabel={x}/>,
            };
          case "multipleSelection":
          case "uniqueSelection":
            const options = Object.keys(checkBox).map(key => ({
              label: checkBox[key],
              value: checkBox[key],
            }));
            return {
              ...commonProps,
              fieldProps: {
                ...commonProps.fieldProps,
              },
              renderFormItem: () => <SelectorInput options={options} mode={fieldtype} fieldName={`${fieldName}.${index}.value`} fieldLabel={x}/>,
            };
          case "refUniqueSelection":
          case "refMultipleSelection":
          case "refLookup":
            return {
              ...commonProps,
              fieldProps: {
                ...commonProps.fieldProps,
              },
              renderFormItem: () => <RefInput field={selectedField} mode={fieldtype} fieldName={`${fieldName}.${index}.value`} fieldLabel={x}/>,
            };
          default:
            return {
              ...commonProps,
            };
        }
      });
}


