import ptBRIntl from "antd/lib/locale/pt_BR";
import {ProCard, ProTable} from "@ant-design/pro-components";
import {Button, ConfigProvider, Input} from "antd";
import React, {useState} from "react";

export const SharedReports = ({columns, data, setIsModalVisible}) => {

    const [search, setSearch] = useState("");

    const handleAddClick = () => {
        setIsModalVisible(true);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const filteredItems = search.length > 0
        ? data.filter(({name}) => name.toLowerCase().startsWith(search.toLowerCase()))
        : data;

    return (
        <ProCard colSpan={24}>
            <ConfigProvider locale={ptBRIntl}>
                <div style={{ marginBottom: 16 }}>
                    <Input.Search
                        placeholder="Buscar por nome"
                        value={search}
                        onChange={handleSearchChange}
                        allowClear
                        style={{ width: "100%" }}
                    />
                </div>
                <ProTable
                    search={false}
                    rowKey="_id"
                    type="table"
                    columns={columns}
                    dataSource={filteredItems}
                    columnsState={{
                        persistenceKey: "clm-pro-table-search-results-shared-reports",
                        persistenceType: "sessionStorage",
                    }}
                    dateFormatter="number"
                    scroll={{x: true}}
                    style={{padding: 0, height: "100%", width: "100%"}}
                    toolbar={{
                        actions: [
                            <Button type="primary" onClick={handleAddClick}>
                                Adicionar
                            </Button>
                        ],
                    }}
                />
            </ConfigProvider>
        </ProCard>
    );
};
