import {Button, Select, Space} from "antd";
import {triggers_type} from "../constants/triggers";
import React, {useState} from "react";
import Webhook from "./Webhook";
import CronTrigger from "./CronTrigger";
import BaseRowAction from "./BaseRowAction";
import {SendOutlined} from "@ant-design/icons";
import {
    updateTriggerConfigAutomation, updateTypeTriggerAutomation
} from "../../../../../ducks/automation";
import {connect} from "react-redux";

const Trigger = ({trigger, onUpdateTriggerConfigAutomation, onUpdateTypeTriggerAutomation,automationBasicData}) => {

    const typeTrigger = trigger ? trigger.type : null

    const handleTriggerDataChange = async (config) => {
        await onUpdateTriggerConfigAutomation({config})
    }

    const  handleTriggerTypeChange = async (type) => {
        await onUpdateTypeTriggerAutomation({type})
    }

    const handlerTypeTrigger = () => {
        switch (typeTrigger) {
            case "row_created":
                return <BaseRowAction trigger={trigger} onChange={handleTriggerDataChange}/>
            case "row_updated":
                return <BaseRowAction trigger={trigger} onChange={handleTriggerDataChange}/>
            case "row_deleted":
                return <BaseRowAction trigger={trigger} onChange={handleTriggerDataChange}/>
            case "webhook":
                return <Webhook trigger={trigger} automation={automationBasicData} onChange={handleTriggerDataChange}/>
            case "cron_trigger":
                return <CronTrigger trigger={trigger} onChange={handleTriggerDataChange}/>
        }
    }

    return (
        <div style={{height: "100%", width: "100%"}}>
            <p>Tipo de Gatilho</p>
            <Select style={{width: "100%"}}
                    size={"large"}
                    placeholder="Selecione o tipo de gatilho"
                    onChange={(value) => handleTriggerTypeChange(value)}
                    defaultValue={typeTrigger}
            >
                {triggers_type.map(trigger => (
                    <Select.Option key={trigger.type} value={trigger.type}>
                        {trigger.icon} {trigger.name}
                    </Select.Option>
                ))}
            </Select>
            <br/>
            <br/>
            {
                typeTrigger && handlerTypeTrigger()
            }
        </div>
    );
}

const mapStateToProps = ({automation}) => ({
    trigger: automation.trigger,
    automationBasicData: automation.automation
});

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateTriggerConfigAutomation: ({
                                              config
                                          }) => dispatch(updateTriggerConfigAutomation({
            config
        })),
        onUpdateTypeTriggerAutomation: ({type}) => dispatch(updateTypeTriggerAutomation({type})),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
