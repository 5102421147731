import React, {useState} from 'react';
import {List, Modal} from "antd";
import {actions_types} from "./constants/actions";
import {connect} from "react-redux";
import {addActionToAutomaion} from "../../../../ducks/automation";

 const ModalAddAction = ({isModalOpen,hideModal, onAddActionToAutomation,saveDataFromActionAfterChangeStep,automation}) => {
    const {actionStep} = automation;
    const [selectedItem, setSelectedItem] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleOkSelectAction = () =>{
        if(selectedItem){
            const index = actions_types.findIndex(item => item.type === selectedItem);
            saveDataFromActionAfterChangeStep(actionStep);
            onAddActionToAutomation(actions_types[index]);
        }

        setHoveredItem(null);
        setSelectedItem(null)
        hideModal();
    }

    const handleOnDoubleClickSelectAction = (type) =>{
        setSelectedItem(type)
        handleOkSelectAction();
    }

    return (
        <Modal
            title="Escolha o tipo da ação:"
            visible={isModalOpen}
            onOk={handleOkSelectAction}
            onCancel={hideModal}
            okText="Selecionar"
            cancelText="Cancelar"
            okButtonProps={{
                disabled: selectedItem === null
            }}
        >
            <List
                dataSource={actions_types}
                renderItem={(item) => (
                    <List.Item
                        onDoubleClick={() => handleOnDoubleClickSelectAction(item.type)}
                        onClick={() => setSelectedItem(item.type)}
                        onMouseEnter={() => setHoveredItem(item.type)}
                        onMouseLeave={() => setHoveredItem(null)}
                        style={{
                            padding: "5px",
                            backgroundColor: item.type === selectedItem ? '#e6f7ff' : (item.type === hoveredItem ? '#f0f0f0' : 'transparent'),
                            cursor: 'pointer',
                        }}
                    >
                        <List.Item.Meta
                            style={{display: "flex", alignItems: "center"}}
                            avatar={item.icon}
                            title={item.name}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </Modal>
    )
}

const mapStateToProps = ({automation}) => ({
    automation: automation,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onAddActionToAutomation: (action) => dispatch(addActionToAutomaion(action))
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(ModalAddAction)
