import React, { useEffect, useRef, useState } from "react";
import { createStandaloneToast } from "@chakra-ui/toast";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Table,
  Tag,
  Space,
  Modal,
  Input,
  Button,
} from "antd";
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import { getUsers } from "auth/actions/userActions";
import { removeUsuario } from "auth/actions/userActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Misc
 */
const { Content } = Layout;
const { confirm } = Modal;

function AllUsers() {
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const mounted = useRef(true);
  const [user, setUser] = useState([]);
  const [auxUser, setAuxUser] = useState([]);
  const [filtro, setFiltro] = useState("");

  useEffect(() => {
    try {
      if (user.length > 0) {
        return;
      }
    } catch (error) {
      return;
    }
    getUsers().then((items) => {
      setAuxUser(items.message);
      setUser(items.message);
    });
  }, []);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        if (mounted.current) {
          setAlert(false);
        }
      }, 1000);
    }
  }, [alert]);

  const searchName = (pesquisa) => {
    const currValue = pesquisa;
    setFiltro(currValue);
    if (pesquisa === "") {
      setUser(auxUser);
    } else {
      const filteredData = auxUser.filter((entry) => {
        // console.log(entry)
        return entry.userName.toLowerCase().includes(currValue.toLowerCase()); // Convert the userName to lowercase for comparison
      });
      setUser(filteredData);
    }
  };

  /**
   * Redireciona
   * @param {*} info
   */
  const handleRequest = (info) => {
    history.push({
      pathname: `/usuarios/editar`,
      state: info,
    });
  };

  const newUser = () => {
    history.push({
      pathname: `/usuarios/novo`,
    });
  };

  /**
   * Remove usuario
   * @param {*} info
   */
  function removeUser(info) {
    confirm({
      title: `Você realmente deseja excluir o usuário ${info.userName} ?`,
      icon: <ExclamationCircleOutlined />,
      content: "Essa ação não pode ser desfeita.",
      okText: "Confirmar",
      onOk() {
        try {
          removeUsuario(info);
        } finally {
          const emailToRemove = info.email;
          const filteredUsers = user.filter(
            (user) => user.email !== emailToRemove
          );
          setUser(filteredUsers);
        }
      },
      cancelText: "Cancelar",
    });
  }

  const { ToastContainer } = createStandaloneToast();

  const routes = [
    {
      breadcrumbName: "usuários",
    },
    {
      breadcrumbName: "todos",
    },
  ];
  const columns = [
    {
      title: "Nome",
      dataIndex: "userName",
      key: "userName",
      render: (text, user) => (
        <a>
          {user.firstName} {user.lastName}
        </a>
      ),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Autenticação de dois fatores",
      key: "twoFAverified",
      dataIndex: "twoFAverified",
      render: (twoFAverified) => (
        <Tag color={twoFAverified === "S" ? "green" : "red"}>
          {twoFAverified === "S" ? "Sim" : "Não"}
        </Tag>
      ),
    },
    {
      title: "Ações",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => handleRequest(record)}>Editar</a>
          <a onClick={() => removeUser(record)}>Remover</a>
        </Space>
      ),
    },
    // {
    //   title: FilterByNameInput,
    //   dataIndex: "name",
    //   key: "1",
    // },
    // {
    //   title: (
    //     <Button type="primary" onClick={newUser}>
    //       Adicionar usuário <PlusCircleOutlined />
    //     </Button>
    //   ),
    // },
  ];

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0 5px 0" }}
              title="Lista de usuários"
              subTitle="As listas de usuários são usadas para gerenciar e administratar todos os colaboradores."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16} justify="end">
                <Col md={{ span: 6 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <Button type="primary" onClick={newUser}>
                      Adicionar usuário <PlusCircleOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Table columns={columns} dataSource={user} />
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <ToastContainer />
      </LayoutTopSide>
    </Layout>
  );
}

export default AllUsers;
