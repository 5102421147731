function handleCkHidden(e) {
  switch (e) {
    case "uniqueSelection":
      return true;
    case "multipleSelection":
      return true;
    case "refUniqueSelection":
      return false;
    case "refMultipleSelection":
      return false;
    case "refLookup":
      return true;
    default:
      return true;
  }
}

function handleRfHidden(e) {
  switch (e) {
    case "uniqueSelection":
      return false;
    case "multipleSelection":
      return false;
    case "refUniqueSelection":
      return true;
    case "refMultipleSelection":
      return true;
    case "refLookup":
      return true;
    default:
      return true;
  }
}

function handleFormulaHidden(e) {
  switch (e) {
    case "formula":
      return false;
    default:
      return true;
  }
}

function handleLkHidden(e) {
  switch (e) {
    case "uniqueSelection":
      return true;
    case "multipleSelection":
      return true;
    case "refUniqueSelection":
      return true;
    case "refMultipleSelection":
      return true;
    case "refLookup":
      return false;
    default:
      return true;
  }
}

function handleDtHidden(e) {
  switch (e) {
    case "Formula":
    case "Date":
    case "Time":
      return true;
    default:
      return false;
  }
}

function handleDtRules(e) {
  switch (e) {
    case "Date":
    case "Time":
      return [
        {
          required: false,
          message: "",
        },
      ];
    case "refLookup":
      return [
        {
          required: true,
          message: "Você precisa escolher um nome que será usado no campo!",
        },
      ];
    default:
      return [
        {
          required: true,
          message: "Você precisa escolher uma opção!",
        },
      ];
  }
}

function removeRef(string) {
  return (
    string.replace("ref", "").charAt(0).toLowerCase() +
    string.replace("ref", "").slice(1)
  );
}

function removeLk(string) {
  return (
    string.replace("ref", "").charAt(0).toLowerCase() +
    string.replace("ref", "").slice(1)
  );
}

export {
  handleFormulaHidden,
  handleCkHidden,
  handleDtHidden,
  handleRfHidden,
  handleLkHidden,
  handleDtRules,
  removeRef,
  removeLk,
};
