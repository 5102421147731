import React, { useEffect, useState } from "react";
import { Tree, Card, Row, Col, Divider, Empty } from "antd";
import _ from "lodash";

/**
 * Actions
 */
import { allFormsOfSavedObjectsWithFields } from "auth/actions/objActions";
import { filterTree, renderTreeNodes } from "./utils.js";

export default function TreeTransfer(props) {
  const [clean, setClean] = useState([]);
  const [data, setData] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);

  useEffect(() => {
    onGet();
  }, [props.refresh, props.selected]);

  const onGet = () => {
    /**
     * Todos os campos com formName
     */
    allFormsOfSavedObjectsWithFields().then((items) => {
      const { removeSelected, formName } = props;
      const allResults = removeSelected ? items.message.filter(v => v.name !== formName) : items.message || [];
      setClean(allResults);
      // organiza os campos
      var filtered = allResults
        .map((obj) => ({
          title: obj.name.replace("obj_", ""),
          key: obj.name,
          children: obj.fields.map((field) => ({
            key: field._id,
            title: field.field,
          })),
        }))
        .filter((obj) => _.size(obj.children) > 0);
      // carrega os campos
      setData(filtered);
      // verifica se existe campos selecionado
      if (props.selected) {
        var selectedFrm = [],
          selectedFld = [],
          selectedOpt = [];
        // forms
        _.at(
          _.keyBy(filtered, "title"),
          allResults
            .map((form) => ({
              form: form.name,
              fields: _.compact(
                _.at(
                  _.keyBy(form.fields, "_id"),
                  _.map(props.selected, "field")
                )
              ),
            }))
            .filter((obj) => obj.fields.length > 0)
            .map((obj) => obj.form.replace("obj_", ""))
        ).map((obj) => selectedFrm.push(obj.key));
        // campos e opcoes
        _.flattenDeep(
          filtered.map((obj) =>
            _.compact(
              _.at(_.keyBy(obj.children, "key"), _.map(props.selected, "field"))
            )
          )
        ).forEach((field) => {
          selectedFld.push(field.key);
        });
        // seleciona as opcoes
        const filteredTree = filterTree(selectedFrm, selectedFld, filtered);
        setCheckedNodes(filteredTree);
        setSelectedFields(selectedFld);
      }
    });
  };

  const onCheck = (fields, info) => {
    const filteredTree = filterTree(
        fields,
        info.halfCheckedKeys,
        props.selected ? (info.data ? info.data : data) : data
      ),
      nodes = filteredTree,
      result = [];
    nodes.forEach((node) => {
      node.children.forEach((child) => {
        result.push({
          form: `obj_${node.title}`,
          field: child.key,
        });
      });
    });
    // seleciona as opcoes
    props.form.setFieldsValue({ refOpt: result });
    props.onChange(result);
    setCheckedNodes(filteredTree);
    setSelectedFields(fields);
  };

  return (
    <div>
      <div>
        <Card>
          <Tree
            checkable
            defaultExpandAll
            checkedKeys={selectedFields}
            onCheck={onCheck}
            height={200}
            disabled={props.disabled ? true : false}
          >
            {renderTreeNodes(data)}
          </Tree>
        </Card>
      </div>
      {/*<Divider plain>⇅</Divider>
      <div>
        <Card>
          {checkedNodes.length > 0 ? (
            <Tree
              defaultExpandAll
              height={200}
              disabled={props.disabled ? true : false}
            >
              {renderTreeNodes(checkedNodes)}
            </Tree>
          ) : (
            <Empty description="Nenhum item selecionado..." />
          )}
        </Card>
          </div>*/}
    </div>
  );
}
