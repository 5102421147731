import * as React from "react";
const SvgPageHeader = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={146} height={103} {...props}>
    <g fill="none" fillRule="evenodd">
      <rect
        width={146}
        height={102.85}
        fill="#A3B5D6"
        opacity={0.45}
        rx={2.2}
      />
      <path
        fill="#253369"
        d="M7.398 96.325a1.28 1.28 0 0 1-1.28-1.281V7.597a1.28 1.28 0 0 1 1.28-1.281h130.64c.707 0 1.28.573 1.28 1.28v87.448a1.28 1.28 0 0 1-1.28 1.28H7.398z"
      />
      <path
        fill="#E5EDFB"
        d="M6.117 96.365V13.11h133.431v81.975c0 .708-.781 1.281-1.745 1.281H6.117z"
      />
      <path
        fill="#A3B5D6"
        d="M32.597 53.032H14.391c-.64 0-1.16-.52-1.16-1.16V49.01c0-.64.52-1.16 1.16-1.16h18.206c.64 0 1.16.52 1.16 1.16v2.862c0 .64-.52 1.16-1.16 1.16m.018 9.99H14.374a1.143 1.143 0 0 1-1.143-1.142v-2.74c0-.632.512-1.143 1.143-1.143h18.24c.632 0 1.143.511 1.143 1.142v2.74c0 .632-.511 1.143-1.142 1.143"
      />
      <path
        fill="#4E5C93"
        d="M32.615 73.09H14.374a1.143 1.143 0 0 1-1.143-1.142v-2.74c0-.63.512-1.142 1.143-1.142h18.24c.632 0 1.143.511 1.143 1.142v2.74c0 .631-.511 1.143-1.142 1.143"
      />
      <path
        fill="#FFF"
        d="M33.741 30.808c0 5.66-4.588 10.247-10.247 10.247s-10.247-4.587-10.247-10.247c0-5.659 4.588-10.247 10.247-10.247 5.66 0 10.247 4.588 10.247 10.247"
      />
      <path
        fill="#253369"
        d="M23.494 41.055a9.99 9.99 0 0 0 7.658-3.564 8.653 8.653 0 0 0-7.658-4.621 8.653 8.653 0 0 0-7.658 4.621 9.99 9.99 0 0 0 7.658 3.564"
      />
      <path
        fill="#253369"
        d="M27.4 29.424a3.853 3.853 0 1 1-7.706 0 3.853 3.853 0 0 1 7.707 0"
      />
      <rect width={90.2} height={68.2} x={42.8} y={20.9} fill="#FFF" rx={1.1} />
      <path
        fill="#A3B5D6"
        d="M51.917 74.564c-.639 0-1.156-.497-1.156-1.11v-45.08c0-.613.517-1.11 1.156-1.11h71.167c.638 0 1.156.497 1.156 1.11v45.08c0 .613-.518 1.11-1.156 1.11H51.917z"
      />
      <path
        fill="#FFF"
        d="M55.765 34.292a.707.707 0 1 1 0-1.414h5.046a.707.707 0 1 1 0 1.414h-5.046zm.082 3.392a.707.707 0 0 1 0-1.414h5.045a.707.707 0 1 1 0 1.414h-5.045z"
      />
      <path
        fill="#253368"
        d="M50.76 61.554c7.648 0 5.617-9.69 17.2-9.69 11.581 0 10.978 8.662 20.01 8.662 9.174 0 8.568-24.88 17.356-24.88 9.277 0 7.183 22.545 18.739 22.545V73.47c0 .639-.518 1.157-1.157 1.156l-70.992-.061a1.156 1.156 0 0 1-1.155-1.156V61.554z"
      />
      <path
        stroke="#FFF"
        strokeWidth={0.809}
        d="M50.5 65.298c11.556 0 9.917-26.499 17.365-26.499 7.093 0 4.963 26.499 19.653 26.499 9.033 0 11.874-10.802 18.255-10.802 6.381 0 10.645 10.802 18.292 10.802"
      />
      <path
        stroke="#E4E6EF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.971}
        d="M51.534 79.227h71.28m-71.28 4.883h59.888"
      />
    </g>
  </svg>
);
export default SvgPageHeader;
