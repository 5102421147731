import React, {useState} from 'react';
import {Collapse, Typography, Input, Divider, List, Tooltip} from 'antd';
import {ProTable} from "@ant-design/pro-components";
import {connect} from "react-redux";
import {SearchOutlined} from "@ant-design/icons";
import {getNameFromAction} from "../Actions/utils/functions";
import {helpersDoc} from "./helpers";
import uuid from "react-uuid";

const { Panel } = Collapse;
const { Text } = Typography;

const SnippetSections = ({actions, actionStep, trigger, snippets,insertSnippetHelper,insertSnippets, hideHelpers = false,acceptEntryLoop = true}) => {
    const {data} = snippets;
    const [search, setSearch] = useState("");

    const renderTable = (data,step) => (
        <ProTable
            columns={[
                { title: 'Campo', dataIndex: 'field', key: 'field' },
                { title: 'Tipo', dataIndex: 'type', key: 'type', align: 'right' },
            ]}
            onRow={(record) => {
                return {
                    style: { cursor: 'pointer' },
                    onDoubleClick: () => handleDoubleClick(record,'action',step),
                    onClick: () => handleDoubleClick(record,'action',step),
                };
            }}
            dataSource={data}
            rowKey="key"
            pagination={false}
            search={false}
            options={false}
            toolBarRender={false}
        />
    );

    function handleDoubleClick(record,type="action",step = null) {

        if(type === "helper") {
            insertSnippetHelper(record.field, record)
        }else{
            if(!!record.propFromLoop){
                insertSnippets(record.field, actions[step],step,true)
            }else{
                insertSnippets(record.field, actions[step],step)
            }

        }
    }

    const renderTableHelpers = (data) => {
        data = data.map(([key,value]) =>{
            value.field = key
            return value;
        })
        return (

            <ProTable
                columns={[
                    {
                        title: 'Nome',
                        dataIndex: 'field',
                        key: 'field'
                    },
                    {
                        title: 'Descricão',
                        dataIndex: 'description',
                        key: 'description',
                        render: (text) => <div dangerouslySetInnerHTML={{__html: text}}></div>
                    },
                    {
                        title: 'Exemplo',
                        dataIndex: 'example',
                        key: 'example',
                        render: (text) => <Text code>{text}</Text>
                    },
                ]}
                dataSource={data}
                onRow={(record) => {
                    return {
                        style: { cursor: 'pointer' },
                        onDoubleClick: () => handleDoubleClick(record,'helper'),
                        onClick: () => handleDoubleClick(record,'helper'),
                    };
                }}
                rowKey="key"
                pagination={false}
                search={false}
                options={false}
                toolBarRender={false}
            />
        );
    };

    const renderPanelTrigger = () =>{
        if(data[-1]){
            const items = data[-1].filter(item => item.field.startsWith(search));
            if(items.length > 0){
                const header = (<Text>Gatilho ({items && items.length})</Text>)
                return (<Panel key={uuid()} header={header}>
                    {renderTable(items,-1)}
                </Panel>)
            }else return null

        }else return null

    }

    const renderPanelsHelpers = () => {
        return Object.entries(helpersDoc)
            .map(([key, section]) => {
                const items = Object.entries(section).filter(([keyFunction]) => keyFunction.startsWith(search))

                const hearder = (
                    <Text>
                        {key} ({items && items.length})
                    </Text>
                )

                if (items.length > 0) {
                    return (<Panel header={hearder}>
                        {renderTableHelpers(items)}
                    </Panel>)
                }
                return null

            })
    }
    let panels = [];

    for (let i = 0; i < actionStep; i++) {
        const items = data[i].filter(item => item.field.startsWith(search) && !!!item.propFromLoop);
        if(items && items.length > 0){
            const header = (<Text>{getNameFromAction(actions[i])} ({items && items.length})</Text>)
            panels.push(
                <Panel key={uuid()} header={header}>
                    {renderTable(items,i+1)}
                </Panel>
            )
        }
    }

    if( actions[actionStep].config &&
        actions[actionStep].config.loopData &&
        actions[actionStep].config.loopData.active &&
        acceptEntryLoop
    ){
        const items = data[actionStep] ? data[actionStep].filter(item => item.field.startsWith(search) && (!!item.propFromLoop)) : [];
        const header = (<Text>{getNameFromAction(actions[actionStep])} ({items && items.length})</Text>)
        panels.push(
            <Panel key={uuid()} header={header}>
                {renderTable(items,actionStep)}
            </Panel>
        )
    }
    if(hideHelpers){
        panels  = [
            renderPanelTrigger(),
            ...panels,
        ]
    }else{
        panels  = [
            renderPanelTrigger(),
            ...panels,
            ...renderPanelsHelpers()
        ]
    }


    return (
        <React.Fragment>
            <Input prefix={<SearchOutlined/>}
                   allowClear
                   placeholder={"Pesquise por anotações disponíveis"}
                   onChange={(e) => setSearch(e.target.value)}>
            </Input>
            <Divider/>
            <Collapse ghost >
                {panels}
            </Collapse>
        </React.Fragment>
    );
};

const mapStateToProps = ({automation}) => ({
    actions: automation.actions,
    actionStep: automation.actionStep,
    trigger: automation.trigger,
    snippets: automation.snippets
});

export default connect(mapStateToProps,null)(SnippetSections)
