import getApiUrl from "services/getApiUrl";
import axios from 'axios';

const apiClient = axios.create({
    baseURL:`${getApiUrl()}/notifications`,
});


export  const notificationAPI = {
    getAll: async (params) => apiClient.get('/',{params}),

    getOne: async (id) =>  apiClient.get(`/${id}`),

    deleteOne: async (id) =>  apiClient.delete(`/${id}`),

    markAsRead: async (id) => apiClient.get(`/${id}/read`),

    markAsUnread: async (id) => apiClient.get(`/${id}/unread`),

    markAllAsRead: async (user_id) => apiClient.get(`/read-all/${user_id}`),

};


