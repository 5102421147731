import "react-redux";
import { sessionService } from "redux-react-session";
import { createStandaloneToast } from "@chakra-ui/react";
import axios from "axios";

import getApiUrl from "services/getApiUrl";
import getUrlClient from "../../services/getUrlClient";
import {
  clearSession,
  clearSessionReport,
  END_GET_USER_REPORT_DATA,
  ERROR_GET_USER_REPORT_DATA
} from "../../ducks/report";

/**
 * Iniciar sessão.
 * @param {*} credentials
 * @param {*} twofa
 * @param {*} history
 * @param {*} setFieldError
 * @param {*} setSubmitting
 * @param {*} setShowPopup
 */
export const loginUser =  (
  credentials,
  twofa,
  history,
  setFieldError,
  setSubmitting,
  setShowPopup
) =>  async (dispatch) =>  {
  const { toast } = createStandaloneToast();
  // objeto para string e compara para ver se esta no estado inicial
  const compare = JSON.stringify(twofa);
  if (compare === '["","","","","",""]') {
    twofa = "";
  } else {
    var result = twofa.map(function (x) {
      return parseInt(x, 10);
    });
    const legivel = JSON.stringify(result)
      .match(/\d|\.|\-/g)
      .join("");
    twofa = legivel;
  }
  await axios
    .post(`${getApiUrl()}/auth/login`, {
      email: credentials.email,
      password: credentials.password,
      token: twofa,
    })
    .then((response) => {
      setSubmitting(false);
      const userData = response.data.user;
      const token = response.data.user.JWtoken;
      sessionService.saveSession(token).then(() => {
        sessionService
          .saveUser(userData)
          .then(async () => {
            try {
              const {data,status} = await syncReportUser(response.data.user.uuid)
              if (201 === status) {
                dispatch({type: END_GET_USER_REPORT_DATA, payload: data.user});
                localStorage.setItem("user-report", JSON.stringify(data.user));
              } else {
                throw new Error(data);
              }
            }catch (ex) {
              console.error(ex);
              dispatch({type: ERROR_GET_USER_REPORT_DATA, payload: ex.message});
            }
            history.push("/bem-vindo");
          })
          .catch((err) => console.error(err));
      });
    })
    .catch((error) => {
      setSubmitting(false);
      if (error.response.status === 401) {
        if (error.response.data.user.message.includes("Senha")) {
          setFieldError("password", error.response.data.user.message);
        } else if (error.response.data.user.message.includes("Email")) {
          setFieldError("email", error.response.data.user.message);
        } else if (error.response.data.user.message.includes("Abrir popup")) {
          setShowPopup(true);
          //setShowPopup(true);
        } else if (
          error.response.data.user.message.includes("código dois fatores")
        ) {
          setFieldError("password", error.response.data.user.message);
          toast({
            title: "Erro 2 fatores",
            position: "top-right",
            description: "dois fatores incorreto",
            isClosable: false,
            status: "error",
            duration: 1300,
          });
        } else {
          setFieldError("email", error.response.data.user.message);
          setFieldError("password", error.response.data.user.message);
        }
      } else if (error.response.status === 500) {
        setFieldError("email", error.response.data.user.message);
        setFieldError("password", error.response.data.user.message);
      } else {
        setFieldError("password", error.response);
      }
    });
};

/**
 * Finalizar sessão.
 * @param {*} history
 * @param dispatch
 * @returns
 */
export const logoutUser = (history,dispatch) => {
  setTimeout(() => {
    sessionService.deleteSession();
    sessionService.deleteUser();
    dispatch(clearSessionReport())
    history.push("/iniciar-sessao");
  }, 500);
};

/**
 * Esqueci minha senha.
 * @param {*} credentials
 * @param {*} setFieldError
 * @param {*} setSubmitting
 * @param {*} setShowPopup
 */
export const envmailesqueci = async (
  credentials,
  setFieldError,
  setSubmitting,
  setShowPopup
) => {
  const { toast } = createStandaloneToast();
  await axios
    .post(`${getApiUrl()}/auth/esqueci-minha-senha`, null, {
      headers: { email: credentials.email },
    })
    .then((response) => {
      setSubmitting(false);
      setShowPopup(true);
      toast({
        title: "Email",
        position: "top-right",
        description: "Email enviado com sucesso",
        isClosable: false,
        status: "success",
        duration: 1300,
      });
    })
    .catch(async function (error) {
      setSubmitting(false);
      if (error.response.status === 401) {
        setFieldError("email", error.response.data.user.message);
      } else {
        setFieldError("email", error.response.data.user.message);
      }
    });
};

/**
 * Autenticador para alterar senha.
 * @param {*} credentials
 * @param {*} setFieldError
 * @param {*} setSubmitting
 * @param {*} Token
 * @param {*} setShowPopup
 * @param {*} history
 */
export const validamailesqueci = async (
  credentials,
  setFieldError,
  setSubmitting,
  Token,
  setShowPopup,
  history
) => {
  const { toast } = createStandaloneToast();
  // objeto para string e compara para ver se esta no estado inicial
  const compare = JSON.stringify(Token);
  if (compare === '["","","","","",""]') {
    Token = "";
  } else {
    var result = Token.map(function (x) {
      return parseInt(x, 10);
    });
    const legivel = JSON.stringify(result)
      .match(/\d|\.|\-/g)
      .join("");
    Token = legivel;
  }

  await axios
    .post(`${getApiUrl()}/auth/validar-minha-senha`, null, {
      headers: { email: credentials.email, token: Token },
    })
    .then((response) => {
      setSubmitting(false);
      setShowPopup(false);
      // Conteudo/envio para o alterasenha
      const userData = response.data.user;
      const token = response.data.user.Jwtoken;
      sessionService.saveSession(token).then(() => {
        sessionService
          .saveUser(userData)
          .then(() => {
            history.push("/alterar-senha");
          })
          .catch((err) => console.error(err));
      });
    })
    .catch((error) => {
      setSubmitting(false);
      if (error.response.status === 401) {
        if (error.response.data.user.message.includes("incorreto")) {
          setFieldError("password", error.response.data.user.message);
          toast({
            title: "Erro",
            position: "top-right",
            description: "codigo inválido",
            isClosable: false,
            status: "error",
            duration: 1300,
          });
        }
      } else if (error.response.status === 500) {
        setFieldError("email", error.response.data.user.message);
      } else {
        setFieldError("password", error.response);
      }
    });
};

/**
 * Redefine a senha da conta selecionada.
 * @param {*} credentials
 * @param {*} setFieldError
 * @param {*} setSubmitting
 * @param {*} history
 * @param {*} token
 */
export const alterasenha = async (
  credentials,
  setFieldError,
  setSubmitting,
  history,
  token,
  loggedUser = {}
) => {
  const { toast } = createStandaloneToast();
  await axios
    .post(
      `${getApiUrl()}/auth/alterar-senha`,
      {
        pass: credentials.confirmpassword,
        email: credentials.email,
      },
      {
        headers: {
          Authorization: "Barrer " + token,
        },
      }
    )
    .then((response) => {
      setSubmitting(false);
      // Conteudo/envio para o alterasenha
      const userData = response.data.user;
      if ("JWtoken" in userData) {
        userData["JWtoken"] = token;
      }
      toast({
        title: "Senha",
        position: "top-right",
        description: "Senha alterada com sucesso",
        isClosable: false,
        status: "success",
        duration: 1300,
      });
      if (
        credentials.userName == loggedUser.userName ||
        loggedUser.userName == ""
      ) {
        sessionService.saveSession(token).then(() => {
          sessionService
            .saveUser(userData)
            .then(() => {
              if (history.location.pathname === "/usuarios/editar") {
                history.push("/usuarios/todos");
              } else {
                history.push("/bem-vindo");
              }
            })
            .catch((err) => console.error(err));
        });
      } else {
        history.push("/usuarios/todos");
      }
    })
    .catch((error) => {
      setSubmitting(false);
      if (error.response.status === 401) {
        if (error.response.data.user.message.includes("Token")) {
          setFieldError("password", error.response.data.user.message);
          toast({
            title: "Erro",
            position: "top-right",
            description:
              "A senha é inválida ou o usuário não possui uma senha.",
            isClosable: false,
            status: "error",
            duration: 1300,
          });
        }
      } else if (error.response.status === 500) {
        setFieldError("password", error.response.data.user.message);
      } else {
        setFieldError("password", error.response);
      }
    });
};

/**
 * Cria novo usuário.
 * @param {*} credentials
 * @param {*} history
 * @param {*} setFieldError
 * @param {*} setSubmitting
 * @returns
 */
export const signupUser = async (credentials, history) => {
  const { toast } = createStandaloneToast();
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post(`${apiUrl}/usuarios/novo`, credentials, {
      headers: { "Content-Type": "application/json" },
    });
    const { status, data } = response;
    if (status === 201) {
      try {
        toast({
          title: "Adição",
          position: "top-right",
          description: `Usuário ${credentials.userName} cadastrado com sucesso`,
          isClosable: false,
          status: "success",
          duration: 1500,
        });
      } finally {
        setTimeout(function () {
          history.push("/usuarios/todos");
        }, 750);
      }
    } else {
      toast({
        title: "Adição",
        position: "top-right",
        description: "Usuário já consta em nosso sistema",
        isClosable: false,
        status: "error",
        duration: 1500,
      });
    }
    return data;
  } catch (error) {
    toast({
      title: "Adição",
      position: "top-right",
      description: "Usuário já consta em nosso sistema",
      isClosable: false,
      status: "error",
      duration: 1500,
    });
    console.error(error);
  }
};

/**
 * Edita usuario.
 * @param {*} info
 * @param {*} history
 * @returns
 */
export const editaUsuario = async (info, history) => {
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post(`${apiUrl}/usuarios/alterar`, info, {
      headers: { "Content-Type": "application/json" },
    });

    const { toast } = createStandaloneToast();
    toast({
      title: "Edição",
      position: "top-right",
      description: `Usuário ${info.userName} editado com sucesso`,
      isClosable: false,
      status: "success",
      duration: 3300,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(function () {
      history.push("/usuarios/todos");
    }, 500);
  }
};

/**
 * Remove usuario.
 * @param {*} info
 * @returns
 */
export const removeUsuario = async (info) => {
  return await axios
    .post(`${getApiUrl()}/usuarios/remover`, info, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      const { toast } = createStandaloneToast();
      toast({
        title: "Exclusão",
        position: "top-right",
        description: "Usuário excluído com sucesso",
        isClosable: false,
        status: "success",
        duration: 1300,
      });
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Lista de todos usuários.
 * @returns
 */
export const getUsers = async (name = null, simplify = false) => {
  let url = `${getApiUrl()}/usuarios/todos`;

  if(name) {
    url += `?name=${encodeURIComponent(name)}`;
  }

  if(simplify) {
    url += name ? `&simplify=${simplify}` : `?simplify=${simplify}`;
  }

  return await fetch(url).then((response) => response.json());
};

/**
 * Detalhes do usuário.
 * @returns
 */
export const getUserDetails = async ({ id }) => {
  return await fetch(`${getApiUrl()}/usuarios/detalhes/${id}`).then(
    (response) => response.json()
  );
};

const syncReportUser = async (userId) => {
  return await axios.get(`${getUrlClient()}/reports/config/user/${userId}`, {
        headers: { "Content-Type": "application/json" },
      })
}
