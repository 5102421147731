import {Button, Col, Input, Row, Select, Switch} from "antd";
import {SelectForm} from "../SelectForm";
import React, {useEffect, useState} from "react";
import {RecordForm} from "./RecordForm";
import {getFieldType} from "../../../../../../../utils/get-field-type";
import IconTriggerModal from "../../../Snippets/IconTriggerModal";

export const RowAction = ({type, value, onChange, onBlur, fieldName, fieldLabel}) => {

    const [fieldsValues, setFieldsValue] = useState([]);

    const getFirstOptionsFields = (fields) => {
        const fieldsValuesData = transformedFields(fields);
        setFieldsValue(fieldsValuesData)
    }

    const transformedFields = (fields) => {
        return [
            ...fields
                .filter(field => field.fieldtype !== 'formula' || field.isReadOnly)
                .map(field => ({
                    label: field.field,
                    value: field._id,
                    required: !!field.requiredField,
                    type: field.fieldtype,
                    typeText: getFieldType(field.fieldtype),
                    fieldProps: {
                        ...field
                    }
                })),
        ];
    }

    const onChangeRecordId = (e) => {
        handleChangeAll('recordId',e.target.value);
    }

    const onChangeExecuteRelatedTriggersOnThisAction = (valueChange) => {
        handleChangeAll('executeRelatedTriggersOnThisAction',valueChange)
    }

    const handleChangeAll = (prop,valueChange) => {
        const payload = {...value}
        payload[prop] = valueChange;
        if(prop === "form"){
            payload.fields = [];
        }
        if(!payload.recordId && type === "update"){
            payload.recordId = "";
        }
        if(!payload.form){
            payload.form = null;
        }
        if(!payload.fields){
            payload.fields  = [];
        }
        if(!payload.hasOwnProperty('executeRelatedTriggersOnThisAction')){
            payload.executeRelatedTriggersOnThisAction = true;
        }
        onChange(payload);
    }

    const handleChangeFormSelect = (valueChange) => {
        if (valueChange) {
            const {fields, id, name} = valueChange;
            const fieldsValuesData = transformedFields(fields);
            setFieldsValue(fieldsValuesData)
            handleChangeAll('form',{id, name})
        }
    }

    const executeRelatedTriggersOnThisAction = value && value.hasOwnProperty("executeRelatedTriggersOnThisAction") ? value.executeRelatedTriggersOnThisAction : true


    return (<Row gutter={24}>
        <Col span={24}>
            <label>Selecione o formulário:</label>
            <SelectForm
                onBlur={onBlur}
                withFields
                onChange={handleChangeFormSelect}
                value={value && value.form ? value.form : null}
                getFirstOptionsFields={getFirstOptionsFields}
            />
        </Col>
        <Col span={24}>
            <Input.Group>
                <label>Acionar gatilhos atrelados a esse formulário quando essa ação for executada?</label>
                <Select
                    onBlur={(e) => (onBlur) ? onBlur(e) : null}
                    style={{width: '100%'}}
                    value={executeRelatedTriggersOnThisAction}
                    onChange={onChangeExecuteRelatedTriggersOnThisAction}
                    options={[
                        {
                            label: "Sim",
                            value: true
                        },
                        {
                            label: "Não",
                            value: false
                        }
                    ]}
                >
                </Select>
            </Input.Group>
        </Col>
        {(type === "update") ? (
            <Col span={24}>
                <label>ID do Registro</label>
                <Input
                    value={value && value.recordId ? value.recordId : ""}
                    onChange={onChangeRecordId}
                    addonAfter={
                        <IconTriggerModal
                            fieldName={`${fieldName}.recordId`}
                            fieldLabel={"ID do Registro"}
                        />
                    }>
                </Input>
            </Col>
        ) : null}
        <Col span={24}>
            <RecordForm fields={fieldsValues || []} value={value} handleChangeAll={handleChangeAll}
                        disable={fieldsValues.length === 0} fieldName={fieldName}/>
        </Col>
    </Row>)
}
