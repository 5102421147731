import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Avatar,
  Modal,
  Table,
  Empty,
  Space,
  Input,
  notification,
  Tooltip,
  Button,
} from "antd";
import {
  FileSyncOutlined,
  StarOutlined,
  StarFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Actions
 */
import {
  allCategoriesWithObj,
  addHitByObj,
  searchAllByObj,
  pushObjInFavorite,
  pullObjInFavorite,
} from "auth/actions/objActions";
import { getUserDetails } from "auth/actions/userActions";

/**
 * Misc
 */
const { Content } = Layout;
const { confirm } = Modal;

function AllForms({ user }) {
  const history = useHistory();
  const location = useLocation();
  const [forms, setForms] = useState([]);
  const [cleanForms, setCleanForms] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [formFavorites, setFormFavorites] = useState();

  const sortForms = (forms, favorites) => {
    let sortedForms = [...forms];
    sortedForms.sort((a, b) => {
      const aIsFavorite = isFormFavorite(a._id, favorites);
      const bIsFavorite = isFormFavorite(b._id, favorites);
      if (aIsFavorite && !bIsFavorite) {
        return -1;
      } else if (!aIsFavorite && bIsFavorite) {
        return 1;
      } else {
        // Se ambos são favoritos ou não favoritos, classifique por 'hit'
        return b.hit - a.hit;
      }
    });
    return sortedForms;
  };

  const newForm = () => {
    history.push({
      pathname: `/formularios/novo`,
    });
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      getUserDetails({
        id: user.uuid,
      }).then((res) => {
        setFormFavorites(res.message[0].favorites || []);
        allCategoriesWithObj()
          .then((items) => {
            var results = items.message.map((values) => ({
              _id: values._id,
              name: values.name,
              shortName: values.name.replace("obj_", ""),
              categories: values.categories.join(", "),
            }));
            let sortedForms = sortForms(
              results,
              res.message[0].favorites || []
            );
            setCleanForms(sortedForms);
            setForms(sortedForms);
          })
          .catch((err) =>
            notification["error"]({
              message: "Error",
              description: err.message
                ? err.message
                : "Something went wrong, please try again!",
            })
          );
      });
    }
  }, [user, location]);

  useEffect(() => {
    if (formFavorites) {
      let sortedForms = sortForms(forms, formFavorites);
      setForms(sortedForms);
    }
  }, [formFavorites]);

  const routes = [
    {
      breadcrumbName: "formulários",
    },
    {
      breadcrumbName: "todos",
    },
  ];

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);

    if (searchValue === "") {
      // Se a string de pesquisa está vazia, basta configurar 'forms' de volta para 'cleanForms'
      setForms(cleanForms);
    } else {
      // Caso contrário, faça a busca e atualize o estado de 'forms' com os resultados
      searchAllByObj({
        q: searchValue,
      })
        .then((response) => {
          setForms(
            response.message.map((values) => ({
              _id: values._id,
              name: values.name,
              shortName: values.name.replace("obj_", ""),
              categories: values.categories.join(", "),
            }))
          );
        })
        .catch((err) =>
          notification["error"]({
            message: "Erro",
            description: err.message
              ? err.message
              : "Algo está errado, tente novamente!",
          })
        );
    }
  };

  const FilterByNameInput = (
    <Input
      placeholder="Pesquisar por nome"
      value={searchText}
      onChange={handleSearch}
    />
  );
  const pushHit = ({ id, index, name, route }) => {
    addHitByObj({
      id: id,
      index: index,
    })
      .then(async (response) => {
        const value = encodeURIComponent(name);
        history.push(`/formularios/${route}/${value}`);
      })
      .catch((err) =>
        notification["error"]({
          message: "Erro",
          description: err.message
            ? err.message
            : "Algo está errado, tente novamente!",
        })
      );
  };

  const handleFavorite = async ({ formId, push }) => {
    if (push) {
      try {
        await pushObjInFavorite({
          formId,
          userId: user.uuid,
          type: "form",
        });

        // Atualizando o estado local
        setFormFavorites([...formFavorites, { id: formId, type: "form" }]);
      } catch (err) {
        notification["error"]({
          message: "Erro",
          description: err.message
            ? err.message
            : "Algo está errado, tente novamente!",
        });
      }
    } else {
      try {
        await pullObjInFavorite({
          formId,
          userId: user.uuid,
          type: "form",
        });

        // Atualizando o estado local
        setFormFavorites(formFavorites.filter((fav) => fav.id !== formId));
      } catch (err) {
        notification["error"]({
          message: "Erro",
          description: err.message
            ? err.message
            : "Algo está errado, tente novamente!",
        });
      }
    }
  };

  function isFormFavorite(formId, favorites) {
    return favorites
      ? favorites.some(
          (favorite) => favorite.id === formId && favorite.type === "form"
        )
      : false;
  }

  const columns = [
    {
      // title: FilterByNameInput,
      title: "Formulário",
      dataIndex: "shortName",
      key: "shortName",
      render: (text, record) => (
        <>
          {formFavorites && (
            <Button
              size="small"
              type="link"
              shape="circle"
              icon={
                _.size(
                  formFavorites
                    .filter((values) => values.type === "form")
                    .filter((obj) => obj.id === record._id)
                ) > 0 ? (
                  <StarFilled />
                ) : (
                  <StarOutlined />
                )
              }
              onClick={() => {
                const favCount = _.size(
                  formFavorites
                    .filter((values) => values.type === "form")
                    .filter((obj) => obj.id === record._id)
                );

                if (favCount && favCount > 0) {
                  handleFavorite({
                    formId: record._id,
                    push: false,
                  });
                } else {
                  handleFavorite({
                    formId: record._id,
                    push: true,
                  });
                }
              }}
            />
          )}
          <Avatar
            size="small"
            icon={<FileSyncOutlined />}
            style={{
              backgroundColor: "#12b398",
            }}
          />
          <span
            style={{
              marginLeft: 15,
            }}
          >
            {text}
          </span>
        </>
      ),
    },
    {
      title: "Categorias",
      dataIndex: "categories",
      key: "categories",
    },
    {
      title: "Ações",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            href
            onClick={() =>
              pushHit({
                id: record._id,
                index: "forms",
                name: record.name,
                route: "detalhes",
              })
            }
          >
            Configurações
          </a>
          <a
            href
            onClick={() =>
              pushHit({
                id: record._id,
                index: "forms",
                name: record.name,
                route: "registros",
              })
            }
          >
            Registros
          </a>
        </Space>
      ),
    },
  ];

  return (
    <Layout
      style={{
        height: "110vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh"}}>
            <PageHeader
              style={{ margin: "16px 0 5px 0" }}
              title="Lista de formulários"
              subTitle="Utilize a biblioteca de formulários para administrar novos campos e funções."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                background: "white",
              }}
            >
              <Row gutter={16} justify="end">
                <Col span={10}>
                  <Input
                    placeholder="Pesquisar por nome"
                    value={searchText}
                    onChange={handleSearch}
                  />
                </Col>
                <Col md={{ span: 7 }} xl={{ span: 6 }} xxl={{ span: 4 }}>
                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={newForm}
                      style={{ width: "100%" }}
                    >
                      Adicionar Formulário
                      <PlusCircleOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Table
                    columns={columns}
                    dataSource={forms}
                    locale={{
                      emptyText: (
                        <Empty description="Nenhum formulário adicionado." />
                      ),
                    }}
                  />
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps)(AllForms);
