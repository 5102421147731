import {connect} from "react-redux";
import {Button, Drawer, Input, Menu, Space, Collapse, List, Divider, Tooltip} from "antd";
import {openOrCloseModalSnippets, updateActionFromAutomation} from "../../../../../ducks/automation";
import {ProCard} from "@ant-design/pro-components";
import {materialLight} from "@uiw/codemirror-theme-material";
import {json} from "@codemirror/lang-json";
import CodeMirror, {basicSetup} from "@uiw/react-codemirror";
import {autocompletion} from "@codemirror/autocomplete";
import React, {useEffect, useRef, useState} from "react";
import {InfoCircleOutlined, SearchOutlined} from "@ant-design/icons"
import {BsFillPlayCircleFill} from "react-icons/bs";
import {getNameFromAction, getNameFromActionToSnippets, getNameToSnippets} from "../Actions/utils/functions";
import SnippetSections from "./SnippetSection";
import _ from "lodash";

const Snippets = ({actions, actionStep, trigger, snippets, onOpenOrCloseModalSnippets,onUpdateActionFromAutomation}) => {


    const [code, setCode] = useState("");
    const editorRef = useRef();
    const {field, data, showModal} = snippets
    const {label, name,isCode = false,acceptEntryLoop = true} = field;


    useEffect(() => {
        if (actions[actionStep] && actions[actionStep]['config'] && actions[actionStep]['config'][name]) {
            const dataInput = actions[actionStep]['config'][name];
            setCode(dataInput)
        }else{
            if(actions[actionStep] && name && name.includes(".")){
                setCode(getCode(name))
            }else {
                setCode("")
            }

        }
    }, [actions, actionStep, name]);

    const closeDrawer = (e) => {
        onOpenOrCloseModalSnippets({action: false})
    }

    const insertSnippetHelper = (snippet, value)  => {
        if (editorRef.current) {
            let snippetsToInsert = `{{${value.requiresBlock ? "#":""}${snippet}${value.requiresBlock ? " ":""}}}`;
            let lengthToPosition = 0
            if(value.requiresBlock){
                 lengthToPosition = snippetsToInsert.length -2;
            }else{
                 lengthToPosition = snippetsToInsert.length + 2;
            }


            if(value.requiresBlock){
                snippetsToInsert = `${snippetsToInsert}{{/${snippet}}}`;
            }

            const editor = editorRef.current.view;
            const insertPos = editor.state.selection.main.head;
            const transaction = editor.state.update({
                changes: {from: insertPos, insert: snippetsToInsert},
            });
            editor.dispatch(transaction);

            let cursorPos = insertPos + lengthToPosition;
            editor.dispatch({
                selection: {anchor: cursorPos, head: cursorPos},
            });
            editor.focus();
        }

    }

    const insertSnippets = (snippet, action = null, step, propFromLoop = false) => {
        if (editorRef.current) {
            let snippetFinal = getNameToSnippets(step, action, isCode)
            const editor = editorRef.current.view;
            const insertPos = editor.state.selection.main.head;
            let  functionNameWithParentheses = "";
            if(propFromLoop){
                functionNameWithParentheses = isCode ? snippet : `{{ ${snippet} }}` ;
            }else{
                const tempSnippet = needsBrackets(snippet) ? `['${snippet}']` : snippet;
                functionNameWithParentheses = isCode ? `${snippetFinal}['${snippet}']` : `{{ ${snippetFinal}.${tempSnippet} }}` ;
            }


            const transaction = editor.state.update({
                changes: {from: insertPos, insert: functionNameWithParentheses},
            });
            editor.dispatch(transaction);

            let cursorPos = insertPos + functionNameWithParentheses.length;
            editor.dispatch({
                selection: {anchor: cursorPos, head: cursorPos},
            });
            editor.focus();
        }
    }

    const getCode = (name) =>{
        const action = actions[actionStep];
        let formData = { ...action.config };
        const keys = name.split('.');
        let temp = formData;
        for (let i = 0; i < keys.length - 1; i++) {
            if (!temp[keys[i]]) {
                temp[keys[i]] = {};
            }
            temp = temp[keys[i]];
        }
        return _.toString(temp[keys[keys.length - 1]]) || "";
    }

    function saveConfig(e) {
        const action = actions[actionStep];
        let formData = { ...action.config };

        if (name.includes('.')) {
            const keys = name.split('.');
            let temp = formData;
            for (let i = 0; i < keys.length - 1; i++) {
                if (!temp[keys[i]]) {
                    temp[keys[i]] = {};
                }
                temp = temp[keys[i]];
            }
            temp[keys[keys.length - 1]] = code;
        } else {
            formData[name] = code;
        }

        const data = formData;
        onUpdateActionFromAutomation({ step: actionStep, data });
        closeDrawer();
    }

    const  needsBrackets = (propertyName) =>  {
        const specialChars = /[^a-zA-Z0-9_$]/;
        const startsWithDigit = /^\d/;
        return specialChars.test(propertyName) || startsWithDigit.test(propertyName);
    }

    return (
        <Drawer
            size={"large"}
            placement={"bottom"}
            destroyOnClose
            title={label}
            onClose={closeDrawer}
            visible={showModal}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={closeDrawer}>Cancelar</Button>
                    <Button type="primary" onClick={saveConfig}>
                        Salvar
                    </Button>
                </Space>
            }
        >

            <ProCard split={'vertical'}
                     bordered
                     style={{height: "100%"}}
            >
                <ProCard colSpan={10} title={"Adicione vinculações ou use o menu à direita"}>
                    <CodeMirror
                        style={{flexGrow: 1}}
                        height="400px"
                        width="100%"
                        ref={editorRef}
                        theme={materialLight}
                        value={code}
                        extensions={[
                            json(),
                            basicSetup(),
                            autocompletion(),
                        ]}
                        onChange={(value) => {
                            setCode(value);
                        }}
                    />
                </ProCard>
                <ProCard colSpan={14} title={"Anotações"} style={{height: "100%", overflowY: 'auto'}}>
                   <SnippetSections insertSnippetHelper={insertSnippetHelper} insertSnippets={insertSnippets} hideHelpers={isCode} acceptEntryLoop={acceptEntryLoop}/>
                </ProCard>
            </ProCard>

        </Drawer>

    )
}
const mapStateToProps = ({automation}) => ({
    actions: automation.actions,
    actionStep: automation.actionStep,
    trigger: automation.trigger,
    snippets: automation.snippets
});

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenOrCloseModalSnippets: ({action, fieldName, fieldLabel}) => dispatch(openOrCloseModalSnippets({
            action,
            fieldName,
            fieldLabel
        })),
        onUpdateActionFromAutomation: ({step, data}) => dispatch(updateActionFromAutomation({step, data}))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Snippets);
