// Types
import {reportAPI} from "../services/actions/reports-v2";

export const START_GET_USER_REPORT_DATA = 'core/report/START_GET_USER_REPORT_DATA';
export const RESET_USER_REPORT_DATA = 'core/report/RESET_USER_REPORT_DATA';
export const END_GET_USER_REPORT_DATA = 'core/report/END_GET_USER_REPORT_DATA';
export const ERROR_GET_USER_REPORT_DATA = 'core/report/ERROR_GET_USER_REPORT_DATA';

// Reducer
const initialState = {
  user: localStorage.getItem('user-report') ? JSON.parse(localStorage.getItem('user-report')) : null,
  loading: false,
  error: null,
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_USER_REPORT_DATA:
      return initialState;
    case START_GET_USER_REPORT_DATA:
      return {
        ...state,
       loading: true,
        error: null,
      };
    case END_GET_USER_REPORT_DATA:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    case ERROR_GET_USER_REPORT_DATA:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}


export const getUserReportData = () => async (dispatch) => {
  dispatch({type: START_GET_USER_REPORT_DATA});
  try {
    const {status, data} = await reportAPI.getUserData();
    if (200 === status) {
      dispatch({type: END_GET_USER_REPORT_DATA, payload: data});
    } else {
      throw new Error(data);
    }
  } catch (error) {
    dispatch({type: ERROR_GET_USER_REPORT_DATA, payload: error.message});
  }
}

export const clearSessionReport = () => async (dispatch) =>{
  localStorage.removeItem('user-report');
  dispatch({type: RESET_USER_REPORT_DATA});
}
