import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Table,
  Space,
  Modal,
  Select,
  Tag,
  Input,
  Button,
} from "antd";
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import { allCategoriesObj, removeCategoryObj } from "auth/actions/objActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import {useSelector} from "react-redux";

/**
 * Misc
 */
const { Content } = Layout;
const { confirm } = Modal;

function AllFormCategories() {
  const user = useSelector((state) => state.session.user);
  const history = useHistory();
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [auxCategory, setAuxCategory] = useState("");
  const [filtro, setFiltro] = useState("");

  useEffect(() => {
    allCategoriesObj().then((items) => {
      setAuxCategory(items.message);
      setCategories(items.message);
    });
  }, []);

  useEffect(() => {
    const data = location.state;
    if (data) {
      setCategories(data.categories);
    }
  }, [location.state, categories]);

  const searchCategory = (pesquisa) => {
    const currValue = pesquisa;
    setFiltro(currValue);
    if (pesquisa === "") {
      setCategories(auxCategory);
    } else {
      const filteredData = auxCategory.filter(
        (entry) => entry.name.toLowerCase().includes(currValue.toLowerCase()) // Convert the userName to lowercase for comparison
      );
      setCategories(filteredData);
    }
  };

  const routes = [
    {
      breadcrumbName: "categorias",
      href: "/formularios/categoria/todas",
    },
    {
      breadcrumbName: "todas",
    },
  ];
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cor",
      dataIndex: "color",
      key: "color",
      render: (record) => (
        <Tag color={record !== undefined ? record.hex : "#03A9F4"}>
          {record !== undefined ? record.hex.toUpperCase() : "#03A9F4"}
        </Tag>
      ),
    },
    {
      title: "Ações",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a href onClick={() => handleRequest(record)}>
            Editar
          </a>
          <a href onClick={() => handleDelete(record)}>
            Remover
          </a>
        </Space>
      ),
    },
  ];

  /**
   * Redericiona para todas categorias
   * @param {*} info
   */
  const handleRequest = (info) => {
    history.push(`/formularios/categoria/editar/${info._id}`);
  };

  const newCategory = () => {
    history.push({
      pathname: `/formularios/categoria/nova`,
    });
  };

  /**
   * Remove categoria
   * @param {*} record
   */
  function handleDelete(record) {
    confirm({
      title: `Você realmente deseja excluir categoria?`,
      icon: <ExclamationCircleOutlined />,
      content: "Essa ação não pode ser desfeita.",
      okText: "Confirmar",
      onOk() {
        removeCategoryObj({...record,deletedBy: user.uuid}).then((res) => {
          window.location.reload();
        });
      },
      cancelText: "Cancelar",
    });
  }

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0 5px 0" }}
              title="Lista de categorias"
              subTitle="As categorias são utilizadas para classificar e organizar seus formulários."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16} justify="end">
                <Col span={10}>
                  <Input
                    placeholder="Pesquisar por nome"
                    value={filtro}
                    onChange={(e) => {
                      searchCategory(e.target.value);
                    }}
                  />
                </Col>
                <Col  md={{ span: 7 }} xl={{ span: 6 }} xxl={{ span: 4 }}>
                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={newCategory}
                      style={{ width: "100%" }}
                    >
                      Adicionar Categoria
                      <PlusCircleOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Table columns={columns} dataSource={categories} />
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

export default AllFormCategories;
