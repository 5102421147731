import { Input, Typography, Row, Col, Form, Button } from 'antd';
import cronstrue from 'cronstrue/i18n';
import {useEffect, useState} from "react";

const { Text } = Typography;

export default function CronTrigger({ trigger, onChange }) {

    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [cronDescription, setCronDescription] = useState('');

    useEffect(() => {
        const initialValues = trigger.config ? trigger.config : null;
        if (initialValues && initialValues.cron) {
            const [minute, hour, dayOfMonth, month, dayOfWeek] = initialValues.cron.split(" ").map(x => x.trim());
            form.setFieldsValue({minute, hour, dayOfMonth, month, dayOfWeek});
        }
    },[trigger])

    const handleFinish = (e) => {
       const values = form.getFieldsValue()
        const { minute, hour, dayOfMonth, month, dayOfWeek } = values;
        const cronExpression = `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
        try {
            const description = cronstrue.toString(cronExpression, { locale: "pt_BR" });
            onChange({
                cron:cronExpression,
            })
            setCronDescription(description);
            setError('');
        } catch (error) {
            setError('Expressão de cron inválida. Verifique e tente novamente.');
            setCronDescription('');
        }
    };


    return (
        <div>
            <Form form={form} onValuesChange={handleFinish}  layout="vertical">
                <Row gutter={8}>
                    <Col span={4}>
                        <Form.Item initialValue={"*"} name="minute" label="Minuto" >
                            <Input placeholder="Minuto" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item initialValue={"*"} name="hour"  label="Hora" >
                            <Input placeholder="Hora"  />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item initialValue={"*"} name="dayOfMonth" label="Dia do mês" >
                            <Input placeholder="Dia do mês" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item initialValue={"*"} name="month" label="Mês" >
                            <Input placeholder="Mês" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item initialValue={"*"} name="dayOfWeek" label="Dia da semana" >
                            <Input placeholder="Dia da semana" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {error && <Text type="danger">{error}</Text>}
            {cronDescription && (
                <div style={{ marginTop: 20 }}>
                    <Text>{cronDescription}</Text>
                </div>
            )}
        </div>
    );
}
