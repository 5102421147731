import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Typography,
  Radio,
  TreeSelect,
  Modal,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";

/**
 * Actions
 */
import { allObjects, addFields } from "auth/actions/objActions";

/**
 * Components
 */
import { LayoutTopSide } from "components/NavBar/styles";
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { Docs } from "assets/icons";
import CheckBoxForm from "components/Forms/SignIn/Fields/New/CheckBoxForm";
import { QuickStartDecorator, PageHeaderDecorator } from "assets/icons";

import TreeTransfer from "components/Forms/SignIn/Fields/Ref/TranferTree.misc";

/**
 * Utils
 */
import {
  handleCkHidden,
  handleRfHidden,
  handleDtHidden,
  handleDtRules,
  removeRef,
} from "utils/form-params";

/**
 * Misc
 */
const { Content } = Layout;
const { Paragraph } = Typography;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const { confirm } = Modal;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function NewField() {
  const [form] = Form.useForm();
  const history = useHistory();
  const formValues = Form.useWatch("fields", form);
  const [allForms, setAllForms] = useState([]);

  useEffect(() => {
    let mounted = true;
    try {
      if (allForms.length > 0) {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    allObjects().then((items) => {
      if (mounted) {
        setAllForms(items.message);
      }
    });
    return () => (mounted = false);
  }, [allForms]);

  /**
   * Classifica e organiza objetos
   * @param {*} values
   * @returns
   */
  function trySortObjects(values) {
    return _(values)
      .map((value, key) => ({
        index: Number(key),
        field: value.field,
        fieldtype: value.fieldtype,
        checkBox: value.checkBox
          ? value.checkBox.reduce(
              (obj, item) =>
                Object.assign(obj, {
                  [Object.keys(obj).length]: item.currentValue,
                }),
              {}
            )
          : null,
        refOpt: value.refOpt,
        dica: value.dica,
        sufixo: value.sufixo,
        autoFill: value.autoFill,
        marker: value.marker,
        requiredField: value.requiredField
      }))
      .value();
  }

  /**
   * Adiciona campo ao formulário
   * @param {*} values
   */
  const onFinish = (values) => {
    let posição = allForms.length + 1;
    const formname = "obj_" + values.form;
    const updatedValues = values.fields.map((campos, idx) => {
      posição++;
      // verifica se esta tentando adicionar uma checkbox
      return { ...campos, index: posição };
    });
    const filteredValues = trySortObjects(updatedValues);
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    if (values.fields.length > 0) {
      addFields({ formName: formname, fields: filteredValues }).then(
        async () => {
          await sleep(500);
          window.location.reload();
        }
      );
    }
  };

  const IconLink = ({ src, text, link }) => (
    <a
      style={{ marginTop: -3, marginRight: 16, lineHeight: 2.5 }}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <img
        className="example-link-icon"
        src={src}
        alt={text}
        style={{ marginRight: 8 }}
        width={24}
      />
      {text}
    </a>
  );
  const routes = [
    {
      breadcrumbName: "formulários",
      href: "/formularios/todos",
    },
    {
      breadcrumbName: "campos",
    },
    {
      breadcrumbName: "novo",
    },
  ];
  const content = (
    <>
      <Paragraph>
        Você pode adicionar outros campos de entrada a um formulário existente.
      </Paragraph>
      <Paragraph>
        Cada campo de entrada se vincula a um campo no objeto ao qual o
        formulário está conectado.
      </Paragraph>
      <div>
        <IconLink
          src={QuickStartDecorator}
          text="Como começar"
          link="https://cantoporto.gitbook.io/sac/guides/criando-primeiro-campo-para-seu-formulario"
        />
        <IconLink
          src={Docs}
          text="Documentação"
          link={`${process.env.REACT_APP_API_PROD}/docs`}
        />
      </div>
    </>
  );
  const ContentAlternative = ({ children, extraContent }) => (
    <Row>
      <div style={{ flex: 1 }}>{children}</div>
      <div className="image">{extraContent}</div>
    </Row>
  );

  return (
    <Layout
      style={{
        height: "auto",
        background: "#f0f2f5",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", height: "auto" }}>
            <PageHeader
              className="site-page-header"
              style={{ margin: "16px 0 16px 0", background: "#fff" }}
              title="Novo campo ao formulário"
              subTitle="Construa campos para seus formulários personalizados."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            >
              <ContentAlternative
                extraContent={
                  <img src={PageHeaderDecorator} alt="content" width="100%" />
                }
              >
                {content}
              </ContentAlternative>
            </PageHeader>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16}>
                <Col span={18}>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                    type="flex"
                    justify="center"
                  >
                    <Form.Item
                      name="form"
                      label="Formulário"
                      rules={[
                        {
                          required: true,
                          message: "Precisamos saber qual formulário deseja!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Selecione ou pesquise por formulários"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {allForms.map((item) => (
                          <Option
                            value={item.name.slice(4)}
                            key={item.name.slice(4)}
                          >
                            {item.name.slice(4)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.List name="fields">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }) => (
                              <React.Fragment key={key}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "field"]}
                                  label="Nome do campo"
                                  tooltip="Este será o nome que o campo terá no formulário!"
                                  fieldKey={[fieldKey, "field"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Por favor insira um nome para este campo!",
                                    },
                                    {
                                      pattern: /^[^.\n]*$/,
                                      message: "Este campo não pode conter o caractere ponto (.)",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Ex: Dia de nascimento" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "dica"]}
                                  label="Dica"
                                  tooltip="Este campo sera usado como dica para o usuário!"
                                  fieldKey={[fieldKey, "placeholder"]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "fieldtype"]}
                                  label="Tipo"
                                  fieldKey={[fieldKey, "fieldtype"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Precisamos saber tipo do campo!",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Selecione tipo do campo">
                                    <Option value="Text">Texto</Option>
                                    <Option value="Numeric">Númerico</Option>
                                    <Option value="multipleSelection">
                                      Seleção Multipla
                                    </Option>
                                    <Option value="refMultipleSelection">
                                      Seleção Multipla Referenciado
                                    </Option>
                                    <Option value="uniqueSelection">
                                      Seleção Única
                                    </Option>
                                    <Option value="refUniqueSelection">
                                      Seleção Única Referenciada
                                    </Option>
                                    <Option value="Date">
                                      Seleção de Data
                                    </Option>
                                    <Option value="Time">
                                      Seleção de Tempo
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  name={[name, "refOpt"]}
                                  label="Formulários e campos"
                                  fieldKey={[fieldKey, "refOpt"]}
                                  hidden={
                                    formValues
                                      ? formValues[name]?.fieldtype
                                        ? handleCkHidden(
                                            formValues[name].fieldtype
                                          )
                                        : true
                                      : true
                                  }
                                >
                                  {formValues ? (
                                    formValues[name]?.fieldtype ? (
                                      <TreeTransfer
                                        type={removeRef(
                                          formValues[name].fieldtype
                                        )}
                                      />
                                    ) : null
                                  ) : null}
                                </Form.Item>
                                <Form.Item
                                  wrapperCol={{ span: 24, offset: 5 }}
                                  hidden={
                                    formValues
                                      ? formValues[name]?.fieldtype
                                        ? handleRfHidden(
                                            formValues[name].fieldtype
                                          )
                                        : true
                                      : true
                                  }
                                >
                                  <CheckBoxForm fieldKey={name} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "marker"]}
                                  label="Etiqueta"
                                  tooltip="Destaca o campo na lista de lançamentos do aplicativo."
                                  fieldKey={[fieldKey, "marker"]}
                                  rules={
                                    formValues
                                      ? formValues[name]?.fieldtype
                                        ? handleDtRules(
                                            formValues[name].fieldtype
                                          )
                                        : handleDtRules()
                                      : handleDtRules()
                                  }
                                >
                                  <Radio.Group
                                    disabled={
                                      formValues
                                        ? formValues[name]?.fieldtype
                                          ? handleDtHidden(
                                              formValues[name].fieldtype
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    <Radio value={true}>Sim</Radio>
                                    <Radio value={false}>Não</Radio>
                                  </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "autoFill"]}
                                  label="Preenchimento"
                                  tooltip="Campo será preenchido com último registro enviado!"
                                  fieldKey={[fieldKey, "autoFill"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Precisamos saber se você deseja que este campo preenchido com último registro enviado!",
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    <Radio value={true}>Sim</Radio>
                                    <Radio value={false}>Não</Radio>
                                  </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "requiredField"]}
                                  label="Campo obrigatório"
                                  tooltip="Campo obrigatório para preenchimento no formulário enviado!"
                                  fieldKey={[fieldKey, "requiredField"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Precisamos saber se você deseja que este campo sejá obrigatório ou não!",
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    <Radio value={true}>Sim</Radio>
                                    <Radio value={false}>Não</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              
                                <Form.Item {...tailFormItemLayout}>
                                  <Button
                                    wrapperCol={{ span: 14, offset: 4 }}
                                    type="danger"
                                    onClick={() => remove(name)}
                                    block
                                    icon={<MinusCircleOutlined />}
                                  >
                                    Remover campo
                                  </Button>
                                </Form.Item>
                              </React.Fragment>
                            )
                          )}

                          <Form.Item {...tailFormItemLayout}>
                            <Button
                              wrapperCol={{ span: 14, offset: 4 }}
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Adicionar campo
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item {...tailFormItemLayout}>
                      <Button type="primary" htmlType="submit">
                        Adicionar campos ao formulário
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Content>
            <div style={{ height: "20vh" }}></div>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}
