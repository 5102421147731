import React, {useState} from "react";
import {connect} from "react-redux";
import {
    Layout,
    PageHeader,
    Breadcrumb,
    Button,
    ConfigProvider,
    Avatar,
    Card,
    Tooltip, notification, Dropdown, Row, Col, Form, Input, Checkbox, Typography,
} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {FileSyncOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import ptBRIntl from "antd/lib/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import {allActivitiesForms} from "auth/actions/activitieActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import {LayoutTopSide} from "components/NavBar/styles";
import {createAutomation} from "../../../auth/actions/automationsActions";



function NewAutomation({user}) {
    const history = useHistory();

    const routes = [
        {
            breadcrumbName: "Automações",
            href: "/automacoes",
        },
        {
            breadcrumbName: "Nova",
            href: "/automacoes/nova",
        },
    ];

    const { Content } = Layout;
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const [form] = Form.useForm();
    const onFinish = async (data) => {
        if(!loading){
            setLoading(true)
            const {name, description= null} = data;
            const response = await createAutomation({name, description,active:false});
            setLoading(false);
            history.push(`/automacoes/editar/${response.id}`);
        }


    }

    const [loading, setLoading] = useState(false);
    return (
        <Layout>
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout
                        style={{
                            padding: "0 24px 24px",
                            minHeight: "100vh",
                        }}
                    >
                        <PageHeader
                            style={{margin: "16px 0 5px 0"}}
                            title="Nova Automação"
                            onBack={() => window.history.back()}
                            breadcrumb={
                                <Breadcrumb>
                                    {routes.map((a) =>
                                        a.hasOwnProperty("href") ? (
                                            <Breadcrumb.Item key={Math.random()}>
                                                <a href onClick={() => history.push(a.href)}>
                                                    {a.breadcrumbName}
                                                </a>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                                        )
                                    )}
                                </Breadcrumb>
                            }
                        />
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 46,
                                margin: 0,
                                minHeight: 280,
                                background: "white",
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={16}>
                                    <Form
                                        {...formItemLayout}
                                        form={form}
                                        name="register"
                                        onFinish={onFinish}
                                        scrollToFirstError
                                    >
                                        <Form.Item
                                            name="name"
                                            label="Nome da Automação"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Por favor insira um nome para esta automação!",
                                                    whitespace: true,
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="description"
                                            label="Descrição da Automação"
                                            tooltip={"Qual o proposito dessa automação"}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Por favor insira uma descrição dessa automação, não pode terminar em espaço em brancho!",
                                                    whitespace: true,
                                                }
                                            ]}
                                        >
                                            <Input.TextArea />
                                        </Form.Item>
                                        <Form.Item {...tailFormItemLayout}>
                                            <Button type="primary" htmlType="submit" loading={loading}>
                                                Adicionar Automação
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}

const mapStateToProps = ({session}) => ({
    user: session.user,
});

export default connect(mapStateToProps)(NewAutomation);
